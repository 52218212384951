import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FinishFlow } from '../../../../components/flow/finish';

export const RegistrationFinished: React.FC = () => {
  const history = useHistory();
  const [registrationDone, setRegistrationDone] = useState(false);
  const { registrationSuccess, registrationFailed, registrationWorking } = useSelector(state => state.registration);

  if (!registrationDone || registrationWorking) {
    return (
      <FinishFlow
        timeout={3000}
        onTimeout={() => {
          setRegistrationDone(true);
        }}
        headline="Ihre Daten werden sicher übermittelt."
      />
    );
  }

  if (registrationSuccess) {
    return (
      <FinishFlow
        hideLoader={true}
        timeout={10000}
        onTimeout={() => {
          history.push('/');
        }}
        headline="Registrierungsanfrage wurde verschickt."
        text="Wir haben Ihre Registrierungsdaten erhalten und setzen uns in Kürze mit Ihnen in Verbindung."
      />
    );
  }

  if (registrationFailed) {
    return (
      <FinishFlow
        hideLoader={true}
        timeout={10000}
        onTimeout={() => {
          history.push('/');
        }}
        headline="Fehler bei der Registrierung."
        text="Leider ist ein Fehler bei der Registrierung aufgetreten. Bitte versuchen Sie es später erneut. Sie werden in Kürze weitergeleitet."
      />
    );
  }
  return <></>;
};
