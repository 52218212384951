import { Bx, IBxProps, ICtnProps, LayoutImage, Typo, useBreakpoints } from '@curry-group/mui-curcuma';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/pro-light-svg-icons';

import Grid, { GridProps } from '@material-ui/core/Grid';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { ILandingPageFooterProps, LandingPageFooter } from '../../../../components/landingpage/footer';
import { LandingPageHeader } from '../../../../components/landingpage/header';
import { LandingPageKeyVisual } from '../../../../components/landingpage/keyvisual';
import { LandingPageQuoteSection } from '../../../../components/landingpage/quote-section';
import { LandingPageSectionWrapper } from '../../../../components/landingpage/section-wrapper';
import { LandingPageTabSection } from '../../../../components/landingpage/tab-section';
import { LandingPageTextSection } from '../../../../components/landingpage/text-section';
import { LandingPageImageSection } from '../../../../components/landingpage/image-section';
import { LandingPageSplitSection } from '../../../../components/landingpage/split-section';
import { Sponsors } from '../../../../components/landingpage/sponsors';

const useStyles = makeStyles({
  line: {
    '&:after': {
      content: "''",
      height: '5px',
      width: '148px',
      display: 'block',
      background: 'linear-gradient(to right, #D50057 0%, #D50057 50%, #A00049 50%, #A00049 85%, #6D0032 85%, #6D0032 100%)',
      margin: '40px 0 40px 0'
    }
  },
  boldText: {
    '& b': {
      fontWeight: 900
    }
  }
});

export const PublicLandingPageView: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles();

  // Responsive Design
  const responsive = useBreakpoints();
  const isXLUp = responsive.xlUp || false;
  const isMDUp = responsive.mdUp || false;
  const isXS = responsive.xs || false;
  // General Landingpage configuration
  const ctnMaxWidth: ICtnProps['maxWidth'] = 'lg';
  const ctnMaxWidthHeader: ICtnProps['maxWidth'] = 'xl';

  const h1FontSize = isMDUp ? 43 : 32;
  const keyvisualFontSize = isMDUp ? 72 : 48;
  const body4FontSize = isMDUp ? 20 : 16;
  const sectionPy: IBxProps['py'] = { xs: 6, md: 10 };
  const keyVisualSectionPy: IBxProps['py'] = { xs: 6, md: 20 };
  const sectionPx: IBxProps['px'] = { xs: 4, md: 4 };
  const innerSectionSpacing: GridProps['spacing'] = isMDUp ? 10 : 5;
  const quoteIconSize = isMDUp ? 48 : 24;

  // Scroll handling
  const [scrolled, setScrolled] = React.useState(false);
  React.useEffect(() => {
    function setScrolling() {
      if (scrolled && window.scrollY < 100) {
        setScrolled(false);
      } else if (!scrolled && window.scrollY >= 100) {
        setScrolled(true);
      }
    }

    setScrolling();

    window.addEventListener('scroll', setScrolling);

    return () => {
      window.removeEventListener('scroll', setScrolling);
    };
  });

  // Slider Configuration
  const [, setQuoateCarouselLoad] = React.useState<number>(0);
  const onQuoteImageLoaded = () => {
    setQuoateCarouselLoad(Date.now());
  };

  const quoteItems: React.ReactElement[] = [
    <Bx style={{ borderRadius: theme.shape.borderRadius4, overflow: 'hidden' }}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <LayoutImage
            src="images/landingpage/zitate.svg"
            alt="Bild von Schüler"
            width="100%"
            height="100%"
            objectFit="cover"
            objectPosition="center"
            onLoadCallback={onQuoteImageLoaded}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Bx
            bgcolor="primary.main"
            color="primary.contrastText"
            height="100%"
            py={{ xs: 3, md: 6 }}
            px={{ xs: 2, md: 4 }}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Bx>
              <Bx color="accent.main" style={{ fontSize: quoteIconSize }}>
                <FontAwesomeIcon icon={faQuoteRight} />
              </Bx>
              <Bx pl={{ xs: 3, md: 7 }} pr={{ xs: 2, md: 6 }}>
                <Typo fontWeight={500} variant="body1" component="blockquote" style={{ fontSize: body4FontSize }}>
                  ... das Vorhaben klingt sehr durchdacht. Es zu verwirklichen ist ein Gewinn für die Gesellschaft.
                </Typo>
              </Bx>
              <Bx color="accent.main" style={{ fontSize: quoteIconSize }} textAlign="right">
                <FontAwesomeIcon icon={faQuoteLeft} />
              </Bx>
            </Bx>
            <Bx mt={{ xs: 2, md: 5 }} pl={{ xs: 3, md: 7 }} pr={{ xs: 2, md: 6 }}>
              <Typo variant="body2" fontWeight={500}>
                U. Aßfalg
              </Typo>
              {/* <Typo variant="body2">Tutor für Englisch und Physik</Typo> */}
            </Bx>
          </Bx>
        </Grid>
      </Grid>
    </Bx>,
    <Bx style={{ borderRadius: theme.shape.borderRadius4, overflow: 'hidden' }}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <LayoutImage
            src="images/landingpage/zitate.svg"
            alt="Bild von Schüler"
            width="100%"
            height="100%"
            objectFit="cover"
            objectPosition="center"
            onLoadCallback={onQuoteImageLoaded}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Bx
            bgcolor="primary.main"
            color="primary.contrastText"
            height="100%"
            py={{ xs: 3, md: 6 }}
            px={{ xs: 2, md: 4 }}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Bx>
              <Bx color="accent.main" style={{ fontSize: quoteIconSize }}>
                <FontAwesomeIcon icon={faQuoteRight} />
              </Bx>
              <Bx pl={{ xs: 3, md: 7 }} pr={{ xs: 2, md: 6 }}>
                <Typo fontWeight={500} variant="body1" component="blockquote" style={{ fontSize: body4FontSize }}>
                  Heute habe ich von Ihrem Projekt der Tandem-Schulhilfe erfahren. Was für eine hilfreiche, gute Idee!
                </Typo>
              </Bx>
              <Bx color="accent.main" style={{ fontSize: quoteIconSize }} textAlign="right">
                <FontAwesomeIcon icon={faQuoteLeft} />
              </Bx>
            </Bx>
            <Bx mt={{ xs: 2, md: 5 }} pl={{ xs: 3, md: 7 }} pr={{ xs: 2, md: 6 }}>
              <Typo variant="body2" fontWeight={500}>
                B. Mitenzwei
              </Typo>
              {/* <Typo variant="body2">Schüler der 11. Klasse</Typo> */}
            </Bx>
          </Bx>
        </Grid>
      </Grid>
    </Bx>,
    <Bx style={{ borderRadius: theme.shape.borderRadius4, overflow: 'hidden' }}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <LayoutImage
            src="images/landingpage/zitate.svg"
            alt="Bild von Schüler"
            width="100%"
            height="100%"
            objectFit="cover"
            objectPosition="center"
            onLoadCallback={onQuoteImageLoaded}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Bx
            bgcolor="primary.main"
            color="primary.contrastText"
            height="100%"
            py={{ xs: 3, md: 6 }}
            px={{ xs: 2, md: 4 }}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Bx>
              <Bx color="accent.main" style={{ fontSize: quoteIconSize }}>
                <FontAwesomeIcon icon={faQuoteRight} />
              </Bx>
              <Bx pl={{ xs: 3, md: 7 }} pr={{ xs: 2, md: 6 }}>
                <Typo fontWeight={500} variant="body1" component="blockquote" style={{ fontSize: body4FontSize }}>
                  Ihr Projekt erscheint mir sehr sinnvoll. Habe selbst 2 Teenager, die mit den ganzen Schulschließungen versuchen über die Runden zu kommen.
                </Typo>
              </Bx>
              <Bx color="accent.main" style={{ fontSize: quoteIconSize }} textAlign="right">
                <FontAwesomeIcon icon={faQuoteLeft} />
              </Bx>
            </Bx>
            <Bx mt={{ xs: 2, md: 5 }} pl={{ xs: 3, md: 7 }} pr={{ xs: 2, md: 6 }}>
              <Typo variant="body2" fontWeight={500}>
                S. Schwanhäußer
              </Typo>
              {/* <Typo variant="body2">Schülerin der 10. Klasse</Typo> */}
            </Bx>
          </Bx>
        </Grid>
      </Grid>
    </Bx>,
    <Bx style={{ borderRadius: theme.shape.borderRadius4, overflow: 'hidden' }}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <LayoutImage
            src="images/landingpage/zitate.svg"
            alt="Bild von Schüler"
            width="100%"
            height="100%"
            objectFit="cover"
            objectPosition="center"
            onLoadCallback={onQuoteImageLoaded}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Bx
            bgcolor="primary.main"
            color="primary.contrastText"
            height="100%"
            py={{ xs: 3, md: 6 }}
            px={{ xs: 2, md: 4 }}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Bx>
              <Bx color="accent.main" style={{ fontSize: quoteIconSize }}>
                <FontAwesomeIcon icon={faQuoteRight} />
              </Bx>
              <Bx pl={{ xs: 3, md: 7 }} pr={{ xs: 2, md: 6 }}>
                <Typo fontWeight={500} variant="body1" component="blockquote" style={{ fontSize: body4FontSize }}>
                  Es ist wirklich toll, dass unseren Kindern so eine Möglichkeit geboten wird. Viel konnten wir, als Elternhaus, in dieser schwierigen Zeit glücklicherweise
                  abfedern, jedoch würden wir uns freuen, wenn wir unserem Sohn mit der Tandem-Schulhilfe eine weitere Stütze geben könnten.
                </Typo>
              </Bx>
              <Bx color="accent.main" style={{ fontSize: quoteIconSize }} textAlign="right">
                <FontAwesomeIcon icon={faQuoteLeft} />
              </Bx>
            </Bx>
            <Bx mt={{ xs: 2, md: 5 }} pl={{ xs: 3, md: 7 }} pr={{ xs: 2, md: 6 }}>
              <Typo variant="body2" fontWeight={500}>
                S Dolenek
              </Typo>
              {/* <Typo variant="body2">Schülerin der 10. Klasse</Typo> */}
            </Bx>
          </Bx>
        </Grid>
      </Grid>
    </Bx>
  ];

  const imageItems: {
    id: number;
    text: string;
    imageSrc: string;
    imageAlt: string;
  }[] = [
    {
      id: 1,
      text: 'Übersichtlicher und Barrierefreier <b>Tandem Chat</b>',
      imageSrc: 'images/landingpage/plattform-tandem.png',
      imageAlt: 'Ansicht eines Tandems'
    },
    {
      id: 2,
      text: 'Übersichtlicher und Barrierefreier <b>Tandem Videochat</b>',
      imageSrc: 'images/landingpage/plattform-chat.png',
      imageAlt: 'Ansicht des Chats'
    }
  ];

  const splitItems: {
    headline?: string;
    text: string;
    imageSrc: string;
    imageAlt: string;
    items: { children: React.ReactNode; icon: React.ReactNode }[];
    order: 'even' | 'odd';
  }[] = [
    {
      headline: '<b>Tandem & Regeln</b>',
      text: '<b>Wir verbinden die Tandems nach einem persönlichen Termin mit dem Orga-Team nach folgenden Kriterien:</b>',
      imageSrc: 'images/landingpage/tandem-und-regeln.svg',
      imageAlt: 'Zwei Personen, die einschlagen',
      items: [
        {
          children: <Typo style={{ fontSize: body4FontSize }}>Der Sprachfähigkeiten der Beteiligten</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{ fontSize: body4FontSize }}>Hobbies und Kompetenzen</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{ fontSize: body4FontSize }}>Präferierte Schulinhalte von Mentor*In und Mentee</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{ fontSize: body4FontSize }}>Erfahrung der Mentor*In</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{ fontSize: body4FontSize }}>Soziale und kommunikative Fähigkeiten</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        }
      ],
      order: 'odd'
    },
    {
      text: '<b>Wir legen Wert auf Community Regeln</b>',
      imageSrc: 'images/landingpage/community-regeln.svg',
      imageAlt: 'Person, die unterschreibt',
      items: [
        {
          children: <Typo style={{ fontSize: body4FontSize }}>Keine 3. Partei zum Treffen dazu holen ohne Absprache</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{ fontSize: body4FontSize }}>Zuverlässig Vereinbarungen einhalten</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{ fontSize: body4FontSize }}>Das Gesagte wird nicht nach außen getragen</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{ fontSize: body4FontSize }}>Aufsichtspflicht liegt nicht beim Mentor*In</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{ fontSize: body4FontSize }}>Beide Beteiligten sind für sich selbst verantwortlich</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{ fontSize: body4FontSize }}>Es gibt keine Erfolgsgarantie</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        }
      ],
      order: 'even'
    }
  ];

  const sponsorItems: {
    partner: { imageSrc: string; imageAlt: string }[];
    sponsors: { imageSrc: string; imageAlt: string }[];
  } = {
    partner: [
      {
        imageSrc: 'images/landingpage/logo-curry-solutions.svg',
        imageAlt: 'Logo CURRY Solutions'
      },
      {
        imageSrc: 'images/sponsors/alfa.png',
        imageAlt: 'Logo ALFA'
      },
      {
        imageSrc: 'images/sponsors/rotary.png',
        imageAlt: 'Logo Rotary'
      }
    ],
    sponsors: [
      {
        imageSrc: 'images/sponsors/eta.png',
        imageAlt: 'Logo ETA'
      },
      {
        imageSrc: 'images/sponsors/rotary.png',
        imageAlt: 'Logo Rotary'
      },
      {
        imageSrc: 'images/sponsors/sparkasse.png',
        imageAlt: 'Logo Sparkasse'
      },
      {
        imageSrc: 'images/sponsors/stabilo.png',
        imageAlt: 'Logo Stabilo'
      }
    ]
  };

  const footerLinks: ILandingPageFooterProps['links'] = [
    {
      name: 'Impressum',
      to: '/impressum'
    },
    {
      name: 'Datenschutz',
      to: '/datenschutz'
    },
    {
      name: 'Nutzungsbedingungen',
      to: '/nutzungsbedingungen'
    },
    {
      name: 'Gemeinschaftsstandards',
      to: '/gemeinschaftsstandards'
    },
    {
      name: 'Cookie Consents verwalten',
      onClick: e => {
        window['klaro']?.show?.();
      }
    },
    {
      name: 'Cookie Consents zurücksetzen',
      onClick: e => {
        window['klaro']?.getManager?.().resetConsents?.();
        window.location.reload();
      }
    }
  ];

  return (
    <Bx style={{ gridArea: 'content' }}>
      <LandingPageHeader
        logoSrc="images/logo.svg"
        logoAlt="Logo von Schüler Connect Online"
        partnerLogoSrc="images/landingpage/griebel.png"
        partnerLogoAlt="Logo Ortrun Griebel"
        ctnMaxWidth={ctnMaxWidthHeader}
        responsive={responsive}
        scrolled={scrolled}
        sectionPx={sectionPx}
        registerLabel="Jetzt registrieren"
        registerLink="/anfrage"
        loginLabel="Anmelden"
        loginLink="/login"
        sponsorLabel="Werde Sponsor"
        sponsorLink="/werde-sponsor"
      />
      <LandingPageKeyVisual
        ctnMaxWidth={ctnMaxWidthHeader}
        sectionPy={keyVisualSectionPy}
        sectionPx={sectionPx}
        keyvisualFontSize={keyvisualFontSize}
        body4FontSize={body4FontSize}
        imageSrc="images/landingpage/keyvisual.svg"
        imageAlt="Alt-Text der Illustration"
        logoSrc="images/logo.svg"
        logoAlt="Schüler Connect Online Logo"
        registerLabel="Jetzt registrieren"
        registerLink="/anfrage"
        loginLabel="Anmelden"
        loginLink="/login"
        headline="<b>Schüler Connect</b>
                  <br />
                  Online"
        content="Dein Tandem für mehr Erfolg in deinem Schulalltag"
        responsive={{ isMDUp }}
        classes={classes}
      />
      <LandingPageTabSection ctnMaxWidth={ctnMaxWidth} innerSectionSpacing={innerSectionSpacing} responsive={{ isMDUp }} sectionPx={sectionPx} body4FontSize={body4FontSize} />
      <LandingPageSectionWrapper bgcolor="background.paper" sectionPy={sectionPy} sectionPx={sectionPx}>
        <LandingPageTextSection
          headline="<b>Im Tandem</b> durch die Schulzeit"
          h1FontSize={h1FontSize}
          body4FontSize={body4FontSize}
          ctnMaxWidth={ctnMaxWidth}
          responsive={{ isXS }}
          imageSrc="images/landingpage/chatting.svg"
          imageAlt="Zwei chattende Personen"
          classes={classes}
        />
      </LandingPageSectionWrapper>
      <LandingPageSectionWrapper sectionPy={sectionPy} sectionPx={sectionPx}>
        <LandingPageImageSection
          headline="<b>Die Plattform</b>"
          h1FontSize={h1FontSize}
          body4FontSize={body4FontSize}
          items={imageItems}
          ctnMaxWidth={ctnMaxWidth}
          responsive={{ isMDUp }}
          classes={classes}
        />
      </LandingPageSectionWrapper>
      {splitItems.map((item, index) => {
        return (
          <Bx position="relative" key={index}>
            {isMDUp && (
              <Bx
                width="100%"
                height="100%"
                position="absolute"
                left="0"
                right="0"
                top="0"
                bottom="0"
                zIndex={-10}
                display="flex"
                flexDirection={isMDUp ? (item.order === 'even' ? 'row' : 'row-reverse') : 'column'}
              >
                <Bx width={isMDUp ? '50%' : '100%'} height="100%" bgcolor="accent.light" />
                <Bx width={isMDUp ? '50%' : '100%'} height="100%" bgcolor="background.paper" />
              </Bx>
            )}
            <LandingPageSplitSection
              innerSectionSpacing={innerSectionSpacing}
              h1FontSize={h1FontSize}
              body4FontSize={body4FontSize}
              ctnMaxWidth={ctnMaxWidth}
              headline={item.headline}
              text={item.text}
              imageSrc={item.imageSrc}
              imageAlt={item.imageAlt}
              content={item.items}
              order={item.order}
              classes={classes}
              zIndex={10}
              sectionPy={sectionPy}
              sectionPx={sectionPx}
              responsive={{ isMDUp }}
            />
          </Bx>
        );
      })}
      <LandingPageSectionWrapper sectionPy={sectionPy} sectionPx={sectionPx}>
        <LandingPageQuoteSection
          headline="Warum <b>Schüler Connect Online?</b>"
          h1FontSize={h1FontSize}
          ctnMaxWidth={ctnMaxWidth}
          items={quoteItems}
          responsive={{ isMDUp, isXLUp }}
          theme={theme}
          classes={classes}
        />
      </LandingPageSectionWrapper>
      <LandingPageSectionWrapper sectionPy={sectionPy} sectionPx={sectionPx} bgcolor="accent.light">
        <Sponsors
          ctnMaxWidth={ctnMaxWidth}
          h1FontSize={h1FontSize}
          body4FontSize={body4FontSize}
          headline="<b>Sponsoren & Partner</b>"
          text="Eine Chance für junge Menschen in dieser Gesellschaft - gerade jetzt ist es besonders wichtig! Ihre Unterstützung, ob klein ob groß, hilft weiter. Wir finanzieren damit Pflege und Betrieb der Homepage sowie die Ehrenamtspauschalen für die MentorInnen. Werden Sie Sponsor!"
          classes={classes}
          sponsorItems={sponsorItems}
          sponsorLabel="Werde Sponsor"
          sponsorLink="/werde-sponsor"
          responsive={{ isXS }}
        />
      </LandingPageSectionWrapper>
      <LandingPageFooter
        ctnMaxWidth={ctnMaxWidth}
        sectionPx={sectionPx}
        logoSrc="images/logo.svg"
        logoAlt="Logo von Schüler Connect Online"
        partnerLogoSrc="images/landingpage/griebel.png"
        partnerLogoAlt="Logo Ortrun Griebel"
        links={footerLinks}
        responsive={responsive}
      />
    </Bx>
  );
};
