import { Bx } from '@curry-group/mui-curcuma';
import { faHandPaper, faMicrophone, faPhoneSlash, faVideo } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { ConferencingButton } from '../button';

export interface IConferencingControlsMiddle {
  toggleAudio?: () => void;
  toggleVideo?: () => void;
  toggleHand?: () => void;
  close?: () => void;
  audio?: boolean;
  video?: boolean;
  hand?: boolean;
}

export const ConferencingControlsMiddle: React.FC<IConferencingControlsMiddle> = ({ audio, video, hand, toggleAudio, toggleVideo, toggleHand, close }) => {
  return (
    <Bx>
      <Bx p={2} display="flex" justifyContent="center">
        <ConferencingButton size="large" onClick={toggleHand} active={hand} tooltip={hand ? 'Hand nicht mehr heben' : 'Hand heben'} icon={faHandPaper} />
      </Bx>
      <Bx display="flex">
        <Bx ml={1} mr={1}>
          <ConferencingButton size="large" onClick={toggleAudio} active={audio} tooltip={audio ? 'Stummschalten' : 'Stummschaltung aufheben'} icon={faMicrophone} />
        </Bx>
        <Bx ml={1} mr={1}>
          <ConferencingButton size="large" onClick={close} emphasize icon={faPhoneSlash} tooltip="Videokonferenz beenden" />
        </Bx>
        <Bx ml={1} mr={1}>
          <ConferencingButton size="large" onClick={toggleVideo} active={video} icon={faVideo} tooltip={video ? 'Kamera ausschalten' : 'Kamera einschalten'} />
        </Bx>
      </Bx>
    </Bx>
  );
};
