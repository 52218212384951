import { Bx, Card, Typo, CardImageHeader, Ava } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useIsMobile } from '../../../../../helper/hooks/useIsMobile';
import { assetUrl } from '../../../../../helper';
import { IScoUserProfileListItem } from '../../../../../model/profile';
import { FramedContent } from '../../../../../components/framed-content';

export interface ICreateTandemConfirmationProps {
  tutor: IScoUserProfileListItem;
  student: IScoUserProfileListItem;
}

export const CreateTandemConfirmation: React.FC<ICreateTandemConfirmationProps> = ({ tutor, student }) => {
  const isMobile = useIsMobile();

  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Bestätigen Sie Ihre Auswahl
        </Typo>
      </Bx>
      <Typo variant="subtitle1">Sie können Ihre Auswahl jederzeit ändern.</Typo>
      <Bx my={8}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4} xl={3}>
            <Card activeColor="secondary" variant="outlined">
              <CardImageHeader
                src={
                  'https://media.istockphoto.com/vectors/abstract-simple-geometric-vector-seamless-pattern-with-gold-line-on-vector-id1160720443?k=6&m=1160720443&s=612x612&w=0&h=oLzqIKD0IjLngapr4l2Vu-0CMTDajzN_V6OgpknRm4g='
                }
                alt={`${tutor.fullName} Keyvisual`}
                avatar={<Ava src={tutor.avatar ? assetUrl(tutor.avatar) : undefined} size="larger" alt={`${tutor.fullName} Avatar`} />}
              />
              <Bx py={2} px={3} minHeight={isMobile ? 150 : 240} display="flex" flexDirection="column">
                <Bx mb={1}>
                  <Typo align="center" variant="body1">
                    <strong>{tutor.fullName}</strong>
                  </Typo>
                </Bx>
                <Bx mb={2}>
                  <Typo align="center" variant="body2">
                    Tutor
                  </Typo>
                </Bx>
              </Bx>
            </Card>
          </Grid>

          <Grid item xs={12} lg={4} xl={3}>
            <Card activeColor="secondary" variant="outlined">
              <CardImageHeader
                src={
                  'https://media.istockphoto.com/vectors/abstract-simple-geometric-vector-seamless-pattern-with-gold-line-on-vector-id1160720443?k=6&m=1160720443&s=612x612&w=0&h=oLzqIKD0IjLngapr4l2Vu-0CMTDajzN_V6OgpknRm4g='
                }
                alt={`${student.fullName} Keyvisual`}
                avatar={<Ava src={student.avatar ? assetUrl(student.avatar) : undefined} size="larger" alt={`${student.fullName} Avatar`} />}
              />
              <Bx py={2} px={3} minHeight={isMobile ? 150 : 240} display="flex" flexDirection="column">
                <Bx mb={1}>
                  <Typo align="center" variant="body1">
                    <strong>{student.fullName}</strong>
                  </Typo>
                </Bx>
                <Bx mb={2}>
                  <Typo align="center" variant="body2">
                    Schüler
                  </Typo>
                </Bx>
              </Bx>
            </Card>
          </Grid>
        </Grid>
      </Bx>
    </FramedContent>
  );
};
