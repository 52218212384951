import * as React from 'react';
import clsx from 'clsx';
import { Bx, Ctn, IBxProps } from '@curry-group/mui-curcuma';

export interface IFramedContentProps extends IBxProps {
  innerBoxProps?: IBxProps;
}

/**
 * The `FramedContent` component is an extension of the [Material-UI Box](https://material-ui.com/components/box/) and inherits all properties of it.
 */
export const FramedContent: React.FunctionComponent<IFramedContentProps> = ({ children, px, py, innerBoxProps, className, ...rest }) => {
  return (
    <Bx
      px={typeof px === 'number' || typeof px === 'object' ? px : { xs: 2, md: 2, lg: 5 }}
      py={typeof py === 'number' || typeof py === 'object' ? py : { xs: 4, md: 3.5, lg: 5.5 }}
      flexGrow={1}
      className={clsx(className)}
      {...rest}
    >
      <Ctn style={{ height: rest.height }}>
        <Bx {...innerBoxProps}>{children}</Bx>
      </Ctn>
    </Bx>
  );
};
