import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Btn, BtnList, Bx, Ctn, IBxProps, IconBtn, ICtnProps, Link, TBreakpointContextProps, Typo } from '@curry-group/mui-curcuma';
import Fade from '@material-ui/core/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons';

interface ILandingPageHeaderProps {
  logoSrc: string;
  logoAlt: string;
  partnerLogoSrc: string;
  partnerLogoAlt: string;
  ctnMaxWidth: ICtnProps['maxWidth'];
  scrolled: boolean;
  responsive: TBreakpointContextProps;
  registerLabel: string;
  registerLink: string;
  loginLabel: string;
  loginLink: string;
  sponsorLabel: string;
  sponsorLink: string;
  sectionPx: IBxProps['px'];
  backButtonLink?: () => void;
}

const Button = props => {
  return <Btn {...props} />;
};

export const LandingPageHeader: React.FunctionComponent<ILandingPageHeaderProps> = ({
  logoSrc,
  logoAlt,
  partnerLogoSrc,
  partnerLogoAlt,
  ctnMaxWidth,
  scrolled,
  responsive,
  registerLabel,
  registerLink,
  loginLabel,
  loginLink,
  sponsorLabel,
  sponsorLink,
  sectionPx,
  backButtonLink
}) => {
  return (
    <Bx
      component="header"
      position={responsive.mdUp ? 'fixed' : 'sticky'}
      top={0}
      width="100%"
      py={backButtonLink ? 0 : responsive.mdUp ? (scrolled ? 2 : 7) : 2}
      display={backButtonLink ? 'flex' : undefined}
      alignItems="center"
      px={sectionPx}
      zIndex={2}
      bgcolor={responsive.mdUp ? (scrolled ? 'accent.main' : undefined) : 'background.paper'}
      boxShadow={{ xs: 3, md: scrolled ? 3 : 0 }}
      style={{ zIndex: 2, transition: 'all .25s ease-in-out', willChange: 'padding, box-shadow, background-color' }}
      height={responsive.mdUp ? 80 : 60}
    >
      {(responsive.wqhd || responsive.wqhdUp) && backButtonLink && (
        <Bx zIndex={2} position="absolute" top={12}>
          <IconBtn mrSelf size="large" onClick={() => backButtonLink()}>
            <FontAwesomeIcon icon={faLongArrowLeft} />
          </IconBtn>
        </Bx>
      )}
      <Ctn maxWidth={ctnMaxWidth}>
        <Bx display="flex" alignItems={{ md: !scrolled ? 'flex-start' : 'center' }} justifyContent="space-between">
          <Bx display="flex" alignItems="center">
            {!responsive.wqhd && responsive.wqhdDown && backButtonLink && (
              <Bx zIndex={2} position={responsive.wqhdUp ? 'absolute' : undefined} left={responsive.wqhdUp ? -80 : undefined} mr={!responsive.wqhdUp ? 3 : undefined}>
                <IconBtn size="large" onClick={() => backButtonLink()}>
                  <FontAwesomeIcon icon={faLongArrowLeft} />
                </IconBtn>
              </Bx>
            )}
            <Link
              to="/"
              onClick={e => {
                e.preventDefault();
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              style={{ textDecoration: 'none' }}
            >
              <Bx display="flex">
                <img src={logoSrc} alt={logoAlt} style={{ height: responsive.mdUp ? 56 : 28}} />
                <Typo variant={'body1'} style={{ paddingTop: responsive.mdUp ? 25 : 4 }}>
                  Schüler Connect Online
                </Typo>
              </Bx>
            </Link>
          </Bx>
          {responsive.mdUp && (
            <Fade in={scrolled}>
              <Bx ml="auto">
                <BtnList spacing={1}>
                  <Button color="primary" size={responsive.mdUp ? 'large' : 'medium'} variant="contained" component={RouterLink} to={registerLink}>
                    {registerLabel}
                  </Button>
                  <Button variant="outlined" size={responsive.mdUp ? 'large' : 'medium'} component={RouterLink} to={loginLink}>
                    {loginLabel}
                  </Button>
                  <Button color="secondary" size={responsive.mdUp ? 'large' : 'medium'} variant="contained" component={RouterLink} to={sponsorLink}>
                    {sponsorLabel}
                  </Button>
                </BtnList>
              </Bx>
            </Fade>
          )}
          {responsive.mdUp && !scrolled && (
            <Bx display="flex" flexDirection="column">
              <Typo variant="h6" gutterBottom>
                Lern-Portal Schüler Connect Online
              </Typo>
              <Bx mt={{ xs: 3, sm: 0 }} display="flex" justifyContent="flex-end">
                <Bx mr={2} position="relative" top={-5}>
                  <Typo variant="body2" component="span" style={{ fontSize: 12 }}>
                    Eine Initiative von
                  </Typo>
                </Bx>
                <Bx>
                  <img src={partnerLogoSrc} alt={partnerLogoAlt} />
                </Bx>
              </Bx>
            </Bx>
          )}
          {!responsive.mdUp && <img src={partnerLogoSrc} alt={partnerLogoAlt} style={{ height: 28, display: 'block' }} />}
        </Bx>
      </Ctn>
    </Bx>
  );
};
