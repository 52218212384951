import { theme } from '@curry-group/mui-curcuma';
import { ICurcumaTheme } from '@curry-group/mui-curcuma/dist/ui/Theme/ThemeProvider';
import { createTheme } from '@material-ui/core/styles';

const customPalette: ICurcumaTheme['palette'] = {
  primary: {
    main: '#087585',
    light: '#187c8c',
    dark: '#006e7e',
    contrastText: '#ffffff'
  },
  secondary: {
    main: '#db2e64',
    light: '#e4386b',
    dark: '#d2235d',
    contrastText: '#ffffff'
  },
  tertiary: {
    main: '#011638',
    light: '#021739',
    dark: '#001537',
    contrastText: '#ffffff'
  },
  quaternary: {
    main: '#011638',
    light: '#021739',
    dark: '#001537',
    contrastText: '#ffffff'
  },
  accent: {
    main: '#B0E5D4',
    light: '#DDF0EB',
    dark: '#99DD8C',
    contrastText: '#ffffff'
  }
};

// const customBreakpoints: ICurcumaTheme['breakpoints'] = {
//   ...theme.breakpoints
// };

export const customTheme: ICurcumaTheme = createTheme({
  ...theme,
  // breakpoints: { ...customBreakpoints },
  palette: { ...customPalette }
});
