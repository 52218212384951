import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderComponent, MobileHeaderComponent } from '../../../components/header';
import { setAppstateNavbarOpen } from '../../../data/reducer/app';
import { useIsMobile } from '../../../helper/hooks/useIsMobile';

export const ConnectedHeader: React.FC = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { navbarOpen } = useSelector(state => state.app);

  if (isMobile) return <MobileHeaderComponent open={!!navbarOpen} setOpen={() => dispatch(setAppstateNavbarOpen(!navbarOpen))} />;
  else return <HeaderComponent />;
};
