// import { IThing } from '../ryve/Thing';
// import { ISearchEntry } from '../search/SearchEntry';
import { IThing } from '../ryve/thing';
import { IParticipantModel, IParticipationResolvedModel } from './Participant';
import { IPermissionModel } from './Permissions';
import { IRoleInfoModel } from './Role';

export class CommunicationType {
  static GROUP = 'group';
  static ONETOONE = 'onetoone';
}

export interface OneToOneStatus {
  id: string;
  alias: string;
  status: 'own-pending' | 'partner-pending' | 'accepted';
  participation?: IParticipationResolvedModel;
}

export interface ICommunicationModel extends IPermissionModel {
  type: 'group' | 'onetoone';
  allowRequests: boolean;
  inlineThreading: boolean;
  public: boolean;
  participantManagement: boolean;
  participants: IParticipantModel[];
  roles: IRoleInfoModel[];
}

export interface ICommunicationListItem extends IThing<any> {
  typeId: string;
}
