import React from 'react';
import { IConferencingParticipant } from '../../interfaces';
import { ParticipantDesktop } from './desktop';
import { ParticipantGrid } from './grid';

export const ParticipantOutlet: React.FC<{ desktop?: IConferencingParticipant; participants?: IConferencingParticipant[]; speakers?: string[] }> = ({
  participants,
  desktop,
  speakers
}) => {
  const mapping = participants || [];
  const withoutSelf = mapping.filter(p => !p.self);
  return (
    <>
      {desktop && <ParticipantDesktop participants={withoutSelf} speakers={speakers} self={mapping.filter(p => p.self)[0]} desktop={desktop} />}
      {!desktop && <ParticipantGrid participants={withoutSelf} speakers={speakers} self={mapping.filter(p => p.self)[0]} />}
    </>
  );
};
