import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IScoUserProfile } from '../../../model/profile';
import { IResetPasswordRequestData } from '../../../model/data/password';
import { connectionEstablishedSignalRAction } from '../../sagas/communication/signalr';

interface IAppState {
  loggedIn: boolean;
  loginError: boolean;
  isMobile: boolean;
  navbarOpen?: boolean;
  profile?: IScoUserProfile | null;

  lostPasswordWorking?: boolean;
  lostPasswordSuccess?: boolean;
  lostPasswordFailed?: boolean;
  resetPasswordWorking?: boolean;
  resetPasswordSuccess?: boolean;
  resetPasswordFailed?: boolean;

  updatePasswordWorking?: boolean;
  updatePasswordError?: boolean;

  signalRConnected?: boolean;
}

const initialState: IAppState = {
  isMobile: false,
  loggedIn: false,
  loginError: false
};

const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    fetchStartupRequest: () => {},
    fetchStartupSuccess: (state, action: PayloadAction<{ profile: IScoUserProfile }>) => {
      state.profile = action.payload.profile;
      if (state.profile) {
        state.profile.fullName = ((state.profile.title ?? '') + ' ' + (state.profile.firstName ?? '') + ' ' + (state.profile.lastName ?? '')).trim();
      }
    },
    fetchStartupFailed: state => {
      state.profile = null;
      state.loggedIn = false;
      state.loginError = false;
    },
    setAppstateIsMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
    },
    setAppstateNavbarOpen: (state, action: PayloadAction<boolean>) => {
      state.navbarOpen = action.payload;
    },
    userCredLoginRequest: (state, action: PayloadAction<{ username: string; password: string; persist: boolean }>) => {},
    userDeauth: state => {
      state.loggedIn = false;
      state.loginError = false;
      state.profile = null;
    },
    userLoginFailed: state => {
      state.loggedIn = false;
      state.loginError = true;
      state.profile = null;
    },
    userLoginSuccess: state => {
      state.profile = null;
      state.loggedIn = true;
      state.loginError = false;
    },
    userLogoutFailed: state => {
      state.loggedIn = false;
      state.loginError = true;
    },
    userLogoutRequest: () => {},
    userLogoutSuccess: state => {
      state.loggedIn = false;
      state.loginError = false;
    },
    lostPasswordRequest: (state, payload: PayloadAction<{ email: string }>) => {
      state.lostPasswordWorking = true;
      state.lostPasswordSuccess = false;
      state.lostPasswordFailed = false;
    },
    lostPasswordSuccess: state => {
      state.lostPasswordWorking = false;
      state.lostPasswordSuccess = true;
      state.lostPasswordFailed = false;
    },
    lostPasswordFailed: state => {
      state.lostPasswordWorking = false;
      state.lostPasswordSuccess = false;
      state.lostPasswordFailed = true;
    },
    resetPasswordRequest: (state, payload: PayloadAction<IResetPasswordRequestData>) => {
      state.resetPasswordWorking = true;
      state.resetPasswordSuccess = false;
      state.resetPasswordFailed = false;
    },
    resetPasswordSuccess: state => {
      state.resetPasswordWorking = false;
      state.resetPasswordSuccess = true;
      state.resetPasswordFailed = false;
    },
    resetPasswordFailed: state => {
      state.resetPasswordWorking = false;
      state.resetPasswordSuccess = false;
      state.resetPasswordFailed = true;
    },
    updatePasswordRequest: (state, action: PayloadAction<{ userId: string; oldPassword: string; newPassword: string }>) => {
      state.updatePasswordWorking = true;
      state.updatePasswordError = false;
    },
    updatePasswordSuccess: state => {
      state.updatePasswordWorking = false;
      state.updatePasswordError = false;
    },
    updatePasswordFailed: state => {
      state.updatePasswordWorking = false;
      state.updatePasswordError = true;
    }
  },
  extraReducers: builder =>
    builder.addCase(connectionEstablishedSignalRAction, state => {
      state.signalRConnected = true;
    })
});

export const {
  fetchStartupRequest,
  fetchStartupSuccess,
  fetchStartupFailed,
  setAppstateIsMobile,
  setAppstateNavbarOpen,
  userCredLoginRequest,
  userDeauth,
  userLoginFailed,
  userLoginSuccess,
  userLogoutFailed,
  userLogoutRequest,
  userLogoutSuccess,
  lostPasswordRequest,
  lostPasswordSuccess,
  lostPasswordFailed,
  resetPasswordFailed,
  resetPasswordRequest,
  resetPasswordSuccess,
  updatePasswordRequest,
  updatePasswordFailed,
  updatePasswordSuccess
} = app.actions;
export default app.reducer;
