import React, { MouseEventHandler } from 'react';
import { Anohana, Btn, Typo, ListWrapper, ExtendedListItem, Ava, IconAva, IconBtn, Bx } from '@curry-group/mui-curcuma';
import { Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUser, faVolumeMute } from '@fortawesome/pro-light-svg-icons';
import { IParticipantResolvedModel } from '../../../../model/communication/Participant';
import { ConferencingSubModal } from '..';
import { assetUrl } from '../../../../helper';

export interface IConferencingModalParticipantsProps {
  open?: boolean;
  participants?: IParticipantResolvedModel[];
  onCloseClick?: MouseEventHandler;
  onInfoClick?: MouseEventHandler;
}

export const ConferencingModalParticipants: React.FC<IConferencingModalParticipantsProps> = ({ open, participants, onCloseClick, onInfoClick }) => {
  return (
    <Anohana open={!!open} scroll="body" maxWidth="sm" fullWidth disableScrollLock transition="fade" keepMounted style={{ zIndex: 1301 }}>
      <ConferencingSubModal title="Informationen" onCloseClick={onCloseClick} onTitleClick={onInfoClick}>
        <Bx mb={8}>
          <Typo variant="body2" fontWeight={500}>
            Bisherige Teilnehmer
          </Typo>
          <Bx>
            <ListWrapper divider="bottom">
              {participants?.map((participant, idx) => (
                <ExtendedListItem
                  key={participant.userResolved?._id || idx.toString()}
                  avatar={
                    participant.userResolved?.content?.avatar ? (
                      <Ava src={assetUrl(participant.userResolved?.content?.avatar, true)} alt={`Profilbild von ${participant.userResolved?.content?.fullName}`} />
                    ) : (
                      <IconAva variant="circular">
                        <FontAwesomeIcon icon={faUser} />
                      </IconAva>
                    )
                  }
                >
                  <Bx display="flex" alignItems="center" height="100%">
                    <Typo>{participant.userResolved?.content?.fullName}</Typo>
                    <Bx display="none">
                      <Bx ml="auto" pl={1}>
                        <Tooltip title={`${participant.userResolved?.content?.fullName} stummschalten`} arrow>
                          <IconBtn variant="outlined" size="small">
                            <FontAwesomeIcon icon={faVolumeMute} />
                          </IconBtn>
                        </Tooltip>
                      </Bx>
                      <Bx ml={1}>
                        <Tooltip title={`${participant.userResolved?.content?.fullName} entfernen`} arrow>
                          <IconBtn color="quaternary" variant="contained" size="small">
                            <FontAwesomeIcon icon={faTimes} />
                          </IconBtn>
                        </Tooltip>
                      </Bx>
                    </Bx>
                  </Bx>
                </ExtendedListItem>
              ))}
            </ListWrapper>
          </Bx>
        </Bx>
        <Bx display="flex">
          <Bx>
            <Btn variant="outlined" onClick={onCloseClick} size="large">
              Schließen
            </Btn>
          </Bx>
        </Bx>
      </ConferencingSubModal>
    </Anohana>
  );
};
