import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Link, { LinkProps } from '@material-ui/core/Link/Link';
import { LocationDescriptor } from 'history';
import _ from 'lodash';

export interface INavBarMetaLinkProps extends LinkProps {
  to: LocationDescriptor;
}

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.pxToRem(12),
    marginRight: theme.spacing(1),
    '& + &$root:last-child': {
      marginRight: 0
    }
  }
}));

/**
 * The `NavBarMetaLink` component is an extension of the [Material-UI Link](https://material-ui.com/components/link/) and inherits all properties of it with the exception of the `component` property. The `component` property is prefilled with the [RouterLink-API](https://reactrouter.com/web/api/Link)
 */
export const NavBarMetaLink: React.FunctionComponent<INavBarMetaLinkProps> = ({ children, className, to, ...rest }) => {
  const classes = useStyles();

  return (
    <Link color="inherit" component={RouterLink as any} className={clsx(classes.root, className)} to={to} {..._.omit(rest, 'component')}>
      {children}
    </Link>
  );
};
