import React from 'react';
import { Bx } from '@curry-group/mui-curcuma';
import { IConferencingParticipant } from '../../../interfaces';
import { ConferencingParticipant } from '../../../participant';
import { SimpleParticipant } from '../../../participant/simple';
import { useTheme } from '@material-ui/core/styles';

export const ParticipantDesktop: React.FC<{
  participants?: IConferencingParticipant[];
  desktop?: IConferencingParticipant;
  self?: IConferencingParticipant;
  speakers?: string[];
}> = ({ participants, self, desktop, speakers }) => {
  const mapping = participants || [];
  const theme = useTheme();
  return (
    <Bx height="100%">
      <Bx style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Bx style={{ display: 'flex', overflowX: 'auto' }}>
          {mapping
            .filter(p => !p.self)
            .map((p, idx) => {
              return (
                <Bx key={p.id} height="12vh" width="calc(12vh / 9 * 16)" style={{ flexShrink: 0, borderLeft: idx !== 0 ? theme?.spacing(0.5) + 'px solid black' : undefined }}>
                  <ConferencingParticipant
                    audio={p.audio}
                    displayName={p.displayName}
                    hand={p.hand}
                    media={p.media}
                    speaking={speakers?.includes(p.sessionIdInternal ?? '')}
                    video={p.video}
                  />
                </Bx>
              );
            })}
        </Bx>
        {self && (
          <Bx>
            <Bx height="12vh" width="calc(12vh / 9 * 16)" style={{ flexShrink: 0 }}>
              <ConferencingParticipant audio={self.audio} displayName={'Sie'} hand={self.hand} media={self.media} muted speaking={self.speaking} video={self.video} />
            </Bx>
          </Bx>
        )}
      </Bx>
      <Bx height="calc(100% - 12vh)" style={{ borderTop: '2px solid black' }}>
        <SimpleParticipant media={desktop?.media} muted />
      </Bx>
    </Bx>
  );
};
