import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

const forbiddenRedirects = ['/__stats/video', '/logout', '/login', '/', '/anfrage', '/registrierung', '/passwort-vergessen', '/passwort-setzen', '/registrierung-abschließen'];

export function useRedirectAfterLogin() {
  const location = useLocation();
  const history = useHistory();
  const loggedIn = useSelector(state => state.app.loggedIn);
  const isInitialRoute = useRef(false);
  const [redirectPath, setRedirectPath] = useState<string>('/');

  useEffect(() => {
    // initial & not logged in
    if (!isInitialRoute.current && !loggedIn) {
      isInitialRoute.current = true;
      if (forbiddenRedirects.indexOf(location.pathname) < 0) {
        let initialPath = location.pathname;
        if (location.search) initialPath += location.search;

        setRedirectPath(initialPath);
      }
    }

    // after login & path was saved
    if (isInitialRoute.current && loggedIn) {
      setRedirectPath('/');
    }
  }, [location, loggedIn, history]);

  return redirectPath;
}
