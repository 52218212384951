import { Bx, Card, Typo, CardImageHeader, Ava, Btn } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import * as _ from 'lodash';
import { TProfileType } from '../../../../../model/data/registration';
import { useIsMobile } from '../../../../../helper/hooks/useIsMobile';
import { Skeleton } from '@material-ui/lab';
import { assetUrl } from '../../../../../helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTandemUsersRequest } from '../../../../../data/reducer/tandem';
import { IFetchTandemUsersRequest } from '../../../../../model/data/tandem';
import { IScoUserProfileListItem } from '../../../../../model/profile';
import { FramedContent } from '../../../../../components/framed-content';

export interface ICreateTandemUserSelectProps {
  type: TProfileType;
  tutorUserId?: string;
  selectedUserId?: string;
  onUserSelected: (user?: IScoUserProfileListItem) => void;
}

export const CreateTandemUserSelect: React.FC<ICreateTandemUserSelectProps> = ({ type, tutorUserId, selectedUserId, onUserSelected }) => {
  let { usersWorking, users } = useSelector(state => state.tandem);

  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  let headline = 'Wählen Sie den Tutor aus';
  if (type === 'student') {
    headline = 'Wählen Sie den Schüler aus';
  }

  useEffect(() => {
    const requestData: IFetchTandemUsersRequest = {
      type
    };
    if (type === 'student') {
      requestData.tutorUserId = tutorUserId;
    }
    dispatch(fetchTandemUsersRequest(requestData));
  }, [dispatch, type, tutorUserId]);

  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          {headline}
        </Typo>
      </Bx>
      <Typo variant="subtitle1">Sie können Ihre Auswahl jederzeit ändern.</Typo>
      <Bx my={8}>
        <Grid container spacing={3}>
          {/* working */}
          {usersWorking &&
            _.range(3).map(i => (
              <Grid key={`person_search_working_${i}`} item xs={12} lg={4} xl={3}>
                <Card activeColor="secondary" active={false} variant="outlined">
                  <CardImageHeader
                    src={
                      'https://media.istockphoto.com/vectors/abstract-simple-geometric-vector-seamless-pattern-with-gold-line-on-vector-id1160720443?k=6&m=1160720443&s=612x612&w=0&h=oLzqIKD0IjLngapr4l2Vu-0CMTDajzN_V6OgpknRm4g='
                    }
                    alt={`No User Found Keyvisual`}
                    avatar={<Ava loading={true} src={undefined} size="larger" alt={`No User Found Avatar`} />}
                  />
                  <Bx py={2} px={3} minHeight={isMobile ? 150 : 240} display="flex" flexDirection="column">
                    <Bx mb={2}>
                      <Typo align="center" variant="subtitle1">
                        <Skeleton animation="wave" width="80%" />
                      </Typo>
                    </Bx>
                    <Bx>
                      <Typo align="center" variant="body1">
                        <Skeleton animation="wave" width="60%" />
                      </Typo>
                    </Bx>
                  </Bx>
                </Card>
              </Grid>
            ))}
          {!usersWorking && (!users || !users.length) && (
            <Grid key={'person_search_noresult'} item xs={12} lg={4} xl={3}>
              <Card activeColor="secondary" active={false} variant="outlined">
                <CardImageHeader
                  src={
                    'https://media.istockphoto.com/vectors/abstract-simple-geometric-vector-seamless-pattern-with-gold-line-on-vector-id1160720443?k=6&m=1160720443&s=612x612&w=0&h=oLzqIKD0IjLngapr4l2Vu-0CMTDajzN_V6OgpknRm4g='
                  }
                  alt={`No User Found Keyvisual`}
                  avatar={<Ava src={undefined} size="larger" alt={`No User Found Avatar`} />}
                />
                <Bx py={2} px={3} minHeight={isMobile ? 150 : 240} display="flex" flexDirection="column">
                  <Bx mb={2}>
                    <Typo align="center" variant="subtitle1">
                      <strong>Leider konnte für Ihre Suchanfrage kein Teilnehmer gefunden werden.</strong>
                    </Typo>
                  </Bx>
                  <Bx>
                    <Typo align="center" variant="body1">
                      Bitte versuchen Sie andere Suchoptionen.
                    </Typo>
                  </Bx>
                </Bx>
              </Card>
            </Grid>
          )}

          {!usersWorking &&
            users &&
            users.length > 0 &&
            users.map(user => {
              return (
                <Grid key={user._id} item xs={12} lg={4} xl={3}>
                  <Card activeColor="secondary" variant="outlined">
                    <CardImageHeader
                      src={
                        'https://media.istockphoto.com/vectors/abstract-simple-geometric-vector-seamless-pattern-with-gold-line-on-vector-id1160720443?k=6&m=1160720443&s=612x612&w=0&h=oLzqIKD0IjLngapr4l2Vu-0CMTDajzN_V6OgpknRm4g='
                      }
                      alt={`${user.fullName} Keyvisual`}
                      avatar={<Ava src={user.avatar ? assetUrl(user.avatar) : undefined} size="larger" alt={`${user.fullName} Avatar`} />}
                    />
                    <Bx py={2} px={3} minHeight={isMobile ? 150 : 240} display="flex" flexDirection="column">
                      <Bx mb={1}>
                        <Typo align="center" variant="body1">
                          <strong>{user.fullName}</strong>
                        </Typo>
                      </Bx>
                      <Bx textAlign="center" pt={3} mt="auto">
                        {selectedUserId === user._id ? (
                          <Btn variant="text" onClick={() => onUserSelected()} color="secondary" endIcon={<FontAwesomeIcon icon={faCheckCircle} />}>
                            ausgewählt
                          </Btn>
                        ) : (
                          <Btn variant="outlined" onClick={() => onUserSelected(user)}>
                            wählen
                          </Btn>
                        )}
                      </Bx>
                    </Bx>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </Bx>
    </FramedContent>
  );
};
