import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDictionary } from '../../../model/common/dictionary';
import { INotification } from '../../../model/ryve/notification';

export interface INotificationsState {
  working?: boolean;
  notifications?: INotification[];
  badges: IDictionary<number>;
}

const initialState: INotificationsState = {
  notifications: [],
  working: false,
  badges: {}
};

const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setBadgeCount: (state, action: PayloadAction<{ key: string; count: number }>) => {
      state.badges[action.payload.key] = action.payload.count;
    },
    fetchNotificationsRequest: state => {
      state.working = true;
      state.notifications = [];
    },
    fetchNotificationsSuccess: (state, action: PayloadAction<INotification[]>) => {
      const notificationCount = action.payload?.reduce((curr, next) => {
        if (!next.content?.notificationRead) {
          curr += 1;
        }
        return curr;
      }, 0);
      state.badges.notifications = notificationCount;
      state.notifications = action.payload;
      state.working = false;
    },
    fetchNotificationsFailed: state => {
      state.working = false;
      state.notifications = [];
    },
    notificationsUpdated: () => {},
    setNotificationsRead: (state, action: PayloadAction<{ notifications?: string[]; thing?: string }>) => {},
    setNotificationsReadSuccess: () => {},
    setNotificationsReadFailed: () => {}
  }
});

export const {
  setBadgeCount,
  fetchNotificationsRequest,
  fetchNotificationsSuccess,
  fetchNotificationsFailed,
  notificationsUpdated,
  setNotificationsRead,
  setNotificationsReadSuccess,
  setNotificationsReadFailed
} = notifications.actions;
export default notifications.reducer;
