import React from 'react';
import { Bx, Ctn, ICtnProps, Typo } from '@curry-group/mui-curcuma';
import { ClassNameMap } from '@material-ui/styles';
interface ILandingPageImageSectionProps {
    ctnMaxWidth: ICtnProps['maxWidth'];
    responsive: { isMDUp: boolean };
    headline: string;
    h1FontSize: number;
    body4FontSize: number;
    items: { id: number, text: string, imageSrc: string, imageAlt: string }[];
    classes: ClassNameMap<"line" | "boldText">;
}

export const LandingPageImageSection: React.FunctionComponent<ILandingPageImageSectionProps> = ({ctnMaxWidth, responsive, headline, h1FontSize, body4FontSize, items, classes}) => {
    return(
        <Bx component="section" width="100%" className={classes.boldText}>
            <Ctn maxWidth={ctnMaxWidth}>
                <Typo variant="h1" style={{fontSize: h1FontSize}} className={classes.line} dangerouslySetInnerHTML={{ __html: headline }}/>
                {items.map((item, index) => {
                    return(
                        <Bx key={index}>
                            <Bx pb={4} mt={index === 0 ? 0 : 10}>
                                <Typo variant="body1" style={{fontSize: body4FontSize}} className={classes.boldText} dangerouslySetInnerHTML={{ __html: item.text }} />
                            </Bx>
                            <img src={item.imageSrc} alt={item.imageAlt} style={{width: "100%"}} />
                        </Bx>
                    );
                })}
            </Ctn>
        </Bx>
    );
}