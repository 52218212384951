import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Bx } from '@curry-group/mui-curcuma';
import { NavbarHeader } from '../header';
import { NavbarContent } from '../content';
import { NavbarFooter } from '../footer';
import { INavbarBaseProps } from '..';
import { TUtilSpacingProps } from '@curry-group/mui-curcuma/dist/ui/Utils/Spacing';
import { useIsMobile } from '../../../helper/hooks/useIsMobile';
import { NavBar } from '../NavBar';

export const NavbarDesktop: React.FC<INavbarBaseProps & { showHamburger: boolean }> = args => {
  const theme = useTheme();
  const isLG = useMediaQuery(theme.breakpoints.only('lg'));
  const isMobile = useIsMobile();

  let py: TUtilSpacingProps = 3.5;
  if (isLG) {
    py = 2;
  } else if (isMobile) {
    py = 1.5;
  }

  return (
    <NavBar py={py}>
      <NavbarHeader showHamburger={args.showHamburger} hamburgerClicked={() => args.setOpen(!args.open)} open={args.open} />

      <Bx component="nav" width="100%">
        <NavbarContent badges={args.badges} sections={args.sections} />
      </Bx>

      <Bx mt="auto">
        <NavbarFooter userId={args.userId} userFullName={args.userFullName} userAvatarSrc={args.userAvatarSrc} />
      </Bx>
    </NavBar>
  );
};
