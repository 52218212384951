import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CommunicationInput } from '../../../../components/communication/input';
import {
  createMessageRequest,
  setMessageDraftAttachments,
  setMessageDraftContent,
  setMessageDraftEdit,
  setMessageDraftQuotes,
  updateMessageRequest
} from '../../../../data/reducer/communication';
export interface IConnectedCommunicationInputProps {
  small?: boolean;
  inputId: string;
  removeThreadRoot?: () => void;
  displayThreadRoot?: boolean;
  disabledWithoutThreadroot?: boolean;
}
export const ConnectedCommunicationInput: React.FC<IConnectedCommunicationInputProps> = ({
  inputId,
  small,
  removeThreadRoot,
  displayThreadRoot,
  disabledWithoutThreadroot
}) => {
  const dispatch = useDispatch();

  const draft = useSelector(state => state.communication?.messageDraft?.[inputId]);

  const allowAttachments = useSelector(state => state.communication?.communication?.allowAttachments && state.communication?.participation?.permissions?.allowAttachments);
  const canWriteMessage = useSelector(state => state.communication?.participation?.permissions?.writeMessage);
  const id = useSelector(state => state.communication?.id);

  return (
    <CommunicationInput
      editMode={!!draft?.editMessageId}
      displayThreadRoot={displayThreadRoot}
      threadRoot={draft?.threadRoot}
      removeThreadRoot={removeThreadRoot}
      quotes={draft?.quotes}
      removeQuotes={() => {
        dispatch(setMessageDraftQuotes({ id: inputId, quotes: undefined }));
      }}
      small={small}
      error={draft?.error}
      allowAttachments={allowAttachments}
      attachments={draft?.attachments}
      setAttachments={attachments => {
        id && dispatch(setMessageDraftAttachments({ id: inputId, attachments, communicationId: id }));
      }}
      canWriteMessage={canWriteMessage}
      disabled={draft?.working || draft?.attachmentsWorking || (disabledWithoutThreadroot && !draft?.threadRoot)}
      value={draft?.content}
      onChange={content => {
        dispatch(setMessageDraftContent({ id: inputId, content }));
      }}
      onSubmit={() => {
        if (id) {
          if (draft.editMessageId && draft?.content) {
            // edit
            dispatch(updateMessageRequest({ communicationId: id, id: inputId, messageId: draft.editMessageId, content: draft.content }));
          } else {
            // create
            if (!draft.content && !draft?.attachments?.length) return;

            dispatch(
              createMessageRequest({
                id: inputId,
                communicationId: id,
                content: draft?.content || '',
                threadRoot: draft.threadRoot,
                quotes: draft.quotes,
                attachments: draft.attachments
              })
            );
          }
        }
      }}
      onCancel={() => {
        dispatch(setMessageDraftEdit({ id: inputId }));
      }}
    />
  );
};
