import { Typo, Input, Btn, Bx, InputInfoText } from '@curry-group/mui-curcuma';
import { Grid, Hidden } from '@material-ui/core';
import React, { useState } from 'react';
import { Content } from '../../../components/frame';
import { Footer } from '../../../components/frame/footer';
import { Sidebar } from '../../../components/frame/sidebar';
import { BottomBar } from '../../bottom-bar';
import { FramedContent } from '../../framed-content';
import { NavBarLogo } from '../../navbar-logo';
import { SplashSideBar } from '../../splash-sidebar';

export interface ILoginComponentProps {
  headline: string;
  subtitle: string;

  splashSidebarImage: string;
  navBarLogo: string;
  navBarAlt: string;

  loginError?: boolean;
  loginErrorCaption: string;
  onLogin: (username: string, password: string) => void;

  onLostPassword: () => void;
  onRegister: () => void;
}

export const LoginComponent: React.FC<ILoginComponentProps> = ({
  headline,
  subtitle,
  splashSidebarImage,
  navBarLogo,
  navBarAlt,
  loginError,
  loginErrorCaption,
  onLogin,
  onLostPassword,
  onRegister
}) => {
  const [login, setLogin] = useState({ username: '', password: '' });

  return (
    <React.Fragment>
      <Hidden mdDown>
        <Sidebar>
          <SplashSideBar src={splashSidebarImage} alt="Anmeldung">
            <Bx display="flex" width="100%" alignItems="center" justifyContent="space-between">
              <Bx display="flex" justifyContent={'flex-start'} alignItems="center" height="100%">
                <NavBarLogo to="/" src={navBarLogo} alt={navBarAlt} />
              </Bx>
            </Bx>
          </SplashSideBar>
        </Sidebar>
      </Hidden>
      <Content>
        <FramedContent display="flex" alignItems="center" height="100%">
          <Bx mb={1} mt={{ md: 10 }}>
            <Typo variant="h1" component="h1">
              {headline}
            </Typo>
          </Bx>
          <Typo variant="subtitle1">{subtitle}</Typo>
          <Bx my={8}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <Input
                  inputLabel={{ children: 'E-Mail' }}
                  input={{
                    placeholder: 'E-Mail eingeben',
                    type: 'text',
                    value: login.username,
                    onChange: e => {
                      setLogin({ ...login, username: e.target.value });
                    },
                    name: 'username'
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Input
                  inputLabel={{ children: 'Passwort' }}
                  input={{
                    placeholder: 'Passwort eingeben',
                    type: 'password',
                    value: login.password,
                    onChange: e => {
                      setLogin({ ...login, password: e.target.value });
                    },
                    onKeyPress: e => {
                      if (e.key === 'Enter') {
                        onLogin(login.username, login.password);
                      }
                    },
                    name: 'password'
                  }}
                />
                {loginError && (
                  <Bx mt={1}>
                    <InputInfoText type="error">{loginErrorCaption}</InputInfoText>
                  </Bx>
                )}
              </Grid>
            </Grid>
          </Bx>
        </FramedContent>
      </Content>
      <Footer>
        <Bx gridArea="footer" zIndex={2} position="sticky" bottom="0">
          <BottomBar scrolled bottom={'unset'}>
            <Bx display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
              <Hidden only="xs">
                <Bx alignSelf="flex-start" mr={1}>
                  <Btn size="large" color="success" variant="contained" disabled={false} onClick={onRegister}>
                    Registrieren
                  </Btn>
                </Bx>
                <Bx flexGrow={1} flexShrink={1} flexBasis="auto">
                  <Btn size="large" color="success" variant="contained" disabled={false} onClick={onLostPassword}>
                    Passwort vergessen
                  </Btn>
                </Bx>
                <Bx ml={1} alignSelf="flex-end" textAlign="right">
                  <Btn
                    size="large"
                    color="success"
                    variant="contained"
                    disabled={!login.username || !login.password}
                    onClick={() => {
                      onLogin(login.username, login.password);
                    }}
                  >
                    Login
                  </Btn>
                </Bx>
              </Hidden>
              <Hidden smUp>
                <Bx width="100%" mb={1}>
                  <Btn
                    fullWidth
                    size="large"
                    color="success"
                    variant="contained"
                    disabled={!login.username || !login.password}
                    onClick={() => {
                      onLogin(login.username, login.password);
                    }}
                  >
                    Login
                  </Btn>
                </Bx>
                <Bx width="100%" mb={1}>
                  <Btn fullWidth size="large" color="success" variant="contained" disabled={false} onClick={onRegister}>
                    Registrieren
                  </Btn>
                </Bx>
                <Bx width="100%" mb={1}>
                  <Btn fullWidth size="large" color="success" variant="contained" disabled={false} onClick={onLostPassword}>
                    Passwort vergessen
                  </Btn>
                </Bx>
              </Hidden>
            </Bx>
          </BottomBar>
        </Bx>
      </Footer>
    </React.Fragment>
  );
};
