import React, { useState, useCallback, useEffect } from 'react';
import { Bx, CheckboxControl, IconBtn, Input, InputEndAdornment, InputInfoText, InputValidationIcon, Link, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import { validatePassword } from '../../../../../helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { IBaseRegistrationFinalStepProps } from '..';
import { FramedContent } from '../../../../../components/framed-content';
export interface IRegistrationPasswordProps extends IBaseRegistrationFinalStepProps {
  
}

export const RegistrationPassword: React.FC<IRegistrationPasswordProps> = ({ onDataValidation}) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [rulesAccepted, setRulesAccepted] = useState(false);

  function isValid(pw1: string, pw2: string) {
    return !!validatePassword(pw1) && !!validatePassword(pw2) && pw1 === pw2;
  }
  const validate = useCallback((pw1: string, pw2: string, termsAccepted: boolean, rulesAccepted: boolean) => {
    return (isValid(pw1, pw2) && termsAccepted && rulesAccepted);
  },[]);

  useEffect(() => {
    onDataValidation(validate(password, passwordRepeat, termsAccepted, rulesAccepted), {password})
  }, [password, passwordRepeat, termsAccepted, rulesAccepted, validate, onDataValidation])

  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Willkommen auf Schüler Connect Online!
        </Typo>
      </Bx>
      <Bx>
        <Typo variant="subtitle1">Bevor es losgeht vergeben Sie bitte ein sicheres Passwort für Ihren Zugang.</Typo>
      </Bx>
      <Bx my={8}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Input
              inputLabel={{ children: 'Passwort' }}
              input={{
                value: password,
                placeholder: 'Passwort eingeben',
                type: showPassword ? 'text' : 'password',
                onChange: e => {
                  setPassword(e.target.value);
                },
                name: 'password1',
                endAdornment: (
                  <InputEndAdornment>
                    <IconBtn size="small" onClick={() => setShowPassword(!showPassword)}>
                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </IconBtn>
                    {validatePassword(password) && <InputValidationIcon type="success" />}
                  </InputEndAdornment>
                )
              }}
            />
            {password.length > 0 && !validatePassword(password) && (
              <Bx mt={1}>
                <InputInfoText type="error">
                  Das Passwort muss Groß- und Kleinbuchstaben, eine Zahl und ein Sonderzeichen beinhalten und mindestens 8 Zeichen lang sein.
                </InputInfoText>
              </Bx>
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <Input
              inputLabel={{ children: 'Passwort wiederholen' }}
              input={{
                value: passwordRepeat,
                placeholder: 'Passwort eingeben',
                type: showPassword ? 'text' : 'password',
                onChange: e => {
                  setPasswordRepeat(e.target.value);
                },
                name: 'password2',
                endAdornment: (
                  <InputEndAdornment>
                    <IconBtn size="small" onClick={() => setShowPassword(!showPassword)}>
                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </IconBtn>
                    {validatePassword(passwordRepeat) && <InputValidationIcon type="success" />}
                  </InputEndAdornment>
                )
              }}
            />
            {passwordRepeat.length > 0 && !validatePassword(passwordRepeat) && (
              <Bx mt={1}>
                <InputInfoText type="error">Die Passwörter simmen nicht überein!</InputInfoText>
              </Bx>
            )}
          </Grid>
          <Grid xs={12} item>
            <Bx mt={{ xs: 6, md: 8, lg: 12 }}>
              <Bx>
                <CheckboxControl
                  control={{
                    color: 'primary',
                    name: 'termsAccepted',
                    value: termsAccepted,
                    onChange: e => {
                      setTermsAccepted(e.target.checked);
                    }
                  }}
                  checked={termsAccepted}
                  label={
                    <Typo>
                      Ich habe die{' '}
                      <Link to="/nutzungsbedingungen" target="_blank">
                        Nutzungsbedingungen
                      </Link>{' '}
                      gelesen und akzeptiere diese.
                    </Typo>
                  }
                />
              </Bx>
              <Bx mt={1}>
                <CheckboxControl
                  control={{
                    color: 'primary',
                    name: 'rulesAccepted',
                    value: rulesAccepted,
                    onChange: e => {
                      setRulesAccepted(e.target.checked);
                    }
                  }}
                  checked={rulesAccepted}
                  label={
                    <Typo>
                      Ich habe die{' '}
                      <Link to="/gemeinschaftsstandards" target="_blank">
                        Gemeinschaftsstandards
                      </Link>{' '}
                      gelesen und akzeptiere diese.
                    </Typo>
                  }
                />
              </Bx>
            </Bx>
          </Grid>
        </Grid>
      </Bx>
    </FramedContent>
  );
};
