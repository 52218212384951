import React from 'react';
import { Bx, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import { FormControlInput } from '../../../../../components/form/control/input';
import { validateEmail } from '../../../../../helper';
import { FramedContent } from '../../../../../components/framed-content';

export interface IRegistrationInitializeProps {
  email: string;
  setEmail: (email: string) => void;
}

export const RegistrationInitialize: React.FC<IRegistrationInitializeProps> = ({ email, setEmail }) => {
  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Registrierung auf Schüler Connect Online
        </Typo>
      </Bx>
      <Bx>
        <Typo variant="subtitle1">Bitte tragen Sie Ihre Daten ein damit wir Sie kontaktieren können.</Typo>
      </Bx>
      <Bx my={8}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <Bx mb={2}>
                  <FormControlInput
                    value={email}
                    disabled={false}
                    label={'E-Mail-Adresse'}
                    placeholder={'E-Mail-Adresse eingeben'}
                    minLength={2}
                    maxLength={0}
                    onChange={setEmail}
                    type={'email'}
                    validator={v => validateEmail(v)}
                    fieldGroup={'userData'}
                    successMessage=""
                    invalidMessage="Ihre Email muss ein gültiges Format haben!"
                    infotext={
                      <React.Fragment>
                        Wir verwenden Ihre E-Mail-Adresse ausschließlich zur Anmeldung und zur Zusendung von Benachrichtigungen. Anderen Nutzenden auf dem Portal wird sie
                        nicht angezeigt.
                      </React.Fragment>
                    }
                    lazy
                  />
                </Bx>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Bx>
    </FramedContent>
  );
};
