import React from 'react';
import { Bx, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import { ICategoryItem } from '../../../../../model/ryve/category';
import { FormCategorySelect } from '../../../../../components/form/control/category-select';
import { buildCategoryDictionary } from '../../../../../helper';
import { TProfileType } from '../../../../../model/data/registration';
import { FramedContent } from '../../../../../components/framed-content';

export interface IRegistrationSubjectProps {
  subjectOptions: ICategoryItem[];

  subjects: ICategoryItem[];
  setSubjects: (subjects: ICategoryItem[]) => void;

  subjectsLow: ICategoryItem[];
  setSubjetcsLow: (subjectsLow: ICategoryItem[]) => void;

  subjectsMedium: ICategoryItem[];
  setSubjetcsMedium: (subjectsMedium: ICategoryItem[]) => void;

  subjectsHigh: ICategoryItem[];
  setSubjetcsHigh: (subjectsHigh: ICategoryItem[]) => void;

  type: TProfileType;

  onValidated: (valid: boolean) => void;
}

export const RegistrationSubject: React.FC<IRegistrationSubjectProps> = ({
  subjectOptions,
  subjects,
  setSubjects,
  subjectsLow,
  setSubjetcsLow,
  subjectsMedium,
  setSubjetcsMedium,
  subjectsHigh,
  setSubjetcsHigh,
  type,
  onValidated
}) => {
  function validate() {
    onValidated(subjects.length > 0);
  }

  function handleChange(value: string, setValueFunction: (subjects: ICategoryItem[]) => void, resultList: ICategoryItem[]){
    if(resultList.filter(r => r._id == value).length > 0){
      var selectedRemove = resultList.find(r => r._id == value);
      if(selectedRemove){
        var idx = 0;
        for (let i = 0; i < resultList.length; i++) {
          if(resultList[i]._id == selectedRemove._id) idx = i;
        }
        resultList.splice(resultList.indexOf(selectedRemove), 1);
      }
    }else{
      var selectedAdd = subjectOptions.filter(s => s._id == value)[0];
      if(selectedAdd){
        resultList.push(selectedAdd);
      }
    }
    setValueFunction(resultList);
  }

  const getRemainingLow = () => {
    return subjects.filter(s => {
      for (let i = 0; i < subjectsMedium.length; i++) {
        if(subjectsMedium[i]._id == s._id){
          return false;
        }
      }
      for (let i = 0; i < subjectsHigh.length; i++) {
        if(subjectsHigh[i]._id == s._id){
          return false;
        }
      }
      return true;
    });
  }

  const getRemainingMedium = () => {
    return subjects.filter(s => {
      for (let i = 0; i < subjectsLow.length; i++) {
        if(subjectsLow[i]._id == s._id){
          return false;
        }
      }
      for (let i = 0; i < subjectsHigh.length; i++) {
        if(subjectsHigh[i]._id == s._id){
          return false;
        }
      }
      return true;
    });
  }

  const getRemainingHigh = () => {
    return subjects.filter(s => {
      for (let i = 0; i < subjectsLow.length; i++) {
        if(subjectsLow[i]._id == s._id){
          return false;
        }
      }
      for (let i = 0; i < subjectsMedium.length; i++) {
        if(subjectsMedium[i]._id == s._id){
          return false;
        }
      }
      return true;
    });
  }

  validate();
  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Registrierung auf Schüler Connect Online
        </Typo>
      </Bx>
      <Bx>
        <Typo variant="subtitle1">Bitte tragen Sie Ihre Fächer ein</Typo>
      </Bx>
      <Bx my={8}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormCategorySelect 
              value={subjects.map(s => s._id)}
              categoryTree={subjectOptions}
              categoryRepository={buildCategoryDictionary(subjectOptions)}
              label={'Fächer wählen'}
              placeholder={'Sie haben noch keine Fächer gewählt'}
              placeholderSearch={'Fach finden'}
              buttonTitle={'Fach hinzufügen'}
              minLength={0}
              maxLength={0}
              onChange={item => {
                handleChange(item, setSubjects, subjects);
              }}
              />
              <FormCategorySelect 
              value={subjectsHigh.map(s => s._id)}
              categoryTree={getRemainingHigh()}
              categoryRepository={buildCategoryDictionary(subjectOptions)}
              label={type == 'student' ? 'Fächer wählen bei denen ich viel Hilfe brauche' : 'Fächer bei denen ich sehr gut Hilfe geben kann'}
              placeholder={'Sie haben noch keine Fächer gewählt'}
              placeholderSearch={'Fach finden'}
              buttonTitle={'Fach hinzufügen'}
              minLength={0}
              maxLength={0}
              onChange={item => {
                handleChange(item, setSubjetcsHigh, subjectsHigh);
              }}
              />
              <FormCategorySelect 
              value={subjectsLow.map(s => s._id)}
              categoryTree={getRemainingLow()}
              categoryRepository={buildCategoryDictionary(subjectOptions)}
              label={type == 'student' ? 'Fächer bei denen ich wenig Hilfe brauche' : 'Fächer bei denen ich wenig Hilfe geben kann'}
              placeholder={'Sie haben noch keine Fächer gewählt'}
              placeholderSearch={'Fach finden'}
              buttonTitle={'Fach hinzufügen'}
              minLength={0}
              maxLength={0}
              onChange={item => {
                handleChange(item, setSubjetcsLow, subjectsLow);
              }}
              />
              <FormCategorySelect 
              value={subjectsMedium.map(s => s._id)}
              categoryTree={getRemainingMedium()}
              categoryRepository={buildCategoryDictionary(subjectOptions)}
              label={type == 'student' ? 'Fächer wählen bei denen ich mittlere Hilfe brauche' : 'Fächer bei denen ich gut Hilfe geben kann'}
              placeholder={'Sie haben noch keine Fächer gewählt'}
              placeholderSearch={'Fach finden'}
              buttonTitle={'Fach hinzufügen'}
              minLength={0}
              maxLength={0}
              onChange={item => {
                handleChange(item, setSubjetcsMedium, subjectsMedium);
              }}
              />
            </Bx>
          </Grid>
        </Grid>
      </Bx>
    </FramedContent>
  );
};
