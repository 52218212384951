import React, { RefObject, useEffect, useRef } from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import { Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTimes } from '@fortawesome/pro-light-svg-icons';
import { Bx, useBreakpoints } from '@curry-group/mui-curcuma';

import { useDispatch, useSelector } from 'react-redux';
import { setMessageDraftEdit, setMessageDraftQuotes, setMessageDraftThreadRoot, voteMessageRequest } from '../../../../data/reducer/communication';

import { ConferenceChatFrame, ConferenceIconBtn } from '../../../../components/conferencing';
import { HeadlineContainer } from '../../../../components/headline-container';
import { ConnectedCommunicationInput } from '../../communication/input';
import { ConnectedMessages } from '../../communication/messages';

export const ConferencingSidebar: React.FC<{ onCloseClick?: () => void; onDropdownClick?: (anchor: HTMLButtonElement) => void; fullscreen?: boolean }> = ({
  onCloseClick,
  onDropdownClick,
  fullscreen
}) => {
  const dispatch = useDispatch();
  const conferenceMessage = useSelector(state => state.conferencing.message);
  const communicationDraftThreadRootId = useSelector(state => state.communication.messageDraft?.conference?.threadRoot?.id);
  const theme = useTheme();
  const breakpoints = useBreakpoints();
  const menuButtonRef = useRef<HTMLButtonElement>() as RefObject<HTMLButtonElement>;
  useEffect(() => {
    if (conferenceMessage?.id !== communicationDraftThreadRootId) {
      dispatch(setMessageDraftThreadRoot({ id: 'conference', threadRoot: conferenceMessage }));
    }
  }, [dispatch, conferenceMessage, communicationDraftThreadRootId]);

  if (conferenceMessage) {
    return (
      <ConferenceChatFrame
        height="100%"
        bgcolor="background.default"
        fullscreen={fullscreen}
        bottomBar={
          <ConnectedCommunicationInput
            small
            inputId="conference"
            displayThreadRoot={false}
            removeThreadRoot={() => {
              dispatch(setMessageDraftThreadRoot({ id: 'conference', threadRoot: conferenceMessage }));
            }}
          />
        }
        headBar={
          <HeadlineContainer
            p={0}
            bgcolor="background.paper"
            px={3}
            py={2.5}
            minHeight={theme?.custom?.['headBar']?.minHeightDesktop}
            headline={conferenceMessage?.content}
            headlineVariant="h4"
            headlineComponent="h5"
            endAdornment={
              <Bx display="flex">
                {breakpoints.smDown && (
                  <Bx mr={2}>
                    <ConferenceIconBtn
                      ref={menuButtonRef}
                      semiTransparent
                      aria-controls="mobile-more-menu"
                      aria-haspopup="true"
                      onClick={() => {
                        if (menuButtonRef.current) {
                          onDropdownClick?.(menuButtonRef.current);
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faEllipsisH} />
                    </ConferenceIconBtn>
                  </Bx>
                )}
                <Bx>
                  <Tooltip title="Chat schließen" arrow>
                    <ConferenceIconBtn semiTransparent onClick={onCloseClick}>
                      <FontAwesomeIcon icon={faTimes} />
                    </ConferenceIconBtn>
                  </Tooltip>
                </Bx>
              </Bx>
            }
          />
        }
      >
        <Bx height="100%" display="flex" flexDirection="column" flexWrap="nowrap">
          <ConnectedMessages
            p={3}
            scrollContainer={true}
            alias={conferenceMessage.id}
            autoWidth={false}
            quoteClicked={message => {
              dispatch(setMessageDraftQuotes({ quotes: message, id: 'conference' }));
            }}
            disableThreading={true}
            forceInlineThreading
            threadRoot={conferenceMessage}
            threadingClicked={message => {
              dispatch(setMessageDraftThreadRoot({ id: 'conference', threadRoot: message }));
            }}
            editMessage={message => {
              dispatch(setMessageDraftEdit({ id: 'conference', message }));
            }}
            voteDownClick={message => {
              dispatch(voteMessageRequest({ communicationId: message.parent, alias: 'conferencing', messageId: message.id, up: false }));
            }}
            voteUpClick={message => {
              dispatch(voteMessageRequest({ communicationId: message.parent, alias: 'conferencing', messageId: message.id, up: true }));
            }}
          />
        </Bx>
      </ConferenceChatFrame>
    );
  } else {
    return null;
  }
};
