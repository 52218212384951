import { useState } from 'react';
import { Anohana, useBreakpoints } from '@curry-group/mui-curcuma';

import { IConferencingOutlet, IConferencingParticipant } from '../interfaces';
import { Sidebar } from '../../frame/sidebar';
import { Frame, Content } from '../../frame';
import { ConferenceFrame } from '../layout/frame';
import { ParticipantOutlet } from './participants';
import { ConferencingControlsFrame } from '../controls/frame';
import { ConferencingControlsLeft } from '../controls/left';
import { ConferencingControlsMiddle } from '../controls/middle';
import { ConferencingControlsRight } from '../controls/right';
import { ConferencingModalSettings } from '../sub-modal/settings';
import { ConferencingModalParticipants } from '../sub-modal/participants';

export const ConferencingOutlet: React.FC<IConferencingOutlet> = ({
  desktopActive,
  myMedia,
  open,
  publisher,
  currentParticipants,
  session,
  sidebar,
  sidebarVisible,
  speakers,
  setSidebarVisible,
  onDevicesChange,
  closeClick,
  toggleHandClick,
  toggleAudioClick,
  toggleVideoClick,
  toggleDesktopClick,
  onInfoClick
}) => {
  const [fullscreen, setFullscreen] = useState(false);
  const [participantsOpen, setParticipantsOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const breakpoints = useBreakpoints();
  let cav = true;
  if (localStorage.getItem('__vidConfCtlAlways')) {
    cav = localStorage['__vidConfCtlAlways'] === '1';
  }
  const [ctlAlVis, setCtlAlVis] = useState(cav);
  const dp: any = publisher?.filter(m => m.desktop)[0];
  const normalPublisher: any[] = publisher?.filter(m => !m.desktop) || [];
  const publisherUnion: IConferencingParticipant[] = [
    { id: session?.publisherIdJanus || -100, self: true, audio: session?.audio, video: session?.video, media: myMedia, speaking: session?.speaking },
    ...normalPublisher
  ];
  let visibleParticipants: IConferencingParticipant[] = publisherUnion;

  return (
    <Anohana open={open} fullScreen transition="zoom">
      <Frame>
        {sidebar && <Sidebar sidebarPosition="right">{sidebar}</Sidebar>}
        <Content>
          <ConferenceFrame
            controlsAlwaysVisible={ctlAlVis}
            stream={<ParticipantOutlet participants={visibleParticipants} speakers={speakers} desktop={dp} />}
            frameControls={
              !sidebarVisible && breakpoints.smDown ? (
                <ConferencingControlsFrame
                  onInfoClick={() => {
                    setParticipantsOpen(true);
                  }}
                  onSettingsClick={() => {
                    setSettingsOpen(true);
                  }}
                />
              ) : undefined
            }
            leftControls={
              breakpoints.smDown ? undefined : (
                <ConferencingControlsLeft
                  desktop={desktopActive}
                  toggleDesktop={toggleDesktopClick}
                  toggleSettings={() => {
                    setSettingsOpen(!settingsOpen);
                  }}
                  toggleParticipants={() => {
                    setParticipantsOpen(!participantsOpen);
                  }}
                />
              )
            }
            middleControls={
              <ConferencingControlsMiddle
                audio={session?.audio}
                video={session?.video}
                hand={session?.hand}
                close={closeClick}
                toggleHand={toggleHandClick}
                toggleAudio={toggleAudioClick}
                toggleVideo={toggleVideoClick}
              />
            }
            rightControls={
              breakpoints.smDown ? undefined : (
                <ConferencingControlsRight
                  gridview={!fullscreen}
                  chatVisible={sidebarVisible}
                  onChatVisibleChange={v => {
                    setSidebarVisible?.(v);
                  }}
                  onGridviewChange={gridview => {
                    setFullscreen(!gridview);
                  }}
                />
              )
            }
          />
          <ConferencingModalParticipants
            open={participantsOpen}
            onInfoClick={onInfoClick}
            participants={currentParticipants}
            onCloseClick={() => {
              setParticipantsOpen(false);
            }}
          />
          <ConferencingModalSettings
            open={settingsOpen}
            previewEnabled={!session?.video}
            ctlAlVis={ctlAlVis}
            onCtlAlVisChange={vis => {
              localStorage['__vidConfCtlAlways'] = vis ? '1' : '0';
              setCtlAlVis(vis);
            }}
            onDevicesChange={(audio, audioOut, video) => {
              onDevicesChange?.(audio, audioOut, video);
            }}
            onCancel={() => {
              setSettingsOpen(false);
            }}
            onCloseClick={() => {
              setSettingsOpen(false);
            }}
            onSave={() => {
              setSettingsOpen(false);
            }}
          />
        </Content>
      </Frame>
    </Anohana>
  );
};
