import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import clsx from 'clsx';
import { Bx, IBxProps } from '@curry-group/mui-curcuma';

export interface INavBarProps extends IBxProps {
  small?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: 'hidden',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 12
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.primary.dark
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.light
    }
  }
}));

/**
 * The `NavBar` component is an extension of the [Material-UI Box](https://material-ui.com/components/box/) and inherits all properties of it.
 */
export const NavBar: React.FunctionComponent<INavBarProps> = ({ small, children, className, ...rest }) => {
  const classes = useStyles({ small });

  return (
    <Bx
      component="header"
      position="relative"
      top={0}
      left={0}
      display="flex"
      flexDirection="column"
      alignItems={small ? 'center' : 'flex-start'}
      height="100%"
      px={small ? 0 : 3.5}
      py={3}
      bgcolor="primary.main"
      color="primary.contrastText"
      width={small ? 64 : 342}
      className={clsx(classes.root, className)}
      {...rest}
    >
      {children}
    </Bx>
  );
};

NavBar.defaultProps = {
  small: false
};
