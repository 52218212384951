import React, {useState, useCallback, useEffect} from 'react';
import { Bx, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import { FormControlInput } from '../../../../../components/form/control/input';
import { validateEmail, validateInput, validatePhone } from '../../../../../helper';
import { IBaseRegistrationStepProps } from '../..';
import { useSelector } from 'react-redux';
import { FramedContent } from '../../../../../components/framed-content';

export interface IRegistrationParentsProps extends IBaseRegistrationStepProps {
}

export const RegistrationGuardian: React.FC<IRegistrationParentsProps> = ({
  onDataValidation
}) => {
  const {registrationRequestData} = useSelector(state => state.registrationRequest);
  const [firstName,   setFirstName]   = useState<string>(registrationRequestData?.guardianFirstName || "");
  const [lastName,    setLastName]    = useState<string>(registrationRequestData?.guardianLastName || "");
  const [email,       setEmail]       = useState<string>(registrationRequestData?.guardianEmail || "");
  const [phone,       setPhone]       = useState<string>(registrationRequestData?.guardianPhone || "");

  const validate = useCallback((firstName: string, lastName: string, email: string, phone: string) => {
    return (!!validateEmail(email) && !!validatePhone(phone) && !!validateInput(firstName) && !!validateInput(lastName));
  }, []);

  useEffect(() => {
    onDataValidation(validate(firstName, lastName, email, phone), {guardianFirstName: firstName, guardianEmail: email, guardianLastName: lastName, guardianPhone: phone});
  }, [firstName, lastName, email, phone, validate, onDataValidation]);
  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Registrierung auf Schüler Connect Online
        </Typo>
      </Bx>
      <Bx>
        <Typo variant="subtitle1">Bitte tragen Sie die Daten eines Erziehungsberechtigten ein.</Typo>
      </Bx>
      <Bx my={8}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormControlInput
                value={firstName}
                label={'Vorname'}
                placeholder={'Vorname eingeben'}
                minLength={2}
                maxLength={50}
                onChange={setFirstName}
                validator={value => !!value}
                successMessage=""
                fieldGroup={'userData'}
                lazy
              />
            </Bx>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormControlInput
                value={lastName}
                label={'Nachname'}
                placeholder={'Nachname eingeben'}
                minLength={2}
                maxLength={50}
                onChange={setLastName}
                validator={value => !!value}
                successMessage=""
                fieldGroup={'userData'}
                lazy
              />
            </Bx>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <Bx mb={2}>
                  <FormControlInput
                    value={email}
                    disabled={false}
                    label={'E-Mail-Adresse'}
                    placeholder={'E-Mail-Adresse eingeben'}
                    minLength={2}
                    maxLength={0}
                    onChange={setEmail}
                    type={'email'}
                    validator={v => validateEmail(v)}
                    fieldGroup={'userData'}
                    successMessage=""
                    invalidMessage="Ihre Email muss ein gültiges Format haben!"
                    infotext={
                      <React.Fragment>
                        Wir verwenden Ihre E-Mail-Adresse ausschließlich zur Anmeldung und zur Zusendung von Benachrichtigungen. Anderen Nutzenden auf dem Portal wird sie
                        nicht angezeigt.
                      </React.Fragment>
                    }
                    lazy
                  />
                </Bx>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Bx mb={2}>
                  <FormControlInput
                    value={phone}
                    label={'Telefon'}
                    placeholder={'Telefonnummer eingeben'}
                    minLength={0}
                    maxLength={0}
                    onChange={setPhone}
                    validator={v => validatePhone(v)}
                    successMessage=""
                    invalidMessage="Bitte geben Sie ein gültige Telefonnummer an"
                    lazy
                  />
                </Bx>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Bx>
    </FramedContent>
  );
};
