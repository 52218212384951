import { Location } from 'history';
import { faHome, faBell, faComment, IconDefinition, faSlidersH } from '@fortawesome/pro-light-svg-icons';
import { INavigationConfig } from '../../model/configuration/navigation';
import { INavBarProps } from './NavBar';

export const iconMapping: { [key: string]: IconDefinition } = {
  start: faHome,
  notifications: faBell,
  tandems: faComment,
  'admin-dashboard': faSlidersH
};
export interface INavbarBadges {
  badges: { [id: string]: number };
}
export interface INavbarFrameProps extends INavbarBadges {
  sections: INavigationConfig[];
  userId?: string;
  userFullName?: string;
  userAvatarSrc?: string;
}

export interface INavbarOpenProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}
export interface INavbarFrameConnectedProps {
  small: boolean;
  showHamburger: boolean;
}
export function navItemActive(location: Location<unknown>, path: string) {
  return (location.pathname === '/' && (path === '/' || path === '/start')) || (location.pathname !== '/' && path !== '/' && location.pathname.startsWith(path));
}

export interface INavbarContentProps extends INavbarBadges {
  sections: INavigationConfig[];
}

export interface INavbarFooterProps {
  userId?: string;
  userFullName?: string;
  userAvatarSrc?: string;
}

export interface INavbarBaseProps extends INavBarProps, INavbarContentProps, INavbarHeaderProps, INavbarFooterProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}
export interface INavbarHeaderProps {
  open: boolean;
}

export { NavbarDrawer } from './drawer';
export { NavbarFrame } from './frame';
