import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LoginComponent } from '../../../../components/auth/login';
import { userCredLoginRequest } from '../../../../data/reducer/app';

export const LoginView: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loginError = useSelector(state => state.app.loginError);

  return (
    <LoginComponent
      headline="Willkommen zurück!"
      subtitle="Melden Sie sich mit Ihren Zugangsdaten auf Schüler Connect Online an."
      splashSidebarImage="/images/welcome.svg"
      navBarLogo="/images/logo.svg"
      navBarAlt="Schüler Connect Online"
      loginError={loginError}
      loginErrorCaption="Benutzer existiert nicht oder E-Mail und Passwort stimmen nicht überein."
      onLogin={(username, password) => {
        dispatch(userCredLoginRequest({ username, password, persist: true }));
      }}
      onLostPassword={() => {
        history.push('/passwort-vergessen');
      }}
      onRegister={() => {
        history.push('/anfrage');
      }}
    />
  );
};
