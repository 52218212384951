import { useHistory } from 'react-router-dom';
import { Btn, Bx, IconBtn, Typo } from '@curry-group/mui-curcuma';
import { Wizard, WizardContent, WizardNavigation } from '@curry-group/react-wizard';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Hidden } from '@material-ui/core';
import React, { useState } from 'react';
import { Header, Content, Footer, Sidebar } from '../../../../components/frame';
import { useIsMobile } from '../../../../helper/hooks/useIsMobile';
import { useDispatch } from 'react-redux';
import {  initRegistrationRequest } from '../../../../data/reducer/registration';
import { validateEmail } from '../../../../helper';

import { RegistrationInitialize } from './../step-one/initialize';
import { RegistrationInitializeFinished } from './../step-one/finished';
import { NavBarLogo } from '../../../../components/navbar-logo';
import { MobileHeadBar } from '../../../../components/mobile-head-bar';
import { HeadBar } from '../../../../components/head-bar';
import { BottomBar } from '../../../../components/bottom-bar';
import { SplashSideBar } from '../../../../components/splash-sidebar';

export const InitializeRegistration: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const numSteps = 1;
    const [currentStep, setCurrentStep] = useState(0);
    const [mailAddress, setMailAddress] = useState<string>('');
    const [valid, setValid] = useState(false);
    const mobile = useIsMobile();
  
    const prevStep = () => {
      history.push('/');
    };
  
    const sendMail = () => {
      if (valid && mailAddress) {
        dispatch(initRegistrationRequest(mailAddress));
        setCurrentStep(1);
      }
    };
  
    const onMailChanged = (mail: string) => {
      setMailAddress(mail);
      setValid(validateEmail(mail));
    };
  
    return (
      <>
        <Hidden mdDown>
          <Sidebar>
            <SplashSideBar src="/images/register.svg" alt="Anmeldung">
              <Bx display="flex" width="100%" alignItems="center" justifyContent="space-between">
                <Bx display="flex" justifyContent={'flex-start'} alignItems="center" height="100%">
                  <NavBarLogo to="/" src="/images/logo.svg" alt="Schüler Connect Online" />
                </Bx>
              </Bx>
            </SplashSideBar>
          </Sidebar>
        </Hidden>
        <Wizard step={currentStep} stepCount={numSteps}>
          {currentStep <= 3 && (
            <Header>
              {mobile && (
                <MobileHeadBar>
                  <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" py={1}>
                    <Grid item xs={3}>
                      <Bx display="flex" pr={1} alignItems="center" justifyContent="flex-start" height="100%">
                        <IconBtn size="medium" onClick={prevStep}>
                          <FontAwesomeIcon icon={faLongArrowLeft} />
                        </IconBtn>
                      </Bx>
                    </Grid>
                    <Grid item xs={6}>
                      <Bx display="flex" alignItems="center" justifyContent="center" height="100%">
                        <NavBarLogo to="/" src="/images/logo_dark.svg" />
                      </Bx>
                    </Grid>
                  </Bx>
                </MobileHeadBar>
              )}
  
              {!mobile && <HeadBar transparent={false} backButtonLink={prevStep}></HeadBar>}
            </Header>
          )}
          <Content>
            <WizardContent>
              {(callbacks, options) => {
                return (
                  <React.Fragment key={`registration_${options?.currentStep}`}>
                    {options.currentStep === 0 && <RegistrationInitialize setEmail={onMailChanged} email={mailAddress} />}
                    {options.currentStep === numSteps && <RegistrationInitializeFinished />}
                  </React.Fragment>
                );
              }}
            </WizardContent>
          </Content>
          <Footer>
            <WizardNavigation>
              {(callbacks, options) => {
                if (options.currentStep > 0) return <></>;
  
                return (
                  <Bx gridArea="footer" zIndex={2} position="sticky" bottom="0">
                    <BottomBar scrolled bottom={'unset'}>
                      <Bx flexGrow={1} flexShrink={1} flexBasis="auto">
                        &nbsp;
                      </Bx>
                      <Bx alignSelf="flex-end" textAlign="right">
                        <Btn
                          size="large"
                          color="success"
                          disabled={!valid}
                          variant="contained"
                          onClick={e => {
                            e.preventDefault();
                            sendMail();
                          }}
                        >
                          <Typo variant="inherit" noWrap>
                            Senden
                          </Typo>
                        </Btn>
                      </Bx>
                    </BottomBar>
                  </Bx>
                );
              }}
            </WizardNavigation>
          </Footer>
        </Wizard>
      </>
    );
  };