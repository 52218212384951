import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import clsx from 'clsx';

export interface INavBarHeadlineProps extends React.HTMLAttributes<HTMLSpanElement> {}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    fontSize: theme.typography.pxToRem(12)
  }
}));

/**
 * The `NavBarHeadline` component inherits the HTML attributes of an `span`-Element.
 */
export const NavBarHeadline: React.FunctionComponent<INavBarHeadlineProps> = ({ children, className, ...rest }) => {
  const classes = useStyles();

  return (
    <span className={clsx(classes.root, className)} {...rest}>
      {children}
    </span>
  );
};
