import { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { fetchStartupRequest } from '../data/reducer/app';

import { RootRouting } from './routing';
import { Frame } from '../components/frame';
import { ConferencingOutletImpl } from './views/conferencing';
import { SignalRWatchComponent } from '../components/communication/signalr-watch';
import { fetchNotificationsRequest } from '../data/reducer/notifications';

const imgTrackUrl = process.env.REACT_APP_MATOMO_IMGTRACKER;

export const AppRouting = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(state => state.app.loggedIn);

  useEffect(() => {
    dispatch(fetchStartupRequest());
    dispatch(fetchNotificationsRequest());
  }, [loggedIn, dispatch]);

  return (
    <Frame>
      {/* matomo img tracker */}
      {imgTrackUrl && <img src={imgTrackUrl + '&__now=' + Date.now()} referrerPolicy="unsafe-url" style={{ border: 0, display: 'none' }} alt="" />}

      {/* app */}
      <RootRouting />

      {/* conferencing lab ui */}
      <Switch>
        <Route path={'/:seg1/:seg2/:seg3/:seg4'}>
          <SignalRWatchComponent />
        </Route>
      </Switch>

      {/* conferencing */}
      <ConferencingOutletImpl />
    </Frame>
  );
};
