import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import useTheme from '@material-ui/core/styles/useTheme';
import { Ava, Bx, Divider, IAvaProps, IBxProps, ILinkProps, isExternalUrl, Link, TCurcumaColors, Typo } from '@curry-group/mui-curcuma';

export type TNavBarUserLinkProps = {
  name: string;
  title?: string;
  to?: string;
  target?: HTMLAnchorElement['target'];
  onClick?: (e: React.MouseEvent) => void;
};
export interface INavBarUserProps extends IBxProps {
  avatar: IAvaProps;
  fullName: string;
  avatarTo?: (e: React.MouseEvent) => void | string;
  links?: Array<TNavBarUserLinkProps>;
  linksColor?: TCurcumaColors;
  linksSpacing?: number;
  linksDivider?: React.ReactElement;
  linksUnderline?: ILinkProps['underline'];
}

interface INavBarUserStyles {
  linksSpacing?: number;
  linksColor: React.CSSProperties['color'];
  linksColorHover: React.CSSProperties['color'];
}

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    innerBox: {
      marginLeft: theme.spacing(2)
    },
    name: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 500
    },
    link: {
      cursor: 'pointer',
      fontSize: theme.typography.pxToRem(12),
      color: (props: INavBarUserStyles) => props.linksColor,

      '&:hover': {
        color: (props: INavBarUserStyles) => props.linksColorHover
      }
    },
    lastLink: {
      marginRight: (props: INavBarUserStyles) => (props.linksSpacing ? theme.spacing(props.linksSpacing / 2) : undefined)
    },
    linkBox: {
      marginRight: (props: INavBarUserStyles) => (props.linksSpacing ? theme.spacing(props.linksSpacing / 2) : undefined),
      '&:last-child': {
        marginRight: 0
      }
    }
  }),
  {
    name: 'Curcuma',
    meta: 'NavBarUser'
  }
);

/**
 * The `NavBarUser` component is an extension of the [Material-UI Box](https://material-ui.com/components/box/) and inherits all properties of it.
 */
export const NavBarUser: React.FunctionComponent<INavBarUserProps> = ({
  avatar,
  fullName,
  avatarTo,
  links,
  linksColor,
  linksUnderline,
  className,
  linksSpacing,
  linksDivider,
  ...rest
}) => {
  const theme = useTheme();
  const compLinkDivider = linksDivider || <Divider direction="vertical" strokeColor="currentColor" height={10} />;
  let fc;
  let fch;
  if (linksColor === 'default') {
    fc = 'inherit';
    fch = 'inherit';
  } else {
    fc = _.get(theme.palette, linksColor + '.main', linksColor);
    fch = _.get(theme.palette, linksColor + '.light', linksColor);
  }
  const classes = useStyles({ linksSpacing, linksColor: fc, linksColorHover: fch });

  return (
    <Bx className={clsx(classes.root, className)} {...rest} display="flex">
      {typeof avatarTo === 'string' && (
        <Link to={avatarTo}>
          <Ava {...avatar} />
        </Link>
      )}
      {typeof avatarTo === 'function' && <Ava {...avatar} style={{ cursor: 'pointer' }} onClick={avatarTo} />}
      {typeof avatarTo === 'undefined' && <Ava {...avatar} />}

      <Bx className={classes.innerBox}>
        <Typo color="inherit" className={classes.name}>
          {fullName}
        </Typo>

        <Bx display="flex" flexWrap="wrap">
          {links &&
            links?.length > 0 &&
            links.map((link, idx) => {
              return (
                <Bx key={idx + link.name.toLowerCase()} className={classes.linkBox} display="flex" alignItems="center">
                  <Link
                    component={link.to && !isExternalUrl(link.to) ? undefined : 'a'}
                    to={isExternalUrl(link.to) ? undefined : (link.to as any)}
                    href={isExternalUrl(link.to) ? link.to : undefined}
                    target={link.target}
                    onClick={link.onClick}
                    title={link.title}
                    underline={linksUnderline}
                    className={clsx(classes.link, idx + 1 !== links.length && classes.lastLink)}
                  >
                    {link.name}
                  </Link>
                  {idx + 1 !== links.length && compLinkDivider}
                </Bx>
              );
            })}
        </Bx>
      </Bx>
    </Bx>
  );
};

NavBarUser.defaultProps = {
  linksUnderline: 'hover',
  linksSpacing: 2,
  linksDivider: undefined,
  linksColor: 'accent'
};
