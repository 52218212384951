import React from 'react';
import clsx from 'clsx';

import { Btn, Bx, Ctn, ICtnProps, Typo } from '@curry-group/mui-curcuma';
import Grid from '@material-ui/core/Grid';
import { ClassNameMap } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

interface ILandingPageSponsorsProps {
  ctnMaxWidth: ICtnProps['maxWidth'];
  headline: string;
  h1FontSize: number;
  body4FontSize: number;
  text: string;
  classes: ClassNameMap<'line' | 'boldText'>;
  sponsorItems: { partner: { imageSrc: string; imageAlt: string }[]; sponsors: { imageSrc: string; imageAlt: string }[] };
  sponsorLabel: string;
  sponsorLink: string;
  responsive: { isXS: boolean };
}

const Button = props => {
  return <Btn {...props} />;
};

export const Sponsors: React.FC<ILandingPageSponsorsProps> = ({
  ctnMaxWidth,
  headline,
  h1FontSize,
  body4FontSize,
  text,
  classes,
  sponsorItems,
  sponsorLabel,
  sponsorLink,
  responsive
}) => {
  return (
    <Bx component="section" width="100%">
      <Ctn maxWidth={ctnMaxWidth}>
        <Grid container spacing={8}>
          <Grid item xs={12} md={4}>
            <Typo variant="h1" style={{ fontSize: h1FontSize }} className={clsx(classes.line, classes.boldText)} gutterBottom dangerouslySetInnerHTML={{ __html: headline }} />
            <Typo variant="body1" style={{ fontSize: body4FontSize }}>
              {text}
            </Typo>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={responsive.isXS ? 2 : 4}>
              <Grid item xs={12}>
                <Typo variant="h2" component="b" style={{ fontSize: h1FontSize }} gutterBottom>
                  Partner
                </Typo>
              </Grid>
              {sponsorItems.partner.map((partner, index) => {
                return (
                  <Grid item xs={4} key={index}>
                    <img src={partner.imageSrc} alt={partner.imageAlt} style={{ width: '100%', borderRadius: '8px' }} />
                  </Grid>
                );
              })}
              <Grid item xs={12}>
                <Typo variant="h2" component="b" style={{ fontSize: h1FontSize }} gutterBottom>
                  Sponsoren
                </Typo>
              </Grid>
              {sponsorItems.sponsors.map((sponsor, index) => {
                return (
                  <Grid item xs={3} key={index}>
                    <img src={sponsor.imageSrc} alt={sponsor.imageAlt} style={{ width: '100%', borderRadius: '8px' }} />
                  </Grid>
                );
              })}
              <Grid item xs={12}>
                <Button color="secondary" size="large" variant="contained" component={RouterLink} to={sponsorLink}>
                  {sponsorLabel}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Ctn>
    </Bx>
  );
};
