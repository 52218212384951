import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useRedirectAfterLogin } from '../../helper/hooks/useRedirectAfterLogin';

import { LoginView } from '../views/auth/login';
import { LogoutView } from '../views/auth/logout';
import { LostPasswordView } from '../views/auth/lost-password';
import { ResetPasswordView } from '../views/auth/reset-password';
import { RegistrationView } from '../views/registration';
import { InitializeRegistration } from '../views/registration/step-one';
import { FinalizeRegistrationView } from '../views/registration/final';
import { PublicLandingPageView } from '../views/landingpage/public';
import { ContentRouting } from './content';

import { ImprintView } from '../views/imprint';

export const RootRouting = () => {
  const loggedIn = useSelector(state => state.app.loggedIn);
  const signalRConnected = useSelector(state => state.app.signalRConnected);
  const conferencingVisible = useSelector(state => state.conferencing.visible);
  const redirectPathAfterLogin = useRedirectAfterLogin();

  if (conferencingVisible) return <></>;

  return (
    <Switch>
      {/* <Route path="/__stats/video">
        <VideoStatsComponent />
      </Route> */}

      {loggedIn && (
        <Route path="/login">
          <Redirect to={redirectPathAfterLogin} />
        </Route>
      )}

      <Route exact path="/anfrage">
        <InitializeRegistration />
      </Route>

      <Route exact path="/registrierung">
        <RegistrationView />
      </Route>

      <Route exact path="/registrierung-abschließen">
        <FinalizeRegistrationView />
      </Route>
      <Route exact path="/passwort-vergessen">
        <LostPasswordView />
      </Route>
      <Route exact path="/passwort-setzen">
        <ResetPasswordView />
      </Route>
      {!loggedIn && (
        <Route exact path={['/']}>
          <PublicLandingPageView />
        </Route>
      )}
      <Route path={['/login']}>
        <LoginView />
      </Route>
      <Route path={['/impressum']}>
        <ImprintView content="impressum" />
      </Route>
      <Route path={['/datenschutz']}>
        <ImprintView content="datenschutz" />
      </Route>
      <Route path={['/nutzungsbedingungen']}>
        <ImprintView content="nutzungsbedingungen" />
      </Route>
      <Route path={['/gemeinschaftsstandards']}>
        <ImprintView content="gemeinschaftsstandards" />
      </Route>
      <Route path={['/werde-sponsor']}>
        <ImprintView content="werde-sponsor" />
      </Route>
      {!loggedIn && (
        <Route>
          <Redirect to="/" />
        </Route>
      )}
      <Route path={['/logout']}>
        <LogoutView />
      </Route>

      {!signalRConnected && <>CONNECTING!</>}
      {signalRConnected && (
        <Route path="*">
          <ContentRouting />
        </Route>
      )}
    </Switch>
  );
};
