import { combineReducers } from 'redux';

import app from './app';
import conferencing from './conferencing';
import registration from './registration';
import notifications from './notifications';
import communication from './communication';
import detail from './detail';
import tandem from './tandem';
import admin from './admin';
import registrationRequest from './registrationRequest';
import registrationFinalRequest from './registrationFinalRequest';

export type RootState = ReturnType<ReturnType<typeof setupRootReducers>>;

// DefaultRootState for connected components
declare module 'react-redux' {
  export interface DefaultRootState extends RootState {}
}

export const setupRootReducers = () => {
  return combineReducers({
    registration,
    registrationRequest,
    registrationFinalRequest,
    app,
    conferencing,
    notifications,
    communication,
    detail,
    tandem,
    admin
  });
};
