import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IRegistrationRequestData} from '../../../model/data/registration';

interface IRegistrationRequestState {
    registrationRequestData: IRegistrationRequestData;
}

const initialState: IRegistrationRequestState = {
    registrationRequestData: {
        token: '',
        firstName: '',
        lastName: '',
        dateOfBirth: 0,
        email: '',
        phone: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
        street: '',
        type: undefined,
        dataProtectionAccepted: false,
        dataProtectionFileId: '',
        certificateOfConductFileId: '',
        guardianEmail: '',
        guardianFirstName: '',
        guardianLastName: '',
        guardianPhone: ''
    }
};

const registrationRequest = createSlice({
  name: 'registrationRequest',
  initialState,
  reducers: {
    setRegistrationRequestData: (state, action: PayloadAction<Partial<IRegistrationRequestData>>) => {
        state.registrationRequestData = {...state.registrationRequestData, ...action.payload}
    }
  }
});

export const {
  setRegistrationRequestData
} = registrationRequest.actions;
export default registrationRequest.reducer;
