import { ReactNode, FC } from 'react';
import { Bx } from '@curry-group/mui-curcuma';

export interface IContentWrapperProps {
  header: ReactNode;
}

export const Frame: FC = ({ children }) => {
  return (
    <Bx
      id="frame"
      display="grid"
      minHeight="calc(var(--vh, 1vh) * 100)"
      gridTemplateAreas={`
"navbar sidebar header sidebar-right"
"navbar sidebar content sidebar-right"
"navbar sidebar footer sidebar-right"
`}
      gridGap={0}
      gridTemplateColumns="auto auto minmax(0, 1fr) auto"
      gridTemplateRows="auto 1fr auto"
    >
      {children}
    </Bx>
  );
};

export { Header } from './header';
export { Navbar } from './navbar';
export { Content } from './content';
export { Sidebar } from './sidebar';
export { Footer } from './footer';
