import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IConferenceConnections, IConnectionExportRequest } from '../../../model/data/connction-export';
import { IRegistrationRequestListItem } from '../../../model/data/registration';

export interface IAdminState {
  connectionExportWorking?: boolean;
  connectionExportError?: boolean;
  connectionExportData?: IConferenceConnections[];

  requestsWorking?: boolean;
  requestsError?: boolean;
  requests?: IRegistrationRequestListItem[];
}

const initialState: IAdminState = {};

const admin = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    fetchConnectionExportDataRequest: (state, action: PayloadAction<IConnectionExportRequest>) => {
      state.connectionExportError = false;
      state.connectionExportWorking = true;
      state.connectionExportData = undefined;
    },
    fetchConnectionExportDataSuccess: (state, action: PayloadAction<IConferenceConnections[]>) => {
      state.connectionExportError = false;
      state.connectionExportWorking = false;
      state.connectionExportData = action.payload;
    },
    fetchConnectionExportDataFailed: state => {
      state.connectionExportError = true;
      state.connectionExportWorking = false;
      state.connectionExportData = undefined;
    },
    fetchRequestsRequest: state => {
      state.requests = [];
      state.requestsWorking = true;
      state.requestsError = false;
    },
    fetchRequestsFailed: state => {
      state.requests = [];
      state.requestsWorking = false;
      state.requestsError = true;
    },
    fetchRequestsSuccess: (state, action: PayloadAction<IRegistrationRequestListItem[]>) => {
      state.requests = action.payload;
      state.requestsWorking = false;
      state.requestsError = false;
    }
  }
});

export const {
  fetchRequestsFailed,
  fetchRequestsRequest,
  fetchRequestsSuccess,
  fetchConnectionExportDataRequest,
  fetchConnectionExportDataSuccess,
  fetchConnectionExportDataFailed
} = admin.actions;
export default admin.reducer;
