import { Bx, LinearLoader, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

export interface ILogoutComponentProps {
  caption: string;
  image: string;
  onLoggedOut: () => void;
}
export const LogoutComponent: React.FC<ILogoutComponentProps> = ({ caption, image, onLoggedOut }) => {
  const [progress, setProgress] = useState(0);

  const logout = progress > 95;
  useEffect(() => {
    if (logout) onLoggedOut();
  }, [onLoggedOut, logout]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        const rest = (100 - oldProgress) / 100;
        const diff = rest * Math.random() * 40;
        return Math.min(oldProgress + diff, 100);
      });
    }, 10);

    return () => clearInterval(timer);
  }, []);

  return (
    <Bx gridArea="content" display="flex" alignItems="center" justifyContent="center" height="100%">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item xs={12} md={10} lg={8} xl={6}>
              <Bx textAlign="center" mb={{ xs: 1, md: 2, lg: 3 }}>
                <img src={image} alt={caption} />
              </Bx>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item xs={12} md={10} lg={8} xl={6}>
              <Typo variant="subtitle1" align="center">
                {caption}
              </Typo>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item xs={10} sm={8} md={6} lg={4} xl={3}>
              <Bx mt={2}>
                <LinearLoader variant="determinate" value={progress} />
              </Bx>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Bx>
  );
};
