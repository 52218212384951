import { callGet, callPost } from '@curry-group/data-addons';
import { takeLatest, all, put, select } from 'redux-saga/effects';
import {
  fetchNotificationsRequest,
  fetchNotificationsSuccess,
  fetchNotificationsFailed,
  setNotificationsRead,
  notificationsUpdated,
  setNotificationsReadSuccess,
  setNotificationsReadFailed
} from '../../reducer/notifications';
import api from '../../api';
import { fetchStartupSuccess } from '../../reducer/app';

function* watcher() {
  yield takeLatest(fetchStartupSuccess.type, fetchNotificationsWorker);
  yield takeLatest(fetchNotificationsRequest.type, fetchNotificationsWorker);
  yield takeLatest(setNotificationsRead.type, setNotificationsReadWorker);
  yield takeLatest(notificationsUpdated.type, notificationsUpdatedWatcher);
}

function* notificationsUpdatedWatcher() {
  yield put(fetchNotificationsRequest());
}

function* fetchNotificationsWorker() {
  try {
    const notifications = yield callGet(api.app.notifications);
    yield put(fetchNotificationsSuccess(notifications));
  } catch (e) {
    yield put(fetchNotificationsFailed());
  }
}

function* setNotificationsReadWorker(action: ReturnType<typeof setNotificationsRead>) {
  try {
    let payload: string[] = [];
    if (action.payload.notifications) {
      payload = action.payload.notifications;
    } else if (action.payload.thing) {
      const notificationState = yield select(state => state.notifications);
      if (notificationState?.items?.filter(n => n.content.itemRef === action.payload.thing)?.length) {
        payload = notificationState?.items?.filter(n => n.content.itemRef === action.payload.thing)?.map(n => n._id);
      }
    }
    if (payload.length) {
      const result = yield callPost(api.app.notifications, payload);
      yield put(setNotificationsReadSuccess());
    }
  } catch (e) {
    yield put(setNotificationsReadFailed());
  }
}

export function* notifications() {
  yield all([watcher()]);
}
