function urlWithParams(url: string, args: any) {
  for (let key in args) {
    url = url.replace('#{' + key + '}#', args[key]);
  }
  return url;
}

function urlWithQuery(url: string, params: any) {
  let queryString = '';
  for (let key in params) {
    const value = params[key];
    if (value) {
      if (queryString) queryString += '&';
      queryString += key + '=' + value;
    }
  }
  if (queryString.length) return url + '?' + queryString;
  return url;
}

const coreApiUrl = '/uwao-api/core';

export default Object.freeze({
  urlWithParams: urlWithParams,
  urlWithQuery: urlWithQuery,
  core: coreApiUrl,
  auth: {
    logout: `${coreApiUrl}/auth/logout`,
    credentialLogin: `${coreApiUrl}/auth/credentiallogin`,
    lostPassword: `${coreApiUrl}/auth/lostpassword`,
    finishLostPassword: `${coreApiUrl}/auth/finishLostPassword`
  },
  app: {
    startup: '/uwao-api/sco/app/startup',
    notifications: '/uwao-api/sco/notifications',
    connections: '/uwao-api/sco/admin/export/connections',
    requests: '/uwao-api/sco/admin/requests',
    changePassword: '/uwao-api/sco/app/changepassword'
  },
  tandem: {
    users: '/uwao-api/sco/tandem/users',
    create: '/uwao-api/sco/tandem'
  },
  detail: {
    tandem: '/uwao-api/sco/tandem/#{alias}#',
    content: '/uwao-api/sco/app/content/#{alias}#'
  },
  communication: {
    prepareAttachments: '/uwao-api/sco/communication/#{id}#/prepare',
    finalizeUpload: '/uwao-api/sco/communication/#{id}#/finalize',
    files: '/uwao-api/sco/communication/#{id}#/files',
    downloadFiles: '/uwao-api/sco/communication/#{id}#/files/download',
    signalr: '/uwao-api/core/event-hub',
    base: '/uwao-api/sco/communication',
    byId: '/uwao-api/sco/communication/#{id}#',
    readMessage: '/uwao-api/sco/communication/#{id}#/read/#{timestamp}#',
    unreadMessagesForId: '/uwao-api/sco/communication/#{id}#/unread',
    unreadMessagesForTypes: '/uwao-api/sco/communication/unread',
    participation: '/uwao-api/sco/communication/#{id}#/participation',
    messages: '/uwao-api/sco/communication/#{id}#/messages',
    message: '/uwao-api/sco/communication/#{id}#/messages/#{messageId}#',
    voteMessage: '/uwao-api/sco/communication/#{id}#/messages/#{messageId}#/vote/#{vote}#',
    conferenceMessage: '/uwao-api/sco/communication/#{id}#/messages/conference?title=#{title}#',
    joinconference: '/uwao-api/sco/communication/conference/join?messageId=#{messageId}#'
  },
  registration: {
    initialize: '/uwao-api/sco/registration/init',
    register: '/uwao-api/sco/registration',
    finalize: '/uwao-api/sco/registration/finalize',
    dataprotection: '/uwao-api/sco/registration/dataprotection',
    certificate: '/uwao-api/sco/registration/certificate',
    subjects: '/uwao-api/sco/registration/final'
  }
});
