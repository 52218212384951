import React, { useEffect, useState } from 'react';
import { CheckboxControl, Ava, Bx, DropZone, formatBytes, IconBtn, Typo, InputInfoText } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import { allowedMimeTypes } from '../../../../../helper';
import { useDispatch, useSelector } from 'react-redux';
import { uploadCertificateFileRemoved, uploadCertificateFileRequest } from '../../../../../data/reducer/registration';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FramedContent } from '../../../../../components/framed-content';
export interface IRegistrationCertificateProps {
  onFileUploaded: (tempFileName?: string) => void;
  pending?: boolean;
  onPendingChanged: (pending: boolean) => void;
  missing?: boolean;
  onMissingChanged: (missing: boolean) => void;
}

export const RegistrationCertificate: React.FC<IRegistrationCertificateProps> = ({ pending, onMissingChanged, missing, onPendingChanged, onFileUploaded }) => {
  const dispatch = useDispatch();
  const [certificateAvailable, setCertificateAvailable] = useState(false);
  const [_missingChecked, _setMissingChecked] = useState(false);
  const [_pendingChecked, _setPendingChecked] = useState(false);
  const [currentFile, setCurrentFile] = useState<File>();
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileUploadFailed, setFileUploadFailed] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const { certificateTempFileName, certificateFailed, certificatePreparing } = useSelector(state => state.registration);

  const removeFile = () => {
    setCurrentFile(undefined);
    setFileUploadFailed(false);
    setFileUploaded(false);
    setFileUploading(false);
    onFileUploaded(undefined);
    dispatch(uploadCertificateFileRemoved());
  };

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setCurrentFile(file);
    dispatch(uploadCertificateFileRequest(file));
  };

  useEffect(() => {
    if (certificateTempFileName && !fileUploaded) {
      // uploaded just now
      setFileUploadFailed(false);
      setFileUploaded(true);
      setFileUploading(false);
      onFileUploaded(certificateTempFileName);
    } else if (certificateFailed && !fileUploadFailed) {
      // upload failed
      setFileUploadFailed(true);
      setFileUploaded(false);
      setFileUploading(false);
      setCurrentFile(undefined);
      onFileUploaded(undefined);
    } else if (certificatePreparing && !fileUploading) {
      // upload started
      setFileUploadFailed(false);
      setFileUploaded(false);
      setFileUploading(true);
    }
  }, [certificateTempFileName, certificateFailed, certificatePreparing, fileUploaded, fileUploading, fileUploadFailed, onFileUploaded]);

  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Willkommen auf Schüler Connect Online!
        </Typo>
      </Bx>
      <Bx>
        <Typo variant="subtitle1">Da Sie über 15 Jahre alt sind ist ein Führungszeugnis erforderlich.</Typo>
      </Bx>
      <Bx my={8}>
        <Grid container spacing={4}>
          <Grid xs={12} item>
            <Bx mt={{ xs: 6, md: 8, lg: 12 }}>
              <Bx>
                <CheckboxControl
                  control={{
                    color: 'primary',
                    name: 'missing',
                    value: missing,
                    checked: _missingChecked,
                    onChange: e => {
                      onMissingChanged(e.target.checked);
                      _setMissingChecked(e.target.checked);
                      _setPendingChecked(false);
                      setCertificateAvailable(false);
                    }
                  }}
                  label={<Typo>Führungszeugnis muss noch beantragt werden.</Typo>}
                />
              </Bx>
            </Bx>
          </Grid>
          <Grid xs={12} item>
            <Bx mt={{ xs: 6, md: 8, lg: 12 }}>
              <Bx>
                <CheckboxControl
                  control={{
                    color: 'primary',
                    name: 'pending',
                    value: pending,
                    checked: _pendingChecked,
                    onChange: e => {
                      onPendingChanged(e.target.checked);
                      _setPendingChecked(e.target.checked);
                      _setMissingChecked(false);
                      setCertificateAvailable(false);
                    }
                  }}
                  label={<Typo>Führungszeugnis ist bereits beantragt.</Typo>}
                />
              </Bx>
            </Bx>
          </Grid>
          <Grid xs={12} item>
            <Bx mt={{ xs: 6, md: 8, lg: 12 }}>
              <Bx>
                <CheckboxControl
                  control={{
                    color: 'primary',
                    name: 'certificate-available',
                    value: certificateAvailable,
                    checked: certificateAvailable,
                    onChange: e => {
                      setCertificateAvailable(e.target.checked);
                      _setMissingChecked(false);
                      _setPendingChecked(false);
                      onPendingChanged(false);
                      onMissingChanged(false);
                    }
                  }}
                  label={<Typo>Führungszeugnis vorhanden.</Typo>}
                />
              </Bx>
            </Bx>
          </Grid>
        </Grid>
        {certificateAvailable && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Bx mb={4}>
                <DropZone onDrop={onDrop} maxSize={Infinity} minSize={50} multiple={false} label="Klicken oder Dateien hier ablegen" accept={allowedMimeTypes} />
                {currentFile && (
                  <Bx mt={1}>
                    <Bx mt={0.5} display="flex" alignItems="center" p={1} bgcolor="#F8F8F8" border={1} borderRadius={'borderRadius2'} borderColor="#D6D7D9">
                      <Bx mr={2}>
                        <Typo variant="h1" color="primary" component="div">
                          <Ava size="small" variant="rounded" src={URL.createObjectURL(currentFile)} />
                        </Typo>
                      </Bx>
                      <Bx mr={2}>
                        <Typo variant="body2" fontWeight={500}>
                          {currentFile.name}
                        </Typo>
                        <Typo variant="subtitle3">{formatBytes(currentFile.size)}</Typo>
                      </Bx>
                      <Bx ml="auto">
                        <IconBtn size="small" onClick={removeFile}>
                          <FontAwesomeIcon icon={faTimes} />
                        </IconBtn>
                      </Bx>
                    </Bx>
                  </Bx>
                )}
                {certificateFailed && (
                  <Bx mt={1}>
                    <InputInfoText type={'error'}>{'Datei fehlerhaft'}</InputInfoText>
                  </Bx>
                )}
              </Bx>
            </Grid>
          </Grid>
        )}
      </Bx>
    </FramedContent>
  );
};
