import { Bx } from '@curry-group/mui-curcuma';
import { faCog, faInfo, faDesktop } from '@fortawesome/pro-light-svg-icons';
import React, { MouseEventHandler } from 'react';
import { ConferencingButton } from '../button';

export interface IConferencingControlsLeft {
  desktop?: boolean;
  toggleDesktop?: MouseEventHandler;
  toggleSettings?: MouseEventHandler;
  toggleParticipants?: MouseEventHandler;
}

export const ConferencingControlsLeft: React.FC<IConferencingControlsLeft> = ({ desktop, toggleDesktop, toggleSettings, toggleParticipants }) => {
  return (
    <Bx display="flex">
      <Bx mr={2}>
        <ConferencingButton active icon={faCog} tooltip="Einstellungen" onClick={toggleSettings} />
      </Bx>
      <Bx mr={2}>
        <ConferencingButton active icon={faInfo} tooltip="Informationen" onClick={toggleParticipants} />
      </Bx>
      <Bx mr={2}>
        <ConferencingButton active={desktop} tooltip={desktop ? 'Bildschirm nicht mehr teilen' : 'Bildschirm teilen'} icon={faDesktop} onClick={toggleDesktop} />
      </Bx>
    </Bx>
  );
};
