import React, { useEffect } from 'react';
import { Bx, Html2View, IBxProps, ICtnProps, Link, useBreakpoints } from '@curry-group/mui-curcuma';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { NotFoundComponent } from '../../../components/not-found';

import { LandingPageFooter, ILandingPageFooterProps } from '../../../components/landingpage/footer';
import { fetchContentRequest } from '../../../data/reducer/detail';
import { HeadBar } from '../../../components/head-bar';
import { FramedContent } from '../../../components/framed-content';

export const ImprintView: React.FC<{ content: string }> = ({ content }) => {
  const theme = useTheme();
  const breakpoints = useBreakpoints();
  const dispatch = useDispatch();

  const working = useSelector(state => state.detail.working);
  const item = useSelector(state => state.detail.item);
  const title = useSelector(state => state.detail.item?.title);
  const html = useSelector(state => state.detail.item?.content);

  useEffect(() => {
    dispatch(fetchContentRequest(content));
    window.scrollTo({ top: 0 });
  }, [content, dispatch]);

  const isMDUp = useMediaQuery(theme.breakpoints.up('md'));
  const ctnMaxWidth: ICtnProps['maxWidth'] = 'lg';
  const sectionPx: IBxProps['px'] = { xs: 0, md: 4 };

  return (
    <Bx gridArea="content">
      <HeadBar transparent={false} backButtonLink={'/'}>
        <Bx style={{ paddingRight: 224, paddingLeft: 224 }} py={2}>
          <Link
            to="/"
            onClick={e => {
              e.preventDefault();
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            <img src={'images/logo.svg'} alt={'Logo von Schüler Connect Online'} style={{ height: isMDUp ? undefined : 28, display: 'block' }} />
          </Link>
        </Bx>
      </HeadBar>
      <Bx bgcolor="background.paper">
        <FramedContent>
          {!working && item && <Html2View>{`<h1>${title}</h1>${html}`}</Html2View>}
          {!working && !item && <NotFoundComponent />}
          {working && <Html2View boosted loading />}
        </FramedContent>
      </Bx>
      <LandingPageFooter
        ctnMaxWidth={ctnMaxWidth}
        sectionPx={sectionPx}
        logoSrc="images/logo.svg"
        logoAlt="Logo von Schüler Connect Online"
        partnerLogoSrc="images/landingpage/griebel.png"
        partnerLogoAlt="Logo Ortrun Griebel"
        links={footerLinks}
        responsive={breakpoints}
      />
    </Bx>
  );
};

const footerLinks: ILandingPageFooterProps['links'] = [
  {
    name: 'Impressum',
    to: '/impressum'
  },
  {
    name: 'Datenschutz',
    to: '/datenschutz'
  },
  {
    name: 'Nutzungsbedingungen',
    to: '/nutzungsbedingungen'
  },
  {
    name: 'Gemeinschaftsstandards',
    to: '/gemeinschaftsstandards'
  },
  {
    name: 'Cookie Consents verwalten',
    onClick: e => {
      window['klaro']?.show?.();
    }
  },
  {
    name: 'Cookie Consents zurücksetzen',
    onClick: e => {
      window['klaro']?.getManager?.().resetConsents?.();
      window.location.reload();
    }
  }
];
