import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { GetMessagesRequest, IMessageModel } from '../../../model/communication/Message';
import { ICommunicationListItem, ICommunicationModel } from '../../../model/communication/Communication';
import { IDictionary } from '../../../model/common/dictionary';
import { IParticipantResolvedModel, IParticipationResolvedModel } from '../../../model/communication/Participant';
import { IDeleteFilesResponse, IUploadableFile } from '../../../model/common/file';
import { IAsset, IAssetResolved } from '../../../model/ryve/asset';

export interface IMessageDraft {
  editMessageId?: string; // wenn eine nachricht bearbeitet wird steht hier ihre _id drin
  content?: string; // text inhalt
  working?: boolean; // wird erstellt oder bearbeitet
  error?: string;
  quotes?: IMessageModel; // zitiert diese nachricht
  threadRoot?: IMessageModel; // antwort auf
  attachments?: IUploadableFile[];
  attachmentsWorking?: boolean;
}

export interface IMessagesState {
  working?: boolean;
  messages?: IMessageModel[];

  atStart?: boolean;
  atEnd?: boolean;
  atBottom?: boolean;

  olderWorking?: boolean;
  newerWorking?: boolean;
}

export interface IActioncanvasState {
  open?: boolean;
  threadRoot?: IMessageModel;
}

export interface ICommunicationState {
  id?: string;
  communication?: ICommunicationModel;

  participationWorking?: boolean;
  participation?: IParticipationResolvedModel;

  messages: IDictionary<IMessagesState>;
  messageDraft: IDictionary<IMessageDraft>;
  actioncanvas?: IActioncanvasState;

  participants?: IParticipantResolvedModel[];
  participantsWorking?: boolean;

  files?: IAssetResolved[];
  filesWorking?: boolean;

  communicationsWorking?: boolean;
  communications?: ICommunicationListItem[];

  numUnreadMessages?: IDictionary<IDictionary<number>>;

  uploadFiles?: IUploadableFile[];
  uploadFilesActive?: boolean;
  uploadFilesPreparing?: boolean;
  uploadFilesWorking?: boolean;

  deleteFiles?: IAsset[];
  deleteFilesActive?: boolean;
  deleteFilesWorking?: boolean;
  deleteFilesResponse?: IDeleteFilesResponse;

  downloadFilesActive?: boolean;
  downloadFilesPreparing?: boolean;

  conferenceMobileModal?: boolean;
}

const initialState: ICommunicationState = {
  id: undefined,
  participationWorking: false,
  participation: undefined,
  communication: undefined,

  messages: {},

  messageDraft: {},

  numUnreadMessages: {},
  conferenceMobileModal: false
};

const communication = createSlice({
  name: 'communication',
  initialState,
  reducers: {
    /* communication */
    setCommunication: (state, action: PayloadAction<{ id?: string; communication?: ICommunicationModel }>) => {
      state.communication = action.payload.communication;

      if (state.id !== action.payload.id) {
        state.messages = {};
      }

      state.id = action.payload.id;
      state.messageDraft = {};

      state.participants = undefined;
      state.participantsWorking = false;

      state.files = [];
      state.filesWorking = false;

      state.uploadFiles = [];
      state.uploadFilesActive = false;
      state.uploadFilesPreparing = false;
      state.uploadFilesWorking = false;

      state.deleteFiles = [];
      state.deleteFilesActive = false;
      state.deleteFilesResponse = undefined;
      state.deleteFilesWorking = false;

      state.downloadFilesActive = false;
      state.downloadFilesPreparing = false;
    },
    setActionCanvas: (state, action: PayloadAction<{ threadRoot?: IMessageModel; open: boolean }>) => {
      state.actioncanvas = action.payload;
    },
    setLastReadTimestampRequest: (state, action: PayloadAction<{ id: string; timestamp: number }>) => {},
    setLastReadTimestampSuccess: (state, action: PayloadAction<{ id: string }>) => {
      if (state.numUnreadMessages) {
        for (let type of Object.keys(state.numUnreadMessages)) {
          if (state.numUnreadMessages[type]) {
            for (let id of Object.keys(state.numUnreadMessages[type])) {
              if (action.payload.id === id && state.numUnreadMessages[type][id] !== undefined) {
                state.numUnreadMessages[type][id] = 0;
              }
            }
          }
        }
      }
    },
    setLastReadTimestampFailed: (state, action: PayloadAction<{ message?: string }>) => {},
    fetchCommunicationsRequest: (state, action: PayloadAction<{ types?: string[] }>) => {
      const x = action.payload.types;
      state.communicationsWorking = true;
      state.communications = [];
    },
    fetchCommunicationsSuccess: (state, action: PayloadAction<{ communications: ICommunicationListItem[] }>) => {
      state.communicationsWorking = false;
      state.communications = action.payload.communications;
    },
    fetchCommunicationsFailed: (state, action: PayloadAction<{ message?: string }>) => {
      state.communicationsWorking = false;
      state.communications = [];
    },
    fetchCommunicationByIdRequest: (state, action: PayloadAction<{ id: string }>) => {},
    fetchCommunicationByIdSuccess: (state, action: PayloadAction<{ id: string; communication: ICommunicationListItem }>) => {
      state.communications = _.reject(state.communications, { _id: action.payload.id });
      if (action.payload.communication) {
        state.communications.unshift(action.payload.communication);
      }
    },
    fetchCommunicationByIdFailed: (state, action: PayloadAction<{ id: string; message?: string }>) => {
      state.communications = _.reject(state.communications, { _id: action.payload.id });
    },
    /* signalR */
    subscribeToSignalR: (state, action: PayloadAction<{ id: string }>) => {},
    unsubscribeFromSignalR: (state, action: PayloadAction<{ id: string }>) => {},
    /* media */
    downloadFilesRequest: (state, action: PayloadAction<{ communicationId: string; files: IAsset[] }>) => {
      state.downloadFilesActive = true;
      state.downloadFilesPreparing = true;
    },
    downloadFilesResponse: (state, action: PayloadAction<{ success: boolean }>) => {
      state.downloadFilesPreparing = false;
      if (!action.payload.success) {
        state.downloadFilesActive = false;
      }
    },
    closeDownloadFilesModal: (state, action: PayloadAction<{}>) => {
      state.downloadFilesActive = false;
    },
    setDeleteFiles: (state, action: PayloadAction<{ files?: IAsset[] }>) => {
      state.deleteFiles = action.payload.files;
    },
    setDeleteFilesActive: (state, action: PayloadAction<boolean>) => {
      state.deleteFiles = [];
      state.deleteFilesActive = action.payload;
      state.deleteFilesWorking = false;
      state.deleteFilesResponse = undefined;
    },
    deleteFilesRequest: (state, action: PayloadAction<{ communicationId: string; files: IAsset[] }>) => {
      state.deleteFilesWorking = true;
      state.deleteFilesResponse = undefined;
    },
    deleteFilesSuccess: (state, action: PayloadAction<IDeleteFilesResponse>) => {
      state.deleteFilesWorking = false;
      state.deleteFilesResponse = action.payload;
    },
    deleteFilesFailed: (state, action: PayloadAction<IDeleteFilesResponse>) => {
      state.deleteFilesWorking = false;
      state.deleteFilesResponse = action.payload;
    },
    setUploadFiles: (state, action: PayloadAction<{ communicationId: string; files?: IUploadableFile[] }>) => {
      state.uploadFiles = action.payload.files;
    },
    setUploadFilePrepared: (state, action: PayloadAction<{ file: IUploadableFile; tempFilename: string }>) => {
      if (state.uploadFiles?.length) {
        const found = _.find(state.uploadFiles, att => att.name === action.payload.file.name);
        if (!found) return;

        found.tempname = action.payload.tempFilename;
        delete found.uploading;

        let allPrepared = true;
        for (let file of state.uploadFiles) {
          if (file.uploading) {
            allPrepared = false;
            break;
          }
        }

        state.uploadFilesPreparing = !allPrepared;
      }
    },
    setUploadFileFailed: (state, action: PayloadAction<{ file: IUploadableFile }>) => {
      if (state.uploadFiles?.length) {
        state.uploadFiles = _.reject(state.uploadFiles, att => att.name === action.payload.file.name);

        let allPrepared = true;
        for (let file of state.uploadFiles) {
          if (file.uploading) {
            allPrepared = false;
            break;
          }
        }

        state.uploadFilesPreparing = !allPrepared;
      }
    },
    setUploadFilePreparing: (state, action: PayloadAction<{ file: IUploadableFile }>) => {
      if (state.uploadFiles?.length) {
        const found: any = _.find(state.uploadFiles, att => att.name === action.payload.file.name);
        if (!found) return;
        found.uploading = true;

        state.uploadFilesPreparing = true;
      }
    },
    setUploadFilesActive: (state, action: PayloadAction<boolean>) => {
      state.uploadFiles = [];
      state.uploadFilesActive = action.payload;
      state.uploadFilesPreparing = false;
      state.uploadFilesWorking = false;
    },
    uploadFilesRequest: (state, action: PayloadAction<{ communicationId: string; files: IUploadableFile[] }>) => {
      state.uploadFilesWorking = true;
    },
    uploadFilesSuccess: (state, action: PayloadAction<{}>) => {
      state.uploadFiles = [];
      state.uploadFilesActive = false;
      state.uploadFilesPreparing = false;
      state.uploadFilesWorking = false;
    },
    uploadFilesFailed: (state, action: PayloadAction<{ message?: string }>) => {
      state.uploadFilesPreparing = false;
      state.uploadFilesWorking = false;
    },
    fetchFilesRequest: (state, action: PayloadAction<{ id?: string }>) => {
      state.files = [];
      state.filesWorking = true;
    },
    fetchFilesSuccess: (state, action: PayloadAction<{ files?: IAssetResolved[] }>) => {
      state.files = action.payload.files;
      state.filesWorking = false;
    },
    fetchFilesFailed: (state, action: PayloadAction<{ message?: string }>) => {
      state.files = [];
      state.filesWorking = false;
    },
    /* participation */
    fetchParticipationRequest: (state, action: PayloadAction<{ id: string }>) => {
      state.participationWorking = true;
      state.participation = undefined;
    },
    fetchParticipationSuccess: (state, action: PayloadAction<{ participation: IParticipationResolvedModel }>) => {
      state.participationWorking = false;
      state.participation = action.payload.participation;
    },
    fetchParticipationFailed: (state, action: PayloadAction<{ message: string }>) => {
      state.participationWorking = false;
      state.participation = undefined;
    },
    /* messages */
    fetchMessagesRequest: (
      state,
      action: PayloadAction<
        {
          reset?: boolean;
          alias: string;
          communicationId: string;
        } & GetMessagesRequest
      >
    ) => {
      let messagesState = state.messages[action.payload.alias];
      if (!messagesState) {
        messagesState = {};
        state.messages[action.payload.alias] = messagesState;
      }

      const oldMessages = messagesState.messages;
      const newestMessage = oldMessages?.length ? oldMessages[oldMessages.length - 1] : null;

      messagesState.working = true;
      messagesState.atStart = true;
      messagesState.atEnd = true;
      messagesState.atBottom = true;
      messagesState.messages = action.payload.reset ? [] : newestMessage ? [newestMessage] : undefined;
    },
    fetchMessagesSuccess: (state, action: PayloadAction<{ alias: string; messages: IMessageModel[]; atStart?: boolean; atEnd?: boolean }>) => {
      const messagesState = state.messages[action.payload.alias];
      if (!messagesState) return;

      messagesState.working = false;
      messagesState.messages = action.payload.messages;
      messagesState.atEnd = action.payload.atEnd;
      messagesState.atStart = action.payload.atStart;

      action.payload.messages.forEach(message => {
        if (!!message.threadChildren?.length) {
          state.messages[message.id] = {
            working: false,
            messages: message.threadChildren
          };
        }
      });
    },
    fetchMessagesFailed: (state, action: PayloadAction<{ alias: string; message: string }>) => {
      const messagesState = state.messages[action.payload.alias];
      if (!messagesState) return;

      messagesState.working = false;
      messagesState.atStart = true;
      messagesState.atEnd = true;
    },
    fetchOlderMessagesRequest: (state, action: PayloadAction<{ alias: string; communicationId: string } & GetMessagesRequest>) => {
      let messagesState = state.messages[action.payload.alias];
      if (!messagesState) return;

      messagesState.olderWorking = true;
    },
    fetchOlderMessagesSuccess: (state, action: PayloadAction<{ alias: string; messages: IMessageModel[]; atStart?: boolean; atEnd?: boolean } & GetMessagesRequest>) => {
      const messagesState = state.messages[action.payload.alias];
      if (!messagesState) return;

      messagesState.messages = action.payload.messages.concat(messagesState.messages || []);
      messagesState.olderWorking = false;
      messagesState.atEnd = action.payload.atEnd;

      if (messagesState.messages.length > 40) {
        messagesState.messages = messagesState.messages.slice(0, 39);
        messagesState.atStart = false;
      }

      action.payload.messages.forEach(message => {
        if (!!message.threadChildren?.length) {
          state.messages[message.id] = {
            working: false,
            messages: message.threadChildren
          };
        }
      });
    },
    fetchOlderMessagesFailed: (state, action: PayloadAction<{ alias: string; message: string }>) => {
      const messagesState = state.messages[action.payload.alias];
      if (!messagesState) return;

      messagesState.olderWorking = false;
      messagesState.atEnd = true;
    },
    fetchNewerMessagesRequest: (state, action: PayloadAction<{ alias: string; communicationId: string } & GetMessagesRequest>) => {
      let messagesState = state.messages[action.payload.alias];
      if (!messagesState) return;

      messagesState.newerWorking = true;
    },
    fetchNewerMessagesSuccess: (state, action: PayloadAction<{ alias: string; messages: IMessageModel[]; atStart?: boolean; atEnd?: boolean } & GetMessagesRequest>) => {
      const messagesState = state.messages[action.payload.alias];
      if (!messagesState) return;

      messagesState.messages = (messagesState.messages || []).concat(action.payload.messages);
      messagesState.newerWorking = false;
      messagesState.atStart = action.payload.atStart;

      if (messagesState.messages.length > 40) {
        messagesState.messages = messagesState.messages.slice(messagesState.messages.length - 40);
        messagesState.atEnd = false;
      }

      action.payload.messages.forEach(message => {
        if (!!message.threadChildren?.length) {
          state.messages[message.id] = {
            working: false,
            messages: message.threadChildren
          };
        }
      });
    },
    fetchNewerMessagesFailed: (state, action: PayloadAction<{ alias: string; message: string }>) => {
      const messagesState = state.messages[action.payload.alias];
      if (!messagesState) return;

      messagesState.newerWorking = false;
      messagesState.atStart = true;
    },
    setMessageDraftContent: (state, action: PayloadAction<{ id: string; content: string }>) => {
      let draftState = state.messageDraft[action.payload.id];
      if (!draftState) {
        draftState = {};
        state.messageDraft[action.payload.id] = draftState;
      }

      draftState.content = action.payload.content;
    },
    setMessageDraftQuotes: (state, action: PayloadAction<{ id: string; quotes?: IMessageModel }>) => {
      let draftState = state.messageDraft?.[action.payload.id];
      if (!draftState) {
        draftState = {};
        state.messageDraft[action.payload.id] = draftState;
      }

      draftState.quotes = action.payload.quotes;
    },
    setMessageDraftThreadRoot: (state, action: PayloadAction<{ id: string; threadRoot?: IMessageModel }>) => {
      let draftState = state.messageDraft?.[action.payload.id];
      if (!draftState) {
        draftState = {};
        state.messageDraft[action.payload.id] = draftState;
      }

      draftState.threadRoot = action.payload.threadRoot;
    },
    setMessageDraftEdit: (state, action: PayloadAction<{ id: string; message?: IMessageModel }>) => {
      if (!action.payload.message) {
        state.messageDraft[action.payload.id] = {
          editMessageId: undefined,
          quotes: undefined,
          content: ''
        };
      } else {
        if (action.payload.message.deleted) return;

        const message = action.payload.message;
        state.messageDraft[action.payload.id] = {
          editMessageId: message.id,
          quotes: message.quotesResolved,
          content: message.content
        };
      }
    },
    setMessageDraftAttachments: (state, action: PayloadAction<{ communicationId: string; id: string; attachments?: IUploadableFile[] }>) => {
      let draftState = state.messageDraft?.[action.payload.id];
      if (!draftState) {
        draftState = {};
        state.messageDraft[action.payload.id] = draftState;
      }

      draftState.attachments = action.payload.attachments;
    },
    setMessageDraftAttachmentPrepared: (state, action: PayloadAction<{ id: string; attachment: IUploadableFile; tempFilename: string }>) => {
      let draftState = state.messageDraft?.[action.payload.id];
      if (!draftState) {
        draftState = {};
        state.messageDraft[action.payload.id] = draftState;
      }
      if (draftState.attachments) {
        const found = _.find(draftState.attachments, att => att.name === action.payload.attachment.name);
        if (!found) return;

        found.tempname = action.payload.tempFilename;
        delete found.uploading;

        let allPrepared = true;
        for (let file of draftState.attachments) {
          if (file.uploading) {
            allPrepared = false;
            break;
          }
        }

        draftState.attachmentsWorking = !allPrepared;
      }
    },
    setMessageDraftAttachmentFailed: (state, action: PayloadAction<{ id: string; attachment: IUploadableFile }>) => {
      let draftState = state.messageDraft?.[action.payload.id];
      if (!draftState) {
        draftState = {};
        state.messageDraft[action.payload.id] = draftState;
      }
      if (draftState.attachments) {
        draftState.attachments = _.reject(draftState.attachments, att => att.name === action.payload.attachment.name);

        let allPrepared = true;
        for (let file of draftState.attachments) {
          if (file.uploading) {
            allPrepared = false;
            break;
          }
        }

        draftState.attachmentsWorking = !allPrepared;
      }
    },
    setMessageDraftAttachmentPreparing: (state, action: PayloadAction<{ id: string; attachment: IUploadableFile }>) => {
      let draftState = state.messageDraft?.[action.payload.id];
      if (!draftState) {
        draftState = {};
        state.messageDraft[action.payload.id] = draftState;
      }
      const found: any = _.find(draftState.attachments, att => att.name === action.payload.attachment.name);
      if (!found) return;
      found.uploading = true;

      draftState.attachmentsWorking = true;
    },
    createMessageRequest: (
      state,
      action: PayloadAction<{
        id: string;
        attachments?: IUploadableFile[];
        quotes?: IMessageModel;
        threadRoot?: IMessageModel;
        communicationId: string;
        content: string;
      }>
    ) => {
      let draftState = state.messageDraft?.[action.payload.id];
      if (!draftState) return;

      draftState.working = true;
    },
    createMessageSuccess: (state, action: PayloadAction<{ id: string; message: IMessageModel }>) => {
      let draftState = state.messageDraft?.[action.payload.id];
      if (!draftState) return;

      state.messageDraft[action.payload.id] = {
        threadRoot: state.messageDraft[action.payload.id].threadRoot,
        content: ''
      };
    },
    createMessageFailed: (state, action: PayloadAction<{ id: string; message: string }>) => {
      let draftState = state.messageDraft?.[action.payload.id];
      if (!draftState) return;

      draftState.working = false;
      draftState.error = action.payload.message;
    },
    deleteMessageRequest: (state, action: PayloadAction<{ communicationId: string; id: string; deleted?: boolean }>) => {},
    deleteMessageSuccess: (state, action: PayloadAction<{ message: IMessageModel; localRemove?: boolean }>) => {
      const threadRoot = action.payload.message.threadRoot;
      for (let root in state.messages) {
        var messages = state.messages[root];
        if (messages && messages.messages && messages.messages.length) {
          if (action.payload.localRemove) {
            // remove message from all arrays
            state.messages[root].messages = _.filter(messages.messages, message => message.id !== action.payload.message.id);

            if (threadRoot) {
              const rootMessage = _.find(messages.messages, { id: threadRoot });
              if (rootMessage && rootMessage.numThreadChildren) {
                rootMessage.numThreadChildren -= 1;
              }
            }
          } else {
            // update deleted!
          }
        }
      }
    },
    deleteMessageFailed: (state, action: PayloadAction<{ message: string }>) => {},
    updateMessageRequest: (state, action: PayloadAction<{ id: string; communicationId: string; messageId: string; content: string }>) => {
      let draftState = state.messageDraft?.[action.payload.id];
      if (!draftState) return;

      draftState.working = true;
    },
    updateMessageSuccess: (state, action: PayloadAction<{ id: string; message: IMessageModel }>) => {
      let draftState = state.messageDraft?.[action.payload.id];
      if (!draftState) return;

      state.messageDraft[action.payload.id] = {
        threadRoot: state.messageDraft[action.payload.id].threadRoot,
        content: ''
      };
    },
    updateMessageFailed: (state, action) => {
      let draftState = state.messageDraft?.[action.payload.id];
      if (!draftState) return;

      draftState.working = false;
      draftState.error = action.payload.message;
    },
    fetchMessageByIdRequest: (state, action: PayloadAction<{ new?: boolean; communicationId: string; messageId: string }>) => {},
    fetchMessageByIdSuccess: (state, action: PayloadAction<{ new?: boolean; message: IMessageModel }>) => {
      let messagesState: IMessagesState;
      const threadRoot = action.payload.message.threadRoot;
      if (threadRoot) {
        // in threadroot
        if (!state.messages[threadRoot])
          state.messages[threadRoot] = {
            messages: [],
            atEnd: true,
            atStart: true
          };
        messagesState = state.messages[threadRoot];
      } else {
        // in main
        if (!state.messages['main'])
          state.messages['main'] = {
            messages: [],
            atEnd: true,
            atStart: true
          };
        messagesState = state.messages['main'];
      }
      if (!messagesState) return;
      const alreadyExisting = messagesState.messages?.find(m => m.id === action.payload.message.id);
      if (alreadyExisting) {
        const index = messagesState.messages?.indexOf(alreadyExisting);
        if (messagesState.messages && index !== undefined && index >= 0) {
          messagesState.messages[index] = action.payload.message;
        }
      } else {
        // das darf nur passieren wenn man ganz unten ist
        if (messagesState.atStart && messagesState.atBottom) {
          messagesState.messages?.push(action.payload.message);
        } else {
          // "neue nachricht verfügbar"
          messagesState.atStart = false;
        }
      }

      if (threadRoot) {
        // parent finden
        messagesState = state.messages['main'];
        if (!messagesState) return;
        const rootMessage = _.find(messagesState.messages, { id: threadRoot });
        if (rootMessage) {
          if (!rootMessage.numThreadChildren) rootMessage.numThreadChildren = 0;
          rootMessage.numThreadChildren += 1;
        }
      }

      // finde eine message, welche diese zitiert
      for (let alias of Object.keys(state.messages)) {
        messagesState = state.messages[alias];
        if (messagesState && messagesState.messages && messagesState.messages.length) {
          for (let message of messagesState.messages) {
            if (message.quotes === action.payload.message.id) {
              message.quotesResolved = action.payload.message;
            }
          }
        }
      }
    },
    fetchMessageByIdFailed: (state, action: PayloadAction<{ message?: string }>) => {},
    setMessagesScrolledBottom: (state, action: PayloadAction<{ alias: string; atBottom?: boolean }>) => {
      const s = state.messages[action.payload.alias];
      if (s) s.atBottom = action.payload.atBottom;
    },
    getNumUnreadMessagesByIdRequest: (state, action: PayloadAction<{ id: string }>) => {},
    getNumUnreadMessagesByIdSuccess: (state, action: PayloadAction<{ id: string; dict: IDictionary<IDictionary<number>> }>) => {
      if (!state.numUnreadMessages) {
        state.numUnreadMessages = action.payload.dict;
      } else {
        const dict = action.payload.dict;
        for (let type of Object.keys(dict)) {
          if (!state.numUnreadMessages[type]) {
            state.numUnreadMessages[type] = dict[type];
          } else {
            for (let id of Object.keys(dict[type])) {
              state.numUnreadMessages[type][id] = dict[type][id];
            }
          }
        }
      }
    },
    getNumUnreadMessagesByIdFailed: (state, action: PayloadAction<{ message?: string }>) => {},
    getNumUnreadMessagesRequest: (state, action: PayloadAction<{ types?: string[] }>) => {},
    getNumUnreadMessagesSuccess: (state, action: PayloadAction<{ dict: IDictionary<IDictionary<number>> }>) => {
      state.numUnreadMessages = {
        ...state.numUnreadMessages,
        ...action.payload.dict
      };
    },
    getNumUnreadMessagesFailed: (state, action: PayloadAction<{ message?: string }>) => {},
    voteMessageRequest: (state, action: PayloadAction<{ communicationId: string; messageId: string; up: boolean; alias: string }>) => {},
    unreadMessagesChanged: () => {},
    /* conferencing */
    joinConferenceRequest: (state, action: PayloadAction<{ message: IMessageModel }>) => {},
    joinConferenceSuccess: (state, action: PayloadAction<{}>) => {},
    joinConferenceFailed: (state, action: PayloadAction<{}>) => {},
    createConferenceMessageRequest: (state, action: PayloadAction<{ id: string; communicationId: string; title?: string }>) => {},
    createConferenceMessageSuccess: (state, action: PayloadAction<{ id: string; message: IMessageModel }>) => {},
    createConferenceMessageFailed: (state, action: PayloadAction<{ id: string; message: string }>) => {}
  }
});

export const {
  downloadFilesRequest,
  downloadFilesResponse,
  closeDownloadFilesModal,
  deleteFilesFailed,
  deleteFilesRequest,
  deleteFilesSuccess,
  setDeleteFiles,
  setDeleteFilesActive,
  unreadMessagesChanged,
  voteMessageRequest,
  setMessagesScrolledBottom,
  subscribeToSignalR,
  unsubscribeFromSignalR,
  setCommunication,
  fetchParticipationRequest,
  fetchParticipationSuccess,
  fetchParticipationFailed,
  fetchMessagesRequest,
  fetchMessagesSuccess,
  fetchMessagesFailed,
  setMessageDraftContent,
  setMessageDraftQuotes,
  setMessageDraftThreadRoot,
  setActionCanvas,
  joinConferenceRequest,
  joinConferenceSuccess,
  joinConferenceFailed,
  createConferenceMessageRequest,
  createConferenceMessageSuccess,
  createConferenceMessageFailed,
  createMessageRequest,
  createMessageSuccess,
  createMessageFailed,
  deleteMessageRequest,
  deleteMessageSuccess,
  deleteMessageFailed,
  updateMessageRequest,
  updateMessageSuccess,
  updateMessageFailed,
  fetchMessageByIdRequest,
  fetchMessageByIdSuccess,
  fetchMessageByIdFailed,
  getNumUnreadMessagesRequest,
  getNumUnreadMessagesSuccess,
  getNumUnreadMessagesFailed,
  getNumUnreadMessagesByIdRequest,
  getNumUnreadMessagesByIdFailed,
  getNumUnreadMessagesByIdSuccess,
  setLastReadTimestampRequest,
  setLastReadTimestampSuccess,
  setLastReadTimestampFailed,
  setMessageDraftEdit,
  setUploadFiles,
  setUploadFilePrepared,
  setUploadFileFailed,
  setUploadFilePreparing,
  uploadFilesRequest,
  uploadFilesSuccess,
  uploadFilesFailed,
  setUploadFilesActive,
  setMessageDraftAttachments,
  setMessageDraftAttachmentPrepared,
  setMessageDraftAttachmentPreparing,
  setMessageDraftAttachmentFailed,
  fetchCommunicationsRequest,
  fetchCommunicationsSuccess,
  fetchCommunicationsFailed,
  fetchCommunicationByIdRequest,
  fetchCommunicationByIdSuccess,
  fetchCommunicationByIdFailed,
  fetchFilesRequest,
  fetchFilesSuccess,
  fetchFilesFailed,
  fetchOlderMessagesRequest,
  fetchOlderMessagesSuccess,
  fetchOlderMessagesFailed,
  fetchNewerMessagesRequest,
  fetchNewerMessagesSuccess,
  fetchNewerMessagesFailed
} = communication.actions;
export default communication.reducer;
