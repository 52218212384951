import { Ava, Grd, Btn, Bx, Ctn, IBxProps, IconBtn, Typo, useBreakpoints, InputInfoText, BtnList } from '@curry-group/mui-curcuma';
import { Content, Header } from '../../../components/frame';
import { FramedContent } from '../../../components/framed-content';
import { assetUrl, validatePassword } from '../../../helper';
import useTheme from '@material-ui/core/styles/useTheme';
import { Fade, Hidden } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { useState } from 'react';
import { HeadBar } from '../../../components/head-bar';
import { useLocation } from 'react-router-dom';
import { useIsMobile } from '../../../helper/hooks/useIsMobile';
import { MobileHeadBar } from '../../../components/mobile-head-bar';
import { faEdit, faKey, faLongArrowLeft, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProfileTypes } from '../../../model/profile';
import { HeadlineContainer } from '../../../components/headline-container';
import { FormControlInput } from '../../../components/form/control/input';
import { updatePasswordRequest } from '../../../data/reducer/app';
import { useEffect } from 'react';

const avaRight = { xs: 3 * 8, md: 7.5 * 8 };
const boxBorderRadius: IBxProps['borderRadius'] = 'borderRadius2';
const boxBgColor: IBxProps['bgcolor'] = 'background.paper';
const boxPy: IBxProps['py'] = { xs: 2, md: 5.5 };
const boxPx: IBxProps['px'] = { xs: 3, md: 7.5 };
const boxMb: IBxProps['mb'] = 5;

const ProfileBox = props => {
  return (
    <Bx
      mt={{ xs: 3, md: 10 }}
      position="relative"
      bgcolor={boxBgColor}
      borderRadius={boxBorderRadius}
      py={boxPy}
      px={boxPx}
      mb={boxMb}
      {..._.omit(props, ['borderRadius', 'bgcolor', 'py', 'px'])}
    />
  );
};

interface IProfileHeadProps {
  type?: string;
  userName?: string;
  userFunction?: string;
  isMdUp?: boolean;
}

const ProfileHead: React.FC<IProfileHeadProps> = props => {
  return (
    <>
      {/* Type */}
      {props.type && (
        <Bx display="flex" justifyContent="space-between" alignItems="center" mb={5}>
          <Bx>
            <Typo variant="subtitle2" component="span" textTransform="uppercase">
              {props.type}
            </Typo>
          </Bx>
        </Bx>
      )}

      {/* Name & Function */}
      <Bx mb={3.5}>
        <Typo variant={props.isMdUp ? 'h1boosted' : 'h1'} component="h1" gutterBottom>
          {props.userName}
          {props.userFunction && (
            <Bx mt={1}>
              <Typo variant={props.isMdUp ? 'h4boosted' : 'h4'}>{props.userFunction}</Typo>
            </Bx>
          )}
        </Typo>
      </Bx>
    </>
  );
};

function getPasswordInvalidMsg(password: string, repeat) {
  const errorList = new Array<string>();

  if (password.length < 8) {
    errorList.push('min. 8 Zeichen lang sein');
  }

  if (!/[A-ZÄÖÜß]+/.test(password)) {
    errorList.push('Großbuchstaben enthalten');
  }

  if (!/[a-zäöüß]+/.test(password)) {
    errorList.push('Kleinbuchstaben enthalten');
  }

  if (!/[0-9]+/.test(password)) {
    errorList.push('Zahlen enthalten');
  }

  if (!!!!password && password !== repeat) {
    errorList.push('übereinstimmen');
  }

  return errorList.length > 0 ? 'Passwort muss ' + errorList.join(', ') + '!' : '';
}

export const MyProfileView = () => {
  const [isScrolled, setIsScrolled] = useState(false); // TODO
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [isChanging, setIsChanging] = useState(false);

  const profile = useSelector(state => state.app.profile);

  const { updatePasswordWorking, updatePasswordError } = useSelector(state => state.app);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('');

  const location = useLocation();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const breakpoints = useBreakpoints();
  const theme = useTheme();
  const isMdUp = breakpoints.mdUp || false;

  const getReferrer = (url: string) => {
    const query = new URLSearchParams(url);
    return query.get('ref');
  };

  const startChangePassword = () => {
    setIsChangePassword(true);
  };

  const savePassword = () => {
    if (profile) {
      setIsChanging(true);
      dispatch(updatePasswordRequest({ userId: profile?.userId, oldPassword, newPassword }));
    }
  };

  const cancelChangePassword = () => {
    setIsChangePassword(false);
  };

  useEffect(() => {
    if (isChanging && isChangePassword && !updatePasswordWorking && !updatePasswordError) {
      setIsChanging(false);
      setIsChangePassword(false);
    }
  }, [isChangePassword, isChanging, updatePasswordWorking, updatePasswordError]);

  if (!profile) return <div>no profile</div>;

  return (
    <>
      <Header>
        {!isMobile ? (
          <HeadBar py={0} zIndex={1} backButtonLink={getReferrer(location.search) || '/'} id="content-head-container">
            <Fade in={true}>
              <Bx height="100%" display="flex" alignItems="center">
                <Ctn style={{ height: '100%' }}>
                  <Bx display="flex" justifyContent="space-between" alignItems="center" height="100%"></Bx>
                </Ctn>
              </Bx>
            </Fade>
          </HeadBar>
        ) : (
          <MobileHeadBar id="content-head-container">
            <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" py={1}>
              <Grd item xs={3}>
                <Bx display="flex" pr={1} alignItems="center" justifyContent="flex-start" height="100%">
                  <IconBtn size="medium" to={getReferrer(location.search) || '/'}>
                    <FontAwesomeIcon icon={faLongArrowLeft} />
                  </IconBtn>
                </Bx>
              </Grd>
              <Grd item xs={6}></Grd>
              <Grd item xs={3}></Grd>
            </Bx>
          </MobileHeadBar>
        )}
      </Header>
      <Content>
        <FramedContent>
          <ProfileBox>
            {!isChangePassword && (
              <>
                <Hidden smDown>
                  <Bx
                    position="absolute"
                    top={-(theme.custom?.ava?.largest?.height as number) / 2}
                    right={breakpoints.smDown ? avaRight.xs : avaRight.md}
                    boxShadow={1}
                    borderRadius="full"
                  >
                    <Ava src={assetUrl(profile.avatar || '', true)} size="largest" variant="circular" />
                  </Bx>
                </Hidden>
                <Hidden mdUp>
                  <Bx
                    position="absolute"
                    top={-(theme.custom?.ava?.large?.height as number) / 2}
                    right={breakpoints.smDown ? avaRight.xs : avaRight.md}
                    boxShadow={1}
                    borderRadius="full"
                  >
                    <Ava src={assetUrl(profile.avatar || '', true)} size="large" variant="circular" />
                  </Bx>
                </Hidden>

                <Bx mb={5}>
                  <ProfileHead type="Profil" userName={(profile.fullName + '').trim()} userFunction={ProfileTypes[profile.type] || 'Nutzer'} isMdUp={isMdUp} />
                </Bx>

                <Bx mb={6}>
                  <HeadlineContainer
                    mb={2}
                    headline="Passwort ändern"
                    headlineVariant={isMdUp ? 'h3boosted' : 'h3'}
                    endAdornment={
                      <Bx ml={1}>
                        <Hidden smDown>
                          <Btn size="large" startIcon={<FontAwesomeIcon icon={faKey} />} variant="outlined" onClick={startChangePassword}>
                            Passwort ändern
                          </Btn>
                        </Hidden>
                        <Hidden mdUp>
                          <IconBtn size="small" shape="rounded" variant="outlined" onClick={startChangePassword}>
                            <FontAwesomeIcon icon={faKey} />
                          </IconBtn>
                        </Hidden>
                      </Bx>
                    }
                  />
                  <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Hier können Sie Ihr Passwort ändern.</Typo>
                </Bx>
              </>
            )}
            {isChangePassword && (
              <>
                <HeadlineContainer mb={6} headline="Passwort ändern" headlineVariant={isMdUp ? 'h3boosted' : 'h3'} hideUnderline />

                <Grd container spacing={4}>
                  <Grd item xs={12} md={6}>
                    <FormControlInput
                      label="Altes Passwort"
                      type="password"
                      minLength={0}
                      maxLength={0}
                      value={oldPassword}
                      onChange={setOldPassword}
                      successMessage=""
                      placeholder=""
                      autoTrim
                      invalidMessage=""
                      noLengthWarning={true}
                    />
                  </Grd>
                  <Grd item xs={12} md={6}></Grd>
                  <Grd item xs={12} md={6}>
                    <FormControlInput
                      label="Neues Passwort"
                      type="password"
                      minLength={8}
                      maxLength={0}
                      value={newPassword}
                      onChange={setNewPassword}
                      successMessage=""
                      placeholder=""
                      autoTrim
                      noLengthWarning
                      invalidMessage=""
                      validator={validatePassword}
                      fieldGroup={'passwordData'}
                    />
                  </Grd>
                  <Grd item xs={12} md={6}>
                    <FormControlInput
                      label="Neues Passwort (Wiederholung)"
                      type="password"
                      minLength={8}
                      maxLength={0}
                      value={newPasswordRepeat}
                      onChange={setNewPasswordRepeat}
                      successMessage=""
                      placeholder=""
                      autoTrim
                      noLengthWarning
                    />
                  </Grd>
                </Grd>

                {!!getPasswordInvalidMsg(newPassword, newPasswordRepeat) ? <small>{getPasswordInvalidMsg(newPassword, newPasswordRepeat)}</small> : <small>&nbsp;</small>}

                {updatePasswordError && (
                  <Bx mt={1}>
                    <InputInfoText type="error">Es ist ein Fehler aufgetreten</InputInfoText>
                  </Bx>
                )}

                <Bx mt={6} display="flex" flexDirection="row" justifyContent="space-between">
                  <Btn
                    disabled={updatePasswordWorking}
                    size="large"
                    startIcon={<FontAwesomeIcon icon={faTimes} />}
                    variant="outlined"
                    color="default"
                    onClick={cancelChangePassword}
                  >
                    Abbrechen
                  </Btn>
                  <Btn
                    disabled={updatePasswordWorking || !validatePassword(newPassword) || (!!newPassword && newPassword !== newPasswordRepeat)}
                    size="large"
                    startIcon={<FontAwesomeIcon icon={faKey} />}
                    variant="contained"
                    color="success"
                    onClick={() => {
                      savePassword();
                    }}
                  >
                    Neues Passwort speichern
                  </Btn>
                </Bx>
              </>
            )}
          </ProfileBox>
        </FramedContent>
      </Content>
    </>
  );
};
