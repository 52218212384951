import React, { FC } from 'react';

import { Anohana, Ava, Btn, Bx, formatBytes, IconBtn, Typo } from '@curry-group/mui-curcuma';
import { Hidden } from '@material-ui/core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { assetUrl, iconForExtension } from '../../../../helper';
import { IDeleteFilesResponse } from '../../../../model/common/file';
import { IAsset, isImage } from '../../../../model/ryve/asset';

export interface IFileDeleteModalProps {
  open: boolean;
  close: () => void;

  files: IAsset[];
  removeFile: (file: IAsset) => void;

  deleting: boolean;
  onSubmit: () => void;

  response?: IDeleteFilesResponse;
}

export const DeleteFilesModal: FC<IFileDeleteModalProps> = ({ open, close, files, removeFile, deleting, onSubmit, response }) => {
  return (
    <Anohana open={open} transition="zoom" disableScrollLock parent={() => document.querySelector('#frame__content')} fullWidth maxWidth="sm" onClose={() => {}}>
      <Bx p={2.5}>
        {response && (
          <>
            <Bx display="flex" alignItems="center" mb={3}>
              <Typo variant="h4" component="span">
                {response.success ? 'Gelöscht' : 'Achtung'}
              </Typo>
              <Bx ml="auto" mr={-1}>
                <IconBtn onClick={close}>
                  <FontAwesomeIcon icon={faTimes} />
                </IconBtn>
              </Bx>
            </Bx>
            <Bx mb={4}>
              {response.success && <Typo>Die Dateien wurden erfolgreich gelöscht.</Typo>}

              {!response.success && (
                <Typo>
                  <strong>{response.data?.length}</strong> {response.data?.length === 1 ? 'Datei konnte' : 'Dateien konnten'} nicht gelöscht werden.
                </Typo>
              )}
              {!response.success && response.message && (
                <Typo>
                  <strong>Grund:</strong> {response.message}
                </Typo>
              )}
            </Bx>
            <Bx mb={4}>
              {files
                .filter(f => response.data?.includes(f._id))
                .map((file, idx) => {
                  return (
                    <React.Fragment key={file._id}>
                      <Bx mt={0.5} display="flex" alignItems="center" p={1} bgcolor="#F8F8F8" border={1} borderRadius={'borderRadius2'} borderColor="#D6D7D9">
                        <Bx mr={2}>
                          <Typo variant="h1" color="primary" component="div">
                            {isImage(file) ? (
                              <Ava src={assetUrl(file._id, true)} variant="rounded" size="small" />
                            ) : (
                              <FontAwesomeIcon icon={iconForExtension(file.extension)} />
                            )}
                          </Typo>
                        </Bx>
                        <Bx mr={2}>
                          <Typo variant="body2" fontWeight={500}>
                            {file.name}
                          </Typo>
                          <Typo variant="subtitle3">{formatBytes(file.fileSize)}</Typo>
                        </Bx>
                      </Bx>
                    </React.Fragment>
                  );
                })}
            </Bx>
          </>
        )}
        {!response && (
          <>
            <Bx display="flex" alignItems="center" mb={3}>
              <Typo variant="h4" component="span">
                {files.length === 1 ? `Eine Datei löschen` : `${files.length} Dateien löschen`}
              </Typo>
              <Bx ml="auto" mr={-1}>
                <IconBtn onClick={close}>
                  <FontAwesomeIcon icon={faTimes} />
                </IconBtn>
              </Bx>
            </Bx>
            <Bx mb={4}>
              <Typo>
                Bitte bestätigen Sie Ihre Aktion. Alle nachfolgend aufgelisteten Dateien werden <strong>unwiderruflich</strong> gelöscht.
              </Typo>
            </Bx>
            <Bx mb={4}>
              {files.map((file, idx) => {
                return (
                  <React.Fragment key={file._id}>
                    <Bx mt={0.5} display="flex" alignItems="center" p={1} bgcolor="#F8F8F8" border={1} borderRadius={'borderRadius2'} borderColor="#D6D7D9">
                      <Bx mr={2}>
                        <Typo variant="h1" color="primary" component="div">
                          {isImage(file) ? <Ava src={assetUrl(file._id, true)} variant="rounded" size="small" /> : <FontAwesomeIcon icon={iconForExtension(file.extension)} />}
                        </Typo>
                      </Bx>
                      <Bx mr={2}>
                        <Typo variant="body2" fontWeight={500}>
                          {/* <Link underline="hover" to="/"> */}
                          {file.name}
                          {/* </Link> */}
                        </Typo>
                        <Typo variant="subtitle3">{formatBytes(file.fileSize)}</Typo>
                      </Bx>
                      <Bx ml="auto">
                        <IconBtn disabled={deleting} size="small" onClick={() => removeFile(file)}>
                          <FontAwesomeIcon icon={faTimes} />
                        </IconBtn>
                      </Bx>
                    </Bx>
                  </React.Fragment>
                );
              })}
            </Bx>
            <Bx display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
              <Bx>
                <Hidden smDown>
                  <Btn variant="outlined" onClick={close} size="medium">
                    Abbrechen
                  </Btn>
                </Hidden>
                <Hidden mdUp>
                  <Btn fullWidth variant="outlined" onClick={close} size="medium">
                    Abbrechen
                  </Btn>
                </Hidden>
              </Bx>
              <Bx ml={{ xs: 0, md: 'auto' }} mt={{ xs: 1, md: 0 }}>
                <Hidden smDown>
                  <Btn disabled={deleting} onClick={onSubmit} color="quaternary" variant="contained" size="medium">
                    {files.length === 1 ? 'Ausgewählte Datei löschen' : 'Ausgewählte Dateien löschen'}
                  </Btn>
                </Hidden>
                <Hidden mdUp>
                  <Btn fullWidth disabled={deleting} onClick={onSubmit} color="quaternary" variant="contained" size="medium">
                    {files.length === 1 ? 'Ausgewählte Datei löschen' : 'Ausgewählte Dateien löschen'}
                  </Btn>
                </Hidden>
              </Bx>
            </Bx>
          </>
        )}
      </Bx>
    </Anohana>
  );
};
