import { FC } from 'react';
import { Bx } from '@curry-group/mui-curcuma';

export const Navbar: FC = ({ children }) => {
  return (
    <Bx id="frame__navbar" gridArea="navbar" position="sticky" top="0" height="100vh">
      {children}
    </Bx>
  );
};
