import { FC } from 'react';
import { Bx } from '@curry-group/mui-curcuma';

export const Footer: FC = ({ children }) => {
  return (
    <Bx id="frame__footer" gridArea="footer" zIndex={2} position="sticky" bottom="0">
      {children}
    </Bx>
  );
};
