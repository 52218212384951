import { IScoUserProfile } from '../profile';
import { IThing } from '../ryve/thing';
import { IRoleModel } from './Role';
export class ParticipationRequestType {
  static INVITE = 'invite';
  static REQUEST = 'request';
  static CREATOR = 'creator';
  static MANAGED = 'managed';
  static PUBLIC = 'public';
  static PASSIVE = 'passive';
}

export class ParticipationRequestStatus {
  static PENDING = 'pending';
  static ACCEPTED = 'accepted';
  static DECLINED = 'declined';
  static PASSIVE = 'passive';
}

export interface IParticipantModel {
  user: string;
  roles: string[];
  type: string;
  status: string;
}

export interface IParticipantResolvedModel extends IParticipantModel {
  userResolved: IThing<IScoUserProfile>;
  message: string;
}

export interface IParticipationModel extends IParticipantModel {
  _id: string;
  parent: string;
  createdBy: string;
  createdAt: number;
  modifiedAt: number;
  message: string;
  lastReadTimestamp?: number;
}

export interface IParticipationResolvedModel extends IParticipationModel {
  permissions: IRoleModel;
  createdByResolved: IThing<IScoUserProfile>;
}
