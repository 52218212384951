import { all } from 'redux-saga/effects';

import { registration } from './registration';
import { app } from './app';
import { auth } from './auth';
import { notifications } from './notifications';
import { communication } from './communication';
import { detail } from './detail';
import { tandem } from './tandem';
import { admin } from './admin';

export default function* sagas() {
  yield all([registration(), app(), auth(), notifications(), communication(), detail(), tandem(), admin()]);
}
