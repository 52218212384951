import { callGet, callPost } from '@curry-group/data-addons';
import { takeLatest, all, put } from 'redux-saga/effects';
import api from '../../api';
import {
  userCredLoginRequest,
  userDeauth,
  userLoginFailed,
  userLoginSuccess,
  userLogoutFailed,
  userLogoutRequest,
  userLogoutSuccess,
  fetchStartupFailed,
  lostPasswordRequest,
  lostPasswordSuccess,
  lostPasswordFailed,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailed
} from '../../reducer/app';

function* watcher() {
  yield takeLatest(userDeauth.type, userDeauthWorker);
  yield takeLatest(userCredLoginRequest.type, userCredLoginRequestWorker);
  yield takeLatest(userLogoutRequest.type, logoutWorker);
  yield takeLatest(userLogoutSuccess.type, afterLogoutWorker);
  yield takeLatest(lostPasswordRequest.type, lostPasswordWorker);
  yield takeLatest(resetPasswordRequest.type, resetPasswordWorker);
}

function* lostPasswordWorker(action: ReturnType<typeof lostPasswordRequest>) {
  try {
    yield callPost(api.auth.lostPassword, { username: action.payload.email });
    yield put(lostPasswordSuccess());
  } catch {
    yield put(lostPasswordFailed());
  }
}

function* resetPasswordWorker(action: ReturnType<typeof resetPasswordRequest>) {
  try {
    yield callPost(api.urlWithQuery(api.auth.finishLostPassword, { token: action.payload.token }), { password: action.payload.password });
    yield put(resetPasswordSuccess());
  } catch {
    yield put(resetPasswordFailed());
  }
}

function* logoutWorker() {
  try {
    yield callGet(api.auth.logout);
    yield put(userLogoutSuccess());
  } catch {
    yield put(userLogoutFailed());
  }
}

function* afterLogoutWorker() {
  yield put(fetchStartupFailed());
}

function* userDeauthWorker() {
  yield put(userDeauth());
}

function* userCredLoginRequestWorker(action: ReturnType<typeof userCredLoginRequest>) {
  try {
    yield callPost(api.auth.credentialLogin, action.payload);
    yield put(userLoginSuccess());
  } catch (e) {
    console.log(e);
    yield put(userLoginFailed());
  }
}

export function* auth() {
  yield all([watcher()]);
}
