import React, {useState, useEffect} from 'react';
import { Bx, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import { FormControlInput } from '../../../../../components/form/control/input';
import { TProfileType } from '../../../../../model/data/registration';
import { useSelector } from 'react-redux';
import { IBaseRegistrationFinalStepProps } from '..';
import { validateInput } from '../../../../../helper';
import { FramedContent } from '../../../../../components/framed-content';

export interface IRegistrationSchoolProps extends IBaseRegistrationFinalStepProps {
  type: TProfileType;
}

function validate(school: string, schoolGrade: string) {
  return (!!validateInput(school) && !!schoolGrade);
}

export const RegistrationSchool: React.FC<IRegistrationSchoolProps> = ({
  type,
  onDataValidation
}) => {
  const {registrationFinalizeRequestData} = useSelector(state => state.registrationFinalRequest);
  const [school, setSchool]           = useState(registrationFinalizeRequestData.school || "");
  const [schoolGrade, setSchoolGrade] = useState(registrationFinalizeRequestData.schoolGrade || "");
  const [hobbies, setHobbies]         = useState(registrationFinalizeRequestData.hobbies || "");

  useEffect(() => {
    onDataValidation(validate(school, schoolGrade), {school, schoolGrade, hobbies});
  }, [school, schoolGrade, hobbies, onDataValidation]);

  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Registrierung auf Schüler Connect Online
        </Typo>
      </Bx>
      <Bx>
        <Typo variant="subtitle1">Bitte tragen Sie Ihre Schuldaten ein</Typo>
      </Bx>
      <Bx my={8}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormControlInput
                value={school}
                label={type == 'student' ? 'Schule' : 'Schule/Universität'}
                placeholder={type == 'student' ? 'Schule eingeben' : 'Schule/Universität eingeben' }
                minLength={2}
                maxLength={50}
                onChange={setSchool}
                validator={value => !!value}
                successMessage=""
                fieldGroup={'userData'}
                lazy
              />
            </Bx>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormControlInput
                value={schoolGrade}
                label={type == 'student' ? 'Klassenstufe' : 'Klassenstufe/Semester'}
                placeholder={ type == 'student' ? 'Klassenstufe eingeben' : 'Klassenstufe/Semester eingeben'}
                minLength={1}
                maxLength={50}
                onChange={setSchoolGrade}
                validator={value => !!value}
                successMessage=""
                fieldGroup={'userData'}
                lazy
              />
            </Bx>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormControlInput
                value={hobbies}
                label={'Hobbies (optional)'}
                placeholder={'Hobbies eingeben'}
                minLength={0}
                maxLength={0}
                onChange={setHobbies}
                validator={value => !!value}
                successMessage=""
                lazy
              />
            </Bx>
          </Grid>
        </Grid>
      </Bx>
    </FramedContent>
  );
};
