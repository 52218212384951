import { FC, ReactNode } from 'react';
import { resolveTimestamp } from '../../../../helper';
import { IConferenceInformation, IMessageReferenceMeeting, IMessageReferences } from '../../../../model/communication/Message';
import { Link, Typo, Btn, TimeAva } from '@curry-group/mui-curcuma';
import { Box } from '@material-ui/core';
import dayjs from 'dayjs';
import { faArrowToRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IMessageContentProps {
  isDeleted?: boolean;
  content: string;
  conferenceInfo?: IConferenceInformation;
  onJoinConference?: () => void;
  color?: string;
  references?: IMessageReferences;
  onReferenceSelected?: (type: string, data: any) => void;
}

export const MessageContent: FC<IMessageContentProps> = ({ content, isDeleted, conferenceInfo, onJoinConference, color, references, onReferenceSelected }) => {
  let Content: ReactNode = undefined;
  if (isDeleted) {
    Content = <Typo fontWeight="lighter">Diese Nachricht wurde gelöscht</Typo>;
  } else {
    if (conferenceInfo && onJoinConference) {
      Content = <MessageConferenceContent conferenceInfo={conferenceInfo} onJoinConference={onJoinConference} />;
    } else {
      Content = <MessageTextContent data={resolveMessageContentLinks(content)} />;
    }
  }
  return <Box fontStyle={isDeleted ? 'italic' : 'normal'}>{Content}</Box>;
};

export interface IResolvedMessageContent {
  content: string[];
  links: string[];
}

function resolveMessageContentLinks(content: string): IResolvedMessageContent {
  const linkRegExp = /((http|https):\/\/)(\S+)([a-z]{2,}?)(.*?)( |,|$|\.)/gim;
  const eliminatedHtml = content
    .replace(/&/g, '&amp')
    .replace(/</g, '&lt')
    .replace(/>/g, '&gt')
    .replace(/(\r\n|\n|\r)/gm, ' <br/>');

  return {
    content: eliminatedHtml.replace(linkRegExp, '<>').split('<>'),
    links: eliminatedHtml.match(linkRegExp) ?? []
  };
}

export interface IMessageTextContentProps {
  data: IResolvedMessageContent;
}
export const MessageTextContent: React.FC<IMessageTextContentProps> = ({ data }) => {
  const resultArray = Array<React.ReactFragment>();

  for (let i = 0; i < data.content.length; i++) {
    resultArray.push(<Typo key={i} dangerouslySetInnerHTML={{ __html: data.content[i] }} fontWeight="normal" />);

    if (data.links.length > i) {
      resultArray.push(
        <Typo fontWeight="normal">
          <Link key={i + '-link'} to={'//' + data.links[i].replace(/(http|https):\/\//, '')} target="_blank">
            {data.links[i]}
          </Link>
        </Typo>
      );
    }
  }

  return <>{resultArray}</>;
};

export interface IMessageConferenceContentProps {
  conferenceInfo: IConferenceInformation;
  onJoinConference: () => void;
}

export const MessageConferenceContent: FC<IMessageConferenceContentProps> = ({ conferenceInfo, onJoinConference }) => {
  if (conferenceInfo.started && !conferenceInfo.ended) {
    return (
      <Btn variant="contained" color="secondary" onClick={() => onJoinConference?.()}>
        An Videokonferenz teilnehmen
      </Btn>
    );
  }

  if (conferenceInfo.started && conferenceInfo.ended) {
    return <Typo fontWeight="normal">Beendet: {resolveTimestamp(conferenceInfo.ended)}</Typo>;
  }

  return <></>;
};

export interface IMessageMeetingContentProps {
  meeting: IMessageReferenceMeeting;
  onMeetingClicked?: () => void;
  color?: string;
}
