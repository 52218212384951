import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IRegistrationFinalizeRequestData} from '../../../model/data/registration';

interface IRegistrationFinalRequestState {
    registrationFinalizeRequestData: IRegistrationFinalizeRequestData;
}

const initialState: IRegistrationFinalRequestState = {
    registrationFinalizeRequestData: {
        token: '',
        password: '',
        school: '',
        schoolGrade: '',
        hobbies: '',
        subjects: [],
        subjectsHigh: [],
        subjectsLow: [],
        subjectsMedium: [],
        languages: []
      }
    }

const registrationFinalRequest = createSlice({
  name: 'registrationFinalRequest',
  initialState,
  reducers: {
    setRegistrationFinalizeRequestData: (state, action: PayloadAction<Partial<IRegistrationFinalizeRequestData>>) => {
        state.registrationFinalizeRequestData = {...state.registrationFinalizeRequestData, ...action.payload}
    }
  }
});

export const {
    setRegistrationFinalizeRequestData
} = registrationFinalRequest.actions;
export default registrationFinalRequest.reducer;
