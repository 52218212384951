import React, { MouseEventHandler, RefObject, useEffect, useRef, useState } from 'react';
import { Anohana, Btn, SwitchControl, Input, Bx, useBreakpoints } from '@curry-group/mui-curcuma';
import { Select, MenuItem } from '@material-ui/core';
import { ConferencingSubModal } from '..';

export interface IConferencingModalSettingsProps {
  open?: boolean;
  previewEnabled?: boolean;
  ctlAlVis?: boolean;
  onCtlAlVisChange?: (ctlAlVis: boolean) => void;
  onDevicesChange?: (audioDeviceId: string, audioOutDeviceId: string, videoDeviceId: string) => void;
  onCancel?: () => void;
  onCloseClick?: MouseEventHandler;
  onSave?: () => void;
}

export const ConferencingModalSettings: React.FC<IConferencingModalSettingsProps> = ({
  open,
  previewEnabled,
  ctlAlVis,
  onCtlAlVisChange,
  onDevicesChange,
  onCancel,
  onCloseClick,
  onSave
}) => {
  const videoPreviewRef = useRef<HTMLVideoElement>() as RefObject<HTMLVideoElement>;
  const [ctlAlVisInternal, setCtlAlVisInternal] = useState(ctlAlVis);
  const [audioDevices, setAudioDevices] = useState([] as MediaDeviceInfo[]);
  const [audioOutDevices, setAudioOutDevices] = useState([] as MediaDeviceInfo[]);
  const [videoDevices, setVideoDevices] = useState([] as MediaDeviceInfo[]);
  const [selectedAudioDevice, setSelectedAudioDevice] = useState<string>('');
  const [selectedAudioOutDevice, setSelectedAudioOutDevice] = useState<string>('');
  const [selectedVideoDevice, setSelectedVideoDevice] = useState<string>('');
  const breakpoints = useBreakpoints();
  const closeOpenStream = () => {
    const oldMs = videoPreviewRef.current?.srcObject as MediaStream;
    if (oldMs) {
      oldMs.getVideoTracks().forEach(tr => {
        tr.stop();
      });
    }
  };
  useEffect(() => {
    async function getDevices() {
      try {
        const media = await navigator.mediaDevices.getUserMedia({ audio: true, video: true }).catch(() => undefined);
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audio: MediaDeviceInfo[] = [];
        const audioOut: MediaDeviceInfo[] = [];
        const video: MediaDeviceInfo[] = [];
        for (let device of devices) {
          if (device.kind === 'audioinput') {
            audio.push(device);
          }
          if (device.kind === 'audiooutput') {
            audioOut.push(device);
          }
          if (device.kind === 'videoinput') {
            video.push(device);
          }
        }
        media?.getTracks().forEach(tr => tr.stop());
        setAudioDevices(audio);
        setAudioOutDevices(audioOut);
        setVideoDevices(video);
      } catch {}
    }
    if (open) {
      getDevices();
    } else {
      setAudioDevices([]);
      setAudioOutDevices([]);
      setVideoDevices([]);
    }
  }, [open]);
  useEffect(() => {
    if (audioDevices.length) {
      let foundDevice: MediaDeviceInfo | undefined = undefined;
      for (let audioDevice of audioDevices) {
        if (audioDevice.deviceId === localStorage['__vidConfDevAud']) {
          foundDevice = audioDevice;
          break;
        }
      }
      let device = foundDevice || audioDevices?.[0];
      if (device) {
        setSelectedAudioDevice(device.deviceId);
      } else {
        setSelectedAudioDevice('');
      }
    }
  }, [audioDevices]);
  useEffect(() => {
    if (audioOutDevices.length) {
      let foundDevice: MediaDeviceInfo | undefined = undefined;
      for (let audioOutDevice of audioOutDevices) {
        if (audioOutDevice.deviceId === localStorage['__vidConfDevAudOut']) {
          foundDevice = audioOutDevice;
          break;
        }
      }
      let device = foundDevice || audioOutDevices?.[0];
      if (device) {
        setSelectedAudioOutDevice(device.deviceId);
      } else {
        setSelectedAudioOutDevice('');
      }
    }
  }, [audioOutDevices]);
  useEffect(() => {
    if (videoDevices.length) {
      let foundDevice: MediaDeviceInfo | undefined = undefined;
      for (let videoDevice of videoDevices) {
        if (videoDevice.deviceId === localStorage['__vidConfDevVid']) {
          foundDevice = videoDevice;
          break;
        }
      }
      let device = foundDevice || videoDevices?.[0];
      if (device) {
        navigator.mediaDevices
          .getUserMedia({ video: { deviceId: { exact: device.deviceId } } })
          .then(d => {
            const ms = new MediaStream();
            d.getVideoTracks().forEach(tr => {
              ms.addTrack(tr);
            });
            if (videoPreviewRef.current) {
              videoPreviewRef.current.srcObject = ms;
            }
            setSelectedVideoDevice(device.deviceId);
          })
          .catch();
      } else {
        setSelectedVideoDevice('');
      }
    }
  }, [videoDevices]);
  return (
    <Anohana open={!!open} scroll="body" maxWidth="sm" fullWidth disableScrollLock transition="fade" keepMounted style={{ zIndex: 1301 }}>
      {open && (
        <ConferencingSubModal
          title="Einstellungen"
          onCloseClick={e => {
            closeOpenStream();
            onCloseClick?.(e);
          }}
        >
          {previewEnabled && breakpoints.mdUp && (
            <Bx mb={4} width="100%" height="auto" display="flex" justifyContent="center">
              <video autoPlay muted id="video-device-preview" ref={videoPreviewRef} width="240px" height="100%" />
            </Bx>
          )}
          <Bx mb={4}>
            <Input
              inputLabel={{ children: 'Videogerät', htmlFor: 'video-input' }}
              inputComponent={
                <Select
                  MenuProps={{ style: { zIndex: 1302 } }}
                  disabled={videoDevices.length < 1}
                  id="video-input"
                  value={selectedVideoDevice}
                  onChange={e => {
                    if (selectedVideoDevice !== (e.target.value as string)) {
                      closeOpenStream();
                    }
                    navigator.mediaDevices
                      .getUserMedia({ video: { deviceId: { exact: e.target.value as string } } })
                      .then(d => {
                        const ms = new MediaStream();
                        d.getVideoTracks().forEach(tr => {
                          ms.addTrack(tr);
                        });
                        if (videoPreviewRef.current) {
                          videoPreviewRef.current.srcObject = ms;
                        }
                        setSelectedVideoDevice(e.target.value as string);
                      })
                      .catch();
                  }}
                >
                  {videoDevices.map(vd => (
                    <MenuItem key={vd.deviceId} value={vd.deviceId}>
                      {vd.label}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </Bx>
          <Bx mb={4}>
            <Input
              inputLabel={{ children: 'Audioeingabegerät', htmlFor: 'audio-input' }}
              inputComponent={
                <Select
                  MenuProps={{ style: { zIndex: 1302 } }}
                  disabled={audioDevices.length < 1}
                  id="audio-input"
                  value={selectedAudioDevice}
                  onChange={e => {
                    setSelectedAudioDevice(e.target.value as string);
                  }}
                  style={{ zIndex: 1302 }}
                >
                  {audioDevices.map(ad => (
                    <MenuItem key={ad.deviceId} value={ad.deviceId}>
                      {ad.label}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </Bx>
          <Bx mb={4}>
            <Input
              inputLabel={{ children: 'Audioausgabegerät', htmlFor: 'audio-output' }}
              inputComponent={
                <Select
                  MenuProps={{ style: { zIndex: 1302 } }}
                  disabled={audioOutDevices.length < 1}
                  id="audio-output"
                  value={selectedAudioOutDevice}
                  onChange={e => {
                    setSelectedAudioOutDevice(e.target.value as string);
                  }}
                  style={{ zIndex: 1302 }}
                >
                  {audioOutDevices.map(ad => (
                    <MenuItem key={ad.deviceId} value={ad.deviceId}>
                      {ad.label}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </Bx>
          <Bx mb={8}>
            <Input
              inputLabel={{ children: 'Kontroll-Elemente immer anzeigen', htmlFor: 'controls-on-input' }}
              inputComponent={
                <Bx m={-1}>
                  <SwitchControl
                    id="controls-on-input"
                    color="primary"
                    size="medium"
                    checked={ctlAlVisInternal}
                    onChange={() => {
                      setCtlAlVisInternal(!ctlAlVisInternal);
                    }}
                  />
                </Bx>
              }
            />
          </Bx>
          <Bx display="flex">
            <Bx>
              <Btn
                variant="outlined"
                onClick={() => {
                  closeOpenStream();
                  onCancel?.();
                  setCtlAlVisInternal(ctlAlVis);
                }}
                size="large"
              >
                Abbrechen
              </Btn>
            </Bx>
            <Bx ml="auto">
              <Btn
                color="secondary"
                variant="contained"
                size="large"
                onClick={() => {
                  closeOpenStream();
                  onCtlAlVisChange?.(!!ctlAlVisInternal);
                  onDevicesChange?.(selectedAudioDevice, selectedAudioOutDevice, selectedVideoDevice);
                  onSave?.();
                }}
              >
                Speichern
              </Btn>
            </Bx>
          </Bx>
        </ConferencingSubModal>
      )}
    </Anohana>
  );
};
