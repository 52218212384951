import { callGet } from '@curry-group/data-addons';
import { all, put, takeLatest } from 'redux-saga/effects';
import { fetchContentFailed, fetchContentRequest, fetchContentSuccess, fetchTandemFailed, fetchTandemRequest, fetchTandemSuccess } from '../../reducer/detail';
import { fetchParticipationFailed, fetchParticipationRequest, setActionCanvas, setCommunication, subscribeToSignalR } from '../../reducer/communication';
import api from '../../api';
import { setNotificationsRead } from '../../reducer/notifications';

function* watcher() {
  yield takeLatest(fetchTandemRequest.type, fetchTandemWorker);
  yield takeLatest(fetchContentRequest.type, fetchContentWorker);
  yield takeLatest(fetchTandemSuccess.type, tandemSuccessHandler);
}

function* fetchTandemWorker(action: ReturnType<typeof fetchTandemRequest>) {
  try {
    yield put(setCommunication({ communication: undefined, id: undefined }));
    yield put(fetchParticipationFailed({ message: '' }));

    const url = api.urlWithParams(api.detail.tandem, { alias: action.payload });
    const result = yield callGet(url);

    yield put(fetchTandemSuccess(result));
  } catch (e) {
    yield put(fetchTandemFailed());
  }
}

function* fetchContentWorker(action: ReturnType<typeof fetchContentRequest>) {
  try {
    yield put(setCommunication({ communication: undefined, id: undefined }));
    yield put(fetchParticipationFailed({ message: '' }));

    const url = api.urlWithParams(api.detail.content, { alias: action.payload });
    const result = yield callGet(url);

    yield put(fetchContentSuccess(result));
  } catch (e) {
    yield put(fetchContentFailed());
  }
}

function* tandemSuccessHandler(action: ReturnType<typeof fetchTandemSuccess>) {
  if (action.payload) {
    try {
      if (action.payload.communication) {
        yield put(setCommunication({ communication: action.payload.communication, id: action.payload._id }));
        yield put(fetchParticipationRequest({ id: action.payload._id }));
        yield put(subscribeToSignalR({ id: action.payload._id }));
        yield put(setActionCanvas({ open: false }));
        yield put(setNotificationsRead({ thing: action.payload._id }));
      }
    } catch (e) {}
  }
}

export function* detail() {
  yield all([watcher()]);
}
