import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMessageModel } from '../../../model/communication/Message';

export interface IConferencingState {
  visible: boolean;
  message?: IMessageModel;
  token?: string;
}

const initialState: IConferencingState = {
  visible: false,
  message: undefined,
  token: undefined
};

const conferencing = createSlice({
  name: 'conferencing',
  initialState,
  reducers: {
    toggleConferencing: (state, action: PayloadAction<any>) => {
      const newVisible = !state.visible;
      state.visible = newVisible;
      if (state.visible) {
        if (action.payload.message) {
          state.message = action.payload.message;
        }
        if (action.payload.token) {
          state.token = action.payload.token;
        }
      } else {
        state.message = undefined;
        state.token = undefined;
      }
    },
    updateConferencingMessage: (state, action: PayloadAction<{ message?: IMessageModel | null; token?: string }>) => {
      if (action.payload?.message !== undefined) {
        state.message = action.payload.message || undefined;
      }
      if (action.payload?.token) {
        state.token = action.payload.token;
      }
    }
  }
});

export const { toggleConferencing, updateConferencingMessage } = conferencing.actions;
export default conferencing.reducer;
