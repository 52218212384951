import React from 'react';
import { useLocation } from 'react-router';
import { Bx } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { iconMapping, INavbarContentProps, navItemActive } from '..';
import { getNavigationPath, INavigationConfig, INavigationContainerConfig } from '../../../model/configuration/navigation';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { IDictionary } from '../../../model/common/dictionary';
import { NavBarHeadline } from '../../navbar-headline';
import { NavBarBtn } from '../../navbar-btn';
import { ThingTypes } from '../../../model/common/thingTypes';

export const NavbarContent: React.FC<INavbarContentProps> = ({ sections, badges }) => {
  return (
    <React.Fragment>
      {sections.map((item, sectionIndex) => {
        return (
          <React.Fragment key={sectionIndex}>
            {item.name && (
              <Bx mt={5} mb={1}>
                <NavBarHeadline>{item.name}</NavBarHeadline>
              </Bx>
            )}
            {!!item.configs.length &&
              item.configs.filter(item => item.visible).map((config, itemIndex) => <NavbarContentBtn key={config.alias} badges={badges} section={item} config={config} />)}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export const NavbarContentBtn: React.FC<{ section: INavigationConfig; config: INavigationContainerConfig; badges: IDictionary<number> }> = ({ section, config, badges }) => {
  const location = useLocation();
  let badgeCount = 0;
  if (config.alias === 'notifications') {
    badgeCount = badges['notifications'] || 0;
  } else if (config.alias === 'tandems') {
    for (let type of [ThingTypes.ADMINCHAT, ThingTypes.TANDEM]) {
      if (badges[type]) {
        badgeCount += badges[type];
      }
    }
  }
  const active = navItemActive(location, getNavigationPath(section, config));
  return (
    <NavBarBtn
      key={config.alias}
      active={active}
      to={getNavigationPath(section, config)}
      badgeContent={badgeCount ? badgeCount : undefined}
      badgeColor={active ? 'tertiary' : 'secondary'}
      startIcon={<FontAwesomeIcon icon={iconMapping[config.alias] || faQuestionCircle} />}
    >
      {config.name}
    </NavBarBtn>
  );
};
