import { buildAbbreviation } from '@curry-group/mui-curcuma';
import React from 'react';
import { useSelector } from 'react-redux';
import { Message } from '..';
import { assetUrl, resolveTimestamp } from '../../../../helper';
import { IMessageModel, getMessageCreatedByName } from '../../../../model/communication/Message';
import { MessageThreadDivider } from '../divider';

export interface IMessageWrapperProps {
  currentUserId: string;
  message: IMessageModel;
  allowThreading?: boolean;
  width?: number;
  asInlineThread?: boolean;
  inlineThreading?: boolean;
  allowVoting?: boolean;
  allowQuotes?: boolean;
  manageMessages?: boolean;
  threadingClicked?: (message: IMessageModel) => void;
  voteUpClick?: (message: IMessageModel) => void;
  voteDownClick?: (message: IMessageModel) => void;
  quoteClicked?: (message: IMessageModel) => void;
  loadThreadMessages?: (message: IMessageModel) => void;
  editMessage?: (message: IMessageModel) => void;
  deleteMessage?: (message: IMessageModel) => void;
  onMessageRead?: (message: IMessageModel) => void;
  onJoinConference?: (message: IMessageModel) => void;
  onReferenceSelected?: (type: string, data: any) => void;
}

export const MessageWrapper: React.FC<IMessageWrapperProps> = ({
  currentUserId,
  inlineThreading,
  message,
  allowVoting,
  asInlineThread,
  allowThreading,
  width,
  allowQuotes,
  manageMessages,
  voteUpClick,
  voteDownClick,
  threadingClicked,
  quoteClicked,
  loadThreadMessages,
  editMessage,
  deleteMessage,
  onMessageRead,
  onJoinConference,
  onReferenceSelected
}) => {
  const state = useSelector(state => state.communication?.messages?.[message.id]);
  const isOwnMessage = message.createdBy === currentUserId;
  let userVoting = 0;
  if (message.voting) {
    userVoting = message.voting[currentUserId];
  }
  const modified = message.createdAt !== message.modifiedAt;

  const result: any = [];
  result.push(
    <Message
      allowQuotes={allowQuotes}
      allowVoting={allowVoting}
      allowThreading={allowThreading}
      asInlineThread={asInlineThread}
      canEdit={isOwnMessage || manageMessages}
      canDelete={!message.numThreadChildren && (isOwnMessage || manageMessages)}
      key={'message-' + message.id}
      width={width}
      id={message.id}
      createdBy={message.createdBy}
      isOwnMessage={isOwnMessage}
      deleted={message.deleted}
      modified={modified}
      content={message.content}
      quotes={message.quotesResolved}
      numVotes={message.numVotes}
      attachments={message.attachmentsResolved}
      userVoting={userVoting}
      numThreadMessages={inlineThreading ? 0 : message.numThreadChildren}
      references={message.referencesResolved}
      timestamp={modified ? resolveTimestamp(message.modifiedAt) : resolveTimestamp(message.createdAt)}
      abbreviation={buildAbbreviation(getMessageCreatedByName(message, ''))}
      avatarSrc={message.createdByResolved?.content?.avatar ? assetUrl(message.createdByResolved?.content?.avatar, true) : undefined}
      name={getMessageCreatedByName(message)}
      conferenceInfo={message.conferenceInfo}
      voteDownClick={() => voteDownClick?.(message)}
      voteUpClick={() => voteUpClick?.(message)}
      onEditClicked={() => editMessage?.(message)}
      onDeleteClicked={() => deleteMessage?.(message)}
      quoteClicked={() => quoteClicked?.(message)}
      onMessageRead={() => onMessageRead?.(message)}
      onJoinConference={m => onJoinConference?.(m || message)}
      threadingClicked={() => threadingClicked?.(message)}
      onReferenceSelected={onReferenceSelected}
    />
  );

  const loadedThreadChildren = state?.messages;
  const numLoadedThreadChildren = loadedThreadChildren?.length;
  if (inlineThreading && message.numThreadChildren && loadedThreadChildren && numLoadedThreadChildren) {
    const numThreadChildren = message.numThreadChildren - numLoadedThreadChildren;
    result.push(
      <MessageThreadDivider
        key={message.id}
        caption={!!numThreadChildren ? numThreadChildren + ' weitere Antworten' : undefined}
        working={state?.working || state?.olderWorking}
        loadMoreClicked={() => {
          loadThreadMessages?.(message);
        }}
      />
    );

    for (let threadIndex = 0; threadIndex < numLoadedThreadChildren; threadIndex++) {
      const threadMessage = loadedThreadChildren[threadIndex];
      result.push(
        <MessageWrapper
          currentUserId={currentUserId}
          allowQuotes={allowQuotes}
          quoteClicked={() => quoteClicked?.(threadMessage)}
          allowVoting={allowVoting}
          voteUpClick={() => voteUpClick?.(threadMessage)}
          voteDownClick={() => voteDownClick?.(threadMessage)}
          inlineThreading={inlineThreading}
          asInlineThread={true}
          allowThreading={false}
          width={width}
          key={'thread-message-' + threadMessage.id}
          message={threadMessage}
          onReferenceSelected={onReferenceSelected}
        />
      );
      if (threadIndex !== numLoadedThreadChildren - 1) {
        result.push(<MessageThreadDivider key={'thread-divider-' + threadMessage.id} />);
      }
    }
  }

  return <>{result}</>;
};
