import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight, faReply, faThumbsDown, faThumbsUp } from '@fortawesome/pro-light-svg-icons';
import { Box } from '@material-ui/core';

import { Typo, IconBtn, Btn } from '@curry-group/mui-curcuma';
import useTheme from '@material-ui/core/styles/useTheme';

export interface IMessageInteractionBarProps {
  hasVoting?: boolean;
  hasThreading?: boolean;
  hasQuotes?: boolean;

  isDeleted?: boolean;
  isOwnMessage?: boolean;

  numChildren?: number;
  numVotes?: number;
  userVoting?: number;

  requestThread: () => void;
  requestQuote: () => void;

  voteUp: () => void;
  voteDown: () => void;
}

export const MessageInteractionBar: FC<IMessageInteractionBarProps> = ({
  hasVoting,
  hasThreading,
  hasQuotes,
  isDeleted,
  isOwnMessage,
  numChildren,
  numVotes,
  userVoting,
  requestThread,
  requestQuote,
  voteUp,
  voteDown
}) => {
  const theme = useTheme();

  return (
    <>
      {(hasVoting || hasThreading || hasQuotes) && (
        <Box
          mt={2}
          borderTop={hasVoting ? `1px solid ${theme.palette.divider}` : ''}
          borderBottom={hasVoting ? `1px solid ${theme.palette.divider}` : ''}
          display={hasVoting ? 'flex' : ''}
        >
          {!isDeleted && hasVoting && (
            <>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgcolor={isOwnMessage ? 'background.paper' : 'secondary.main'}
                color={isOwnMessage ? 'primary.main' : 'background.paper'}
                minWidth={36}
              >
                <Typo variant="h4">{numVotes || 0}</Typo>
              </Box>
              <Box borderRight={`1px solid ${theme.palette.divider}`}>
                <IconBtn disabled={userVoting !== undefined && userVoting > 0} onClick={voteUp} size="small" color="inherit">
                  <FontAwesomeIcon icon={faThumbsUp} />
                </IconBtn>
              </Box>
              <Box borderRight={`1px solid ${theme.palette.divider}`}>
                <IconBtn disabled={userVoting !== undefined && userVoting < 0} onClick={voteDown} size="small" color="inherit">
                  <FontAwesomeIcon icon={faThumbsDown} />
                </IconBtn>
              </Box>
            </>
          )}

          {hasThreading && (
            <Box mr={!hasVoting ? 1 : 0} ml={hasVoting ? 'auto' : ''} display={hasVoting ? 'flex' : ''} alignItems={hasVoting ? 'center' : ''}>
              <Btn
                onClick={() => requestThread()}
                noXPadding
                color={isOwnMessage || isDeleted ? 'inherit' : 'primary'}
                size="small"
                startIcon={<FontAwesomeIcon icon={faReply} />}
                disabled={!numChildren && isDeleted}
              >
                {!numChildren && 'Antworten'}
                {!!(numChildren && numChildren === 1) && '1 Antwort'}
                {!!(numChildren && numChildren > 1) && numChildren + ' Antworten'}
              </Btn>
            </Box>
          )}

          {!isDeleted && hasQuotes && !hasThreading && (
            <Box mr={!hasVoting ? 1 : 0} ml={hasVoting ? 'auto' : ''} display={hasVoting ? 'flex' : ''} alignItems={hasVoting ? 'center' : ''}>
              <Btn onClick={() => requestQuote()} noXPadding color={isOwnMessage ? 'inherit' : 'primary'} size="small" startIcon={<FontAwesomeIcon icon={faQuoteRight} />}>
                Zitieren
              </Btn>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
