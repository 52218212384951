import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import clsx from 'clsx';
import useTheme from '@material-ui/core/styles/useTheme';
import { Bx, IBxProps } from '@curry-group/mui-curcuma';

export interface IConferenceChatFrameProps extends Omit<IBxProps, 'bgcolor'> {
  bottomBar?: React.ReactNode;
  headBar?: React.ReactNode;
  bgcolor?: React.CSSProperties['backgroundColor'];
  fullscreen?: boolean;
}

export interface IConferenceChatFrameThemeExtension {
  conferenceChatFrame: {
    widthXS?: React.CSSProperties['width'];
    widthSM?: React.CSSProperties['width'];
    widthMD?: React.CSSProperties['width'];
    widthLG?: React.CSSProperties['width'];
    widthXL?: React.CSSProperties['width'];
    scrollBarTrackBackgroundColor?: React.CSSProperties['backgroundColor'];
    scrollBarThumbBackgroundColor?: React.CSSProperties['backgroundColor'];
  };
}

interface IConferenceChatFrameStyles {
  bgcolor?: React.CSSProperties['backgroundColor'];
}

const useStyles = makeStyles(
  theme => ({
    conferenceChatFrameRoot: {
      overflowX: 'hidden',
      overflowY: 'hidden'
    },
    headline: {
      fontSize: 18,
      fontWeight: 500
    },
    headlineContainer: {
      background: (props: IConferenceChatFrameStyles) => `linear-gradient(to bottom, ${props.bgcolor} 75%, transparent)`
    }
  }),
  { name: 'Curcuma' }
);

export const ConferenceChatFrame: React.FunctionComponent<IConferenceChatFrameProps> = ({ headBar, bottomBar, children, className, fullscreen, ...rest }) => {
  const theme = useTheme();
  const bgcolor = rest.bgcolor ? _.get(theme.palette, rest.bgcolor, rest.bgcolor) : undefined;
  const classes = useStyles({ bgcolor });

  return (
    <Bx width={fullscreen ? '100%' : 450} className={clsx(classes.conferenceChatFrameRoot, className)} {...rest}>
      <Bx
        display="grid"
        gridTemplateColumns="1fr"
        gridTemplateRows="auto minmax(0px, 1fr) auto"
        gridGap="0px 0px"
        gridTemplateAreas={`
      "chatHead"
      "chatContent"
      "chatBar"`}
        height="100%"
      >
        {headBar && <Bx gridArea="chatHead">{headBar}</Bx>}
        <Bx gridArea="chatContent">{children}</Bx>
        {bottomBar && <Bx gridArea="chatBar">{bottomBar}</Bx>}
      </Bx>
    </Bx>
  );
};
