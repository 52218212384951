import React, { MouseEventHandler } from 'react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Bx, IconBtn, Typo } from '@curry-group/mui-curcuma';
import { Tooltip } from '@material-ui/core';

export interface IConferencingSubModal {
  title?: string;
  onTitleClick?: MouseEventHandler;
  onCloseClick?: MouseEventHandler;
}

export const ConferencingSubModal: React.FC<IConferencingSubModal> = ({ children, title, onCloseClick, onTitleClick }) => {
  return (
    <Bx p={2.5}>
      <Bx display="flex" alignItems="center" mb={3}>
        <Typo variant="h4" component="span" onClick={onTitleClick}>
          {title}
        </Typo>
        <Bx ml="auto" mr={-1}>
          <Tooltip title={`${title} schließen`} arrow>
            <IconBtn onClick={onCloseClick}>
              <FontAwesomeIcon icon={faTimes} />
            </IconBtn>
          </Tooltip>
        </Bx>
      </Bx>
      {children}
    </Bx>
  );
};
