import { Bx, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React from 'react';

export const NotFoundComponent: React.FC = () => {
  return (
    <Bx gridArea="content" display="flex" alignItems="center" justifyContent="center" height="100%">
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item xs={12} md={10} lg={8} xl={6}>
              <Bx textAlign="center" mb={{ xs: 1, md: 2, lg: 3 }}>
                <img src="/images/frage.svg" alt="Error 404: Page not found" />
              </Bx>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item xs={12} md={10} lg={8} xl={6}>
              <Typo variant="subtitle1" align="center">
                Diese Seite wurde leider nicht gefunden
              </Typo>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Bx>
  );
};
