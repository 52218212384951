import { FC } from 'react';
import { Bx } from '@curry-group/mui-curcuma';

export const Sidebar: FC<{ sidebarPosition?: 'left' | 'right'; isMobile?: boolean }> = ({ children, sidebarPosition = 'left', isMobile }) => {
  return (
    <Bx id="frame__sidebar" gridArea={isMobile ? 'content' : sidebarPosition === 'left' ? 'sidebar' : 'sidebar-right'} zIndex={3} position="sticky" top="0" height="100vh">
      {children}
    </Bx>
  );
};
