import React, { useEffect,useCallback, useState } from "react";
import { Bx, Typo } from "@curry-group/mui-curcuma";
import { Grid } from "@material-ui/core";
import { FormControlInput } from "../../../../../components/form/control/input";
import { IBaseRegistrationStepProps } from "../..";
import { useSelector } from "react-redux";
import { validateInput } from "../../../../../helper";
import { FramedContent } from "../../../../../components/framed-content";

export interface IRegistrationAddressProps extends IBaseRegistrationStepProps {
  
}

export const RegistrationAddress: React.FC<IRegistrationAddressProps> = ({
  onDataValidation
}) => {
  const {registrationRequestData} = useSelector(state => state.registrationRequest);
  const [street,setStreet] = useState(registrationRequestData?.street || '');
  const [city,setCity] = useState(registrationRequestData?.city || '');
  const [zipCode,setZipCode] = useState(registrationRequestData?.zipCode || '');
  
  const validate = useCallback(
    (_city, _street, _zipCode) => {
      return (!!validateInput(_city) && !!validateInput(_street) && !!validateInput(_zipCode));
    },
    []
  );

  useEffect(() => {
    onDataValidation(validate(city, street, zipCode), {city, street, zipCode});
  }, [city, street, zipCode, validate, onDataValidation]);

  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Registrierung auf Schüler Connect Online
        </Typo>
      </Bx>
      <Bx>
        <Typo variant="subtitle1">Bitte tragen Sie Ihre Adressdaten ein.</Typo>
      </Bx>
      <Bx my={8}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormControlInput
                value={city}
                label={"Stadt"}
                placeholder={"Stadt eingeben"}
                minLength={2}
                maxLength={50}
                onChange={setCity}
                validator={(value) => !!value}
                successMessage=""
                fieldGroup={"userData"}
                lazy
              />
            </Bx>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormControlInput
                value={zipCode}
                label={"Postleitzahl"}
                placeholder={"Postleitzahl eingeben"}
                minLength={2}
                maxLength={50}
                onChange={setZipCode}
                validator={(value) => !!value}
                successMessage=""
                fieldGroup={"userData"}
                lazy
              />
            </Bx>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormControlInput
                value={street}
                label={"Straße"}
                placeholder={"Straße eingeben"}
                minLength={2}
                maxLength={50}
                onChange={setStreet}
                validator={(value) => !!value}
                successMessage=""
                fieldGroup={"userData"}
                lazy
              />
            </Bx>
          </Grid>
        </Grid>
      </Bx>
    </FramedContent>
  );
};
