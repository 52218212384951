import { FC } from 'react';
import { Bx } from '@curry-group/mui-curcuma';

export const Content: FC = ({ children }) => {
  return (
    <Bx id="frame__content" gridArea="content" position="relative">
      {children}
    </Bx>
  );
};
