export class MmoaoActionTypes {
  static MESSAGE_CREATED = 'message-created';
  static MESSAGE_UPDATED = 'message-updated';
  static MESSAGE_DELETED = 'message-deleted';
  static MESSAGE_VOTED = 'message-voted';

  static PARTICIPANT_REMOVED = 'participant-removed';
  static PARTICIPANT_LEFT = 'participant-left';

  static INVITATION_CREATED = 'invitation-created';
  static INVITATION_UPDATED = 'invitation-updated';
  static INVITATION_ACCEPTED = 'invitation-accepted';
  static INVITATION_DECLINED = 'invitation-declined';
  static INVITATION_REVOKED = 'invitation-revoked';

  static REQUEST_CREATED = 'request-created';
  static REQUEST_ACCEPTED = 'request-accepted';
  static REQUEST_DECLINED = 'request-declined';
  static REQUEST_REVOKED = 'request-revoked';
}

export interface MmoaoAction<T> {
  type: string;
  parent: string;

  createdBy: string;
  timestamp: number;

  payload: T;
}

export interface MmoaoMessageActionPayload {
  messageId: string;
}

export interface MmoaoMessageAction extends MmoaoAction<MmoaoMessageActionPayload> {}

export interface MmoaoParticipationActionPayload {
  userId: string;
  status: string;
  type: string;
}

export interface MmoaoParticipationAction extends MmoaoAction<MmoaoParticipationActionPayload> {}
