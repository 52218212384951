import {
  Bx,
  Ctn,
  IBxProps,
  ICtnProps,
  Typo,
  UnsortedList,
} from "@curry-group/mui-curcuma";
import Grid, { GridProps } from "@material-ui/core/Grid";
import { ClassNameMap, makeStyles } from "@material-ui/styles";
import React from "react";
import clsx from "clsx";

interface ILandingPageSplitSectionProps {
  sectionPy: IBxProps["px"];
  sectionPx: IBxProps["px"];
  ctnMaxWidth: ICtnProps["maxWidth"];
  innerSectionSpacing: GridProps["spacing"];
  imageSrc: string;
  imageAlt: string;
  headline?: string;
  h1FontSize: number;
  body4FontSize: number;
  content: { children: React.ReactNode; icon: React.ReactNode }[];
  text: string;
  order?: "even" | "odd";
  classes: ClassNameMap<"line" | "boldText">;
  zIndex: number;
  responsive: { isMDUp: boolean };
}

const useStyles = makeStyles({
  mobileBx: {
    width: 'calc(100% + 64px)',
    height: 'calc(100% + 96px)',
    margin: '-48px -32px -48px -32px',
  },
  desktopBx: {
    width: "100%",
    height: "100%"
  }
})

export const LandingPageSplitSection: React.FunctionComponent<ILandingPageSplitSectionProps> =
  ({
    headline,
    h1FontSize,
    body4FontSize,
    content,
    sectionPy,
    sectionPx,
    ctnMaxWidth,
    innerSectionSpacing,
    imageSrc,
    imageAlt,
    order,
    text,
    classes,
    zIndex,
    responsive,
  }) => {
    const TextTemplate = (
      <Grid item xs={12} md={6}>
        <Bx
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          pt={responsive.isMDUp ? 0 : 6}
        >
          {headline && (
            <Typo
              variant="h1"
              component="h2"
              style={{fontSize: h1FontSize}}
              className={clsx(classes.line, classes.boldText)}
              gutterBottom
              dangerouslySetInnerHTML={{ __html: headline }}
            />
          )}
          <Bx pb={2}>
            <Typo
              variant="body1"
              style={{fontSize: body4FontSize}}
              component="p"
              gutterBottom
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </Bx>
          <UnsortedList items={content} spacing={2} />
        </Bx>
      </Grid>
    );

    const muiClasses = useStyles();

    return (
      <Bx component="section" width="100%" py={sectionPy} px={sectionPx} zIndex={zIndex} bgcolor={responsive.isMDUp ? "" : "background.paper"}>
        <Ctn maxWidth={ctnMaxWidth}>
          <Grid container spacing={innerSectionSpacing}>
            {order === "odd" && responsive.isMDUp && TextTemplate}
            <Grid item xs={12} md={6}>
              <Bx
                display="flex"
                alignItems="center"
                bgcolor="accent.light"
                className={!responsive.isMDUp ? muiClasses.mobileBx : muiClasses.desktopBx}
                p={responsive.isMDUp ? 0 : 4}
              >
                <img
                  src={imageSrc}
                  alt={imageAlt}
                  style={{ width: "100%", height: "auto" }}
                />
              </Bx>
            </Grid>
            {!responsive.isMDUp ? TextTemplate : order === "even" ? TextTemplate : null}
          </Grid>
        </Ctn>
      </Bx>
    );
  };
