import { FC } from 'react';
import { Bx } from '@curry-group/mui-curcuma';

export const Header: FC = ({ children }) => {
  return (
    <Bx id="frame__header" gridArea="header" zIndex={2} position="sticky" top="0">
      {children}
    </Bx>
  );
};
