import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActionCanvas, setMessageDraftEdit, setMessageDraftQuotes, setMessageDraftThreadRoot, voteMessageRequest } from '../../../../data/reducer/communication';
import { ConnectedCommunicationInput } from '../input';
import useTheme from '@material-ui/core/styles/useTheme';
import { ActionCanvas, Bx, IconBtn, useBreakpoints } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConnectedMessages } from '../messages';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { GetMessagesRequest } from '../../../../model/communication/Message';
import { useIsMobile } from '../../../../helper/hooks/useIsMobile';
import { HeadlineContainer } from '../../../../components/headline-container';

export interface IConnectedCommunicationActionCanvasProps {
  headline: string;
  threadOnly?: boolean;
  request?: GetMessagesRequest;
}
export const ConnectedCommunicationActionCanvas: React.FC<IConnectedCommunicationActionCanvasProps> = ({ headline, threadOnly, request }) => {
  const theme = useTheme<any>();
  const breakpoints = useBreakpoints();
  const state = useSelector(state => state.communication?.actioncanvas);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  let boxWidth;
  if (breakpoints.xs || breakpoints.sm) {
    boxWidth = '100%';
  } else {
    boxWidth = 'calc(100% * (2 - 1.6180334))';
  }

  // das actioncanvas für den chat in der detailansicht wird direkt gemounted
  // soll aber erst laden wenn es geöffnet wird (dann ist threadRoot gesetzt)
  if (threadOnly && !state?.threadRoot) return <></>;

  function close() {
    dispatch(setActionCanvas({ open: false, threadRoot: undefined }));
  }

  return (
    <ActionCanvas
      headerElement={isMobile ? undefined : document.getElementById('frame__header') || undefined}
      container={isMobile ? undefined : document.getElementById('frame__content') || undefined}
      backdropProps={{ open: state?.open || false, onClick: () => close(), style: { top: isMobile ? 0 : undefined } }}
      slideProps={{ in: state?.open, direction: 'left' }}
      boxProps={{ width: boxWidth }}
    >
      <Bx height="100%" display="flex" flexDirection="column" flexWrap="nowrap">
        <HeadlineContainer
          bgcolor="background.paper"
          px={isMobile ? 2 : 3}
          py={isMobile ? 2 : 2.5}
          headline={headline}
          minHeight={isMobile ? 0 : theme.custom?.headBar?.minHeightDesktop}
          headlineVariant="h4"
          headlineComponent="h5"
          endAdornment={
            <IconBtn size="small" onClick={() => close()}>
              <FontAwesomeIcon icon={faTimes} />
            </IconBtn>
          }
        />
        <ConnectedMessages
          p={isMobile ? 2 : 3}
          scrollContainer={true}
          request={request}
          alias={state?.threadRoot?.id || 'main'}
          autoWidth={false}
          quoteClicked={message => {
            dispatch(setMessageDraftQuotes({ quotes: message, id: 'actioncanvas' }));
          }}
          disableThreading={!!state?.threadRoot}
          forceInlineThreading
          threadRoot={state?.threadRoot}
          threadingClicked={message => {
            dispatch(setMessageDraftThreadRoot({ id: 'actioncanvas', threadRoot: message }));
          }}
          voteDownClick={message => {
            dispatch(voteMessageRequest({ communicationId: message.parent, alias: 'actioncanvas', messageId: message.id, up: false }));
          }}
          voteUpClick={message => {
            dispatch(voteMessageRequest({ communicationId: message.parent, alias: 'actioncanvas', messageId: message.id, up: true }));
          }}
          editMessage={message => {
            dispatch(setMessageDraftEdit({ id: 'actioncanvas', message }));
          }}
        />
        {(!state?.threadRoot || !state?.threadRoot.deleted) && (
          <ConnectedCommunicationInput
            inputId="actioncanvas"
            small
            displayThreadRoot={!state?.threadRoot}
            removeThreadRoot={() => {
              // befinden wir uns im actioncanvas muss der threadRoot wieder auf den ursprünglichen wert gesetzt werden (undefined oder IMessageModel)
              dispatch(setMessageDraftThreadRoot({ id: 'actioncanvas', threadRoot: state?.threadRoot }));
            }}
          />
        )}
      </Bx>
    </ActionCanvas>
  );
};
