import { useDispatch, useSelector } from 'react-redux';
import { INavbarFrameConnectedProps, NavbarDrawer, NavbarFrame } from '../../../components/navbar';
import { setAppstateNavbarOpen } from '../../../data/reducer/app';
import { assetUrl } from '../../../helper';

export interface INavigationConfig {
  name: string;
  alias: string;
  configs: INavigationContainerConfig[];
}

export interface INavigationContainerConfig {
  name: string;
  alias: string;
  visible: boolean;
  order: number;
}

const useNavbarProps = () => {
  const { badges } = useSelector(state => state.notifications) || { notificationCount: 0, badges: {} };
  const { profile, navbarOpen } = useSelector(state => state.app);
  const dispatch = useDispatch();
  const setNavbarOpen = () => dispatch(setAppstateNavbarOpen(!navbarOpen));

  const sections = [
    {
      name: '',
      alias: '',
      configs: [
        { name: 'Start', alias: 'start', visible: true, order: 0 },
        { name: 'Benachrichtigungen', alias: 'notifications', visible: true, order: 1 }
      ]
    },
    {
      name: 'Kommunikation',
      alias: '',
      configs: [{ name: 'Deine Tandems', alias: 'tandems', visible: true, order: 0 }]
    }
  ];

  if (profile?.type === 'admin') {
    sections.push({
      name: 'Administration',
      alias: '',
      configs: [{ name: 'Dashboard', alias: 'admin-dashboard', visible: true, order: 0 }]
    });
  }

  return {
    open: !!navbarOpen,
    setOpen: setNavbarOpen,
    sections,
    badges,
    userId: profile?.userId,
    userFullName: profile?.fullName,
    userAvatarSrc: profile?.avatar ? assetUrl(profile.avatar, true) : undefined
  };
};

export const ConnectedNavBarDrawer: React.FC = () => {
  const stateProps = useNavbarProps();
  return <NavbarDrawer {...stateProps} />;
};

export const ConnectedNavBar: React.FC<INavbarFrameConnectedProps> = props => {
  const stateProps = useNavbarProps();
  return <NavbarFrame {...props} {...stateProps} />;
};
