import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FinishFlow } from '../../../../../components/flow/finish';
import { finalStepInitialRequest, IRegistrationFinalStepData } from '../../../../../data/reducer/registration';

export interface IRegistrationFinalStepInitializeProps {
  successCallback: (result: IRegistrationFinalStepData) => void;
}

export const RegistrationFinalStepInitialize: React.FC<IRegistrationFinalStepInitializeProps> = ({successCallback}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [smthng, setSmthng] = useState(true);
  const { finalStepSuccess, finalStepFailed, finalStepWorking} = useSelector(state => state.registration);
  let token = new URLSearchParams(location.search).get('token') || '';
  if(smthng){
    dispatch(finalStepInitialRequest(token));
    setSmthng(false);
  }

  if (finalStepWorking) {
    return (
      <FinishFlow
        timeout={3000}
        onTimeout={() => {
        }}
        headline="Ihre Daten werden sicher übertragen"
      />
    );
  }

  if (finalStepSuccess != null) {
    successCallback(finalStepSuccess);
    return (
      <div>{}</div>
    );
  }

  if (finalStepFailed) {
    return (
      <FinishFlow
        hideLoader={true}
        timeout={10000}
        onTimeout={() => {
          history.push('/');
        }}
        headline="Fehler beim Laden von Daten."
        text="Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut. Sie werden in Kürze weitergeleitet."
      />
    );
  }
  return <></>;
};
