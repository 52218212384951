import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Btn, Bx, IconBtn, Typo } from '@curry-group/mui-curcuma';
import { Wizard, WizardContent, WizardNavigation } from '@curry-group/react-wizard';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Hidden, Typography } from '@material-ui/core';
import { IRegistrationFinalizeRequestData, TProfileType } from '../../../../model/data/registration';
import { Header, Content, Footer, Sidebar } from '../../../../components/frame';
import { useIsMobile } from '../../../../helper/hooks/useIsMobile';
import { useDispatch, useSelector } from 'react-redux';
import { finalizeRegistrationRequest } from '../../../../data/reducer/registration';
import { setRegistrationFinalizeRequestData } from '../../../../data/reducer/registrationFinalRequest';

import { RegistrationPassword } from './password';
import { RegistrationFinalizeFinished } from './finished';
import { RegistrationSchool } from './school';
import { RegistrationIBAN } from './iban';
import { RegistrationSubject } from './subject';
import { RegistrationFinalStepInitialize } from './initialize';
import { ICategoryItem } from '../../../../model/ryve/category';
import { BottomBar } from '../../../../components/bottom-bar';
import { HeadBar } from '../../../../components/head-bar';
import { MobileHeadBar } from '../../../../components/mobile-head-bar';
import { NavBarLogo } from '../../../../components/navbar-logo';
import { SplashSideBar } from '../../../../components/splash-sidebar';
import { RegistrationLanguage } from './language';

enum FinalizeRegistrationSteps{
  INITIAL = 1,
  SCHOOL = INITIAL << 2,
  LANGUAGES = SCHOOL << 2,
  SUBJECTS = LANGUAGES << 2,
  IBAN = SUBJECTS << 2,
  PASSWORD = IBAN << 2,
  FINISHED = PASSWORD << 2
}


export interface IBaseRegistrationFinalStepProps {
  onDataValidation: (valid: boolean, data?: Partial<IRegistrationFinalizeRequestData>) => void;
}

export const FinalizeRegistrationView: React.FC = () => {
  let defaultSteps = FinalizeRegistrationSteps.INITIAL + 
  FinalizeRegistrationSteps.SCHOOL + 
  FinalizeRegistrationSteps.LANGUAGES +
  FinalizeRegistrationSteps.SUBJECTS + 
  FinalizeRegistrationSteps.PASSWORD + 
  FinalizeRegistrationSteps.FINISHED;
  
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  
  const [numSteps, setNumSteps] = useState(defaultSteps);
  const [currentStep, setCurrentStep] = useState(FinalizeRegistrationSteps.INITIAL);
  const [lastStep, setLastStep] = useState(FinalizeRegistrationSteps.PASSWORD);
  const [subjects, setSubjects] = useState<ICategoryItem[]>([]);
  const [languages, setLanguages] = useState<ICategoryItem[]>([]);
  const [type, setType] = useState<TProfileType>("tutor");
  const [valid, setValid] = useState(false);
  const [token, setToken] = useState('');
  const [personInfo, setPersonInfo] = useState<{firstName: string, lastName: string} | null>(null);
  const mobile = useIsMobile();
  const {registrationFinalizeRequestData} = useSelector(state => state.registrationFinalRequest);

  useEffect(() => {
    if(type == 'tutor'){
      if((numSteps & FinalizeRegistrationSteps.IBAN) !== FinalizeRegistrationSteps.IBAN)
        setNumSteps(numSteps + FinalizeRegistrationSteps.IBAN);
    }else{
      if((numSteps & FinalizeRegistrationSteps.IBAN) === FinalizeRegistrationSteps.IBAN)
        setNumSteps(numSteps - FinalizeRegistrationSteps.IBAN);
    }
  }, [type]);

  if(!token){
    dispatch(setRegistrationFinalizeRequestData({token: new URLSearchParams(location.search).get('token') || ''}));
    setToken(new URLSearchParams(location.search).get('token') || '');
  }

  // const [registrationFinalizeRequestData, setRegistrationFinalizeRequestData] = useState<IRegistrationFinalizeRequestData>({
  //   token: new URLSearchParams(location.search).get('token') || '',
  //   password: '',
  //   school: '',
  //   schoolGrade: '',
  //   hobbies: '',
  //   subjects: [],
  //   subjectsHigh: [],
  //   subjectsLow: [],
  //   subjectsMedium: []
  // });

  useEffect(() => {
    if (!registrationFinalizeRequestData.token || !type) {
      history.replace('/');
    }
  }, []);

  const onDataValidation = useCallback((valid: boolean, data?: Partial<IRegistrationFinalizeRequestData>) => {
    setValid(valid);
    if(data){
      dispatch(setRegistrationFinalizeRequestData(data));
    }
  }, []);

  function setSubjectsData(subjects: ICategoryItem[]) {
    dispatch(setRegistrationFinalizeRequestData({subjects}));
  }
  function setSubjectsLow(subjectsLow: ICategoryItem[]) {
    dispatch(setRegistrationFinalizeRequestData({subjectsLow}));
  }
  function setSubjectsMedium(subjectsMedium: ICategoryItem[]) {
    dispatch(setRegistrationFinalizeRequestData({subjectsMedium}));
  }
  function setSubjectsHigh(subjectsHigh: ICategoryItem[]) {
    dispatch(setRegistrationFinalizeRequestData({subjectsHigh}));
  }

  function setLanguagesData(languages: ICategoryItem[]) {
    dispatch(setRegistrationFinalizeRequestData({languages}));
  }

  function prevStep() {
    if (currentStep > FinalizeRegistrationSteps.INITIAL) {
      let prev = currentStep >> 2;
      if(prev & numSteps) setCurrentStep(currentStep >> 2);
      else setCurrentStep(prev >> 2);
    } else {
      history.replace('/');
    }
  }
  function nextStep() {
    if (currentStep < lastStep) {
      let next = currentStep << 2;
      if(next & numSteps) setCurrentStep(currentStep << 2);
      else setCurrentStep(next << 2);
    } else if (currentStep === lastStep) {
      dispatch(finalizeRegistrationRequest(registrationFinalizeRequestData));
      setCurrentStep(FinalizeRegistrationSteps.FINISHED);
    }
  }

  return (
    <>
    <Hidden mdDown>
        <Sidebar>
          <SplashSideBar src="/images/register.svg" alt="Anmeldung">
            <Bx display="flex" width="100%" alignItems="center" justifyContent="space-between">
              <Bx display="flex" justifyContent={'flex-start'} alignItems="center" height="100%">
                <NavBarLogo to="/" src="/images/logo.svg" alt="Schüler Connect Online" />
              </Bx>
            </Bx>
          </SplashSideBar>
        </Sidebar>
      </Hidden>
      <Wizard step={currentStep} stepCount={numSteps}>
        {currentStep < FinalizeRegistrationSteps.FINISHED && (
          <Header>
            {mobile && (
              <MobileHeadBar>
                <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" py={1}>
                  <Grid item xs={3}>
                    <Bx display="flex" pr={1} alignItems="center" justifyContent="flex-start" height="100%">
                      <IconBtn size="medium" onClick={prevStep}>
                        <FontAwesomeIcon icon={faLongArrowLeft} />
                      </IconBtn>
                    </Bx>
                  </Grid>
                  <Grid item xs={6}>
                    <Bx display="flex" alignItems="center" justifyContent="center" height="100%">
                      <NavBarLogo to="/" src="/images/logo_dark.svg" />
                    </Bx>
                  </Grid>
                </Bx>
              </MobileHeadBar>
            )}
            
            {!mobile && 
            <HeadBar transparent={false} backButtonLink={prevStep}>
              {personInfo && 
                <Bx display="flex" style={{marginLeft: "12%", paddingTop: "10px"}}>
                  <Typography variant="h2">{`Herzlich Willkommen, ${personInfo.firstName} ${personInfo.lastName}`}</Typography>
                </Bx>}
            </HeadBar>}
          </Header>
        )}
        <Content>
          <WizardContent>
            {(callbacks, options) => {
              return (
                <React.Fragment key={`registration_${options?.currentStep}`}>
                  {((FinalizeRegistrationSteps.INITIAL & numSteps) === FinalizeRegistrationSteps.INITIAL) && 
                  (options.currentStep === FinalizeRegistrationSteps.INITIAL) && 
                  <RegistrationFinalStepInitialize
                    successCallback = {(result) => {
                    setSubjects(result.subjectCategories); 
                    setLanguages(result.languageCategories);
                    setType(result.type);
                    setPersonInfo({firstName: result.firstName, lastName: result.lastName});
                    setCurrentStep(FinalizeRegistrationSteps.SCHOOL); }}
                  />}
                  {((FinalizeRegistrationSteps.SCHOOL & numSteps) === FinalizeRegistrationSteps.SCHOOL) && 
                  (options.currentStep === FinalizeRegistrationSteps.SCHOOL) && 
                  <RegistrationSchool 
                    type={type as TProfileType}
                    onDataValidation={onDataValidation}
                   />}
                   {((FinalizeRegistrationSteps.LANGUAGES & numSteps) === FinalizeRegistrationSteps.LANGUAGES) &&
                   (options.currentStep === FinalizeRegistrationSteps.LANGUAGES) &&
                   <RegistrationLanguage
                   languageOptions={languages}
                   languages={[...registrationFinalizeRequestData.languages]}
                   setLanguages={setLanguagesData}
                   onValidated={setValid} />}
                   {((FinalizeRegistrationSteps.SUBJECTS & numSteps) === FinalizeRegistrationSteps.SUBJECTS) && 
                   (options.currentStep === FinalizeRegistrationSteps.SUBJECTS) && 
                  <RegistrationSubject
                    subjectOptions={subjects}
                    subjects={[...registrationFinalizeRequestData.subjects]}
                    setSubjects={setSubjectsData}
                    subjectsLow={[...registrationFinalizeRequestData.subjectsLow]}
                    setSubjetcsLow={setSubjectsLow}
                    subjectsMedium={[...registrationFinalizeRequestData.subjectsMedium]}
                    setSubjetcsMedium={setSubjectsMedium}
                    subjectsHigh={[...registrationFinalizeRequestData.subjectsHigh]}
                    setSubjetcsHigh={setSubjectsHigh}
                    type={type}
                    onValidated={setValid}
                   />}
                   {((numSteps & FinalizeRegistrationSteps.IBAN) === FinalizeRegistrationSteps.IBAN) && 
                   options.currentStep === FinalizeRegistrationSteps.IBAN && 
                  <RegistrationIBAN 
                    onDataValidation={onDataValidation}
                   />}
                  {((FinalizeRegistrationSteps.PASSWORD & numSteps) === FinalizeRegistrationSteps.PASSWORD) && 
                  (options.currentStep === FinalizeRegistrationSteps.PASSWORD) && 
                  <RegistrationPassword onDataValidation={onDataValidation} />}

                  {((FinalizeRegistrationSteps.FINISHED & numSteps) === FinalizeRegistrationSteps.FINISHED) && 
                  (options.currentStep === FinalizeRegistrationSteps.FINISHED) && 
                  <RegistrationFinalizeFinished />}
                </React.Fragment>
              );
            }}
          </WizardContent>
        </Content>
        <Footer>
          <WizardNavigation>
            {(callbacks, options) => {
              const isLastStep = options.currentStep === lastStep;
              // ausgeblendet im ladescreen
              if (options.currentStep === FinalizeRegistrationSteps.FINISHED) return <></>;

              return (
                <Bx gridArea="footer" zIndex={2} position="sticky" bottom="0">
                  <BottomBar scrolled bottom={'unset'}>
                    <Bx flexGrow={1} flexShrink={1} flexBasis="auto">
                      &nbsp;
                    </Bx>
                    <Bx alignSelf="flex-end" textAlign="right">
                      <Btn
                        size="large"
                        color="success"
                        disabled={!valid || !registrationFinalizeRequestData.token}
                        variant="contained"
                        onClick={e => {
                          e.preventDefault();
                          nextStep();
                        }}
                      >
                        <Typo variant="inherit" noWrap>
                          {isLastStep ? 'Abschließen' : 'Ok, weiter'}
                        </Typo>
                      </Btn>
                    </Bx>
                  </BottomBar>
                </Bx>
              );
            }}
          </WizardNavigation>
        </Footer>
      </Wizard>
    </>
  );
};