import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteFilesModal } from '../../../../../components/communication/file-manager/delete-modal';
import { IAsset } from '../../../../../model/ryve/asset';
import * as _ from 'lodash';
import { deleteFilesRequest, setDeleteFiles, setDeleteFilesActive } from '../../../../../data/reducer/communication';

export const ConnectedDeleteFilesModal: React.FC = () => {
  const dispatch = useDispatch();

  const communicationId = useSelector(state => state.communication?.id);
  const open = useSelector(state => state.communication?.deleteFilesActive);
  const deleting = useSelector(state => state.communication?.deleteFilesWorking);
  const response = useSelector(state => state.communication?.deleteFilesResponse);
  const files = useSelector(state => state.communication?.deleteFiles);

  const setFiles = (files: IAsset[]) => {
    dispatch(setDeleteFiles({ files }));
  };

  const close = () => dispatch(setDeleteFilesActive(false));
  const removeFile = (fileToRemove: IAsset) => {
    const newFiles = _.reject(files, file => file === fileToRemove);
    setFiles(newFiles);
  };

  const deleteFiles = () => {
    communicationId && files && dispatch(deleteFilesRequest({ communicationId, files }));
  };

  return (
    <DeleteFilesModal response={response} onSubmit={deleteFiles} open={open || false} close={close} files={files || []} removeFile={removeFile} deleting={deleting || false} />
  );
};
