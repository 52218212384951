import React from 'react';
import { useLocation } from 'react-router';
import { Bx } from '@curry-group/mui-curcuma';
import { INavbarFooterProps } from '..';
import { NavBarMetaLink } from '../../navbar-meta-link';
import { NavBarUser } from '../../navbar-user';

export const NavbarFooter: React.FC<INavbarFooterProps> = ({ userId, userFullName, userAvatarSrc }) => {
  const location = useLocation();
  return (
    <>
      <NavBarUser
        mt={4}
        mb={4}
        fullName={userFullName ?? ''}
        avatar={{ src: userAvatarSrc, alt: 'Avatar von ' + userFullName }}
        links={[
          { name: 'Zum Profil', to: '/profile?ref=' + location.pathname },
          { name: 'Ausloggen', to: '/logout' }
        ]}
      />
      <Bx display="flex" flexWrap="wrap">
        <NavBarMetaLink to={'/impressum?ref=' + location.pathname}>Impressum</NavBarMetaLink>
        <NavBarMetaLink to={'/datenschutz?ref=' + location.pathname}>Datenschutz</NavBarMetaLink>
        <NavBarMetaLink to={'/gemeinschaftsstandards?ref=' + location.pathname}>Gemeinschaftsstandards</NavBarMetaLink>
        <NavBarMetaLink to={'/nutzungsbedingungen?ref=' + location.pathname}>Nutzungsbedingungen</NavBarMetaLink>
      </Bx>
    </>
  );
};
