import { Bx, Hamburger } from '@curry-group/mui-curcuma';
import { INavbarHeaderProps } from '..';
import { NavBarIconBtn } from '../../navbar-icon-btn';
import { NavBarLogo } from '../../navbar-logo';

export const NavbarHeader: React.FC<INavbarHeaderProps & { showHamburger: boolean; hamburgerClicked: () => void }> = ({ showHamburger, hamburgerClicked, open }) => {
  return (
    <Bx display="flex" width="100%" alignItems="center" justifyContent="space-between">
      {showHamburger && (
        <NavBarIconBtn to="#" onClick={hamburgerClicked}>
          <Hamburger toggled={open} />
        </NavBarIconBtn>
      )}
      <Bx display="flex" justifyContent={showHamburger ? 'flex-end' : 'flex-start'} alignItems="center" height="100%">
        <NavBarLogo to="/" src="/images/logo.svg" />
      </Bx>
    </Bx>
  );
};
