import { Bx, IBxProps, ICtnProps, useBreakpoints } from '@curry-group/mui-curcuma';

import { GridProps } from '@material-ui/core/Grid';
import React from 'react';
import { Header } from '../../../../components/frame';
import { LandingPageTabSection } from '../../../../components/landingpage/tab-section';
import { ConnectedHeader } from '../../header';
export const InternalLandingPageView: React.FC = () => {
  // Responsive Design
  const responsive = useBreakpoints();
  const isMDUp = responsive.mdUp || false;

  // General Landingpage configuration
  const ctnMaxWidth: ICtnProps['maxWidth'] = 'lg';
  const sectionPx: IBxProps['px'] = { xs: 4, md: 4 };
  const innerSectionSpacing: GridProps['spacing'] = isMDUp ? 10 : 5;

  return (
    <>
      <Header>
        <ConnectedHeader />
      </Header>
      <Bx style={{ gridArea: 'content' }}>
        <Bx mt={20}>
          <LandingPageTabSection ctnMaxWidth={ctnMaxWidth} innerSectionSpacing={innerSectionSpacing} responsive={{ isMDUp }} sectionPx={sectionPx} body4FontSize={20} />
        </Bx>
      </Bx>
    </>
  );
};
