import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { useLocation } from 'react-router';
import { Ava, Bx, Hamburger } from '@curry-group/mui-curcuma';

import { iconMapping, INavbarBaseProps, navItemActive } from '..';
import { getNavigationPath, INavigationConfig, INavigationContainerConfig } from '../../../model/configuration/navigation';
import { IDictionary } from '../../../model/common/dictionary';
import { NavBarIconBtn } from '../../navbar-icon-btn';
import { NavBar } from '../NavBar';
import { ThingTypes } from '../../../model/common/thingTypes';
// import { useSelector } from 'react-redux';

export const NavbarMobile: React.FC<INavbarBaseProps> = props => {
  // const location = useLocation();
  return (
    <NavBar py={2} small>
      <Bx component="nav">
        <NavBarIconBtn to="#" aria-label={props.open ? 'Menü schließen' : 'Menü öffnen'} onClick={() => props.setOpen(!props.open)}>
          <Hamburger toggled={props.open} label={props.open ? 'Menü schließen' : 'Menü öffnen'} />
        </NavBarIconBtn>

        {props.sections.map((section, sectionIndex) => {
          return (
            <Bx mt={4} display="flex" flexDirection="column" alignItems="center" key={sectionIndex}>
              {section.configs
                .filter(item => item.visible)
                .map((item, itemIndex) => (
                  <NavbarContentBtnMobile key={itemIndex} badges={props.badges} section={section} config={item} />
                ))}
            </Bx>
          );
        })}
      </Bx>

      <Bx mt="auto">
        <Bx mt={4}>
          <Ava size="small" src={props.userAvatarSrc} alt={'Avatar von ' + props.userFullName} />
        </Bx>
      </Bx>
    </NavBar>
  );
};

export const NavbarContentBtnMobile: React.FC<{ section: INavigationConfig; config: INavigationContainerConfig; badges: IDictionary<number> }> = ({
  section,
  config,
  badges
}) => {
  const location = useLocation();
  let badgeCount = 0;
  if (config.alias === 'notifications') {
    badgeCount = badges['notifications'] || 0;
  } else {
    for (let type of [ThingTypes.ADMINCHAT, ThingTypes.TANDEM]) {
      if (badges[type]) {
        badgeCount += badges[type];
      }
    }
  }
  const active = navItemActive(location, getNavigationPath(section, config));
  return (
    <NavBarIconBtn
      to={getNavigationPath(section, config) || '/'}
      key={config.alias}
      active={active}
      aria-label={config.name}
      color="inherit"
      badgeContent={badgeCount ? badgeCount : undefined}
      badgeColor={active ? 'tertiary' : 'secondary'}
    >
      <FontAwesomeIcon icon={iconMapping[config.alias] || faQuestionCircle} />
    </NavBarIconBtn>
  );
};
