import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useIsMobile } from '../../../helper/hooks/useIsMobile';
import { CommunicationType } from '../../../model/communication/Communication';
import { ParticipationRequestStatus } from '../../../model/communication/Participant';
import { CommunicationHeader, CommunicationTab } from '../../../components/communication/header';
import { TandemsSidebar } from './sidebar';
import { createConferenceMessageRequest, setActionCanvas, setMessageDraftThreadRoot } from '../../../data/reducer/communication';
import { Content, Footer, Header } from '../../../components/frame';
import { ConnectedFileManager } from '../communication/file-manager';
import { ConnectedCommunicationActionCanvas } from '../communication/action-canvas';
import { ConnectedCommunicationInput } from '../communication/input';
import { ConnectedCommunication } from '../communication';
import { fetchTandemRequest } from '../../../data/reducer/detail';
import { CreateTandemView } from './create';
import { useBreakpoints } from '@curry-group/mui-curcuma';

const tabs: CommunicationTab[] = [
  {
    title: 'Chat',
    alias: 'main',
    type: 'messages',
    request: undefined
  },
  {
    title: 'Threads',
    alias: 'threads',
    type: 'messages',
    request: {
      numThreadChildren: 1
    }
  },
  {
    title: 'Dateien',
    alias: 'files',
    type: 'files'
  }
];

export const TandemsView: React.FC = () => {
  const [createTandemActive, setCreateTandemActive] = useState(false);
  const route = useRouteMatch<{ alias?: string }>();
  const { alias } = route.params;

  const breakpoints = useBreakpoints();

  if (createTandemActive) {
    return (
      <CreateTandemView
        onDone={() => {
          setCreateTandemActive(false);
        }}
      />
    );
  }

  if (breakpoints.mdDown) {
    return (
      <>
        {!alias && <TandemsSidebar onCreateTandem={() => setCreateTandemActive(true)} />}
        {alias && <TandemDetail alias={alias} />}
      </>
    );
  }
  return (
    <>
      <TandemsSidebar onCreateTandem={() => setCreateTandemActive(true)} />
      {alias && <TandemDetail alias={alias} />}
    </>
  );
};

export const TandemDetail: React.FC<{ alias: string }> = ({ alias }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    alias && dispatch(fetchTandemRequest(alias));
  }, [dispatch, alias]);

  const isMobile = useIsMobile();

  const participation = useSelector(state => state.communication?.participation);
  const participationWorking = useSelector(state => state.communication?.participationWorking);

  const inlineThreading = useSelector(state => state.communication?.communication?.inlineThreading);
  const allowInvites = useSelector(state => state.communication?.communication?.allowInvites && state.communication?.participation?.permissions?.allowInvites);

  const allowConferencing = useSelector(state => {
    const communication = state.communication?.communication;
    if (!communication) return false;

    // no allowed
    if (!communication.allowConferencing) return false;

    // no participation
    const participation = state.communication?.participation;
    if (!participation) return false;

    // group - OK
    if (communication.type === CommunicationType.GROUP) return participation.permissions.allowConferencing;

    // single - own pending
    if (participation.status === ParticipationRequestStatus.PENDING) return false;

    // find other participation
    var userId = state.app.profile?.userId;
    if (!userId) return false;
    var otherParticipation = communication.participants.find(p => p.user !== userId);
    if (!otherParticipation) return false;
    if (otherParticipation.status === ParticipationRequestStatus.PENDING) return false;

    return participation.permissions.allowConferencing;
  });

  const communicationType = useSelector(state => state.communication?.communication?.type);

  const working = useSelector(state => state.detail.working);
  const detailId = useSelector(state => state.detail.item?._id);

  const title = useSelector(state => state.detail.item?.name);

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [view, setView] = useState('chat');

  function _setView(view: string) {
    dispatch(setActionCanvas({ open: false }));
    setView(view);
  }

  if (!detailId) return <></>;

  return (
    <>
      <Header>
        <CommunicationHeader
          openSettings={communicationType === CommunicationType.GROUP ? () => _setView('settings') : undefined}
          working={working}
          title={title}
          tabs={tabs.filter(tab => {
            if (tab.alias === 'threads') {
              return false;
            }
            return true;
          })}
          allowConferencing={allowConferencing}
          onConferenceClick={() => {
            dispatch(createConferenceMessageRequest({ id: 'conference', communicationId: detailId }));
          }}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </Header>

      <CommunicationContent selectedTab={selectedTab} detailId={detailId} />

      <Footer>
        {detailId && selectedTab.alias === 'main' && (
          <ConnectedCommunicationInput
            displayThreadRoot={inlineThreading}
            removeThreadRoot={() => {
              dispatch(setMessageDraftThreadRoot({ id: selectedTab.alias, threadRoot: undefined }));
            }}
            small={false}
            inputId={selectedTab.alias}
          />
        )}
        {detailId && selectedTab.alias === 'threads' && inlineThreading && (
          <ConnectedCommunicationInput
            disabledWithoutThreadroot={true}
            displayThreadRoot={true}
            removeThreadRoot={() => {
              dispatch(setMessageDraftThreadRoot({ id: selectedTab.alias, threadRoot: undefined }));
            }}
            small={false}
            inputId={selectedTab.alias}
          />
        )}
      </Footer>
    </>
  );
};

export interface ICommunicationContentProps {
  selectedTab: CommunicationTab;
  detailId: string;
}
export const CommunicationContent: React.FC<ICommunicationContentProps> = ({ selectedTab, detailId }) => {
  const inlineThreading = useSelector(state => state.communication?.communication?.inlineThreading);
  return (
    <Content>
      {detailId && selectedTab.alias === 'main' && (
        <ConnectedCommunication id={detailId} alias={selectedTab.alias} request={selectedTab.request} inlineThreading={inlineThreading} />
      )}
      {detailId && selectedTab.alias === 'threads' && (
        <ConnectedCommunication id={detailId} alias={selectedTab.alias} request={selectedTab.request} inlineThreading={inlineThreading} />
      )}
      {detailId && selectedTab.type === 'files' && <ConnectedFileManager />}
      {detailId && <ConnectedCommunicationActionCanvas threadOnly headline="Thread" />}
    </Content>
  );
};
