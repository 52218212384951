import { ReactNode, useEffect, useState } from 'react';
import { faDownload, faSlidersV, faTimes, faTrash, faUpload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { Bx, Ctn, DropDownMenu, IconBtn, Indicator } from '@curry-group/mui-curcuma';
import { FileManagerBtn } from '../../../file-manager-btn';
export interface IFileManagerButtonBarProps {
  allowUpload?: boolean;
  uploadButtonClicked: () => void;

  allowDownload?: boolean;
  downloadButtonClicked: () => void;

  deleteButtonClicked: () => void;

  numSelected: number;
  resetSelection: () => void;

  hasFilter?: boolean;
  filters?: string[];
  renderFilterMenu?: () => ReactNode;
}

export const FileManagerButtonBar: React.FC<IFileManagerButtonBarProps> = ({
  numSelected,
  resetSelection,
  allowUpload,
  uploadButtonClicked,
  allowDownload,
  downloadButtonClicked,
  deleteButtonClicked,
  hasFilter,
  filters,
  renderFilterMenu
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [headBarHeight, setHeadBarHeight] = useState<number>();
  const [filterOpen, setFilterOpen] = useState<string | null>(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement | HTMLButtonElement>(null);

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterOpen(event.currentTarget.getAttribute('aria-controls'));
    setFilterAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    function getAndSetHeadBarHeight() {
      let headBarEl = document.querySelector('#content-head-container');
      if (headBarEl) {
        let mt = parseInt(window.getComputedStyle(headBarEl).getPropertyValue('margin-top'));
        let mb = parseInt(window.getComputedStyle(headBarEl).getPropertyValue('margin-bottom'));
        let bt = parseInt(window.getComputedStyle(headBarEl).getPropertyValue('border-top-width'));
        let bb = parseInt(window.getComputedStyle(headBarEl).getPropertyValue('border-bottom-width'));
        let computedStyles = mt + mb + bt + bb;

        headBarHeight !== headBarEl.clientHeight + computedStyles && setHeadBarHeight(headBarEl.clientHeight + computedStyles);
      }
    }

    getAndSetHeadBarHeight();

    window.addEventListener('resize', getAndSetHeadBarHeight);

    return () => {
      window.removeEventListener('resize', getAndSetHeadBarHeight);
    };
  });

  return (
    <>
      <Bx
        position="sticky"
        top={headBarHeight}
        zIndex={2}
        px={isMobile ? 0.5 : 5}
        py={0.5}
        bgcolor="background.fileManagerHeadBar"
        border={1}
        borderColor="divider"
        borderLeft={0}
        borderRight={0}
        borderTop={0}
      >
        <ButtonBarInner
          mobile={isMobile}
          numSelected={numSelected}
          allowUpload={allowUpload}
          allowDownload={allowDownload}
          uploadButtonClicked={uploadButtonClicked}
          downloadButtonClicked={downloadButtonClicked}
          deleteButtonClicked={deleteButtonClicked}
          resetSelection={resetSelection}
          hasFilter={hasFilter && !!renderFilterMenu}
          filters={filters}
          handleFilterClick={handleFilterClick}
        />
      </Bx>
      {!!renderFilterMenu && (
        <DropDownMenu
          id={filterOpen || 'dropdown'}
          elevation={3}
          getContentAnchorEl={null}
          anchorReference="anchorEl"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          anchorEl={filterAnchorEl}
          onClose={() => setFilterOpen(null)}
          MenuListProps={{ component: 'div' }}
          open={filterOpen !== null}
          borderRadius="borderRadius"
          keepMounted
        >
          <Bx bgcolor="background.paper" color="text.primary">
            {renderFilterMenu()}
          </Bx>
        </DropDownMenu>
      )}
    </>
  );
};

export interface IFileManagerButtonBarInnerProps {
  numSelected?: number;
  allowUpload?: boolean;
  uploadButtonClicked: () => void;
  allowDownload?: boolean;
  downloadButtonClicked: () => void;
  deleteButtonClicked: () => void;
  resetSelection: () => void;
  hasFilter?: boolean;
  filters?: string[];
  handleFilterClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export const ButtonBarInner: React.FC<IFileManagerButtonBarInnerProps & { mobile?: boolean }> = ({
  numSelected,
  allowUpload,
  uploadButtonClicked,
  allowDownload,
  downloadButtonClicked,
  deleteButtonClicked,
  resetSelection,
  hasFilter,
  filters,
  handleFilterClick,
  mobile
}) => {
  const ContainerComponent = mobile ? React.Fragment : Ctn;
  const ButtonBarComponent = mobile ? ButtonBarInnerMobile : ButtonBarInnerDesktop;

  return (
    <ContainerComponent>
      <ButtonBarComponent
        numSelected={numSelected}
        allowUpload={allowUpload}
        allowDownload={allowDownload}
        uploadButtonClicked={uploadButtonClicked}
        downloadButtonClicked={downloadButtonClicked}
        deleteButtonClicked={deleteButtonClicked}
        resetSelection={resetSelection}
        hasFilter={hasFilter}
        filters={filters}
        handleFilterClick={handleFilterClick}
      />
    </ContainerComponent>
  );
};

export const ButtonBarInnerMobile: React.FC<IFileManagerButtonBarInnerProps> = ({
  numSelected,
  allowUpload,
  uploadButtonClicked,
  allowDownload,
  downloadButtonClicked,
  deleteButtonClicked,
  resetSelection,
  hasFilter,
  filters,
  handleFilterClick
}) => {
  return (
    <Bx display="flex" alignItems="center">
      {!numSelected && (
        <Bx mr={2}>
          <IconBtn disabled={!allowUpload} onClick={uploadButtonClicked} size="small">
            <FontAwesomeIcon icon={faUpload} />
          </IconBtn>
        </Bx>
      )}

      {numSelected && (
        <>
          <Bx mr={2}>
            <IconBtn disabled={!allowDownload} onClick={downloadButtonClicked} size="small">
              <FontAwesomeIcon icon={faDownload} />
            </IconBtn>
          </Bx>
          <Bx mr={2}>
            <IconBtn onClick={deleteButtonClicked} size="small">
              <FontAwesomeIcon icon={faTrash} />
            </IconBtn>
          </Bx>

          <Bx ml="auto" mr={2}>
            <Indicator onClick={resetSelection} color="primary" badgeContent={numSelected} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }} overlap="circle">
              <IconBtn size="small" variant="outlined">
                <FontAwesomeIcon icon={faTimes} />
              </IconBtn>
            </Indicator>
          </Bx>
        </>
      )}

      {hasFilter && !!filters?.length && (
        <Bx ml={!numSelected ? 'auto' : undefined}>
          <Indicator badgeContent={filters.length} variant="standard" color="primary" overlap="circle" anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
            <IconBtn size="small" variant={filters.length > 0 ? 'outlined' : 'text'} onClick={handleFilterClick} aria-controls="filters" aria-haspopup="true">
              <FontAwesomeIcon icon={faSlidersV} />
            </IconBtn>
          </Indicator>
        </Bx>
      )}
    </Bx>
  );
};

export const ButtonBarInnerDesktop: React.FC<IFileManagerButtonBarInnerProps> = ({
  numSelected,
  allowUpload,
  uploadButtonClicked,
  allowDownload,
  downloadButtonClicked,
  deleteButtonClicked,
  resetSelection,
  hasFilter,
  filters,
  handleFilterClick
}) => {
  return (
    <Bx display="flex" alignItems="center">
      {!numSelected && (
        <Bx mr={2}>
          <FileManagerBtn disabled={!allowUpload} onClick={uploadButtonClicked} startIcon={<FontAwesomeIcon icon={faUpload} />}>
            Hochladen
          </FileManagerBtn>
        </Bx>
      )}

      {numSelected && (
        <>
          <Bx mr={2}>
            <FileManagerBtn disabled={!allowDownload} onClick={downloadButtonClicked} startIcon={<FontAwesomeIcon icon={faDownload} />}>
              Herunterladen
            </FileManagerBtn>
          </Bx>
          <Bx mr={2}>
            <FileManagerBtn onClick={deleteButtonClicked} startIcon={<FontAwesomeIcon icon={faTrash} />}>
              Löschen
            </FileManagerBtn>
          </Bx>

          <Bx ml="auto" mr={2}>
            <FileManagerBtn onClick={resetSelection} startIcon={<FontAwesomeIcon icon={faTimes} />}>
              {numSelected} ausgewählt
            </FileManagerBtn>
          </Bx>
        </>
      )}

      {hasFilter && !!filters?.length && (
        <Bx ml={!numSelected ? 'auto' : undefined}>
          <Indicator badgeContent={filters.length} variant="standard" color="primary" overlap="rectangle">
            <FileManagerBtn
              disabled
              active={filters.length > 0}
              onClick={handleFilterClick}
              aria-controls="filters"
              aria-haspopup="true"
              startIcon={<FontAwesomeIcon icon={faSlidersV} />}
            >
              Filtern
            </FileManagerBtn>
          </Indicator>
        </Bx>
      )}
    </Bx>
  );
};
