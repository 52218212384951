import { useLocation, useHistory } from 'react-router-dom';
import { Btn, Bx, IconBtn, Input, InputEndAdornment, InputInfoText, InputValidationIcon, Typo } from '@curry-group/mui-curcuma';
import { Wizard, WizardContent, WizardNavigation } from '@curry-group/react-wizard';
import { faEye, faEyeSlash, faLongArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { Header, Content, Footer } from '../../../../components/frame';
import { useIsMobile } from '../../../../helper/hooks/useIsMobile';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordRequest } from '../../../../data/reducer/app';
import { validatePassword } from '../../../../helper';
import { FinishFlow } from '../../../../components/flow/finish';
import { IResetPasswordRequestData } from '../../../../model/data/password';
import { MobileHeadBar } from '../../../../components/mobile-head-bar';
import { NavBarLogo } from '../../../../components/navbar-logo';
import { HeadBar } from '../../../../components/head-bar';
import { BottomBar } from '../../../../components/bottom-bar';
import { FramedContent } from '../../../../components/framed-content';

export const ResetPasswordView: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { resetPasswordSuccess, resetPasswordFailed } = useSelector(state => state.app);

  const [resetPasswordDone, setResetPasswordDone] = useState(false);
  const numSteps = 1;
  const [currentStep, setCurrentStep] = useState(0);
  const mobile = useIsMobile();
  const [showPassword, setShowPassword] = useState(false);
  const [passwordRepeat, setPasswordRepeat] = useState('');

  const [valid, setValid] = useState(false);

  const [resetPasswordRequestData, setResetPasswordRequestData] = useState<IResetPasswordRequestData>({
    token: new URLSearchParams(location.search).get('token') || '',
    password: ''
  });

  function validate(pw1: string, pw2: string) {
    setValid(validatePassword(pw1) && validatePassword(pw2) && pw1 === pw2);
  }
  function _setPasswordRepeat(_password: string) {
    setPasswordRepeat(_password);
    validate(resetPasswordRequestData.password, _password);
  }

  function setPassword(_password: string) {
    setResetPasswordRequestData({ ...resetPasswordRequestData, password: _password });
    validate(_password, passwordRepeat);
  }

  function prevStep() {
    history.replace('/');
  }

  function send() {
    dispatch(resetPasswordRequest(resetPasswordRequestData));
    setResetPasswordDone(true);
    setCurrentStep(1);
  }

  return (
    <Wizard step={currentStep} stepCount={numSteps}>
      {currentStep === 0 && (
        <Header>
          {mobile && (
            <MobileHeadBar>
              <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" py={1}>
                <Grid item xs={3}>
                  <Bx display="flex" pr={1} alignItems="center" justifyContent="flex-start" height="100%">
                    <IconBtn size="medium" onClick={prevStep}>
                      <FontAwesomeIcon icon={faLongArrowLeft} />
                    </IconBtn>
                  </Bx>
                </Grid>
                <Grid item xs={6}>
                  <Bx display="flex" alignItems="center" justifyContent="center" height="100%">
                    <NavBarLogo to="/" src="/images/logo_dark.svg" />
                  </Bx>
                </Grid>
              </Bx>
            </MobileHeadBar>
          )}

          {!mobile && <HeadBar transparent={false} backButtonLink={prevStep}></HeadBar>}
        </Header>
      )}
      <Content>
        <WizardContent>
          {(callbacks, options) => {
            return (
              <React.Fragment key={`registration_${options?.currentStep}`}>
                {options.currentStep === 0 && (
                  <FramedContent>
                    <Bx mb={1}>
                      <Typo variant="h1" component="h1">
                        Zurücksetzen Ihres Passworts auf Schüler Connect Online!
                      </Typo>
                    </Bx>
                    <Bx>
                      <Typo variant="subtitle1">Vergeben Sie bitte ein sicheres Passwort für Ihren Zugang.</Typo>
                    </Bx>
                    <Bx my={8}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} lg={6}>
                          <Input
                            inputLabel={{ children: 'Passwort' }}
                            input={{
                              value: resetPasswordRequestData.password,
                              placeholder: 'Passwort eingeben',
                              type: showPassword ? 'text' : 'password',
                              onChange: e => {
                                setPassword(e.target.value);
                              },
                              name: 'password1',
                              endAdornment: (
                                <InputEndAdornment>
                                  <IconBtn size="small" onClick={() => setShowPassword(!showPassword)}>
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                  </IconBtn>
                                  {validatePassword(resetPasswordRequestData.password) && <InputValidationIcon type="success" />}
                                </InputEndAdornment>
                              )
                            }}
                          />
                          {resetPasswordRequestData.password.length > 0 && !validatePassword(resetPasswordRequestData.password) && (
                            <Bx mt={1}>
                              <InputInfoText type="error">
                                Das Passwort muss Groß- und Kleinbuchstaben, eine Zahl und ein Sonderzeichen beinhalten und mindestens 8 Zeichen lang sein.
                              </InputInfoText>
                            </Bx>
                          )}
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Input
                            inputLabel={{ children: 'Passwort wiederholen' }}
                            input={{
                              value: passwordRepeat,
                              placeholder: 'Passwort eingeben',
                              type: showPassword ? 'text' : 'password',
                              onChange: e => {
                                _setPasswordRepeat(e.target.value);
                              },
                              name: 'password2',
                              endAdornment: (
                                <InputEndAdornment>
                                  <IconBtn size="small" onClick={() => setShowPassword(!showPassword)}>
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                  </IconBtn>
                                  {validatePassword(passwordRepeat) && <InputValidationIcon type="success" />}
                                </InputEndAdornment>
                              )
                            }}
                          />
                          {passwordRepeat.length > 0 && !validatePassword(passwordRepeat) && (
                            <Bx mt={1}>
                              <InputInfoText type="error">Die Passwörter simmen nicht überein!</InputInfoText>
                            </Bx>
                          )}
                        </Grid>
                      </Grid>
                    </Bx>
                  </FramedContent>
                )}

                {options.currentStep === 1 && (
                  <>
                    {resetPasswordDone && (
                      <FinishFlow
                        timeout={3000}
                        onTimeout={() => {
                          setResetPasswordDone(false);
                        }}
                        headline="Ihr Passwort wird gesetzt."
                      />
                    )}

                    {resetPasswordSuccess && (
                      <FinishFlow
                        hideLoader={true}
                        timeout={5000}
                        onTimeout={() => {
                          history.replace('/login');
                        }}
                        headline="Passwort erfolgreich gesetzt."
                        text="Ihr Passwort wurde erfolgreich gesetzt. Sie werden in Kürze weitergeleitet und können sich anmelden."
                      />
                    )}

                    {resetPasswordFailed && (
                      <FinishFlow
                        hideLoader={true}
                        timeout={10000}
                        onTimeout={() => {
                          history.replace('/');
                        }}
                        headline="Fehler beim Setzten Ihres Passworts."
                        text="Beim Setzten Ihres Passworts ist ein Fehler aufgetreten. Sie werden in Kürze weitergeleitet."
                      />
                    )}
                  </>
                )}
              </React.Fragment>
            );
          }}
        </WizardContent>
      </Content>
      <Footer>
        <WizardNavigation>
          {(callbacks, options) => {
            // ausgeblendet im ladescreen
            if (options.currentStep === 1) return <></>;

            return (
              <Bx gridArea="footer" zIndex={2} position="sticky" bottom="0">
                <BottomBar scrolled bottom={'unset'}>
                  <Bx flexGrow={1} flexShrink={1} flexBasis="auto">
                    &nbsp;
                  </Bx>
                  <Bx alignSelf="flex-end" textAlign="right">
                    <Btn
                      size="large"
                      color="success"
                      disabled={!valid}
                      variant="contained"
                      onClick={e => {
                        e.preventDefault();
                        send();
                      }}
                    >
                      <Typo variant="inherit" noWrap>
                        Passwort setzen
                      </Typo>
                    </Btn>
                  </Bx>
                </BottomBar>
              </Bx>
            );
          }}
        </WizardNavigation>
      </Footer>
    </Wizard>
  );
};
