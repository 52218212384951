import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBreakpoints } from '@curry-group/mui-curcuma';
import { intersectionWith } from 'lodash';

import { toggleConferencing } from '../../../data/reducer/conferencing';

import { ConferencingOutlet, ConferencingClient, IConferencingSession } from '../../../components/conferencing';
import { ConferencingSidebar } from './sidebar';
import { IParticipantResolvedModel } from '../../../model/communication/Participant';

export const ConferencingOutletImpl: React.FC = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const conferencingState = useSelector(state => state.conferencing);

  const displayName = useSelector(state => state.app.profile?.fullName);
  const participants = useSelector(state => state.communication.participants);

  const confSession = useRef<ConferencingClient>();
  const [session, setSession] = useState<IConferencingSession>();
  const [desktopActive, setDesktopActive] = useState(false);
  const [myMedia, setMyMedia] = useState<MediaStream>();
  const [publisher, setPublisher] = useState<any[]>();
  const sinkUpdateState = useState(Date.now());
  const [speakers, setSpeakers] = useState<string[]>();
  const [currentParticipants, setCurrentParticipants] = useState<IParticipantResolvedModel[]>([]);
  const dispatch = useDispatch();
  const breakpoints = useBreakpoints();
  const isMobile = breakpoints.mdDown;

  useEffect(() => {
    if (conferencingState.message?.parent) {
      // dispatch(fetchParticipantsRequest({ id: conferencingState.message.parent }));
    }
  }, [dispatch, conferencingState.message?.parent]);

  useEffect(() => {
    const resolved = intersectionWith(participants, conferencingState.message?.conferenceInfo?.participants || [], (a, b) => a.user === b);
    setCurrentParticipants(resolved);
  }, [participants, conferencingState.message?.conferenceInfo?.participants]);

  if (!confSession.current) {
    confSession.current = new ConferencingClient();
    confSession.current.on('toggle-desktop', (active: boolean) => {
      setDesktopActive(active);
    });
    confSession.current.on('session-update', (s: IConferencingSession) => {
      setSession(s);
    });
    confSession.current.on('media-update', (ms: MediaStream) => {
      setMyMedia(ms);
    });
    confSession.current.on('sink-update', () => {
      sinkUpdateState?.[1]?.(Date.now());
    });
    confSession.current.on('publisher-update', (pubDict: any) => {
      const publisher: any[] = [];
      for (let key in pubDict) {
        publisher.push({ id: pubDict[key].publisherIdJanus, ...pubDict[key] });
      }
      setPublisher(publisher);
    });
    confSession.current.on('speakers-update', newSpeakers => {
      setSpeakers(newSpeakers);
    });
  }

  if (conferencingState.visible && conferencingState.message && conferencingState.token && !confSession.current.started) {
    confSession.current.setDisplayName(displayName);
    confSession.current.start(conferencingState.message.id, conferencingState.token);
  } else if (!conferencingState.visible && confSession.current.started) {
    confSession.current.stop();
  }

  useEffect(() => {
    if (confSession.current) {
      confSession.current.setMaxVideoParticipants(isMobile ? 1 : 8);
      confSession.current.setDominantSpeaker(true);
    }
  }, [publisher, isMobile]);

  return (
    <ConferencingOutlet
      desktopActive={desktopActive}
      myMedia={myMedia}
      open={conferencingState.visible}
      // currentParticipants={currentParticipants}
      publisher={publisher || []}
      speakers={speakers}
      session={session}
      sidebarVisible={sidebarVisible}
      setSidebarVisible={setSidebarVisible}
      onDevicesChange={(audio, audioOut, video) => {
        confSession.current?.updateDevices(audio, audioOut, video);
      }}
      sidebar={
        conferencingState.message &&
        sidebarVisible && (
          <ConferencingSidebar
            onCloseClick={() => {
              setSidebarVisible(!sidebarVisible);
            }}
          />
        )
      }
      closeClick={() => {
        dispatch(toggleConferencing(null));
        setSidebarVisible(false);
      }}
      toggleAudioClick={() => {
        confSession?.current?.toggleAudio();
      }}
      toggleDesktopClick={() => {
        confSession?.current?.toggleDesktop();
      }}
      toggleHandClick={() => {
        confSession?.current?.toggleHand();
      }}
      toggleVideoClick={() => {
        confSession?.current?.toggleVideo();
      }}
    />
  );
};
