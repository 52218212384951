import clsx from 'clsx';
import * as React from 'react';

import { faHandPaper } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Zoom from '@material-ui/core/Zoom';

import { conferenceTheme } from '../../theme';

export interface IConferenceRaisedHandProps extends React.HTMLAttributes<HTMLDivElement> {
  active?: boolean;
}

export interface IConferenceRaisedHandThemeExtension {
  conferenceRaisedHand: {
    width?: React.CSSProperties['width'];
    height?: React.CSSProperties['height'];
    fontSize?: React.CSSProperties['fontSize'];
    backgroundColor?: React.CSSProperties['backgroundColor'];
    color?: React.CSSProperties['color'];
    borderRadius?: React.CSSProperties['borderRadius'];
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    width: conferenceTheme.conferenceRaisedHand?.width,
    height: conferenceTheme.conferenceRaisedHand?.height,
    fontSize: conferenceTheme.conferenceRaisedHand?.fontSize,
    backgroundColor: conferenceTheme.conferenceRaisedHand?.backgroundColor,
    color: conferenceTheme.conferenceRaisedHand?.color,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: conferenceTheme.conferenceRaisedHand?.borderRadius,
    transition: theme.transitions.create('opacity', { duration: theme.transitions.duration.shorter, easing: theme.transitions.easing.easeInOut })
  }
}));

export const ConferenceRaisedHand: React.FunctionComponent<IConferenceRaisedHandProps> = ({ active, className, ...rest }) => {
  const classes = useStyles();
  return (
    <Zoom in={active}>
      <div className={clsx(classes.root, className)} {...rest}>
        <FontAwesomeIcon icon={faHandPaper} />
      </div>
    </Zoom>
  );
};
