import { callGet, callPost } from '@curry-group/data-addons';
import { takeLatest, all, put } from 'redux-saga/effects';
import { fetchStartupRequest, fetchStartupFailed, fetchStartupSuccess, updatePasswordRequest, updatePasswordSuccess, updatePasswordFailed } from '../../reducer/app';
import api from '../../api';

function* watcher() {
  yield takeLatest(fetchStartupRequest.type, fetchStartupWorker);
  yield takeLatest(updatePasswordRequest.type, updatePasswordWorker);
}

function* fetchStartupWorker() {
  try {
    const startup = yield callGet(api.app.startup);
    yield put(fetchStartupSuccess({ ...startup }));
  } catch (e) {
    yield put(fetchStartupFailed());
  }
}

function* updatePasswordWorker(action: ReturnType<typeof updatePasswordRequest>) {
  try {
    yield callPost(api.app.changePassword, action.payload);
    yield put(updatePasswordSuccess());
  } catch (ex) {
    yield put(updatePasswordFailed());
  }
}

export function* app() {
  yield all([watcher()]);
}
