import React, { FC, useState } from 'react';
import { Ava, Bx, IconAva, IImageGalleryProps } from '@curry-group/mui-curcuma';
import { Menu, MenuItem } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendarAlt, faCameraHome } from '@fortawesome/pro-light-svg-icons';
import { IConferenceInformation, IMessageModel, IMessageReferences } from '../../../model/communication/Message';
import useTheme from '@material-ui/core/styles/useTheme';
import { MessageQuote } from './quote';
import { assetUrl } from '../../../helper';
import { AssetAttachment } from '../attachments';
import { MessageInteractionBar } from './interaction-bar';
import { MessageHeader } from './header';
import { MessageContent } from './content';
import { IAsset, isImage } from '../../../model/ryve/asset';
import { MessageImageGallery } from './gallery';
import { useIsMobile } from '../../../helper/hooks/useIsMobile';
import { ChatMessage } from '../../chat-message';

export interface IMessageProps {
  id: string;
  avatarSrc?: string;
  name: string;
  timestamp: string;
  modified?: boolean;
  content: string;
  deleted?: boolean;
  width?: number;
  attachments?: IAsset[];
  createdBy: string;

  isOwnMessage: boolean;

  canEdit?: boolean;
  onEditClicked?: () => void;
  canDelete?: boolean;
  onDeleteClicked?: () => void;

  quotes?: IMessageModel;
  allowQuotes?: boolean;
  quoteClicked?: () => void;

  allowThreading?: boolean;
  numThreadMessages?: number;
  threadingClicked?: () => void;
  asInlineThread?: boolean;

  allowVoting?: boolean;
  userVoting?: number;
  voteUpClick?: () => void;
  voteDownClick?: () => void;
  numVotes?: number;

  onMessageRead?: () => void;
  onJoinConference?: (message?: IMessageModel) => void;
  abbreviation?: string;
  conferenceInfo?: IConferenceInformation;
  references?: IMessageReferences;
  onReferenceSelected?: (type: string, data: any) => void;
}

export const Message: React.FC<IMessageProps> = ({
  id,
  width,
  avatarSrc,
  name,
  timestamp,
  modified,
  attachments,
  content,
  deleted,
  isOwnMessage,
  allowThreading,
  allowVoting,
  voteUpClick,
  userVoting,
  voteDownClick,
  threadingClicked,
  numThreadMessages,
  asInlineThread,
  quotes,
  numVotes,
  allowQuotes,
  quoteClicked,
  canDelete,
  onDeleteClicked,
  canEdit,
  onEditClicked,
  onMessageRead,
  onJoinConference,
  abbreviation,
  createdBy,
  conferenceInfo,
  references,
  onReferenceSelected
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  /* style */
  let bgColor = isOwnMessage ? theme.palette.primary.main : theme.palette.background.paper;
  let color = isOwnMessage ? theme.palette.primary.contrastText : '';

  if (deleted) {
    color = theme.palette.primary.contrastText;
    bgColor = theme.palette.grey[700];
  }

  const threadOffsetMargin = 24;

  const realWidth = Math.min(Math.max(width || 0, 60), 100);

  let boxWidth = '100%';
  let boxMarginLeft = '0px';
  let boxMarginRight = boxMarginLeft;

  if (asInlineThread) {
    boxWidth = `calc(100% - ${threadOffsetMargin * 2}px)`;
    boxMarginLeft = threadOffsetMargin + 'px';
    boxMarginRight = boxMarginLeft;
  }
  /* style */

  /* misc */
  const downloadAsset = (asset: IAsset) => {
    var a = document.createElement('a');
    a.href = assetUrl(asset._id);
    a.setAttribute('download', asset.name + asset.extension);
    a.click();
  };
  const [anchorEl, setAnchorEl] = useState<{ target: HTMLElement } | null>(null);

  const handleClick = (target: HTMLButtonElement) => {
    setAnchorEl({ target });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  /* misc */

  /* props */
  const hasVoting = allowVoting && !!voteDownClick && !!voteUpClick;
  const hasThreading = (allowThreading || conferenceInfo) && !!threadingClicked;
  const hasQuotes = allowQuotes && !!quoteClicked;

  let hasDropdown = false;
  if (deleted) {
    hasDropdown = !!(canDelete && onDeleteClicked);
  } else {
    hasDropdown = !!(canDelete && onDeleteClicked) || !!(canEdit && onEditClicked) || !!(!deleted && hasQuotes && hasThreading);
  }

  let galleryImages: IImageGalleryProps['images'] = [];
  if (attachments && attachments.length) {
    galleryImages = attachments
      .filter(attachment => isImage(attachment))
      .map(attachment => {
        return {
          thumbnail: { title: attachment.name, alt: attachment.name, image: assetUrl(attachment._id, true), downloadImage: '' },
          fullImage: { title: attachment.name, alt: attachment.name, image: assetUrl(attachment._id), downloadImage: '' }
        };
      });
  }
  /* props */

  const CreatorIcon = () => {
    if (isOwnMessage) return <Bx mr={2} pt={0.5} flexGrow={1} flexShrink={0} flexBasis={asInlineThread ? 32 : 46} />;

    let iconContent = (
      <Ava size={asInlineThread ? 'small' : 'small'} src={avatarSrc}>
        {/* {abbreviation} */}
      </Ava>
    );

    if (conferenceInfo) {
      iconContent = (
        <IconAva variant="circular" size={asInlineThread ? 'small' : 'small'} color="secondary" inverted={true}>
          <FontAwesomeIcon icon={faCameraHome} />
        </IconAva>
      );
    }

    if (references && references.meeting) {
      iconContent = (
        <IconAva variant="circular" size={asInlineThread ? 'small' : 'small'} color="secondary" inverted={true}>
          <FontAwesomeIcon icon={faCalendarAlt} />
        </IconAva>
      );
    }
    return (
      <Bx mr={2} pt={0.5}>
        {iconContent}
      </Bx>
    );
  };

  return (
    <Bx
      id={id}
      display="flex"
      position="relative"
      mb={isMobile ? 2 : 3}
      width={isMobile ? '92%' : boxWidth}
      style={{ marginLeft: isMobile ? (isOwnMessage ? '8%' : undefined) : boxMarginLeft, marginRight: isMobile ? (!isOwnMessage ? '8%' : undefined) : boxMarginRight }}
    >
      {!isMobile && CreatorIcon()}

      <Bx width={isMobile ? '100%' : realWidth + '%'}>
        <ChatMessage px={{ xs: 1.5, md: 2 }} pt={{ xs: 1.5, md: 0.75 }} pb={{ xs: 1, md: 1.25 }} bgcolor={bgColor} color={color}>
          <MessageHeader
            mobile={isMobile}
            isOwnMessage={isOwnMessage}
            isConference={!!conferenceInfo}
            references={references}
            isDeleted={deleted}
            isModified={modified}
            timestamp={timestamp}
            content={content}
            createdById={createdBy}
            createdByName={name}
            hasDropdown={hasDropdown}
            setDropdownAnchor={handleClick}
          />

          {/* quote */}
          {!deleted && quotes && <MessageQuote message={quotes} onJoinConference={() => onJoinConference?.(quotes)} />}

          {/* text content or conference or reference */}
          <MessageContent
            content={content}
            isDeleted={deleted}
            conferenceInfo={conferenceInfo}
            onJoinConference={onJoinConference}
            references={references}
            onReferenceSelected={onReferenceSelected}
            color={color}
          />

          {/* gallery */}
          {!deleted && !!(galleryImages && galleryImages.length) && <MessageImageGallery images={galleryImages} />}

          {/* all attachments */}
          {!deleted &&
            !!(attachments && attachments.length) &&
            attachments.map((attachment, index) => (
              <AssetAttachment key={'attachment-' + index} color={isOwnMessage ? 'text.primary' : undefined} mt={1} onDownload={downloadAsset} asset={attachment} />
            ))}

          {!references?.meeting && (
            <MessageInteractionBar
              hasVoting={hasVoting}
              hasThreading={hasThreading}
              hasQuotes={hasQuotes}
              isDeleted={deleted}
              isOwnMessage={isOwnMessage}
              numChildren={numThreadMessages}
              numVotes={numVotes}
              userVoting={userVoting}
              requestThread={() => threadingClicked?.()}
              requestQuote={() => quoteClicked?.()}
              voteUp={() => voteUpClick?.()}
              voteDown={() => voteDownClick?.()}
            />
          )}
        </ChatMessage>
      </Bx>
      {hasDropdown && (
        <MessageDropdown
          target={anchorEl?.target}
          close={handleClose}
          isDeleted={deleted}
          canDelete={canDelete}
          onDelete={() => {
            handleClose();
            onDeleteClicked?.();
          }}
          canEdit={canEdit}
          onEdit={() => {
            handleClose();
            onEditClicked?.();
          }}
          canQuote={hasQuotes}
          canThread={hasThreading}
          onQuote={() => {
            handleClose();
            quoteClicked?.();
          }}
        />
      )}
    </Bx>
  );
};

export interface IMessageDropdownProps {
  target?: any;
  close: () => void;

  isDeleted?: boolean;
  canDelete?: boolean;
  onDelete?: () => void;

  canEdit?: boolean;
  onEdit?: () => void;

  canQuote?: boolean;
  canThread?: boolean;
  onQuote?: () => void;
}

export const MessageDropdown: FC<IMessageDropdownProps> = ({ target, close, isDeleted, canDelete, onDelete, canEdit, onEdit, canQuote, canThread, onQuote }) => {
  if (!target) return <></>;
  return (
    <Menu anchorEl={target} keepMounted open={true} onClose={close}>
      {!isDeleted && canDelete && !!onDelete && <MenuItem onClick={onDelete}>Löschen</MenuItem>}
      {!isDeleted && canEdit && !!onEdit && <MenuItem onClick={onEdit}>Bearbeiten</MenuItem>}
      {!isDeleted && canQuote && canThread && !!onQuote && <MenuItem onClick={onQuote}>Zitieren</MenuItem>}

      {isDeleted && canDelete && !!onDelete && <MenuItem onClick={onDelete}>Endgültig löschen</MenuItem>}
    </Menu>
  );
};
