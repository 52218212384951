import createSagaMiddleware from 'redux-saga';
import _throttle from 'lodash/throttle';
import { createLogger } from 'redux-logger';
import { applyMiddleware, compose, createStore } from 'redux';
import { RootState, setupRootReducers } from '../reducer';
import sagas from '../sagas';

export const loadLocalStorage = () => {
  if (localStorage.sco_app_state) {
    try {
      const storedState: any = JSON.parse(localStorage.sco_app_state);
      return {
        app: { loggedIn: storedState.app.loggedIn }
      };
    } catch {}
  }
  return {};
};
export const setupStore = (initialState: RootState) => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware: any[] = [sagaMiddleware];
  if ((process.env.NODE_ENV === 'development' && localStorage.disableReduxLogging !== '1') || localStorage.forceReduxLogging) {
    const loggerMiddleware = createLogger({});
    middleware.unshift(loggerMiddleware);
  }
  const rootReducers = setupRootReducers();
  const win = window as any;
  const composeEnhancers =
    typeof window === 'object' && win['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
      ? win['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose;

  const enhancers = composeEnhancers(applyMiddleware(...middleware));

  const store = createStore(rootReducers, initialState, enhancers);

  store.subscribe(
    _throttle(() => {
      const state = store.getState();
      const serializee = {
        app: { loggedIn: state.app.loggedIn }
      };
      localStorage.sco_app_state = JSON.stringify(serializee);
    }, 1000)
  );
  sagaMiddleware.run(sagas);

  return store;
};
