import { Ava, Bx, ExtendedListItem, Typo } from '@curry-group/mui-curcuma';
import { faLockAlt, faLockOpen, faMask } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, ChipTypeMap } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { ReactChild, ReactNode } from 'react';

export interface ISidebarListItemProps {
  title: string;
  timestamp: string;
  badgeContent?: number | string | ReactNode;
  badgeColor?: ChipTypeMap['props']['color'];
  subtitle?: string;
  avatar: ReactChild;
  to?: string;
  isMobile?: boolean;
}

export const SidebarListItem: React.FC<ISidebarListItemProps> = ({ to, title, avatar, timestamp, badgeContent, badgeColor, subtitle, isMobile }) => {
  return (
    <ExtendedListItem to={to} avatar={<Bx ml={isMobile ? 2 : 3}>{avatar}</Bx>}>
      {/* Template: Has Subtitle */}
      {!!subtitle && (
        <Bx display="flex" flexDirection="column" height="100%" justifyContent="center" mr={isMobile ? 2 : 3}>
          {/* Meta */}
          <Bx display="flex" mb={0.5}>
            <Bx lineHeight={0.5}>
              <Typo color="textPrimary" variant="subtitle3" component="span" textTransform="uppercase">
                {subtitle}
              </Typo>
            </Bx>

            <Bx lineHeight={0.5} ml="auto" display="flex">
              <Typo color="textPrimary" variant="subtitle3" component="span">
                {timestamp}
              </Typo>
            </Bx>
          </Bx>

          {/* Content */}
          <Bx display="flex">
            <Bx color="text.primary" fontWeight={500} fontSize="body2" lineHeight={1.25}>
              <Typo variant="inherit" component="span">
                {title}
              </Typo>
            </Bx>
            {!!badgeContent && (
              <Bx display="flex" flexDirection="column" alignItems="flex-end" height="100%" ml="auto">
                <Bx>
                  <Chip color={badgeColor || 'primary'} label={badgeContent} size="small" />
                </Bx>
              </Bx>
            )}
          </Bx>
        </Bx>
      )}

      {/* Template Has _no_ Subtitle */}
      {!subtitle && (
        <Bx display="flex" height="100%" alignItems="center" mr={isMobile ? 2 : 3}>
          {/* Content */}
          <Bx display="flex">
            <Bx color="text.primary" fontWeight={500} fontSize="body2" lineHeight={1.25}>
              <Typo variant="inherit" component="span">
                {title}
              </Typo>
            </Bx>
          </Bx>

          {/* Meta */}
          <Bx display="flex" flexDirection="column" ml="auto" pl={1}>
            <Bx lineHeight={0.5} ml="auto" display="flex">
              <Bx mb={-0.25}>
                <Typo color="textPrimary" variant="subtitle3" component="span" noWrap>
                  {timestamp}
                </Typo>
              </Bx>
            </Bx>
            {!!badgeContent && (
              <Bx display="flex" mt={0.5} flexDirection="column" alignItems="flex-end" height="100%" ml="auto">
                <Bx>
                  <Chip color={badgeColor || 'primary'} label={badgeContent} size="small" />
                </Bx>
              </Bx>
            )}
          </Bx>
        </Bx>
      )}
    </ExtendedListItem>
  );
};

export const SidebarListItemSkeleton = () => {
  return (
    <ExtendedListItem
      avatar={
        <Bx ml={3}>
          <Ava loading variant="circle" size="medium" />
        </Bx>
      }
    >
      <Bx display="flex" justifyContent="center" flexDirection="column" height="100%">
        <Typo fontWeight={500} variant="body1" component="span">
          <Skeleton animation="wave" style={{ width: '200px' }} />
        </Typo>
        <Typo variant="body2" component="span">
          <Skeleton animation="wave" style={{ width: '120px' }} />
        </Typo>
      </Bx>
    </ExtendedListItem>
  );
};
