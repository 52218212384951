import { Anohana, Bx, DropDownMenu } from '@curry-group/mui-curcuma';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem } from '@material-ui/core';
import React, { RefObject, useRef, useState } from 'react';
import { ConferencingSidebar } from '../../../../app/views/conferencing/sidebar';
import { ConferenceIconBtn } from '../../layout/icon-btn';

export interface IConferencingControlsFrame {
  onInfoClick?: () => void;
  onSettingsClick?: () => void;
}

export const ConferencingControlsFrame: React.FC<IConferencingControlsFrame> = ({ onInfoClick, onSettingsClick }) => {
  const buttonRef = useRef<HTMLButtonElement>() as RefObject<HTMLButtonElement>;
  const [menu, setMenu] = useState(false);
  const [mobileChat, setMobileChat] = useState(false);
  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement>();
  const handleChatClick = () => {
    setMenu(false);
    setMobileChat(!mobileChat);
  };
  const handleInfoClick = () => {
    setMenu(false);
    onInfoClick?.();
  };
  const handleSettingsClick = () => {
    setMenu(false);
    onSettingsClick?.();
  };
  return (
    <Bx display="flex">
      <Bx>
        <ConferenceIconBtn
          ref={buttonRef}
          semiTransparent
          aria-controls="mobile-more-menu"
          aria-haspopup="true"
          onClick={() => {
            if (buttonRef.current) {
              setDropdownAnchor(buttonRef.current);
            }
            setMenu(!menu);
          }}
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </ConferenceIconBtn>
      </Bx>
      <DropDownMenu
        id="mobile-more-menu"
        open={menu}
        anchorEl={dropdownAnchor}
        onClose={() => {
          setMenu(false);
        }}
        keepMounted
        style={{ zIndex: 1301 }}
      >
        <Bx bgcolor="background.paper" borderRadius="borderRadius">
          <MenuItem onClick={handleChatClick}>{mobileChat ? 'Chat ausblenden' : 'Chat einblenden'}</MenuItem>
          <MenuItem onClick={handleInfoClick}>Informationen</MenuItem>
          <MenuItem onClick={handleSettingsClick}>Einstellungen</MenuItem>
        </Bx>
      </DropDownMenu>
      <Anohana
        fullScreen
        open={mobileChat}
        onClose={() => {
          setMobileChat(false);
        }}
        transition="slide-down"
      >
        <ConferencingSidebar
          fullscreen
          onDropdownClick={anchor => {
            setDropdownAnchor(anchor);
            setMenu(true);
          }}
          onCloseClick={() => {
            setMobileChat(false);
          }}
        />
      </Anohana>
    </Bx>
  );
};
