import { IUserProfile } from '../ryve/profile';
import { IAddress } from '../common/address';
import { TScoUserProfileTypes } from './types';

export interface IScoUserProfileListItem {
  _id: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  avatar?: string;
  fullName: string;
}
export interface IScoUserProfile extends IUserProfile, IAddress {
  // Basisdaten
  type: TScoUserProfileTypes;
  age: number;

  // Adressdaten
  addressPostalCode?: string;
  addressCity?: string;
  addressStreet?: string;

  // Kontaktdaten
  phone?: string;
  email?: string;
  availability?: string;

  // Schuldaten
  school?: string;
  schoolGrade?: string;

  // Persönliche Informationen
  hobbies?: string;
}

export const ProfileTypes = {
  admin: 'Administrator',
  tutor: 'Tutor',
  student: 'Schüler'
};
