import React from 'react';
import dayjs from 'dayjs';

import { BaseListItem } from '..';
import { IRegistrationRequestListItem } from '../../../../model/data/registration';

import { resolveTimestamp } from '../../../../helper';
import { IconAva } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';

export interface IRegistrationRequestListItemProps {
  request: IRegistrationRequestListItem;
  selected: () => void;
}

export const RegistrationRequestListItem: React.FC<IRegistrationRequestListItemProps> = ({ request, selected }) => {
  var type = '';
  var color: 'secondary' | 'primary' = 'secondary';

  if (request.type === 'student') {
    type = 'Schüler';
    color = 'secondary';
  } else if (request.type === 'tutor') {
    type = 'Tutor';
    color = 'primary';
  }

  return (
    <BaseListItem
      type={resolveTimestamp(dayjs(request.createdAt).unix() * 1000)}
      subtitle={type}
      title={request.fullName}
      selected={selected}
      avatar={<IconAva color={color} children={<FontAwesomeIcon icon={faUser} />} size="large" />}
      fontcolor={'textPrimary'}
    />
  );
};
