import React, { RefObject, useEffect, useRef } from 'react';
import { ConferenceParticipant } from '../layout/participant';

export const ConferencingParticipant: React.FC<{
  displayName?: string;
  audio?: boolean;
  video?: boolean;
  muted?: boolean;
  self?: boolean;
  hand?: Boolean;
  media?: MediaStream;
  speaking?: boolean;
}> = ({ media, displayName, hand, audio, video, muted, self, speaking }) => {
  const videoRef = useRef<HTMLVideoElement>() as RefObject<HTMLVideoElement>;
  const audioRef = useRef<HTMLAudioElement>() as RefObject<HTMLAudioElement>;
  useEffect(() => {
    if (media && videoRef.current) {
      if (videoRef.current.srcObject !== media) {
        videoRef.current.srcObject = media;
      }
    }
    if (media && audioRef.current) {
      if (audioRef.current.srcObject !== media) {
        audioRef.current.srcObject = media;
      }
      const audElm: any = audioRef.current;
      if ('setSinkId' in audElm && localStorage.__vidConfDevAudOut && 'sinkId' in audElm && audElm.sinkId !== localStorage.__vidConfDevAudOut && !muted && !self) {
        audElm.setSinkId(localStorage.__vidConfDevAudOut);
      }
    }
  });
  return (
    <ConferenceParticipant
      raisedHand={!!hand}
      latency={10}
      name={displayName}
      audioActive={audio}
      videoActive={video}
      speaking={speaking}
      self={self}
      stream={
        <>
          <audio autoPlay ref={audioRef} muted={muted} style={{ display: 'none' }} />
          <video autoPlay ref={videoRef} muted playsInline />
        </>
      }
    />
  );
};
