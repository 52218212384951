import React, { FC, ReactChild, ReactNode, useState } from 'react';
import { Bx, IIconAvaProps, ExtendedListItem, Typo, Ava, IconAva, IconBtn, DropDownMenu } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@material-ui/core';
import { faBan, faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { Skeleton } from '@material-ui/lab';

export interface IBaseListItemProps {
  title: string;
  type: string;
  badge?: ReactNode;

  subtitle?: string;
  subtitle_2?: string;

  color?: IIconAvaProps['color'];
  fontcolor?: string;

  to?: string;
  target?: string;
  selected?: () => void;

  hasMenu?: boolean;
  menuClicked?: (close: () => void) => ReactNode;

  actions?: ReactNode;
}

export const BaseListItem: React.FC<
  IBaseListItemProps & {
    avatar: ReactChild;
    _id?: string;
    _score?: number;
  }
> = props => {
  const [anchorEl, setAnchorEl] = useState<{ target: HTMLElement } | null>(null);
  const [renderedOptions, setRenderedOptions] = useState<ReactNode>();
  const handleClick = (target: HTMLButtonElement) => {
    setAnchorEl({ target });
    setRenderedOptions(props.menuClicked?.(() => setAnchorEl(null)));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Bx position="relative">
      <ExtendedListItem to={props.to} target={props.target} onClick={props.selected} avatar={props.avatar}>
        <Bx height="100%" display="flex">
          <Bx display="flex" flexDirection="column" justifyContent="center">
            <Typo variant="subtitle2" color={props.fontcolor ?? 'textPrimary'} component="span" textTransform="uppercase">
              {localStorage.devModeSearch && (
                <span>
                  {props._id} || {props._score} ||{' '}
                </span>
              )}
              {props.type}
            </Typo>
            <Typo fontWeight={500} color={props.fontcolor ?? 'white'} variant="body1" component="span">
              {props.title}
            </Typo>
            {(props.subtitle || props.subtitle_2) && (
              <Bx display="flex" mt={0.25}>
                {props.subtitle && (
                  <Bx mr={2}>
                    <Typo variant="subtitle2" color={props.fontcolor ?? 'textPrimary'} component="span" textTransform="uppercase">
                      {props.subtitle}
                    </Typo>
                  </Bx>
                )}
                {props.subtitle_2 && (
                  <Bx>
                    <Typo variant="subtitle2" color={props.fontcolor ?? 'textPrimary'} component="span" textTransform="uppercase">
                      {props.subtitle_2}
                    </Typo>
                  </Bx>
                )}
              </Bx>
            )}
          </Bx>
          {props.actions}
          {!!props.badge && (
            <Bx display="flex" flexDirection="column" alignItems="flex-end" height="100%" ml="auto">
              <Bx>
                <Chip color="primary" label={props.badge} size="small" />
              </Bx>
            </Bx>
          )}
        </Bx>
      </ExtendedListItem>
      {props.hasMenu && props.menuClicked && (
        <Bx ml="auto" position="absolute" top="calc(50% - 18px)" right="0">
          <IconBtn
            size="small"
            color="default"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              handleClick(e.currentTarget);
            }}
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </IconBtn>
        </Bx>
      )}
      {anchorEl?.target && (
        <DropDownMenu anchorEl={anchorEl?.target} keepMounted open={!!anchorEl} onClose={handleClose}>
          <Bx bgcolor="background.paper" borderRadius="borderRadius">
            {renderedOptions}
          </Bx>
        </DropDownMenu>
      )}
    </Bx>
  );
};

export const BaseListItemSkeleton = () => {
  return (
    <ExtendedListItem avatar={<Ava loading children={<FontAwesomeIcon icon={faBan} />} size="large" />}>
      <Typo fontWeight={500} variant="body1" component="span">
        <Skeleton animation="wave" style={{ width: '200px' }} />
      </Typo>
      <Typo variant="body2" component="span">
        <Skeleton animation="wave" style={{ width: '120px' }} />
      </Typo>
    </ExtendedListItem>
  );
};

export const BaseListItemEmpty: FC<{ title?: string }> = ({ title }) => {
  return (
    <BaseListItem
      type=" "
      subtitle=" "
      title={title || 'Keine Ergebnisse vorhanden.'}
      avatar={<IconAva color="primary" children={<FontAwesomeIcon icon={faBan} />} size="large" />}
    />
  );
};
