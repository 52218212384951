import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIsMobile } from '../../../../helper/hooks/useIsMobile';
// import { useHistory } from 'react-router-dom';
import { Btn, Bx, IconBtn, Typo } from '@curry-group/mui-curcuma';
import { Wizard, WizardContent, WizardNavigation } from '@curry-group/react-wizard';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import { Header, Content, Footer } from '../../../../components/frame';
import { CreateTandemFinished } from './finished';
import { CreateTandemUserSelect } from './user-select';
import { IScoUserProfileListItem } from '../../../../model/profile';
import { CreateTandemConfirmation } from './confirm';
import { createTandemRequest } from '../../../../data/reducer/tandem';
import { MobileHeadBar } from '../../../../components/mobile-head-bar';
import { NavBarLogo } from '../../../../components/navbar-logo';
import { HeadBar } from '../../../../components/head-bar';
import { BottomBar } from '../../../../components/bottom-bar';

export const CreateTandemView: React.FC<{ onDone: () => void }> = ({ onDone }) => {
  const dispatch = useDispatch();

  const [createTandemRequestData, setCreateTandemRequestData] = useState<{ tutor?: IScoUserProfileListItem; student?: IScoUserProfileListItem }>({});

  const numSteps = 3;
  const [currentStep, setCurrentStep] = useState(0);
  const [valid, setValid] = useState(false);
  const mobile = useIsMobile();

  function prevStep() {
    if (currentStep === 0) {
      onDone();
    } else {
      if (currentStep === 1) {
        setValid(!!createTandemRequestData.tutor);
      } else if (currentStep === 2) {
        setValid(!!createTandemRequestData.student);
      }
      setCurrentStep(currentStep - 1);
    }
  }

  function nextStep() {
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
      setValid(currentStep === 1);
    } else if (currentStep === 2) {
      if (createTandemRequestData.student && createTandemRequestData.tutor) {
        dispatch(createTandemRequest({ tutorUserId: createTandemRequestData.tutor._id, studentUserId: createTandemRequestData.student._id }));
        setCurrentStep(4);
      }
    }
  }

  function setTutorUser(tutor?: IScoUserProfileListItem) {
    setCreateTandemRequestData({ ...createTandemRequestData, tutor });
    setValid(!!tutor);
  }
  function setStudentUser(student?: IScoUserProfileListItem) {
    setCreateTandemRequestData({ ...createTandemRequestData, student });
    setValid(!!student);
  }

  return (
    <Wizard step={currentStep} stepCount={numSteps}>
      {currentStep <= 3 && (
        <Header>
          {mobile && (
            <MobileHeadBar>
              <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" py={1}>
                <Grid item xs={3}>
                  <Bx display="flex" pr={1} alignItems="center" justifyContent="flex-start" height="100%">
                    <IconBtn size="medium" onClick={prevStep}>
                      <FontAwesomeIcon icon={faLongArrowLeft} />
                    </IconBtn>
                  </Bx>
                </Grid>
                <Grid item xs={6}>
                  <Bx display="flex" alignItems="center" justifyContent="center" height="100%">
                    <NavBarLogo to="/" src="/images/logo_dark.svg" />
                  </Bx>
                </Grid>
              </Bx>
            </MobileHeadBar>
          )}

          {!mobile && <HeadBar transparent={false} backButtonLink={prevStep}></HeadBar>}
        </Header>
      )}
      <Content>
        <WizardContent>
          {(callbacks, options) => {
            return (
              <React.Fragment key={`create_tandem_${options?.currentStep}`}>
                {options.currentStep === 0 && <CreateTandemUserSelect type="tutor" selectedUserId={createTandemRequestData.tutor?._id} onUserSelected={setTutorUser} />}
                {options.currentStep === 1 && (
                  <CreateTandemUserSelect
                    type="student"
                    tutorUserId={createTandemRequestData.tutor?._id}
                    selectedUserId={createTandemRequestData.student?._id}
                    onUserSelected={setStudentUser}
                  />
                )}
                {options.currentStep === 2 && createTandemRequestData.student && createTandemRequestData.tutor && (
                  <CreateTandemConfirmation tutor={createTandemRequestData.tutor} student={createTandemRequestData.student} />
                )}
                {options.currentStep === options.stepCount + 1 && <CreateTandemFinished onDone={onDone} />}
              </React.Fragment>
            );
          }}
        </WizardContent>
      </Content>
      <Footer>
        <WizardNavigation>
          {(callbacks, options) => {
            const isLastStep = options.currentStep === 2;

            // ausgeblendet im ladescreen
            if (options.currentStep > 2) return <></>;

            return (
              <Bx gridArea="footer" zIndex={2} position="sticky" bottom="0">
                <BottomBar scrolled bottom={'unset'}>
                  <Bx flexGrow={1} flexShrink={1} flexBasis="auto">
                    &nbsp;
                  </Bx>
                  <Bx alignSelf="flex-end" textAlign="right">
                    <Btn
                      size="large"
                      color="success"
                      disabled={!valid}
                      variant="contained"
                      onClick={e => {
                        e.preventDefault();
                        nextStep();
                      }}
                    >
                      <Typo variant="inherit" noWrap>
                        {isLastStep ? 'Abschließen' : 'Ok, weiter'}
                      </Typo>
                    </Btn>
                  </Bx>
                </BottomBar>
              </Bx>
            );
          }}
        </WizardNavigation>
      </Footer>
    </Wizard>
  );
};
