import { Ava, IconAva } from '@curry-group/mui-curcuma';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import { assetUrl, resolveTimestamp } from '../../../../../helper';
import { SidebarListItem } from '../../../../../components/sidebar/item';
import { ICommunicationListItem } from '../../../../../model/communication/Communication';
import { useSelector } from 'react-redux';

export interface ISidebarListItemTandemProps {
  item: ICommunicationListItem;
  isMobile?: boolean;
}

export const SidebarListItemTandem: React.FC<ISidebarListItemTandemProps> = ({ item, isMobile }) => {
  const unreadMessages = useSelector(state => state.communication.numUnreadMessages?.[item.typeId]?.[item._id] || 0);
  let badgeContent: string | number | ReactNode = unreadMessages;

  var timestamp = resolveTimestamp(parseInt(item.modifiedAt || ''));

  let avatar = <IconAva variant="circular" color="secondary" children={<FontAwesomeIcon icon={faUser} />} size="medium" />;

  let title = 'Tandem';
  if (item.content?.partner?.content) {
    // title
    const partner = item.content?.partner?.content;
    title = partner.fullName;

    // avatar
    if (partner.avatar) {
      avatar = <Ava color="secondary" size="medium" src={assetUrl(partner.avatar, true)} />;
    }
  }
  return (
    <SidebarListItem
      badgeColor="primary"
      badgeContent={badgeContent}
      to={'/tandems/' + item.seo?.alias}
      avatar={avatar}
      timestamp={timestamp}
      title={title}
      isMobile={isMobile}
      subtitle="Tandem"
    />
  );
};

export const SidebarListItemAdminChat: React.FC<ISidebarListItemTandemProps> = ({ item, isMobile }) => {
  const unreadMessages = useSelector(state => state.communication.numUnreadMessages?.[item.typeId]?.[item._id] || 0);
  let badgeContent: string | number | ReactNode = unreadMessages;

  var timestamp = resolveTimestamp(parseInt(item.modifiedAt || ''));

  let avatar = <IconAva variant="circular" color="primary" children={<FontAwesomeIcon icon={faUser} />} size="medium" />;

  let title = 'Admin-Chat';
  if (item.content?.partner?.content) {
    // title
    const partner = item.content?.partner?.content;
    title = partner.fullName;

    // avatar
    if (partner.avatar) {
      avatar = <Ava color="primary" size="medium" src={assetUrl(partner.avatar, true)} />;
    }
  }
  return (
    <SidebarListItem
      badgeColor="primary"
      badgeContent={badgeContent}
      to={'/tandems/' + item.seo?.alias}
      avatar={avatar}
      timestamp={timestamp}
      title={title}
      isMobile={isMobile}
      subtitle="Admin-Chat"
    />
  );
};
