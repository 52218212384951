import api from '../data/api';
import dayjs from 'dayjs';
import { faFile, faFileAudio, faFileExcel, faFilePdf, faFilePowerpoint, faFileVideo, faFileWord } from '@fortawesome/pro-light-svg-icons';
import { IConferenceConnections } from '../model/data/connction-export';
import { ICategoryItem, ICategoryTree } from '../model/ryve/category';

function intToDay(int: number, short?: boolean) {
  const daysShort = ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA'];
  const daysLong = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
  if (short) return daysShort[int];
  else return daysLong[int];
}

export function resolveTimestamp(unix?: number, showDay?: boolean, hideTime?: boolean) {
  if (typeof unix === 'undefined' || unix === null) {
    return '';
  }
  const date = dayjs(unix);
  const now = dayjs();
  const time = date.format('HH:mm');
  if (date.startOf('d').unix() === now.startOf('d').unix()) {
    return 'Heute  ' + (hideTime ? '' : time);
  } else if (date.startOf('d').unix() === now.add(-1, 'd').startOf('d').unix()) {
    return 'Gestern ' + (hideTime ? '' : time);
  } else if (date.startOf('d').unix() === now.add(1, 'd').startOf('d').unix()) {
    return 'Morgen ' + (hideTime ? '' : time);
  } else {
    return (showDay ? intToDay(date.day()) + ' ' : '') + date.format('DD.MM.YYYY') + ' ' + (hideTime ? '' : time);
  }
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const allowedMimeTypes = [
  'application/epub+zip',
  'application/gzip',
  'application/msonenote',
  'application/msword',
  'application/pdf',
  'application/vnd.ms-access',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel.addin.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-officetheme',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-powerpoint.addin.macroEnabled.12',
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  'application/vnd.ms-powerpoint.slide.macroEnabled.12',
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
  'application/vnd.ms-powerpoint.template.macroEnabled.12',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.ms-word.template.macroEnabled.12',
  'application/vnd.oasis.opendocument.chart',
  'application/vnd.oasis.opendocument.database',
  'application/vnd.oasis.opendocument.formula',
  'application/vnd.oasis.opendocument.graphics',
  'application/vnd.oasis.opendocument.graphics-template',
  'application/vnd.oasis.opendocument.image',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.presentation-template',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.spreadsheet-template',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.text-master',
  'application/vnd.oasis.opendocument.text-template',
  'application/vnd.oasis.opendocument.text-web',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.slide',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/x-7z-compressed',
  'application/zip',
  'audio/*',
  'image/*',
  'text/csv',
  'text/plain',
  'text/rtf',
  'video/*'
];

export const wordFileExtensions = ['.doc', '.dot', '.docx', '.dotx'];
export const excelFileExtensions = ['.xls', '.xlsx', '.csv'];
export const presentationFileExtensions = ['.ppt', '.pptx'];

export const msDocFileExtensions = wordFileExtensions.concat(excelFileExtensions).concat(presentationFileExtensions);

export const pdfFileExtensions = ['.pdf'];

export const imageFileExtensions = ['.jpg', '.jpeg?tn=1&q=medium&s=background', '.png', '.gif', '.svg', '.bmp'];

export const audioFileExtensions = ['.ogg', '.mp3', '.wav'];

export const videoFileExtensions = ['.mov', '.mp4', '.avi', '.mpeg', '.mkv'];

export const allowedChatFileExtensions = msDocFileExtensions.concat(imageFileExtensions).concat(pdfFileExtensions).concat(videoFileExtensions).concat(audioFileExtensions);

export function iconForExtension(ext: string) {
  if (wordFileExtensions.includes(ext)) return faFileWord;
  if (excelFileExtensions.includes(ext)) return faFileExcel;
  if (presentationFileExtensions.includes(ext)) return faFilePowerpoint;
  if (pdfFileExtensions.includes(ext)) return faFilePdf;
  if (audioFileExtensions.includes(ext)) return faFileAudio;
  if (videoFileExtensions.includes(ext)) return faFileVideo;
  return faFile;
}

export function assetUrl(assetId: string, tn: boolean = false) {
  const params: { id: string; tn?: number } = { id: assetId };
  if (tn) params.tn = 1;

  return api.urlWithQuery(api.core + '/assetbyid', params);
}

export function validateEmail(email: string) {
  return /\b[\w.-]+@[\w.-]+\.\w{2,4}\b/i.test(email);
}

export function validatePhone(phone: string) {
  return /\+[\d\s]{8,}|[\d\s]{6,}/.test(phone);
}

export function validatePassword(pass: string) {
  if (!pass) return false;
  return /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,}$/.test(pass);
}

export function transformConnectionData(data: IConferenceConnections[]) {
  const header = ['Tutor', 'Schueler', 'Dauer', 'Verbindung Start', 'Verbindung Ende', 'Anzahl Nachrichten'];
  const csvData = [header];
  for (let conf of data) {
    for (let connection of conf.connections) {
      csvData.push([
        `${conf.tutor.firstname} ${conf.tutor.lastname}`,
        `${conf.student.firstname} ${conf.student.lastname}`,
        dayjs.unix(connection.duration).format('HH:mm:ss'),
        new Date(connection.startedAt).toUTCString().replace(',', ' '),
        new Date(connection.endedAt).toUTCString().replace(',', ' '),
        connection.numMessages.toString()
      ]);
    }
  }
  return csvData;
}

export function downloadCsv(name: string, data: string[][]): void {
  // transform array to csv string
  let csvString = '';
  for (let row of data) {
    csvString += row.join(';') + '\r\n';
  }
  var blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  var filename = `${name}.csv`;
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
export type CategoryNode = { _id: string; parentId: string; name: string; children: Array<CategoryNode> };
/**
 *  Pre-order tree traversal visits each node using stack.
 *  Checks if leaf node based on children === null otherwise
 *  pushes all children into stack and continues traversal.
 *  hashMap object literal used for deduping.
 *  @param root - deserialized JSON root to begin traversal
 *  @returns array  - final array of nodes in order with no dups
 */
 export function convertTreeToIDList(root: Array<ICategoryTree>) {
  var stack: Array<ICategoryTree> = [...root],
    array: Array<string> = [],
    hashMap = {};
  while (stack.length !== 0) {
    var node = stack.pop();
    if (!node) break;
    if (!node?.children || node?.children.length === 0) {
      visitNode(node, hashMap, array);
    } else {
      visitNode(node, hashMap, array);
      for (var i = node.children.length - 1; i >= 0; i--) {
        stack.push(node.children[i]);
      }
    }
  }
  return array;
}
/**
 *  For each node visit if node not a hashMap key, insert
 *  into array.  Then append node into end of the array.
 *  @params node - object to check
 *  @param hashMap - object literal used for deduping
 *  @param array - final array that nodes are inserted
 */
 function visitNode(node: ICategoryTree, hashMap: any, array: Array<string>) {
  if (!hashMap[node._id]) {
    hashMap[node._id] = true;
    array.push(node._id);
  }
}

export function filterByNodeName(nodeArray, searchVal: string) {
  const getNodes = nodes => {
    let result: any[] = [];
    for (var node of nodes) {
      let addNode: any = null;
      if (node.name.toLowerCase().includes(searchVal.toLowerCase())) {
        addNode = node;
      }
      if (Array.isArray(node.children)) {
        const nodes = getNodes(node.children);
        if (nodes.length) {
          if (addNode) {
            addNode = { ...addNode, children: nodes };
          } else {
            addNode = { ...node, children: nodes };
          }
        } else {
          if (addNode) {
            addNode = { ...addNode, children: [] };
          }
        }
      }
      if (addNode) {
        result.push(addNode);
      }
    }
    return result;
  };
  return getNodes(nodeArray);
}

export const updateArraySelection = (arrayValues: string[], newValue: string) => {
  if (!arrayValues) {
    arrayValues = [];
  }
  if (arrayValues.includes(newValue)) {
    let index = arrayValues.indexOf(newValue);
    let newSelection = [...arrayValues];
    newSelection.splice(index, 1);
    return newSelection;
  } else {
    return [...arrayValues, newValue];
  }
};

const defaultNode: ICategoryItem = {
  _id: "",
  alias: "parent",
  fullPath: "",
  name: "category"
}

function buildCategoryTree(items: ICategoryItem[]): ICategoryTree{
  var result = defaultNode;
  for (let i = 0; i < items.length; i++) {
    const element = items[i];
    
  }
  return result;
}

export function buildCategoryDictionary(items: ICategoryItem[]): {[key: string]: ICategoryItem}{
  let result = {};
  items.forEach(item => {
    result[item._id] = item;
  });
  return result;
}

export function isValidIBANNumber(input) {
  var CODE_LENGTHS = {
      AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
      CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
      FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
      HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
      LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
      MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
      RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,   
      AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
      SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
  };
  var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
          code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
          digits;
  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
      return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => {
      return `${letter.charCodeAt(0) - 55}`;
  });
  // final check
  return mod97(digits);
}

export function mod97(string) {
  var checksum = string.slice(0, 2), fragment;
  for (var offset = 2; offset < string.length; offset += 7) {
      fragment = String(checksum) + string.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

export function validateInput(input: string): boolean{
  return !!input && input.length >= 2 && input.length <= 50;
}