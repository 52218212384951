import React, { useEffect, useState } from 'react';
import { CheckboxControl, Ava, Bx, DropZone, formatBytes, IconBtn, Typo, InputInfoText } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import { allowedMimeTypes } from '../../../../../helper';
import { useDispatch, useSelector } from 'react-redux';
import { uploadDataProtectionFileRemoved, uploadDataProtectionFileRequest } from '../../../../../data/reducer/registration';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IBaseRegistrationStepProps } from '../..';
import { FramedContent } from '../../../../../components/framed-content';

export interface IRegistrationDataProtectionProps extends IBaseRegistrationStepProps {
  needsFileUpload?: boolean;
  onFileUploaded: (tempFileName?: string) => void;
  accepted?: boolean;
  onAcceptedChanged: (accepted: boolean) => void;
}

export const RegistrationDataProtection: React.FC<IRegistrationDataProtectionProps> = ({ needsFileUpload, accepted, onFileUploaded, onAcceptedChanged }) => {
  const dispatch = useDispatch();
  const [currentFile, setCurrentFile] = useState<File>();
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileUploadFailed, setFileUploadFailed] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const { dataProtectionTempFileName, dataProtectionFailed, dataProtectionPreparing } = useSelector(state => state.registration);

  const removeFile = () => {
    setCurrentFile(undefined);
    setFileUploadFailed(false);
    setFileUploaded(false);
    setFileUploading(false);
    onFileUploaded(undefined);
    dispatch(uploadDataProtectionFileRemoved());
  };

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setCurrentFile(file);
    dispatch(uploadDataProtectionFileRequest(file));
  };

  useEffect(() => {
    if (dataProtectionTempFileName && !fileUploaded) {
      // uploaded just now
      setFileUploadFailed(false);
      setFileUploaded(true);
      setFileUploading(false);
      onFileUploaded(dataProtectionTempFileName);
    } else if (dataProtectionFailed && !fileUploadFailed) {
      // upload failed
      setFileUploadFailed(true);
      setFileUploaded(false);
      setFileUploading(false);
      setCurrentFile(undefined);
      onFileUploaded(undefined);
    } else if (dataProtectionPreparing && !fileUploading) {
      // upload started
      setFileUploadFailed(false);
      setFileUploaded(false);
      setFileUploading(true);
    }
  }, [dataProtectionTempFileName, dataProtectionFailed, dataProtectionPreparing, fileUploaded, fileUploading, fileUploadFailed, onFileUploaded]);

  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Willkommen auf Schüler Connect Online!
        </Typo>
      </Bx>
      <Bx>
        <Typo variant="subtitle1">Sie müssen den Datenschutzbestimmungen von Schüler Connect Online zustimmen.</Typo>
      </Bx>
      <Bx my={8}>
        {needsFileUpload && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Bx mb={4}>
                <DropZone onDrop={onDrop} maxSize={Infinity} minSize={50} multiple={false} label="Klicken oder Dateien hier ablegen" accept={allowedMimeTypes} />
                {currentFile && (
                  <Bx mt={1}>
                    <Bx mt={0.5} display="flex" alignItems="center" p={1} bgcolor="#F8F8F8" border={1} borderRadius={'borderRadius2'} borderColor="#D6D7D9">
                      <Bx mr={2}>
                        <Typo variant="h1" color="primary" component="div">
                          <Ava size="small" variant="rounded" src={URL.createObjectURL(currentFile)} />
                        </Typo>
                      </Bx>
                      <Bx mr={2}>
                        <Typo variant="body2" fontWeight={500}>
                          {currentFile.name}
                        </Typo>
                        <Typo variant="subtitle3">{formatBytes(currentFile.size)}</Typo>
                      </Bx>
                      <Bx ml="auto">
                        <IconBtn size="small" onClick={removeFile}>
                          <FontAwesomeIcon icon={faTimes} />
                        </IconBtn>
                      </Bx>
                    </Bx>
                  </Bx>
                )}
                {fileUploadFailed && (
                  <Bx mt={1}>
                    <InputInfoText type={'error'}>{'Datei fehlerhaft'}</InputInfoText>
                  </Bx>
                )}
              </Bx>
            </Grid>
          </Grid>
        )}
        {!needsFileUpload && (
          <Grid xs={12} item>
            <Bx mt={{ xs: 6, md: 8, lg: 12 }}>
              <Bx>
                <CheckboxControl
                  control={{
                    color: 'primary',
                    name: 'termsAccepted',
                    value: accepted,
                    onChange: e => {
                      onAcceptedChanged(e.target.checked);
                    }
                  }}
                  checked={accepted}
                  label={<Typo>Ich stimme den Datenschutzbestimmungen zu.</Typo>}
                />
              </Bx>
            </Bx>
          </Grid>
        )}
      </Bx>
    </FramedContent>
  );
};
