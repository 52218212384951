import React from 'react';

import { Btn, Bx, Ctn, DropDownMenu, IconBtn, IIconBtnProps, TabItem, TabList, Typo } from '@curry-group/mui-curcuma';
import { faCameraHome, faCog, faEllipsisV, faInfo, faLongArrowLeft, faPlus, faUserPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fade, Grid, MenuItem } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useIsMobile } from '../../../helper/hooks/useIsMobile';
import { GetMessagesRequest } from '../../../model/communication/Message';
import { HeadBar } from '../../head-bar';
import { MobileHeadBar } from '../../mobile-head-bar';

export interface IHeaderProps {
  working?: boolean;
  title?: string;
  actions?: Array<ICommunicationAction>;

  tabs?: string[];
  selectedTab?: number;
  selectTab?: (tab: number) => void;

  backButtonAction?: () => void;
}

export const Header: React.FC<IHeaderProps> = ({ working, title, actions, tabs, selectedTab, selectTab, backButtonAction }) => {
  const contentScrolled = true;
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    selectTab?.(newValue);
  };
  return (
    <HeadBar backButtonLink={backButtonAction} flexShrink={0} pb={0} zIndex={1} transparent={!contentScrolled} id="content-head-container">
      <Fade in={contentScrolled}>
        <Bx flexShrink={1} flexGrow={1}>
          <Bx height="100%" display="flex" alignItems="center">
            <Ctn>
              <Grid container>
                <Grid md={7} item>
                  <Bx display="flex" alignItems="center" height="100%">
                    <Typo component="span" variant="h2">
                      {working ? <Skeleton animation="wave" style={{ width: '200px' }} /> : title ? title : <Skeleton animation="wave" style={{ width: '200px' }} />}
                    </Typo>
                  </Bx>
                </Grid>
                <Grid md={5} item>
                  <Bx display="flex" justifyContent="flex-end" alignItems="center">
                    {actions &&
                      actions.map((action, idx) => {
                        const { specificType, ...validBtnAction } = action;
                        if (specificType === 'IconBtn')
                          return (
                            <Bx key={idx} mr={2}>
                              <IconBtn disabled={working} {...validBtnAction}>
                                {action.icon}
                              </IconBtn>
                            </Bx>
                          );
                        else if (specificType === 'Btn')
                          return (
                            <Bx key={idx} mr={1} display="flex" justifyContent="center" alignItems="center">
                              <Btn {...validBtnAction} startIcon={action.icon}>
                                {action.title}
                              </Btn>
                            </Bx>
                          );
                        else return <></>;
                      })}
                  </Bx>
                </Grid>
                {!!tabs?.length && (
                  <Grid item>
                    <Bx mt={1.5}>
                      <TabList
                        value={selectedTab}
                        fontVariant="body1"
                        textColor="primary"
                        onChange={handleChange as ((event: React.ChangeEvent<{}>, value: any) => void) & ((event: React.FormEvent<HTMLButtonElement>) => void)}
                      >
                        {tabs.map((t, i) => (
                          <TabItem key={i} disabled={working} label={t} />
                        ))}
                      </TabList>
                    </Bx>
                  </Grid>
                )}
              </Grid>
            </Ctn>
          </Bx>
        </Bx>
      </Fade>
    </HeadBar>
  );
};

export const MobileHeader: React.FC<IHeaderProps> = ({ working, title, actions, tabs, selectedTab, selectTab, backButtonAction }) => {
  // const [value, setValue] = React.useState(0);

  // const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
  //   setValue(newValue);
  // };

  const [open, setOpen] = React.useState<null | string>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement | HTMLButtonElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget.getAttribute('aria-controls'));
    setAnchorEl(event.currentTarget);
  };

  const handleMenu = (menuAction?: (e) => void) => {
    setOpen(null);
    setAnchorEl(null);
    menuAction && menuAction(null);
  };

  // const contentScrolled = true;
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    selectTab?.(newValue);
  };
  return (
    <React.Fragment>
      <MobileHeadBar id="content-head-container">
        <Bx display="flex" height="100%" alignItems="center" flexDirection="column" pt={1}>
          <Bx display="flex" alignItems="center" width="100%">
            <Bx zIndex={2}>
              <Bx pr={1}>
                {backButtonAction && (
                  <IconBtn size="medium" onClick={backButtonAction}>
                    <FontAwesomeIcon icon={faLongArrowLeft} />
                  </IconBtn>
                )}
                {!backButtonAction && (
                  <IconBtn size="medium" to={'/tandems'}>
                    <FontAwesomeIcon icon={faLongArrowLeft} />
                  </IconBtn>
                )}
              </Bx>
            </Bx>
            <Bx display="flex" alignItems="center" height="100%" flexGrow={1} flexShrink={1} overflow="hidden">
              <Typo component="span" variant="h3" noWrap>
                {working ? <Skeleton animation="wave" style={{ width: '200px' }} /> : title ? title : <Skeleton animation="wave" style={{ width: '200px' }} />}
              </Typo>
            </Bx>
            {actions && !!actions.length && (
              <Bx ml="auto" pl={2} flexGrow={0} flexShrink={0} flexBasis="auto">
                <Bx>
                  <IconBtn size="small" onClick={handleClick} color="default" variant="text" aria-controls="more" aria-haspopup="true">
                    <FontAwesomeIcon icon={faEllipsisV} />
                  </IconBtn>
                </Bx>
              </Bx>
            )}
          </Bx>
          {!!tabs?.length && (
            <Bx display="flex" alignItems="center" width="100%" mt={1}>
              <TabList
                value={selectedTab}
                fontVariant="body1"
                textColor="primary"
                variant="scrollable"
                onChange={handleChange as ((event: React.ChangeEvent<{}>, value: any) => void) & ((event: React.FormEvent<HTMLButtonElement>) => void)}
              >
                {tabs.map((tab, idx) => (
                  <TabItem key={idx} disabled={working} label={tab} ml={idx ? undefined : 1} />
                ))}
              </TabList>
            </Bx>
          )}
        </Bx>
      </MobileHeadBar>

      <DropDownMenu
        id={open ? open : undefined}
        elevation={3}
        getContentAnchorEl={null}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={anchorEl}
        onClose={() => setOpen(null)}
        MenuListProps={{ component: 'div' }}
        open={open !== null}
        borderRadius="borderRadius"

        // keepMounted
      >
        <Bx bgcolor="primary.main" color="primary.contrastText">
          {actions &&
            actions.map((action, idx) => (
              <MenuItem key={idx} onClick={() => handleMenu(action.onClick)}>
                {action.title}
              </MenuItem>
            ))}
        </Bx>
      </DropDownMenu>
    </React.Fragment>
  );
};

export interface Tab {
  type: 'messages' | 'files' | 'view';
  title: string;
  alias: string;
}
export interface CommunicationTab extends Tab {
  request?: GetMessagesRequest;
}

export interface ICommunicationAction extends IIconBtnProps {
  icon: JSX.Element;
  specificType: 'IconBtn' | 'Btn';
}

export interface ICommunicationHeaderProps {
  working?: boolean;
  title?: string;

  tabs: Tab[];
  selectedTab: Tab;
  setSelectedTab: (tab: Tab) => void;

  openInfo?: () => void;
  openSettings?: () => void;
  openAddParticipants?: () => void;
  allowConferencing?: boolean;
  onConferenceClick?: () => void;
}
export const CommunicationHeader: React.FC<ICommunicationHeaderProps> = ({
  allowConferencing,
  onConferenceClick,
  working,
  title,
  tabs,
  selectedTab,
  setSelectedTab,
  openInfo,
  openSettings,
  openAddParticipants
}) => {
  const communicationActions: Array<ICommunicationAction> = [];
  if (openAddParticipants) {
    communicationActions.push({
      specificType: 'IconBtn',
      onClick: () => openAddParticipants(),
      icon: <FontAwesomeIcon icon={faUserPlus} />,
      title: 'Teilnehmer einladen',
      size: 'small'
    });
  }
  if (openSettings) {
    communicationActions.push({
      specificType: 'IconBtn',
      onClick: () => openSettings(),
      icon: <FontAwesomeIcon icon={faCog} />,
      title: 'Einstellungen',
      size: 'small'
    });
  }
  if (openInfo) {
    communicationActions.push({
      specificType: 'IconBtn',
      onClick: () => openInfo(),
      icon: <FontAwesomeIcon icon={faInfo} />,
      title: 'Informationen',
      size: 'small'
    });
  }
  if (allowConferencing && onConferenceClick) {
    if (!openInfo) {
      communicationActions.push({
        specificType: 'IconBtn',
        onClick: () => onConferenceClick(),
        icon: <FontAwesomeIcon icon={faCameraHome} />,
        title: 'Videokonferenz starten',
        variant: 'contained',
        color: 'secondary'
      });
    } else {
      communicationActions.push({
        specificType: 'Btn',
        onClick: () => onConferenceClick(),
        icon: <FontAwesomeIcon icon={faPlus} />,
        title: 'Meeting erstellen',
        variant: 'contained',
        color: 'secondary'
      });
    }
  }

  const HeaderProps: IHeaderProps = {
    working: working,
    title: title,
    actions: communicationActions,
    selectTab: index => setSelectedTab(tabs[index]),
    selectedTab: tabs.indexOf(selectedTab),
    tabs: tabs.map(t => t.title)
  };

  return <ConnectedHeader {...HeaderProps} />;
};

export const ConnectedHeader: React.FC<IHeaderProps> = props => {
  const mobile = useIsMobile();

  if (mobile) return <MobileHeader {...props} />;
  else return <Header {...props} />;
};
