import { Bx } from '@curry-group/mui-curcuma';
import { faBorderAll, faComment, faUserPlus } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { ConferencingButton } from '../button';

export interface IConferencingControlsRight {
  gridview?: boolean;
  chatVisible?: boolean;
  onGridviewChange?: (gridview: boolean) => void;
  onChatVisibleChange?: (visible: boolean) => void;
}

export const ConferencingControlsRight: React.FC<IConferencingControlsRight> = ({ gridview, chatVisible, onGridviewChange, onChatVisibleChange }) => {
  return (
    <Bx display="flex" width="174px" justifyContent="flex-end">
      <Bx ml={2} display="none">
        <ConferencingButton icon={faUserPlus} tooltip="Teilnehmer hinzufügen" active />
      </Bx>
      <Bx ml={2} display="none">
        <ConferencingButton
          icon={faBorderAll}
          active={gridview}
          tooltip={gridview ? 'Kachelansicht schließen' : 'Kachelansicht öffnen'}
          onClick={() => {
            onGridviewChange?.(!gridview);
          }}
        />
      </Bx>
      <Bx ml={2}>
        <ConferencingButton
          icon={faComment}
          active={chatVisible}
          onClick={() => {
            onChatVisibleChange?.(!chatVisible);
          }}
          tooltip={chatVisible ? 'Chat ausblenden' : 'Chat einblenden'}
        />
      </Bx>
    </Bx>
  );
};
