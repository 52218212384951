import { useLocation, useHistory } from 'react-router-dom';
import { Btn, Bx, IconBtn, Typo } from '@curry-group/mui-curcuma';
import { Wizard, WizardContent, WizardNavigation } from '@curry-group/react-wizard';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Hidden } from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import {  IRegistrationRequestData, TProfileType } from '../../../model/data/registration';
import { Header, Content, Footer, Sidebar } from '../../../components/frame';
import { useIsMobile } from '../../../helper/hooks/useIsMobile';
import { useDispatch, useSelector } from 'react-redux';
import {  resetRegistration, sendRegistrationRequest } from '../../../data/reducer/registration';
import dayjs from 'dayjs';

import { RegistrationTypeSelect } from './step-two/type-select';
import { RegistrationInfo } from './step-two/info';
import { RegistrationAddress } from './step-two/address';
import { RegistrationFinished } from './registration-finished';
import { RegistrationDataProtection } from './step-two/data-protection';
import { RegistrationCertificate } from './step-two/certificate';
import { RegistrationGuardian } from './step-two/guardian';
import { useForceUpdate } from '../../../helper/hooks/useForceUpdate';
import { setRegistrationRequestData } from '../../../data/reducer/registrationRequest';
import { MobileHeadBar } from '../../../components/mobile-head-bar';
import { BottomBar } from '../../../components/bottom-bar';
import { HeadBar } from '../../../components/head-bar';
import { NavBarLogo } from '../../../components/navbar-logo';
import { SplashSideBar } from '../../../components/splash-sidebar';


enum RegistrationSteps {
  TYPE = 1,
  INFO = TYPE << 2,
  ADDRESS = INFO << 2,
  GUARDIAN = ADDRESS << 2,
  DATAPROTECTION = GUARDIAN << 2,
  CERTIFICATE = DATAPROTECTION << 2,
  FINISHED = CERTIFICATE << 2
}


export interface IBaseRegistrationStepProps {
  onDataValidation: (valid: boolean, data?: Partial<IRegistrationRequestData>) => void;
}

export const RegistrationView: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const {registrationRequestData} = useSelector(state => state.registrationRequest);
  
  const defaultSteps = RegistrationSteps.TYPE + 
  RegistrationSteps.INFO + 
  RegistrationSteps.ADDRESS + 
  RegistrationSteps.DATAPROTECTION + 
  RegistrationSteps.FINISHED;

  const [numSteps, setNumSteps] = useState(defaultSteps);
  const [lastStep, setLastStep] = useState(RegistrationSteps.DATAPROTECTION);
  const [currentStep, setCurrentStep] = useState(RegistrationSteps.TYPE);
  const [dataSend, setDataSend] = useState(false);
  const [token, setToken] = useState('');
  const [valid, setValid] = useState(false);
  const mobile = useIsMobile();

  if(!token){
    dispatch(setRegistrationRequestData({token: new URLSearchParams(location.search).get('token') || ''}));
    setToken(new URLSearchParams(location.search).get('token') || '');
  }

  useEffect(() => {
    if(dataSend){
      dispatch(resetRegistration());
      if (!registrationRequestData.token) {
        history.replace('/');
      }
    }
  }, [dataSend]);

  function prevStep() {
    if (currentStep > RegistrationSteps.TYPE) {
      let prev = currentStep >> 2;
      if(prev & numSteps) setCurrentStep(currentStep >> 2);
      else setCurrentStep(prev >> 2);
    } else {
      history.replace('/');
    }
  }
  function nextStep() {
    if (currentStep < lastStep) {
      let next = currentStep << 2;
      if(next & numSteps) setCurrentStep(currentStep << 2);
      else setCurrentStep(next << 2);
    } else if (currentStep === lastStep) {
      dispatch(sendRegistrationRequest(registrationRequestData));
      setDataSend(true);
      setCurrentStep(RegistrationSteps.FINISHED);
    }
    setValid(false);
  }

  function setNumStepsConditional(type: TProfileType | undefined, dateOfBirth: number){
    let steps = numSteps;
    const over15 = dayjs().add(-15, 'y').unix() > dateOfBirth;
    const under18 = dayjs().add(-18, 'y').unix() < dateOfBirth;
    if(over15 && registrationRequestData.type === 'tutor' && !(numSteps & RegistrationSteps.CERTIFICATE)){
      steps += RegistrationSteps.CERTIFICATE;
      setLastStep(RegistrationSteps.CERTIFICATE);
    }else if(numSteps & RegistrationSteps.CERTIFICATE){
      steps -= RegistrationSteps.CERTIFICATE;
      setLastStep(RegistrationSteps.DATAPROTECTION);
    }
    if(under18 && !(numSteps & RegistrationSteps.GUARDIAN)){
      steps += RegistrationSteps.GUARDIAN;
    }else if(numSteps & RegistrationSteps.GUARDIAN){
      steps -= RegistrationSteps.GUARDIAN;
    }
    setNumSteps(steps);
  }

  const onDataValidation = useCallback((valid: boolean, data?: Partial<IRegistrationRequestData>) => {
    setValid(valid);
    if(data){
      dispatch(setRegistrationRequestData(data));
    }
  }, [])

  function onDateOfBirthSet(date: number) {
    setNumStepsConditional(registrationRequestData.type, date || 0);
  }

  function onTypeSelected(type: TProfileType) {
    dispatch(setRegistrationRequestData({type: type}));
    setNumStepsConditional(type, registrationRequestData.dateOfBirth);
    nextStep();
  }

  function setDataProtectionAccepted(accepted: boolean) {
    dispatch(setRegistrationRequestData({dataProtectionAccepted: accepted }));
    if (accepted) setValid(true);
  }
  function onDataProtectionFileUploaded(filename?: string) {
    dispatch(setRegistrationRequestData({dataProtectionFileId: filename || undefined }));
    setValid(!!filename);
  }

  function onCertificationFileUploaded(filename?: string){
    dispatch(setRegistrationRequestData({certificateOfConductFileId: filename || undefined }));
    setValid(!!filename);
  }

  return (
    <>
      <Hidden mdDown>
        <Sidebar>
          <SplashSideBar src="/images/register.svg" alt="Anmeldung">
            <Bx display="flex" width="100%" alignItems="center" justifyContent="space-between">
              <Bx display="flex" justifyContent={'flex-start'} alignItems="center" height="100%">
                <NavBarLogo to="/" src="/images/logo.svg" alt="Schüler Connect Online" />
              </Bx>
            </Bx>
          </SplashSideBar>
        </Sidebar>
      </Hidden>
      <Wizard step={currentStep} stepCount={numSteps}>
        {currentStep < RegistrationSteps.FINISHED && (
          <Header>
            {mobile && (
              <MobileHeadBar>
                <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" py={1}>
                  <Grid item xs={3}>
                    <Bx display="flex" pr={1} alignItems="center" justifyContent="flex-start" height="100%">
                      <IconBtn size="medium" onClick={prevStep}>
                        <FontAwesomeIcon icon={faLongArrowLeft} />
                      </IconBtn>
                    </Bx>
                  </Grid>
                  <Grid item xs={6}>
                    <Bx display="flex" alignItems="center" justifyContent="center" height="100%">
                      <NavBarLogo to="/" src="/images/logo_dark.svg" />
                    </Bx>
                  </Grid>
                </Bx>
              </MobileHeadBar>
            )}

            {!mobile && <HeadBar transparent={false} backButtonLink={prevStep}></HeadBar>}
          </Header>
        )}
        <Content>
          <WizardContent>
            {(callbacks, options) => {
              return (
                <React.Fragment key={`registration_${options?.currentStep}`}>
                  {((RegistrationSteps.TYPE & numSteps) === RegistrationSteps.TYPE && 
                  options.currentStep === RegistrationSteps.TYPE) && (
                  <RegistrationTypeSelect onTypeSelected={onTypeSelected} />)}
                  {(RegistrationSteps.INFO & numSteps && 
                  options.currentStep === RegistrationSteps.INFO) && (
                    <RegistrationInfo
                      onDataValidation={onDataValidation}
                      onDateOfBirthSet={onDateOfBirthSet}
                    />
                  )}
                  {((RegistrationSteps.ADDRESS & numSteps) === RegistrationSteps.ADDRESS && 
                  options.currentStep === RegistrationSteps.ADDRESS) && (
                    <RegistrationAddress
                      onDataValidation={onDataValidation}
                    />
                  )}
                  {(RegistrationSteps.GUARDIAN & numSteps) === RegistrationSteps.GUARDIAN && options.currentStep === RegistrationSteps.GUARDIAN && (
                    <RegistrationGuardian
                      onDataValidation={onDataValidation}
                    />
                  )}
                  {(RegistrationSteps.DATAPROTECTION & numSteps) === RegistrationSteps.DATAPROTECTION  && options.currentStep === RegistrationSteps.DATAPROTECTION && (
                    <RegistrationDataProtection
                      needsFileUpload={dayjs().add(-18, 'y').unix() < registrationRequestData.dateOfBirth}
                      accepted={registrationRequestData.dataProtectionAccepted}
                      onAcceptedChanged={setDataProtectionAccepted}
                      onFileUploaded={onDataProtectionFileUploaded}
                      onDataValidation={onDataValidation}
                    />
                  )}

                  {(RegistrationSteps.CERTIFICATE & numSteps) === RegistrationSteps.CERTIFICATE && options.currentStep === RegistrationSteps.CERTIFICATE && (
                    <RegistrationCertificate 
                    onFileUploaded={onCertificationFileUploaded}
                    onMissingChanged={(missing) => {onDataValidation(missing, {certificateOfConductMissing: missing, certificateOfConductPending: false})}}
                    onPendingChanged={(pending) => {onDataValidation(pending, {certificateOfConductPending: pending, certificateOfConductMissing: false})}}                    
                    />
                  )}

                  {options.currentStep === RegistrationSteps.FINISHED && <RegistrationFinished />}
                </React.Fragment>
              );
            }}
          </WizardContent>
        </Content>
        <Footer>
          <WizardNavigation>
            {(callbacks, options) => {
              const isLastStep = options.currentStep === lastStep;

              // ausgeblendet im ladescreen
              if (options.currentStep === 0) return <></>;

              // ausgeblendet im ladescreen
              if (options.currentStep >= RegistrationSteps.FINISHED) return <></>;

              return (
                <Bx gridArea="footer" zIndex={2} position="sticky" bottom="0">
                  <BottomBar scrolled bottom={'unset'}>
                    <Bx flexGrow={1} flexShrink={1} flexBasis="auto">
                      &nbsp;
                    </Bx>
                    <Bx alignSelf="flex-end" textAlign="right">
                      <Btn
                        size="large"
                        color="success"
                        disabled={!valid}
                        variant="contained"
                        onClick={e => {
                          e.preventDefault();
                          nextStep();
                        }}
                      >
                        <Typo variant="inherit" noWrap>
                          {isLastStep ? 'Abschließen' : 'Ok, weiter'}
                        </Typo>
                      </Btn>
                    </Bx>
                  </BottomBar>
                </Bx>
              );
            }}
          </WizardNavigation>
        </Footer>
      </Wizard>
    </>
  );
};