import { call, put, take, fork, select, cancel, takeLatest, actionChannel } from 'redux-saga/effects';
import { HubConnectionBuilder, HubConnection, LogLevel } from '@microsoft/signalr';
import { EventChannel, eventChannel } from 'redux-saga';
import { MmoaoMessageAction, MmoaoActionTypes, MmoaoParticipationAction } from '../../../model/communication/Actions';

import { userDeauth, userLoginSuccess, userLogoutSuccess } from '../../reducer/app';
import { createAction } from '@reduxjs/toolkit';

import {
  fetchMessageByIdRequest,
  getNumUnreadMessagesByIdRequest,
  fetchCommunicationByIdRequest,
  subscribeToSignalR,
  unsubscribeFromSignalR
} from '../../reducer/communication';

import { DefaultRootState } from 'react-redux';
import api from '../../api';
import { notificationsUpdated } from '../../reducer/notifications';

export const connectionEstablishedSignalRAction = createAction<undefined, 'signalr-connection-established'>('signalr-connection-established');

const messageCreatedSignalRAction = createAction<MmoaoMessageAction, 'signalr-message-created'>('signalr-message-created');
const messageDeletedSignalRAction = createAction<MmoaoMessageAction, 'signalr-message-deleted'>('signalr-message-deleted');
const messageUpdatedSignalRAction = createAction<MmoaoMessageAction, 'signalr-message-updated'>('signalr-message-updated');
const messageVotedSignalRAction = createAction<MmoaoMessageAction, 'signalr-message-voted'>('signalr-message-voted');

function createSignalRConnection() {
  let signalUrl = api.communication.signalr;
  if (localStorage['nodeUrl']) {
    if (localStorage['nodeUrl'].endsWith('/')) {
      signalUrl = (localStorage['nodeUrl'] as string).substr(0, localStorage['nodeUrl'].length - 1) + signalUrl;
    } else {
      signalUrl = localStorage['nodeUrl'] + signalUrl;
    }
  }
  const hubConnBuilder = new HubConnectionBuilder().withAutomaticReconnect({ nextRetryDelayInMilliseconds: () => 5000 }).withUrl(signalUrl);
  if (process.env.NODE_ENV === 'production') {
    hubConnBuilder.configureLogging(LogLevel.Error);
  }
  return hubConnBuilder.build();
}

function createSignalRChannel(hubConn: HubConnection): EventChannel<any> {
  return eventChannel(emit => {
    // message actions
    const handleMessageCreated = (action: MmoaoMessageAction) => {
      emit(messageCreatedSignalRAction(action));
    };
    const handleMessageDeleted = (action: MmoaoMessageAction) => {
      emit(messageDeletedSignalRAction(action));
    };
    const handleMessageUpdated = (action: MmoaoMessageAction) => {
      emit(messageUpdatedSignalRAction(action));
    };
    const handleMessageVoted = (action: MmoaoMessageAction) => {
      emit(messageVotedSignalRAction(action));
    };
    const handleNotificationsUpdated = () => {
      emit(notificationsUpdated());
    };
    const handleConnected = () => {
      emit(connectionEstablishedSignalRAction());
    };
    hubConn.on('reconnect', async () => {
      await hubConn.stop();
      hubConn.start();
    });
    hubConn.on('connected', handleConnected);
    hubConn.on(MmoaoActionTypes.MESSAGE_CREATED, handleMessageCreated);
    hubConn.on(MmoaoActionTypes.MESSAGE_DELETED, handleMessageDeleted);
    hubConn.on(MmoaoActionTypes.MESSAGE_UPDATED, handleMessageUpdated);
    hubConn.on(MmoaoActionTypes.MESSAGE_VOTED, handleMessageVoted);

    hubConn.on('notifications-updated', handleNotificationsUpdated);
    hubConn.start();
    return () => {
      hubConn.off('connected', handleConnected);
      hubConn.off(MmoaoActionTypes.MESSAGE_CREATED, handleMessageCreated);
      hubConn.off(MmoaoActionTypes.MESSAGE_DELETED, handleMessageDeleted);
      hubConn.off(MmoaoActionTypes.MESSAGE_UPDATED, handleMessageUpdated);
      hubConn.off(MmoaoActionTypes.MESSAGE_VOTED, handleMessageVoted);

      hubConn.off('notifications-updated', handleNotificationsUpdated);
      hubConn.stop();
    };
  });
}

function* signalrHandling() {
  try {
    const hubConn = yield call(createSignalRConnection);
    yield fork(outgoingMessages, hubConn);
    const hubChannel = yield call(createSignalRChannel, hubConn);
    try {
      while (true) {
        const action = yield take(hubChannel);
        yield put(action);
      }
    } catch (err) {
      console.log('signalrWatcher err:', err);
    } finally {
      yield hubChannel.close();
    }
  } catch {
    console.error('cannot create signalr connection');
  }
}

function* outgoingMessages(hubConn) {
  try {
    const chan = yield actionChannel([subscribeToSignalR.type, unsubscribeFromSignalR.type]);
    let action;
    while ((action = yield take(chan))) {
      switch (action.type) {
        case subscribeToSignalR.type: {
          hubConn.send('subscribe-to', action.payload.id);
          break;
        }
        case unsubscribeFromSignalR.type: {
          hubConn.send('unsubscribe-from', action.payload.id);
          break;
        }
        case connectionEstablishedSignalRAction.type: {
          const communicationId = yield select(state => state.communication?.id);
          if (communicationId) yield put(subscribeToSignalR({ id: communicationId }));
        }
      }
    }
  } finally {
  }
}

export function* signalrEventHandling() {
  const loggedIn: boolean = yield select((s: DefaultRootState) => s.app.loggedIn);
  let signalrSaga: any;
  if (loggedIn) {
    signalrSaga = yield fork(signalrHandling);
  }
  let action;
  while ((action = yield take([userDeauth.type, userLogoutSuccess.type, userLoginSuccess.type]))) {
    if (signalrSaga) {
      yield cancel(signalrSaga);
    }
    if ([userLoginSuccess.type].includes(action.type)) {
      signalrSaga = yield fork(signalrHandling);
    }
  }
}

export function* signalRWatcher() {
  yield takeLatest(messageCreatedSignalRAction.type, messageCreatedHandler);
  yield takeLatest(messageDeletedSignalRAction.type, messageDeletedHandler);
  yield takeLatest(messageUpdatedSignalRAction.type, messageUpdatedHandler);
  yield takeLatest(messageVotedSignalRAction.type, messageVotedHandler);
}

function* messageCreatedHandler(action: ReturnType<typeof messageCreatedSignalRAction>) {
  const { id } = yield select(state => state.communication);
  if (id === action.payload.parent) {
    yield put(fetchMessageByIdRequest({ new: true, communicationId: action.payload.parent, messageId: action.payload.payload.messageId }));
  }
  yield put(getNumUnreadMessagesByIdRequest({ id: action.payload.parent }));
  yield put(fetchCommunicationByIdRequest({ id: action.payload.parent }));
}

function* messageDeletedHandler(action: ReturnType<typeof messageDeletedSignalRAction>) {
  const { id } = yield select(state => state.communication);
  if (id === action.payload.parent) {
    yield put(fetchMessageByIdRequest({ communicationId: action.payload.parent, messageId: action.payload.payload.messageId }));
  }
  yield put(getNumUnreadMessagesByIdRequest({ id: action.payload.parent }));
}

function* messageUpdatedHandler(action: ReturnType<typeof messageUpdatedSignalRAction>) {
  const { id } = yield select(state => state.communication);
  if (id === action.payload.parent) {
    yield put(fetchMessageByIdRequest({ communicationId: action.payload.parent, messageId: action.payload.payload.messageId }));
  }
  yield put(getNumUnreadMessagesByIdRequest({ id: action.payload.parent }));
}

function* messageVotedHandler(action: ReturnType<typeof messageUpdatedSignalRAction>) {
  const { id } = yield select(state => state.communication);
  if (id === action.payload.parent) {
    yield put(fetchMessageByIdRequest({ communicationId: action.payload.parent, messageId: action.payload.payload.messageId }));
  }
}
