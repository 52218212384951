import { GetMessagesRequest } from '../../../model/communication/Message';
import { ConnectedMessages } from '../communication/messages';
import { ConnectedEmptyContent } from '../../../components/communication/messages';
import { EmptyContent } from '../../../components/communication/empty-content';
import { useDispatch } from 'react-redux';
import { setMessageDraftQuotes, setMessageDraftThreadRoot, setActionCanvas, setMessageDraftEdit, voteMessageRequest } from '../../../data/reducer/communication';
import { FramedContent } from '../../../components/framed-content';

export const ConnectedCommunication: React.FC<{ id: string; alias: string; request?: GetMessagesRequest; inlineThreading?: boolean }> = ({
  id,
  alias,
  request,
  inlineThreading
}) => {
  const dispatch = useDispatch();
  return (
    <FramedContent
      height="100%"
      minHeight="100%"
      innerBoxProps={{
        minHeight: '100%',
        height: '100%'
      }}
    >
      <ConnectedMessages
        renderEmptyContent={
          alias === 'main' ? () => <ConnectedEmptyContent /> : alias === 'threads' ? () => <EmptyContent caption={'Noch kein Thread vorhanden'} /> : undefined
        }
        alias={alias}
        request={request}
        autoWidth
        quoteClicked={message => {
          dispatch(setMessageDraftQuotes({ quotes: message, id: alias }));
        }}
        threadingClicked={message => {
          if (!inlineThreading) {
            // setze dessen für das Input feld im actioncanvas
            dispatch(setMessageDraftThreadRoot({ id: 'actioncanvas', threadRoot: message }));
            // öffne actioncanvas und setze dessen threadRoot
            dispatch(setActionCanvas({ open: true, threadRoot: message }));
          } else {
            // setze threadRoot für main input
            dispatch(setMessageDraftThreadRoot({ id: alias, threadRoot: message }));
          }
        }}
        editMessage={message => {
          dispatch(setMessageDraftEdit({ id: alias, message }));
        }}
        voteDownClick={message => {
          dispatch(voteMessageRequest({ communicationId: id, alias: alias, messageId: message.id, up: false }));
        }}
        voteUpClick={message => {
          dispatch(voteMessageRequest({ communicationId: id, alias: alias, messageId: message.id, up: true }));
        }}
      />
    </FramedContent>
  );
};
