import { callGet, callPost } from '@curry-group/data-addons';
import { takeLatest, all, put } from 'redux-saga/effects';
import api from '../../api';
import { createTandemFailed, createTandemRequest, createTandemSuccess, fetchTandemUsersFailed, fetchTandemUsersRequest, fetchTandemUsersSuccess } from '../../reducer/tandem';

function* watcher() {
  yield takeLatest(fetchTandemUsersRequest.type, fetchTandemUsersWorker);
  yield takeLatest(createTandemRequest.type, createTandemWorker);
}

function* fetchTandemUsersWorker(action: ReturnType<typeof fetchTandemUsersRequest>) {
  try {
    var users = yield callGet(api.urlWithQuery(api.tandem.users, action.payload));
    yield put(fetchTandemUsersSuccess(users));
  } catch {
    yield put(fetchTandemUsersFailed());
  }
}

function* createTandemWorker(action: ReturnType<typeof createTandemRequest>) {
  try {
    var tandem = yield callPost(api.tandem.create, action.payload);
    if (tandem) {
      yield put(createTandemSuccess());
    } else {
      yield put(createTandemFailed());
    }
  } catch {
    yield put(createTandemFailed());
  }
}

export function* tandem() {
  yield all([watcher()]);
}
