import { IconBtn, Bx, Hamburger } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import { HeadBar } from '../head-bar';
import { MobileHeadBar } from '../mobile-head-bar';
// import { useDispatch, useSelector } from 'react-redux';
// import { setAppstateNavbarOpen } from '../../data/reducer/app';
// import { useIsMobile } from '../../helper/hooks/useIsMobile';
import { INavbarOpenProps } from '../navbar';
import { NavBarLogo } from '../navbar-logo';
import { HeaderContainer } from './container';

export const HeaderComponent: React.FC = ({ children }) => {
  return (
    <HeadBar>
      <HeaderContainer>{children}</HeaderContainer>
    </HeadBar>
  );
};

export const MobileHeaderComponent: React.FC<INavbarOpenProps> = ({ open, setOpen }) => {
  return (
    <MobileHeadBar>
      <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" py={1}>
        <Grid item xs={3}>
          <Bx display="flex" pr={1} alignItems="center" justifyContent="flex-start" height="100%">
            <IconBtn onClick={() => setOpen(!open)}>
              <Hamburger width={30} height={30} size={20} toggled={open} label={open ? 'Menü schließen' : 'Menü öffnen'} />
            </IconBtn>
          </Bx>
        </Grid>
        <Grid item xs={6}>
          <Bx display="flex" alignItems="center" justifyContent="center" height="100%">
            <NavBarLogo to="/" src="/images/logo_dark.svg" />
          </Bx>
        </Grid>
        <Grid item xs={3}>
          <Bx display="flex" pl={1} alignItems="center" justifyContent="flex-end" height="100%"></Bx>
        </Grid>
      </Bx>
    </MobileHeadBar>
  );
};
