import { Ctn, ICtnProps, Typo, TabList, TabItem, Card, Divider, UnsortedList, Bx, IBxProps } from '@curry-group/mui-curcuma';
import Grid, { GridProps } from '@material-ui/core/Grid';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/pro-light-svg-icons';

interface ILandingPageTabSection {
  ctnMaxWidth: ICtnProps['maxWidth'];
  innerSectionSpacing: GridProps['spacing'];
  responsive: { isMDUp: boolean };
  sectionPx: IBxProps['px'];
  body4FontSize: number;
}

export const LandingPageTabSection: React.FunctionComponent<ILandingPageTabSection> = ({ ctnMaxWidth, innerSectionSpacing, responsive, sectionPx, body4FontSize }) => {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const textImageSections: Array<{ headline: string; content: { children: React.ReactNode; icon: React.ReactNode }[]; imageSrc: string; imageAlt: string }> = [
    {
      headline: "Mentoring bringt's",
      content: [
        {
          children: <Typo style={{fontSize: body4FontSize}}>Unterstützung beim Verstehen von Lerninhalten</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{fontSize: body4FontSize}}>Gegenseitiges Fördern und Fordern von Mentor*In und Mentee</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{fontSize: body4FontSize}}>Kennenlernen der eigenen Stärken und Schwächen</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{fontSize: body4FontSize}}>Ziele setzen und Wege dahin finden</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{fontSize: body4FontSize}}>Flexibel und praktisch miteinander online kommunizieren</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{fontSize: body4FontSize}}>Soziale und kommunikative Fähigkeiten trainieren</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{fontSize: body4FontSize}}>Keine Fahrzeiten, zeitlich flexibel</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        }
      ],
      imageSrc: '/images/landingpage/mentoring-bringts.svg',
      imageAlt: "Person und Bücherstapel"
    },
    {
      headline: "Für Mentee's",
      content: [
        {
          children: <Typo style={{fontSize: body4FontSize}}>Schüler Connect ist für dich kostenlos</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{fontSize: body4FontSize}}>Ausgewählte Mentor*In speziell für deine Bedürfnisse</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{fontSize: body4FontSize}}>Geschützte Umgebung mit hohem Datenschutz</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{fontSize: body4FontSize}}>Individuelle Hilfe</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{fontSize: body4FontSize}}>Flexibel und praktisch</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        }
      ],
      imageSrc: '/images/landingpage/fuer-mentees.svg',
      imageAlt: "Frau und zwei Bücher"
    },
    {
      headline: 'Für Mentor*Innen',
      content: [
        {
          children: <Typo style={{fontSize: body4FontSize}}>Du wirst für deine Hilfe mit einer Ehrenamtspauschale belohnt</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{fontSize: body4FontSize}}>Team und Führungsfähigkeiten trainieren</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{fontSize: body4FontSize}}>Bestätigung der ehrenamtlichen Tätigkeit</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo style={{fontSize: body4FontSize}}>Du entwickelst neue Fähigkeiten für dein späteres Berufsleben</Typo>,
          icon: (
            <Typo color="primary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        }
      ],
      imageSrc: '/images/landingpage/fuer-mentoren.svg',
      imageAlt: 'Mann am PC'
    }
  ];

  return (
    <Bx component="section" width="100%" px={sectionPx} pb={6} style={{ marginTop: -80 }}>
      <Ctn maxWidth={ctnMaxWidth}>
        <Card>
          <Grid container>
            <Grid item xs={12}>
              <Bx px={6}>
                <TabList
                  onChange={handleChange as ((event: React.ChangeEvent<{}>, value: any) => void) & ((event: React.FormEvent<HTMLButtonElement>) => void)}
                  textColor="primary"
                  fontVariant="h5"
                  value={selectedTab}
                  variant={responsive.isMDUp ? 'fullWidth' : 'scrollable'}
                  scrollButtons="desktop"
                >
                  {textImageSections.map((section, idx) => (
                    <TabItem key={idx} label={section.headline} style={{ height: '80px' }} />
                  ))}
                </TabList>
              </Bx>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Bx p={6} display="flex" alignItems="center">
                <Grid container spacing={innerSectionSpacing} alignItems="center" style={{ minHeight: '350px' }}>
                  <Grid item xs={12} md={3}>
                    <img
                      src={textImageSections[selectedTab].imageSrc}
                      alt={textImageSections[selectedTab].imageAlt}
                      style={{ width: '100%', height: 'auto', maxHeight: '250px' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <UnsortedList items={textImageSections[selectedTab].content} spacing={2} />
                  </Grid>
                </Grid>
              </Bx>
            </Grid>
          </Grid>
        </Card>
      </Ctn>
    </Bx>
  );
};
