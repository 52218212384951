import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { Messages } from '../../../../components/communication/messages';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { GetMessagesRequest, IMessageModel, IMessageReferenceMeeting } from '../../../../model/communication/Message';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  deleteMessageRequest,
  fetchMessagesRequest,
  fetchNewerMessagesRequest,
  fetchOlderMessagesRequest,
  joinConferenceRequest,
  setLastReadTimestampRequest,
  setMessagesScrolledBottom
} from '../../../../data/reducer/communication';
import { IBxProps } from '@curry-group/mui-curcuma';

export interface IConnectedMessagesProps {
  threadingClicked?: (message: IMessageModel) => void;
  voteUpClick?: (message: IMessageModel) => void;
  voteDownClick?: (message: IMessageModel) => void;
  quoteClicked?: (message: IMessageModel) => void;
  threadRoot?: IMessageModel;
  forceInlineThreading?: boolean;
  disableThreading?: boolean;
  autoWidth?: boolean;
  request?: GetMessagesRequest;
  alias: string;
  editMessage?: (message: IMessageModel) => void;
  renderEmptyContent?: () => ReactNode;
  scrollContainer?: boolean;
  p?: IBxProps['p'];
}

export const ConnectedMessages: React.FC<IConnectedMessagesProps> = state => {
  const [atBottom, setAtBottom] = useState(true);
  const location = useLocation();
  const history = useHistory();

  let allowThreading = useSelector(state => state.communication?.communication?.allowThreads && state.communication?.participation?.permissions?.allowThreads);
  if (state.disableThreading) allowThreading = false;
  const allowVoting = useSelector(state => state.communication?.communication?.allowVoting && state.communication?.participation?.permissions?.allowVoting);
  const allowQuotes = useSelector(state => state.communication?.communication?.allowQuotes && state.communication?.participation?.permissions?.allowQuotes);

  let inlineThreading = useSelector(state => state.communication?.communication?.inlineThreading);
  if (state.forceInlineThreading) inlineThreading = true;

  const manageMessagesPermission = useSelector(state => state.communication?.participation?.permissions?.manageMessages);

  let messageWidth = 100;
  const theme: any = useTheme();

  const isXS = useMediaQuery(theme.breakpoints.only('xs'));
  const isSM = useMediaQuery(theme.breakpoints.only('sm'));
  const isMD = useMediaQuery(theme.breakpoints.only('md'));
  const isLG = useMediaQuery(theme.breakpoints.only('lg'));
  const isXL = useMediaQuery(theme.breakpoints.only('xl'));
  if (state.autoWidth) {
    messageWidth = isXS ? 85 : messageWidth;
    messageWidth = isSM ? 80 : messageWidth;
    messageWidth = isMD ? 75 : messageWidth;
    messageWidth = isLG ? 70 : messageWidth;
    messageWidth = isXL ? 60 : messageWidth;
  }

  const id = useSelector(state => state.communication?.id);
  const currentUserId = useSelector(state => state.app.profile?.userId);
  const messages = useSelector(s => s.communication?.messages?.[state.threadRoot?.id || state.alias]);
  const initialMessage = new URLSearchParams(location.search).get('message') || undefined;

  const dispatch = useDispatch();
  const initial = useRef(true);
  useEffect(() => {
    if (id && initial.current) {
      initial.current = false;
      dispatch(
        fetchMessagesRequest({
          reset: true,
          alias: state.alias,
          communicationId: id,
          threadRoot: state.threadRoot?.id,
          numThreadChildren: state.request?.numThreadChildren,
          message: initialMessage
        })
      );
    }
  }, [id, dispatch, state.threadRoot, state.request, state.alias, location, initialMessage]);

  const onMessageRead = useCallback(
    message => {
      id && dispatch(setLastReadTimestampRequest({ id, timestamp: message.createdAt }));
    },
    [id, dispatch]
  );

  function handleMessageReferenceSelected(type: string, data: any) {
    if (type === 'meeting') {
      var { alias } = data as IMessageReferenceMeeting;
      history.push(location.pathname + '/meetings/' + alias);
    }
  }

  return (
    <>
      <Messages
        currentUserId={currentUserId || ''}
        p={state.p}
        scrolledDown={messages?.atBottom}
        setScrolledDown={down => {
          dispatch(setMessagesScrolledBottom({ alias: state.alias, atBottom: down }));
        }}
        scrollContainer={state.scrollContainer}
        renderEmptyContent={state.renderEmptyContent}
        dateDivider={true}
        allowQuotes={allowQuotes}
        quoteClicked={state.quoteClicked}
        allowVoting={allowVoting}
        voteDownClick={state.voteDownClick}
        voteUpClick={state.voteUpClick}
        inlineThreading={inlineThreading}
        messageWidth={messageWidth}
        allowThreading={allowThreading}
        working={messages?.working}
        olderWorking={messages?.olderWorking}
        focusMessage={initialMessage}
        newerWorking={messages?.newerWorking}
        joinConference={message => {
          dispatch(joinConferenceRequest({ message }));
        }}
        fetchOlderMessages={before => {
          if (id) {
            dispatch(
              fetchOlderMessagesRequest({
                before,
                alias: state.alias,
                communicationId: id,
                threadRoot: state.threadRoot?.id,
                numThreadChildren: state.request?.numThreadChildren
              })
            );
          }
        }}
        fetchNewerMessages={since => {
          if (id) {
            dispatch(
              fetchNewerMessagesRequest({
                since,
                alias: state.alias,
                communicationId: id,
                threadRoot: state.threadRoot?.id,
                numThreadChildren: state.request?.numThreadChildren
              })
            );
          }
        }}
        atStart={messages?.atStart}
        atEnd={messages?.atEnd}
        messages={messages?.messages}
        atBottom={atBottom}
        onScrollBottomChange={bottom => {
          setAtBottom(bottom);
        }}
        threadingClicked={state.threadingClicked}
        loadThreadMessages={message => {
          id && dispatch(fetchMessagesRequest({ alias: message.id, communicationId: id, threadRoot: message.id }));
        }}
        manageMessages={manageMessagesPermission}
        editMessage={state.editMessage}
        deleteMessage={message => {
          id && dispatch(deleteMessageRequest({ communicationId: id, id: message.id, deleted: message.deleted }));
        }}
        onMessageRead={onMessageRead}
        onReferenceSelected={handleMessageReferenceSelected}
      />
    </>
  );
};
