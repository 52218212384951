import { useHistory } from 'react-router-dom';
import { Btn, Bx, IconBtn, Typo } from '@curry-group/mui-curcuma';
import { Wizard, WizardContent, WizardNavigation } from '@curry-group/react-wizard';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { Header, Content, Footer } from '../../../../components/frame';
import { useIsMobile } from '../../../../helper/hooks/useIsMobile';
import { useDispatch, useSelector } from 'react-redux';
import { lostPasswordRequest } from '../../../../data/reducer/app';
import { validateEmail } from '../../../../helper';
import { FormControlInput } from '../../../../components/form/control/input';
import { FinishFlow } from '../../../../components/flow/finish';
import { FramedContent } from '../../../../components/framed-content';
import { MobileHeadBar } from '../../../../components/mobile-head-bar';
import { NavBarLogo } from '../../../../components/navbar-logo';
import { HeadBar } from '../../../../components/head-bar';
import { BottomBar } from '../../../../components/bottom-bar';

export const LostPasswordView: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { lostPasswordSuccess, lostPasswordFailed } = useSelector(state => state.app);
  const [lostPasswordDone, setLostPasswordDone] = useState(false);
  const numSteps = 1;
  const [currentStep, setCurrentStep] = useState(0);
  const mobile = useIsMobile();

  const [valid, setValid] = useState(false);
  const [email, setEmail] = useState('');

  function _setEmail(_email: string) {
    setEmail(_email);
    setValid(validateEmail(_email));
  }

  function prevStep() {
    history.replace('/');
  }
  function send() {
    dispatch(lostPasswordRequest({ email }));
    setLostPasswordDone(true);
    setCurrentStep(1);
  }

  return (
    <Wizard step={currentStep} stepCount={numSteps}>
      {currentStep === 0 && (
        <Header>
          {mobile && (
            <MobileHeadBar>
              <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" py={1}>
                <Grid item xs={3}>
                  <Bx display="flex" pr={1} alignItems="center" justifyContent="flex-start" height="100%">
                    <IconBtn size="medium" onClick={prevStep}>
                      <FontAwesomeIcon icon={faLongArrowLeft} />
                    </IconBtn>
                  </Bx>
                </Grid>
                <Grid item xs={6}>
                  <Bx display="flex" alignItems="center" justifyContent="center" height="100%">
                    <NavBarLogo to="/" src="/images/logo_dark.svg" />
                  </Bx>
                </Grid>
              </Bx>
            </MobileHeadBar>
          )}

          {!mobile && <HeadBar transparent={false} backButtonLink={prevStep}></HeadBar>}
        </Header>
      )}
      <Content>
        <WizardContent>
          {(callbacks, options) => {
            return (
              <React.Fragment key={`registration_${options?.currentStep}`}>
                {options.currentStep === 0 && (
                  <FramedContent>
                    <Bx mb={1}>
                      <Typo variant="h1" component="h1">
                        Passwort vergessen?
                      </Typo>
                    </Bx>
                    <Bx>
                      <Typo variant="subtitle1">Bitte geben Sie die E-Mail-Adresse an, die Sie zur Anmeldung verwenden.</Typo>
                    </Bx>
                    <Bx my={8}>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Grid container spacing={4}>
                            <Grid item xs={12} lg={6}>
                              <Bx mb={2}>
                                <FormControlInput
                                  value={email}
                                  label={'E-Mail-Adresse'}
                                  placeholder={'E-Mail-Adresse eingeben'}
                                  minLength={0}
                                  maxLength={0}
                                  onChange={val => {
                                    _setEmail(val);
                                  }}
                                  type={'email'}
                                  validator={value => validateEmail(value)}
                                  fieldGroup={'resetPassData'}
                                  successMessage=""
                                  invalidMessage={'Ihre Email muss ein gültiges Format haben!'}
                                  lazy
                                />
                              </Bx>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Bx>
                  </FramedContent>
                )}

                {options.currentStep === 1 && (
                  <>
                    {lostPasswordDone && (
                      <FinishFlow
                        timeout={3000}
                        onTimeout={() => {
                          setLostPasswordDone(false);
                        }}
                        headline="Ihr Passwort wird zurückgesetzt."
                      />
                    )}

                    {lostPasswordSuccess && (
                      <FinishFlow
                        hideLoader={true}
                        timeout={5000}
                        onTimeout={() => {
                          history.replace('/');
                        }}
                        headline="Passwort ändern Mail wurde verschickt."
                        text="Folgen Sie dem Link in der Mail um ein neues Passwort zu vergeben. Sie werden in Kürze weitergeleitet."
                      />
                    )}

                    {lostPasswordFailed && (
                      <FinishFlow
                        hideLoader={true}
                        timeout={10000}
                        onTimeout={() => {
                          history.replace('/');
                        }}
                        headline="Fehler beim Zurücksetzen Ihres Passworts."
                        text="Beim Zurücksetzen Ihres Passworts ist ein Fehler aufgetreten. Sie werden in Kürze weitergeleitet."
                      />
                    )}
                  </>
                )}
              </React.Fragment>
            );
          }}
        </WizardContent>
      </Content>
      <Footer>
        <WizardNavigation>
          {(callbacks, options) => {
            // ausgeblendet im ladescreen
            if (options.currentStep === 1) return <></>;

            return (
              <Bx gridArea="footer" zIndex={2} position="sticky" bottom="0">
                <BottomBar scrolled bottom={'unset'}>
                  <Bx flexGrow={1} flexShrink={1} flexBasis="auto">
                    &nbsp;
                  </Bx>
                  <Bx alignSelf="flex-end" textAlign="right">
                    <Btn
                      size="large"
                      color="success"
                      disabled={!valid}
                      variant="contained"
                      onClick={e => {
                        e.preventDefault();
                        send();
                      }}
                    >
                      <Typo variant="inherit" noWrap>
                        Passwort zurücksetzen
                      </Typo>
                    </Btn>
                  </Bx>
                </BottomBar>
              </Bx>
            );
          }}
        </WizardNavigation>
      </Footer>
    </Wizard>
  );
};
