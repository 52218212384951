import React from 'react';
import { Bx, ClickableCard, Typo } from '@curry-group/mui-curcuma';
import { TProfileType } from '../../../../../model/data/registration';
import { Grid } from '@material-ui/core';
import { FramedContent } from '../../../../../components/framed-content';

export interface IRegistrationTypeSelectProps {
  onTypeSelected: (type: TProfileType) => void;
}

export const RegistrationTypeSelect: React.FC<IRegistrationTypeSelectProps> = ({ onTypeSelected }) => {
  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Registrierung auf Schüler Connect Online
        </Typo>
      </Bx>
      <Typo variant="subtitle1">Welche Art von Teilnehmer sind sie?</Typo>
      <Bx my={8}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard variant="outlined" fluid={{ from: 278, to: 458 }} height="100%" to="#" onClick={() => onTypeSelected('student')}>
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/landingpage/fuer-mentees.svg" alt="Schüler" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Schüler
                  </Typo>
                  {/* <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Lorem ipsum
                    </Typo>
                  </Bx> */}
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard variant="outlined" fluid={{ from: 278, to: 458 }} height="100%" to="#" onClick={() => onTypeSelected('tutor')}>
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/landingpage/fuer-mentoren.svg" alt="Tutor" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Tutor
                  </Typo>
                  {/* <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Lorem ipsum
                    </Typo>
                  </Bx> */}
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
        </Grid>
      </Bx>
    </FramedContent>
  );
};
