import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton/IconButton';
import * as React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { handleUnit, IIndicatorProps, Indicator, isExternalUrl, numberToMs } from '@curry-group/mui-curcuma';

export interface INavBarIconBtnProps extends IconButtonProps {
  badgeContent?: IIndicatorProps['badgeContent'];
  badgeVariant?: IIndicatorProps['variant'];
  badgeColor?: IIndicatorProps['color'];
  /**
   * Defines the where to redirect after click
   */
  to?: string;
  /**
   * Defines the window target which will be used if `to` is not empty
   */
  target?: '_blank' | '_self';
  /**
   * Defines if the Element should display as active
   */
  active?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: 44,
    height: 44,
    fontSize: theme.typography.pxToRem(20),
    position: 'relative',
    color: (props: INavBarIconBtnProps) => (props.active ? theme.palette.primary.main : 'inherit'),
    cursor: (props: INavBarIconBtnProps) => (props.active ? 'default' : 'pointer'),
    '&:hover:not(:active)': {
      backgroundColor: (props: INavBarIconBtnProps) => (props.active ? 'transparent' : theme.palette.primary.dark)
    },
    '& .MuiTouchRipple-child': {
      backgroundColor: theme?.palette?.accent?.light
    },
    '& .MuiIconButton-label': {
      zIndex: 1
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: `calc((${handleUnit(64)} - ${handleUnit(44)}) / 2 * -1)`,
      width: `calc(${handleUnit(64)} - ${handleUnit(theme.spacing(0.5))})`,
      height: '100%',
      backgroundColor: theme?.palette?.accent?.main,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      zIndex: 0,
      opacity: (props: INavBarIconBtnProps) => (props.active ? 1 : 0),
      transition: `opacity ${numberToMs(theme.transitions.duration.shorter)} ${theme.transitions.easing.easeInOut}`
    }
  }
}));

/**
 * The `NavBarIconBtn` component is an extension of the [Material-UI Button](https://material-ui.com/components/box/) and inherits all properties of it.
 * It also makes use of [Material-UI Badge](https://material-ui.com/components/badge/) and therefore accepts the property `badgeContent` to display a Badge with an number inside.
 */
export const NavBarIconBtn: React.FunctionComponent<INavBarIconBtnProps> = ({ to, target, badgeContent, badgeVariant, badgeColor, active, children, className, ...rest }) => {
  const classes = useStyles({ active });

  const base = (
    <IconButton
      component={to && !isExternalUrl(to) ? (Link as any) : 'a'}
      to={isExternalUrl(to) ? undefined : to}
      target={target}
      href={isExternalUrl(to) ? to : undefined}
      className={clsx(classes.root, className)}
      disableRipple={active}
      {...rest}
    >
      {children}
    </IconButton>
  );

  const badged = (
    <React.Fragment>
      {badgeContent === 0 ? (
        base
      ) : (
        <Indicator badgeContent={badgeContent} color={badgeColor} variant={badgeVariant} overlap="circle">
          {base}
        </Indicator>
      )}
    </React.Fragment>
  );

  return badged;
};
