import * as React from 'react';
import { rgba } from 'polished';
import clsx from 'clsx';
import { Ava, buildAbbreviation, Bx, IBxProps, IconBtn, Typo } from '@curry-group/mui-curcuma';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons';

import { ConferenceRaisedHand } from '../raised-hand';

import { conferenceTheme } from '../../theme';

export interface IConferenceParticipantProps extends IBxProps {
  name?: string;
  stream?: React.ReactChild;
  audioActive?: boolean;
  videoActive?: boolean;
  speaking?: boolean;
  self?: boolean;
  raisedHand?: boolean;
  latency?: number;
  menu?: React.ReactChild;
  menuOnClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  menuOpen?: boolean;
  menuButtonProps?: {
    size?: number;
  };
}

export interface IConferenceParticipantThemeExtension {
  conferenceParticipant: {
    paddingTop?: React.CSSProperties['paddingTop'];
    paddingTopSelf?: React.CSSProperties['paddingTop'];
    borderRadius?: React.CSSProperties['borderTop'];
    boxShadow?: React.CSSProperties['boxShadow'];
    boxShadowSelf?: React.CSSProperties['boxShadow'];
    noStreamBackgroundColor?: React.CSSProperties['backgroundColor'];
    avaBackgroundColor?: React.CSSProperties['backgroundColor'];
    avaColor?: React.CSSProperties['color'];
    menuBtnBackgroundColor?: React.CSSProperties['backgroundColor'];
    menuBtnColor?: React.CSSProperties['color'];
    nameColor?: React.CSSProperties['color'];
  };
}
interface IConferenceParticipantStyle {
  menuButtonSize: number;
}

function stripUnit(val: string | number): number {
  if (typeof val === 'number') {
    return val;
  }

  if (typeof val === 'string') {
    val.replace('px', '').replace('%', '').replace('em', '').replace('rem', '');
    return Number(val);
  } else {
    return Number(val);
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%',
    boxShadow: conferenceTheme.conferenceParticipant?.boxShadow
  },
  noVideoSignal: {
    opacity: 0,
    visibility: 'hidden'
  },
  center: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  rootNoStream: {
    backgroundColor: conferenceTheme.conferenceParticipant?.noStreamBackgroundColor
  },
  speaking: { boxShadow: `inset 0 0 0 3px ${theme?.palette?.accent?.main}, 2px 2px 4px 0 rgba(0,0,0,0.15)` },
  stream: {
    overflow: 'hidden',
    backgroundColor: '#000',

    '& video': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  },
  content: {
    transition: theme.transitions.create('opacity', { duration: theme.transitions.duration.shorter, easing: theme.transitions.easing.easeInOut }),
    opacity: 0,
    backgroundColor: conferenceTheme.conferenceParticipant?.noStreamBackgroundColor ? rgba(conferenceTheme.conferenceParticipant?.noStreamBackgroundColor, 0.9) : undefined,
    '&:hover': {
      opacity: 1
    }
  },
  contentMenuOpen: {
    opacity: 1
  },
  name: {
    color: conferenceTheme.conferenceParticipant?.nameColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  avaContainer: {},
  ava: {
    backgroundColor: conferenceTheme.conferenceParticipant?.avaBackgroundColor,
    color: conferenceTheme.conferenceParticipant?.avaColor,
    border: 0
  },
  raisedHand: {
    position: 'absolute',
    zIndex: 3,
    bottom: theme.spacing(1),
    left: `calc(50% - ${stripUnit(conferenceTheme.conferenceRaisedHand?.width ?? 0) / 2}px)`
  },
  menu: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    padding: theme.spacing(1)
  },
  iconBtn: {
    width: (props: IConferenceParticipantStyle) => props.menuButtonSize,
    height: (props: IConferenceParticipantStyle) => props.menuButtonSize,
    fontSize: (props: IConferenceParticipantStyle) => props.menuButtonSize * 0.75,
    backgroundColor: conferenceTheme.conferenceParticipant?.menuBtnBackgroundColor ? rgba(conferenceTheme.conferenceParticipant?.menuBtnBackgroundColor, 0.5) : undefined,
    color: conferenceTheme.conferenceParticipant?.menuBtnColor,
    '&:hover': {
      backgroundColor: conferenceTheme.conferenceParticipant?.menuBtnBackgroundColor
    }
  }
}));

export const ConferenceParticipant: React.FunctionComponent<IConferenceParticipantProps> = ({
  name,
  stream,
  videoActive,
  audioActive,
  speaking,
  self,
  raisedHand,
  latency,
  menu,
  menuOnClick,
  menuOpen,
  menuButtonProps,
  children,
  className,
  ...rest
}) => {
  const classes = useStyles({ menuButtonSize: menuButtonProps?.size || 32 });
  return (
    <Bx className={clsx(classes.root, (!stream || !videoActive) && classes.rootNoStream, !videoActive && speaking && classes.speaking, className)} {...rest}>
      {stream && (
        <Bx className={clsx(classes.stream, classes.center, !videoActive && classes.noVideoSignal)}>
          <>
            {stream}
            {speaking && <Bx width="100%" height="100%" className={clsx(classes.speaking, classes.center)} />}
          </>
        </Bx>
      )}
      {(!stream || !videoActive) && (
        <Bx display="flex" justifyContent="center" alignItems="center" className={clsx(classes.avaContainer, classes.center)}>
          <Ava size="large" className={clsx(classes.ava)}>
            {buildAbbreviation(name ?? '')}
          </Ava>
        </Bx>
      )}

      <ConferenceRaisedHand active={raisedHand} className={classes.raisedHand} />

      <Bx className={clsx(classes.content, classes.center, menuOpen && classes.contentMenuOpen)}>
        <Typo variant="body1" className={clsx(classes.name, classes.center)}>
          {name}
        </Typo>
        {!self && menuOnClick && menu && (
          <div className={classes.menu}>
            <IconBtn onClick={menuOnClick} className={classes.iconBtn}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </IconBtn>
            {menu}
          </div>
        )}
      </Bx>
    </Bx>
  );
};

ConferenceParticipant.defaultProps = {
  videoActive: true
};
