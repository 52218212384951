import React, { PropsWithChildren, ReactChild, ReactNode } from 'react';

import { Bx, ListWrapper } from '@curry-group/mui-curcuma';
import { HeadlineContainer } from '../headline-container';

export interface IItemRenderer<T> {
  renderItem: (item: T, index: number) => ReactNode;
}

export interface IHasItems<T> {
  items?: T[];
}

export interface IListProps<T> extends IItemRenderer<T>, IHasItems<T> {
  dividerMargin?: number;
  top?: ReactNode;
}
export function List<T>({ top, children, items, renderItem, dividerMargin }: PropsWithChildren<IListProps<T>>) {
  if (!items || !items.length) {
    return (
      <ListWrapper divider="bottom" dividerMargin={dividerMargin}>
        {top}
        {children}
      </ListWrapper>
    );
  }
  return (
    <ListWrapper divider="bottom" dividerMargin={dividerMargin}>
      {top}
      {items?.map(renderItem)}
    </ListWrapper>
  );
}

export interface IListWithHeadlineProps<T> extends IListProps<T> {
  headline?: string;
  endAdornment?: ReactChild;
  top?: ReactChild;
}

export function ListWithHeadline<T>({ children, headline, endAdornment, items, renderItem, top }: PropsWithChildren<IListWithHeadlineProps<T>>) {
  return (
    <>
      {headline && <HeadlineContainer mb={2} headline={headline} headlineVariant="h3" hideUnderline endAdornment={endAdornment} />}
      {/* <Bx mb={6}> */}
      <Bx mb={1}>
        <List<T> top={top} items={items} renderItem={renderItem}>
          {children}
        </List>
      </Bx>
    </>
  );
}
