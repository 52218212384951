import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FinishFlow } from '../../../../../components/flow/finish';

export const RegistrationFinalizeFinished: React.FC = () => {
  const history = useHistory();
  const [registrationFinalized, setRegistrationFinalized] = useState(false);
  const { registrationFinalizeSuccess, registrationFinalizeFailed } = useSelector(state => state.registration);

  if (!registrationFinalized) {
    return (
      <FinishFlow
        timeout={3000}
        onTimeout={() => {
          setRegistrationFinalized(true);
        }}
        headline="Die Registrierung wird abgeschlossen. Ihre Daten werden sicher übermittelt."
      />
    );
  }

  if (registrationFinalizeSuccess) {
    return (
      <FinishFlow
        hideLoader={true}
        timeout={5000}
        onTimeout={() => {
          history.push('/login');
        }}
        headline="Registrierung abgeschlossen."
        text="Die Registrierung war erfolgreich. Sie werden in Kürze weitergeleitet."
      />
    );
  }

  if (registrationFinalizeFailed) {
    return (
      <FinishFlow
        hideLoader={true}
        timeout={10000}
        onTimeout={() => {
          history.push('/');
        }}
        headline="Fehler beim Abschließen der Registrierung."
        text="Leider ist ein Fehler beim Abschließen der Registrierung aufgetreten. Bitte versuchen Sie es später erneut. Sie werden in Kürze weitergeleitet."
      />
    );
  }

  return <></>;
};
