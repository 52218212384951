import { faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment } from '@material-ui/core';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl/FormControl';
import OutlinedInput, { OutlinedInputProps } from '@material-ui/core/OutlinedInput/OutlinedInput';
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel/InputLabel';
import * as React from 'react';
import { hideVisually } from 'polished';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import _ from 'lodash';
import { GenerateRandomString, IconBtn, responsive, useBreakpoints } from '@curry-group/mui-curcuma';

export interface IHeadSearchInputProps extends React.HTMLAttributes<HTMLDivElement> {
  formControl?: FormControlProps;
  input?: OutlinedInputProps;
  inputLabel?: InputLabelProps;
  inputId?: string;
  clearOnClick?: (e: React.MouseEvent) => void;
  adornmentPosition?: 'start' | 'end';
}

interface IHeadSearchInputStyleProps {
  isMobile?: boolean;
  adornmentPosition?: IHeadSearchInputProps['adornmentPosition'];
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%'
  },
  label: {
    ...hideVisually()
  },
  input: {
    width: '100%',
    height: '100%',
    ...responsive(theme, [{ from: 18, to: 24, attribute: 'font-size' }]),
    '& > .MuiOutlinedInput-notchedOutline': {
      border: 0
    },
    '& > .MuiOutlinedInput-input': {
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: 0,
      height: 'auto'
    }
  },
  inputAdornmentStart: {
    paddingLeft: 0
  },
  inputAdornmentEnd: {
    paddingRight: 0
  },
  formControl: {
    width: '100%',
    height: '100%'
  },
  iconButton: {
    ...responsive(theme, [
      { from: 30, to: 48, attribute: 'width' },
      { from: 30, to: 48, attribute: 'height' }
    ])
  },
  inputAdornment: {
    marginRight: (props: IHeadSearchInputStyleProps) => (props.adornmentPosition === 'start' ? (props.isMobile ? theme.spacing(1) : theme.spacing(4)) : undefined),
    marginLeft: (props: IHeadSearchInputStyleProps) => (props.adornmentPosition === 'end' ? (props.isMobile ? theme.spacing(1) : theme.spacing(4)) : undefined)
  },
  inputAdornmentSearch: {
    position: 'relative',
    height: '100%',
    '&:after': {
      content: '""',
      position: 'absolute',
      right: theme.spacing(-1.5),
      top: 0,
      width: 1,
      height: '100%',
      backgroundColor: 'transparent'
    }
  },
  inputAdornmentClear: {
    cursor: 'pointer'
  }
}));

/**
 * The `HeadSearchInput` component is a composed component which inherits the following Material-UI components / APIs:<br>
 * - [FormControl](https://material-ui.com/api/form-control/#formcontrol-api)<br>
 * - [Input](https://material-ui.com/api/input/)<br>
 * - [InputLabel](https://material-ui.com/api/input-label/#inputlabel-api)
 * 
 * **Theme-Extension:**<br />
 * ```
 interface IHeadSearchInputThemeExtension {
  headSearchInput: {
    inputAdornmentBorderColor: string;
  };
}
 */
export const HeadSearchInput: React.FunctionComponent<IHeadSearchInputProps> = ({
  formControl,
  input,
  inputLabel,
  inputId,
  clearOnClick,
  adornmentPosition,
  className,
  ...rest
}) => {
  const isMobile = useBreakpoints().mdDown;
  const classes = useStyles({ isMobile, adornmentPosition });
  const inputRef = React.useRef<HTMLInputElement>(null);
  const generatedId = GenerateRandomString(32);

  const SearchAdornment = (
    //
    <InputAdornment position="start" className={clsx(classes.inputAdornment, classes.inputAdornmentSearch)} onClick={() => inputRef?.current?.focus()}>
      <IconBtn className={classes.iconButton} aria-label={input?.placeholder || 'Suchen'}>
        <FontAwesomeIcon icon={faSearch} />
      </IconBtn>
    </InputAdornment>
  );

  const ClearAdornment = (
    <InputAdornment
      position="start"
      onClick={e => {
        clearOnClick && clearOnClick(e);
        if (inputRef.current) {
          inputRef.current.value = null as any;
        }
      }}
      className={clsx(classes.inputAdornment, classes.inputAdornmentClear)}
    >
      <IconBtn className={classes.iconButton} aria-label="Eingabefeld zurücksetzen">
        <FontAwesomeIcon icon={faTimes} />
      </IconBtn>
    </InputAdornment>
  );

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <FormControl className={clsx(classes.formControl, formControl?.className)} {..._.omit(formControl, 'className')}>
        <InputLabel className={clsx(classes.label, inputLabel?.className)} htmlFor={inputId || generatedId} {..._.omit(inputLabel, ['className', 'children', 'htmlFor'])}>
          {inputLabel?.children || 'Suche'}
        </InputLabel>

        <OutlinedInput
          {..._.omit(input, ['startAdornment', 'endAdornment', 'className', 'id'])}
          id={inputId || generatedId}
          inputRef={inputRef}
          className={clsx(
            classes.input,
            adornmentPosition === 'start' && classes.inputAdornmentStart,
            adornmentPosition === 'end' && classes.inputAdornmentEnd,
            input?.className
          )}
          startAdornment={
            adornmentPosition === 'start' ? ((input?.value || input?.defaultValue) && clearOnClick ? ClearAdornment : SearchAdornment) : input?.startAdornment || undefined
          }
          endAdornment={
            adornmentPosition === 'end' ? ((input?.value || input?.defaultValue) && clearOnClick ? ClearAdornment : SearchAdornment) : input?.endAdornment || undefined
          }
        />
      </FormControl>
    </div>
  );
};

HeadSearchInput.defaultProps = {
  adornmentPosition: 'start'
};
