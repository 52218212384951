export const ThingTypes = {
  TANDEM: '00000000-0000-0000-0002-000000000000',
  ADMINCHAT: '00000000-0000-0000-0003-000000000000',
  REGISTER_REQUEST: '47c03cb4-91d4-48be-905e-aa946e1fe825'
};

export const TypesProps = {
  [ThingTypes.TANDEM]: {
    Name: 'Tandem',
    Names: 'Tandems'
  },
  [ThingTypes.TANDEM]: {
    Name: 'Admin-Chat',
    Names: 'Admin-Chats'
  }
};
