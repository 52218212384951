import React, { useState } from 'react';
import { Btn, Bx, DateTimeControl, Input, OutlinedInputControl } from '@curry-group/mui-curcuma';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import { Grid } from '@material-ui/core';
import { HeadlineContainer } from '../../headline-container';

export interface IConnectionExportProps {
  working?: boolean;
  error?: boolean;
  onExportClicked: (dateFrom?: number, dateTo?: number) => void;
}

export const ConnectionExport: React.FC<IConnectionExportProps> = ({ working, error, onExportClicked }) => {
  const [valid, setValid] = useState(true);
  const [dateFrom, setDateFrom] = useState<number>(dayjs().add(-7, 'd').unix() * 1000);
  const [dateTo, setDateTo] = useState<number>(dayjs().unix() * 1000);

  const validate = (dF?: number, dT?: number) => {
    // validation logic
    setValid(true);
  };

  const dateFromChange = (date: number) => {
    setDateFrom(date);
    validate(date, dateTo);
  };

  const dateToChange = (date: number) => {
    setDateTo(date);
    validate(dateFrom, date);
  };

  return (
    <>
      <HeadlineContainer headline="Einzelverbindungsnachweise" />
      <Grid container>
        <MuiPickersUtilsProvider utils={DayjsUtils} locale="de">
          <Grid item xs={12} md={5}>
            <Bx mt={2}>
              <Input
                inputLabel={{
                  children: 'Von'
                }}
                inputComponent={
                  <DateTimeControl
                    variant="inline"
                    TextFieldComponent={OutlinedInputControl}
                    value={dateFrom}
                    onChange={date => date && dateFromChange(date.toDate().getTime())}
                  ></DateTimeControl>
                }
              />
            </Bx>
          </Grid>
          <Grid item md={2}></Grid>
          <Grid item xs={12} md={5}>
            <Bx mt={2}>
              <Input
                inputLabel={{
                  children: 'Bis'
                }}
                inputComponent={
                  <DateTimeControl
                    variant="inline"
                    TextFieldComponent={OutlinedInputControl}
                    value={dateTo}
                    onChange={date => date && dateToChange(date.toDate().getTime())}
                  ></DateTimeControl>
                }
              />
            </Bx>
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid item xs={12} md={6}>
          <Bx mt={2}>
            <Btn size="large" color="primary" variant="contained" disabled={working || !valid} onClick={() => onExportClicked(dateFrom, dateTo)}>
              Herunterladen
            </Btn>
          </Bx>
        </Grid>
      </Grid>
    </>
  );
};
