import React from 'react';

import { Bx, ExtendedListItem, IconBtn, Input, InputStartAdornment, Typo } from '@curry-group/mui-curcuma';
import { faPlus, faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IAddSearchProps {
  maxHeight?: number;
  size?: 'large' | 'medium';
  placedInSidebar?: boolean;

  addButtonCaption?: string | React.ReactElement;
  searchEnabled?: boolean;
  searchActive?: boolean;
  searchInput?: string;
  searchPlaceholder?: string;
  addButtonClicked?: () => void;
  setSearchActive?: (active: boolean) => void;
  searchInputChange?: (value: string) => void;
  isMobile?: boolean;
}

export const AddSearch: React.FC<IAddSearchProps> = props => {
  return (
    <ExtendedListItem
      height={props.maxHeight}
      maxHeight={props.maxHeight}
      avatar={
        !props.searchActive && props.addButtonClicked ? (
          <Bx ml={!props.size || props.size === 'medium' ? (props.isMobile ? 2 : 3) : 0}>
            <IconBtn
              style={props.size === 'large' ? { width: '64px', height: '64px' } : undefined}
              size={props.size || 'medium'}
              onClick={() => props.addButtonClicked && props.addButtonClicked()}
              color="secondary"
              variant="contained"
            >
              <FontAwesomeIcon icon={faPlus} />
            </IconBtn>
          </Bx>
        ) : undefined
      }
    >
      <Bx
        display="flex"
        mr={props.placedInSidebar ? (props.isMobile ? 2 : 3) : 0}
        ml={props.searchActive && props.placedInSidebar ? (props.isMobile ? 2 : 3) : undefined}
        alignItems="center"
        height="100%"
      >
        <Bx display="flex" alignItems="center" justifyContent="space-between" width="100%">
          {props.searchActive && (
            <Bx my={props.placedInSidebar ? -0.5 : 0} width="100%">
              <Input
                input={{
                  autoFocus: true,
                  startAdornment: props.searchEnabled && props.setSearchActive && (
                    <InputStartAdornment>
                      <IconBtn
                        size="small"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          props.searchInputChange?.('');
                          props.setSearchActive?.(!props.searchActive);
                        }}
                      >
                        <FontAwesomeIcon icon={props.searchActive ? faTimes : faSearch} />
                      </IconBtn>
                    </InputStartAdornment>
                  ),
                  placeholder: props.searchPlaceholder || 'Suche nach...',
                  value: props.searchInput,
                  onChange: e => {
                    props.searchInputChange?.(e.target.value);
                  }
                }}
              />
            </Bx>
          )}
          {!props.searchActive && props.addButtonClicked && (
            <React.Fragment>
              {typeof props.addButtonCaption === 'string' || typeof props.addButtonCaption === undefined ? (
                <Typo color="textPrimary" fontWeight={500} variant="body2" component="span">
                  {props.addButtonCaption || 'Erstellen'}
                </Typo>
              ) : (
                props.addButtonCaption
              )}
            </React.Fragment>
          )}
          {!props.searchActive && props.searchEnabled && props.setSearchActive && (
            <IconBtn
              size="small"
              onClick={(e: any) => {
                e.stopPropagation();
                props.setSearchActive?.(true);
              }}
            >
              <FontAwesomeIcon icon={faSearch} />
            </IconBtn>
          )}
        </Bx>
      </Bx>
    </ExtendedListItem>
  );
};
