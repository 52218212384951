import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FinishFlow } from '../../../../../components/flow/finish';

export const CreateTandemFinished: React.FC<{ onDone: () => void }> = ({ onDone }) => {
  const [createTandemDone, setCreateTandemDone] = useState(false);
  const { working, error, success } = useSelector(state => state.tandem);

  if (!createTandemDone || working) {
    return (
      <FinishFlow
        timeout={3000}
        onTimeout={() => {
          setCreateTandemDone(true);
        }}
        headline="Tandem wird angelegt."
      />
    );
  }

  if (success) {
    return (
      <FinishFlow
        hideLoader={true}
        timeout={3000}
        onTimeout={() => {
          onDone();
        }}
        headline="Tandem wurde erfolgreich erstellt."
        text="Erfolg"
      />
    );
  }

  if (error) {
    return (
      <FinishFlow
        hideLoader={true}
        timeout={10000}
        onTimeout={() => {
          onDone();
        }}
        headline="Fehler beim Erstellen."
        text=""
      />
    );
  }
  return <></>;
};
