import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { ConnectedNavBar, ConnectedNavBarDrawer } from '../../views/navbar';
import { Navbar } from '../../../components/frame';
import { setAppstateIsMobile, setAppstateNavbarOpen } from '../../../data/reducer/app';
import { useBreakpoints } from '@curry-group/mui-curcuma';
import { InternalLandingPageView } from '../../views/landingpage/internal';
import { NotificationsView } from '../../views/notifications';
import { TandemsView } from '../../views/tandems';
import { AdminDasboardView } from '../../views/admin-dashboard';
import { MyProfileView } from '../../views/profile';

export const ContentRouting: React.FC = () => {
  const location = useLocation();
  const isLargeDesktop = !!useBreakpoints().lgUp;
  const isMobile = !!useBreakpoints().mdDown;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAppstateIsMobile(isMobile));
  }, [dispatch, isMobile]);

  useEffect(() => {
    dispatch(setAppstateNavbarOpen(false));
  }, [location.pathname, dispatch]);

  return (
    <>
      {/* Drawer */}
      <ConnectedNavBarDrawer />

      {/* NavBar */}
      {!isMobile && (
        <Navbar>
          <ConnectedNavBar small={!isLargeDesktop} showHamburger={!isLargeDesktop} />
        </Navbar>
      )}

      <Switch>
        <Route exact path={['/start']} key={'redirect'}>
          <Redirect to="/" />
        </Route>

        <Route exact path="/">
          <InternalLandingPageView />
        </Route>

        <Route exact path="/notifications">
          <NotificationsView />
        </Route>

        <Route path={['/tandems/:alias', '/tandems']}>
          <TandemsView />
        </Route>

        <Route exact path="/admin-dashboard">
          <AdminDasboardView />
        </Route>

        <Route path="/profile">
          <MyProfileView />
        </Route>
      </Switch>
    </>
  );
};
