import React from 'react';
import dayjs from 'dayjs';

import { BaseListItem } from '..';
import { INotification } from '../../../../model/ryve/notification';

import { resolveTimestamp } from '../../../../helper';
import { resolveNotificationMessage } from '../../../../model/common/notification';
import { IconAva } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';

export interface INotificationListItemProps {
  notification: INotification;
  selected: () => void;
}

export const NotificationListItem: React.FC<INotificationListItemProps> = ({ notification, selected }) => {
  const isRead = notification.content?.notificationRead;

  return (
    <BaseListItem
      badge={!isRead && <span style={{ visibility: 'hidden' }}>.</span>}
      type={resolveTimestamp(dayjs(notification.modifiedAt).unix() * 1000)}
      title={resolveNotificationMessage(notification)}
      selected={selected}
      avatar={<IconAva color="secondary" children={<FontAwesomeIcon icon={faUser} />} size="large" />}
      //   avatar={avatar} TODO
      //   to={!disableLinks ? getDetailRoute(item, appconfig || {}, location) : '/optin'}
      fontcolor={'textPrimary'}
    />
  );
};
