import React, {useState, useEffect} from 'react';
import { Bx, DateControl, Input, OutlinedInputControl, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import { FormControlInput } from '../../../../../components/form/control/input';
import { validateEmail, validateInput, validatePhone } from '../../../../../helper';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import { IBaseRegistrationStepProps } from '../..';
import { useSelector } from 'react-redux';
import { FramedContent } from '../../../../../components/framed-content';

export interface IRegistrationStepOneProps extends IBaseRegistrationStepProps {
  onDateOfBirthSet: (dateOfBirth: number) => void;
}

function validate(firstName: string, lastName: string, email: string, phone: string, dateOfBirth: number) {
  return (!!validateEmail(email) && !!validatePhone(phone) && !!validateInput(firstName) && !!validateInput(lastName) && dateOfBirth > 0);
}

export const RegistrationInfo: React.FC<IRegistrationStepOneProps> = ({
  onDataValidation,
  onDateOfBirthSet,
}) => {
  const {registrationRequestData} = useSelector(state => state.registrationRequest);
  const [firstName,   setFirstName]   = useState<string>(registrationRequestData?.firstName || "");
  const [lastName,    setLastName]    = useState<string>(registrationRequestData?.lastName || "");
  const [email,       setEmail]       = useState<string>(registrationRequestData?.email || "");
  const [phone,       setPhone]       = useState<string>(registrationRequestData?.phone || "");
  const [dateOfBirth, setDateOfBirth] = useState<number>(registrationRequestData?.dateOfBirth || 0);


  useEffect(() => {
    onDataValidation(validate(firstName, lastName, email, phone, dateOfBirth), {firstName, lastName, email, phone, dateOfBirth});
  }, [firstName, lastName, email, phone, dateOfBirth, onDataValidation]);
  
  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Registrierung auf Schüler Connect Online
        </Typo>
      </Bx>
      <Bx>
        <Typo variant="subtitle1">Bitte tragen Sie Ihre Daten ein damit wir Sie kontaktieren können.</Typo>
      </Bx>
      <Bx my={8}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormControlInput
                value={firstName}
                label={'Vorname'}
                placeholder={'Vorname eingeben'}
                minLength={2}
                maxLength={50}
                onChange={setFirstName}
                validator={value => !!value}
                successMessage=""
                fieldGroup={'userData'}
                lazy
              />
            </Bx>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormControlInput
                value={lastName}
                label={'Nachname'}
                placeholder={'Nachname eingeben'}
                minLength={2}
                maxLength={50}
                onChange={setLastName}
                validator={value => !!value}
                successMessage=""
                fieldGroup={'userData'}
                lazy
              />
            </Bx>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <Bx mb={2}>
                  <MuiPickersUtilsProvider utils={DayjsUtils} locale="de">
                    <Input
                      inputLabel={{
                        children: 'Geburtsdatum'
                      }}

                      inputComponent={
                        <DateControl
                          variant="dialog"
                          TextFieldComponent={OutlinedInputControl}
                          format={"DD. MMM YYYY"}
                          value={dateOfBirth * 1000}
                          onChange={(date) => {
                            if(date){
                              const d = date.toDate().getTime() / 1000;
                              setDateOfBirth(d);
                              onDateOfBirthSet(d);
                            }
                          }}
                        ></DateControl>
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Bx>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <Bx mb={2}>
                  <FormControlInput
                    value={email}
                    disabled={false}
                    label={'E-Mail-Adresse'}
                    placeholder={'E-Mail-Adresse eingeben'}
                    minLength={2}
                    maxLength={0}
                    onChange={setEmail}
                    type={'email'}
                    validator={v => validateEmail(v)}
                    fieldGroup={'userData'}
                    successMessage=""
                    invalidMessage="Ihre Email muss ein gültiges Format haben!"
                    infotext={
                      <React.Fragment>
                        Wir verwenden Ihre E-Mail-Adresse ausschließlich zur Anmeldung und zur Zusendung von Benachrichtigungen. Anderen Nutzenden auf dem Portal wird sie
                        nicht angezeigt.
                      </React.Fragment>
                    }
                    lazy
                  />
                </Bx>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Bx mb={2}>
                  <FormControlInput
                    value={phone}
                    label={'Telefon'}
                    placeholder={'Telefonnummer eingeben'}
                    minLength={2}
                    maxLength={0}
                    onChange={setPhone}
                    validator={v => validatePhone(v)}
                    successMessage=""
                    fieldGroup={"userData"}
                    invalidMessage="Bitte geben Sie ein gültige Telefonnummer an"
                    lazy
                  />
                </Bx>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Bx>
    </FramedContent>
  );
};
