import React, { MouseEventHandler } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSlash } from '@fortawesome/pro-light-svg-icons';
import { Tooltip } from '@material-ui/core';
import { ConferenceIconBtn } from '../../layout/icon-btn';

export interface IConferencingButton {
  active?: boolean;
  icon: IconProp;
  tooltip: string;
  size?: 'small' | 'large';
  emphasize?: boolean;
  onClick?: MouseEventHandler;
}

export const ConferencingButton: React.FC<IConferencingButton> = ({ active, icon, tooltip, size, emphasize, onClick }) => {
  let style: any = undefined;
  if (size === undefined) {
    style = { marginLeft: -3 };
  }
  return (
    <Tooltip title={tooltip} arrow>
      <ConferenceIconBtn color={emphasize ? 'red' : active ? 'light' : 'dark'} size={size} onClick={onClick}>
        {(active || emphasize) && <FontAwesomeIcon icon={icon} />}
        {!active && !emphasize && (
          <span className="fa-layers">
            <FontAwesomeIcon fixedWidth style={style} icon={faSlash} />
            <FontAwesomeIcon icon={icon} />
          </span>
        )}
      </ConferenceIconBtn>
    </Tooltip>
  );
};
