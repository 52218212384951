import { put, select, all, takeLatest, takeEvery } from 'redux-saga/effects';
import { callDelete, callGet, callPost, callPostPlain, callPut } from '@curry-group/data-addons';
import { signalrEventHandling, signalRWatcher } from './signalr';
import * as _ from 'lodash';
import {
  setCommunication,
  fetchParticipationRequest,
  fetchParticipationFailed,
  fetchMessagesRequest,
  createConferenceMessageRequest,
  createConferenceMessageSuccess,
  createConferenceMessageFailed,
  createMessageRequest,
  createMessageSuccess,
  createMessageFailed,
  fetchMessagesSuccess,
  fetchMessagesFailed,
  fetchParticipationSuccess,
  fetchMessageByIdRequest,
  fetchMessageByIdSuccess,
  fetchMessageByIdFailed,
  deleteMessageRequest,
  deleteMessageSuccess,
  deleteMessageFailed,
  getNumUnreadMessagesRequest,
  getNumUnreadMessagesSuccess,
  getNumUnreadMessagesFailed,
  setLastReadTimestampRequest,
  setLastReadTimestampSuccess,
  setLastReadTimestampFailed,
  updateMessageRequest,
  updateMessageSuccess,
  updateMessageFailed,
  fetchCommunicationsRequest,
  fetchCommunicationsSuccess,
  fetchCommunicationsFailed,
  getNumUnreadMessagesByIdRequest,
  getNumUnreadMessagesByIdFailed,
  getNumUnreadMessagesByIdSuccess,
  joinConferenceRequest,
  joinConferenceSuccess,
  joinConferenceFailed,
  fetchCommunicationByIdRequest,
  fetchCommunicationByIdSuccess,
  fetchCommunicationByIdFailed,
  setMessageDraftAttachmentPreparing,
  setMessageDraftAttachments,
  setMessageDraftAttachmentPrepared,
  setMessageDraftAttachmentFailed,
  fetchFilesRequest,
  fetchFilesSuccess,
  fetchFilesFailed,
  fetchOlderMessagesRequest,
  fetchOlderMessagesFailed,
  fetchOlderMessagesSuccess,
  fetchNewerMessagesRequest,
  fetchNewerMessagesSuccess,
  fetchNewerMessagesFailed,
  voteMessageRequest,
  setUploadFiles,
  setUploadFilePreparing,
  setUploadFilePrepared,
  setUploadFileFailed,
  uploadFilesRequest,
  uploadFilesSuccess,
  uploadFilesFailed,
  setUploadFilesActive,
  unreadMessagesChanged,
  deleteFilesRequest,
  deleteFilesFailed,
  deleteFilesSuccess,
  downloadFilesRequest,
  downloadFilesResponse
} from '../../reducer/communication';

import api from '../../api';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { toggleConferencing, updateConferencingMessage } from '../../reducer/conferencing';
import { IMessageModel } from '../../../model/communication/Message';
import { setBadgeCount } from '../../reducer/notifications';
import { IDictionary } from '../../../model/common/dictionary';

function* watcher() {
  yield takeLatest(setCommunication.type, setCommunicationWatcher);

  yield takeLatest(fetchParticipationRequest.type, fetchParticipationWorker);

  yield takeLatest(fetchMessagesRequest.type, fetchMessagesWorker);
  yield takeLatest(fetchOlderMessagesRequest.type, fetchOlderMessagesWorker);
  yield takeLatest(fetchNewerMessagesRequest.type, fetchNewerMessagesWorker);

  yield takeLatest(joinConferenceRequest.type, joinConferenceWorker);
  yield takeLatest(createConferenceMessageRequest.type, createConferenceMessageWorker);
  yield takeLatest(createMessageRequest.type, createMessageWorker);

  yield takeLatest(deleteMessageRequest.type, deleteMessageWorker);
  yield takeLatest(updateMessageRequest.type, updateMessageWorker);

  yield takeEvery(getNumUnreadMessagesRequest.type, getNumUnreadMessagesWorker);
  yield takeLatest(setLastReadTimestampRequest.type, setLastReadTimestampWorker);

  yield takeLatest(fetchCommunicationsRequest.type, fetchCommunicationsWorker);
  yield takeLatest(fetchCommunicationByIdRequest.type, fetchCommunicationByIdWorker);

  yield takeLatest(fetchMessageByIdRequest.type, fetchMessageByIdWorker);
  yield takeLatest(fetchMessageByIdSuccess.type, fetchMessageByIdSuccessWorker);

  yield takeEvery(getNumUnreadMessagesByIdRequest.type, getNumUnreadMessagesByIdWorker);

  yield takeLatest(setMessageDraftAttachments.type, uploadAttachmentsWorker);

  yield takeLatest(setUploadFiles.type, prepareUploadFilesWorker);

  yield takeLatest(uploadFilesRequest.type, finalizeUploadFilesWorker);

  yield takeLatest(fetchFilesRequest.type, fetchFilesWorker);

  yield takeLatest(voteMessageRequest.type, voteMessageWorker);

  yield takeEvery(unreadMessagesChanged.type, unreadMessagesChangedWorker);

  yield takeLatest(deleteFilesRequest.type, deleteFilesWorker);

  yield takeLatest(downloadFilesRequest.type, downloadFilesWorker);
}

function* downloadFilesWorker(action: ReturnType<typeof downloadFilesRequest>) {
  const fileIds = action.payload.files.map(f => f._id);

  if (fileIds.length === 0 || fileIds.length >= 40) {
    yield put(downloadFilesResponse({ success: false }));
  }

  const url = api.urlWithQuery(api.urlWithParams(api.communication.downloadFiles, { id: action.payload.communicationId }), { ids: fileIds });

  try {
    const result = yield fetch(url)
      .then(res => (res.status === 200 ? res.blob() : null))
      .then(blob => {
        if (blob) {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = 'Download.zip';
          document.body.appendChild(a);
          a.click();
          a.remove();
          return true;
        }
        return false;
      });
    if (result) {
      yield put(downloadFilesResponse({ success: true }));
    } else {
      yield put(downloadFilesResponse({ success: false }));
    }
  } catch (e) {
    yield put(downloadFilesResponse({ success: false }));
  }
}

function* deleteFilesWorker(action: ReturnType<typeof deleteFilesRequest>) {
  const url = api.urlWithParams(api.communication.files, { id: action.payload.communicationId });
  const fileIds = action.payload.files.map(f => f._id);
  try {
    const result = yield callDelete(url, fileIds);
    if (result.success) {
      yield put(deleteFilesSuccess(result));
    } else {
      yield put(deleteFilesFailed(result));
    }
    yield put(fetchFilesRequest({ id: action.payload.communicationId }));
  } catch (e) {
    yield put(deleteFilesFailed({ success: false, data: fileIds, message: e.message }));
  }
}

function* unreadMessagesChangedWorker() {
  const numUnreadDict: IDictionary<IDictionary<number>> = yield select(state => state.communication?.numUnreadMessages);
  if (numUnreadDict) {
    for (let type of Object.keys(numUnreadDict)) {
      if (numUnreadDict[type]) {
        const values = _.compact(Object.values(numUnreadDict[type]));
        let reduced = 0;
        if (values && values.length) {
          reduced = _.compact(values).reduce((a, b) => a + b);
        }
        yield put(setBadgeCount({ key: type, count: reduced }));
      }
    }
  }
}

function* uploadAttachmentsWorker(action: ReturnType<typeof setMessageDraftAttachments>) {
  if (!action.payload.attachments) return;
  for (let attachment of action.payload.attachments) {
    if (!attachment.tempname) {
      try {
        yield put(setMessageDraftAttachmentPreparing({ id: action.payload.id, attachment }));
        const newFormData = new FormData();
        newFormData.append('file', attachment);
        const url = api.urlWithParams(api.communication.prepareAttachments, { id: action.payload.communicationId });
        const result = yield callPostPlain(url, newFormData);
        if (result.success) {
          yield put(setMessageDraftAttachmentPrepared({ id: action.payload.id, attachment, tempFilename: result.data }));
        } else {
          yield put(setMessageDraftAttachmentFailed({ id: action.payload.id, attachment }));
        }
      } catch (e) {
        console.error(e);
        yield put(setMessageDraftAttachmentFailed({ id: action.payload.id, attachment }));
      }
    }
  }
}

function* prepareUploadFilesWorker(action: ReturnType<typeof setUploadFiles>) {
  if (!action.payload.files) return;
  for (let file of action.payload.files) {
    if (!file.tempname) {
      try {
        yield put(setUploadFilePreparing({ file }));
        const newFormData = new FormData();
        newFormData.append('file', file);
        const url = api.urlWithParams(api.communication.prepareAttachments, { id: action.payload.communicationId });
        const result = yield callPostPlain(url, newFormData);
        if (result.success) {
          yield put(setUploadFilePrepared({ file, tempFilename: result.data }));
        } else {
          yield put(setUploadFileFailed({ file }));
        }
      } catch (e) {
        console.error(e);
        yield put(setUploadFileFailed({ file }));
      }
    }
  }
}

function* finalizeUploadFilesWorker(action: ReturnType<typeof uploadFilesRequest>) {
  if (!action.payload.communicationId) return;
  if (!action.payload.files || !action.payload.files.length) return;
  try {
    const url = api.urlWithParams(api.communication.finalizeUpload, { id: action.payload.communicationId });
    const result = yield callPost(
      url,
      action.payload.files.map(f => f.tempname)
    );
    if (result.success) {
      yield put(uploadFilesSuccess({}));
      yield put(setUploadFilesActive(false));
      yield put(fetchFilesRequest({ id: action.payload.communicationId }));
    } else {
      yield put(uploadFilesFailed({ message: result.message }));
    }
  } catch (e) {
    yield put(uploadFilesFailed({ message: e.message }));
  }
}

function* fetchCommunicationByIdWorker(action: ReturnType<typeof fetchCommunicationByIdRequest>) {
  try {
    const result = yield callGet(api.urlWithParams(api.communication.byId, { id: action.payload.id }));
    if (result.success) {
      yield put(fetchCommunicationByIdSuccess({ id: action.payload.id, communication: result.data }));
    } else {
      yield put(fetchCommunicationByIdFailed({ id: action.payload.id }));
    }
  } catch (e) {
    yield put(fetchCommunicationByIdFailed({ message: e.message, id: action.payload.id }));
  }
}

function* fetchCommunicationsWorker(action: ReturnType<typeof fetchCommunicationsRequest>) {
  try {
    const result = yield callGet(api.urlWithQuery(api.communication.base, { types: action.payload.types?.join(',') }));
    if (result.success) {
      yield put(fetchCommunicationsSuccess({ communications: result.data }));
    } else {
      yield put(fetchCommunicationsFailed({}));
    }
  } catch (e) {
    yield put(fetchCommunicationsFailed({ message: e.message }));
  }
}

function* setLastReadTimestampWorker(action: ReturnType<typeof setLastReadTimestampRequest>) {
  try {
    const participation = yield select(state => state.communication?.participation);
    if (!participation) return;
    if (participation.lastReadTimestamp >= action.payload.timestamp) return;

    const result = yield callPost(api.urlWithParams(api.communication.readMessage, { timestamp: action.payload.timestamp, id: action.payload.id }));
    if (result.success) {
      yield put(setLastReadTimestampSuccess({ id: action.payload.id }));
      yield put(unreadMessagesChanged());
    } else {
      yield put(setLastReadTimestampFailed({}));
    }
  } catch (e) {
    yield put(setLastReadTimestampFailed({ message: e.message }));
  }
}

function* getNumUnreadMessagesByIdWorker(action: ReturnType<typeof getNumUnreadMessagesByIdRequest>) {
  try {
    const result = yield callGet(api.urlWithParams(api.communication.unreadMessagesForId, { id: action.payload.id }));
    if (result.success) {
      yield put(getNumUnreadMessagesByIdSuccess({ id: action.payload.id, dict: result.data }));
      yield put(unreadMessagesChanged());
    } else {
      yield put(getNumUnreadMessagesByIdFailed({}));
    }
  } catch (e) {
    yield put(getNumUnreadMessagesByIdFailed({ message: e.message }));
  }
}

function* getNumUnreadMessagesWorker(action: ReturnType<typeof getNumUnreadMessagesRequest>) {
  try {
    const result = yield callGet(api.urlWithParams(api.communication.unreadMessagesForTypes, { types: action.payload.types?.join(',') }));
    if (result.success) {
      yield put(getNumUnreadMessagesSuccess({ dict: result.data }));
      yield put(unreadMessagesChanged());
    } else {
      yield put(getNumUnreadMessagesFailed({}));
    }
  } catch (e) {
    yield put(getNumUnreadMessagesFailed({ message: e.message }));
  }
}

function* joinConferenceWorker(action: ReturnType<typeof joinConferenceRequest>) {
  try {
    const result = yield callGet(api.urlWithParams(api.communication.joinconference, { messageId: action.payload.message.id }));
    yield put(joinConferenceSuccess({}));
    yield put(toggleConferencing({ message: action.payload.message, token: result }));
  } catch (e) {
    yield put(joinConferenceFailed({}));
  }
}

function* createConferenceMessageWorker(action: ReturnType<typeof createConferenceMessageRequest>) {
  try {
    const result = yield callPost(
      api.urlWithParams(api.communication.conferenceMessage, { id: action.payload.communicationId, title: action.payload.title || 'Neue Besprechung' })
    );
    if (result.success) {
      yield put(createConferenceMessageSuccess({ id: action.payload.id, message: result.data.message }));
      yield put(toggleConferencing({ message: result.data.message, token: result.data.token }));
    } else {
      yield put(createConferenceMessageFailed({ id: action.payload.id, message: result.message }));
    }
  } catch (e) {
    yield put(createConferenceMessageFailed({ id: action.payload.id, message: e.message }));
  }
}

function* createMessageWorker(action: ReturnType<typeof createMessageRequest>) {
  try {
    const url = api.urlWithParams(api.communication.messages, { id: action.payload.communicationId });

    const result = yield callPost(url, {
      content: action.payload.content,
      threadRoot: action.payload.threadRoot?.id,
      quotes: action.payload.quotes?.id,
      attachments: action.payload.attachments?.map(file => file.tempname)
    });

    if (result.success) {
      yield put(createMessageSuccess({ id: action.payload.id, message: result.data }));
    } else {
      yield put(createMessageFailed({ id: action.payload.id, message: result.message }));
    }
  } catch (e) {
    yield put(createMessageFailed({ id: action.payload.id, message: e.message }));
  }
}

function* voteMessageWorker(action: ReturnType<typeof voteMessageRequest>) {
  try {
    yield callPost(
      api.urlWithParams(api.communication.voteMessage, { id: action.payload.communicationId, messageId: action.payload.messageId, vote: action.payload.up ? 1 : -1 })
    );
  } catch (e) {}
}

function* updateMessageWorker(action: ReturnType<typeof updateMessageRequest>) {
  try {
    const result = yield callPut(api.urlWithParams(api.communication.messages, { id: action.payload.communicationId }), {
      id: action.payload.messageId,
      content: action.payload.content
    });
    if (result.success) {
      yield put(updateMessageSuccess({ id: action.payload.id, message: result.data }));
    } else {
      yield updateMessageFailed({ id: action.payload.id, message: result.message });
    }
  } catch (e) {
    yield put(updateMessageFailed({ id: action.payload.id, message: e.message }));
  }
}

function* deleteMessageWorker(action: ReturnType<typeof deleteMessageRequest>) {
  try {
    const result = yield callDelete(api.urlWithParams(api.communication.message, { id: action.payload.communicationId, messageId: action.payload.id }));
    if (result.success) {
      yield put(deleteMessageSuccess({ message: result.data, localRemove: action.payload.deleted }));
    } else {
      yield put(deleteMessageFailed({ message: result.message }));
    }
  } catch (e) {
    yield put(deleteMessageFailed({ message: e.message }));
  }
}

function* fetchMessageByIdWorker(action: ReturnType<typeof fetchMessageByIdRequest>) {
  try {
    const result = yield callGet(api.urlWithParams(api.communication.message, { id: action.payload.communicationId, messageId: action.payload.messageId }));
    if (result.success) {
      yield put(fetchMessageByIdSuccess({ new: action.payload.new, message: result.data }));
    } else {
      yield put(fetchMessageByIdFailed({}));
    }
  } catch (e) {
    yield put(fetchMessageByIdFailed({ message: e.message }));
  }
}

function* fetchMessageByIdSuccessWorker(action: ReturnType<typeof fetchMessageByIdSuccess>) {
  const conferenceMessage: IMessageModel = yield select<(s: DefaultRootState) => IMessageModel | undefined>(s => s.conferencing?.message);
  const newMessage = action.payload.message;
  const detailId = yield select<(s: DefaultRootState) => string | undefined>(s => s.detail.item?._id);
  if (newMessage && newMessage.parent === detailId) {
    if (newMessage.conferenceInfo?.started && !newMessage.conferenceInfo?.ended) {
      yield put(updateConferencingMessage({ message: newMessage }));
    } else if (newMessage.conferenceInfo?.ended) {
      yield put(updateConferencingMessage({ message: null }));
    }
  }
  if (conferenceMessage?.id === newMessage.id) {
    yield put(updateConferencingMessage({ message: newMessage }));
  }
}

function* fetchMessagesWorker(action: ReturnType<typeof fetchMessagesRequest>) {
  try {
    const query: any = {};
    if (action.payload.threadRoot) query.trt = action.payload.threadRoot;
    if (action.payload.numThreadChildren) query.ntc = action.payload.numThreadChildren;
    if (action.payload.message) {
      query.msg = action.payload.message;
    } else {
      if (action.payload.before) query.bfr = action.payload.before;
      if (action.payload.since) query.snc = action.payload.since;
    }

    const result = yield callGet(api.urlWithQuery(api.urlWithParams(api.communication.messages, { id: action.payload.communicationId }), { q: JSON.stringify(query) }));
    if (result.success) {
      const msgResult = action.payload.message ? result.data ?? [] : (result.data ?? []).reverse();
      yield put(fetchMessagesSuccess({ alias: action.payload.alias, atStart: result.atStart, atEnd: result.atEnd, messages: msgResult }));
    } else {
      yield put(fetchMessagesFailed({ alias: action.payload.alias, message: result.message }));
    }
  } catch (e) {
    yield put(fetchMessagesFailed({ alias: action.payload.alias, message: e.message }));
  }
}

function* fetchOlderMessagesWorker(action: ReturnType<typeof fetchOlderMessagesRequest>) {
  try {
    const query: any = {};
    if (action.payload.threadRoot) query.trt = action.payload.threadRoot;
    if (action.payload.numThreadChildren) query.ntc = action.payload.numThreadChildren;
    if (action.payload.before) query.bfr = action.payload.before;

    const result = yield callGet(api.urlWithQuery(api.urlWithParams(api.communication.messages, { id: action.payload.communicationId }), { q: JSON.stringify(query) }));
    if (result.success) {
      yield put(fetchOlderMessagesSuccess({ alias: action.payload.alias, atStart: result.atStart, atEnd: result.atEnd, messages: (result.data ?? []).reverse() }));
    } else {
      yield put(fetchOlderMessagesFailed({ alias: action.payload.alias, message: result.message }));
    }
  } catch (e) {
    yield put(fetchOlderMessagesFailed({ alias: action.payload.alias, message: e.message }));
  }
}

function* fetchNewerMessagesWorker(action: ReturnType<typeof fetchNewerMessagesRequest>) {
  try {
    const query: any = {};
    if (action.payload.threadRoot) query.trt = action.payload.threadRoot;
    if (action.payload.numThreadChildren) query.ntc = action.payload.numThreadChildren;
    if (action.payload.since) query.snc = action.payload.since;

    const result = yield callGet(api.urlWithQuery(api.urlWithParams(api.communication.messages, { id: action.payload.communicationId }), { q: JSON.stringify(query) }));
    if (result.success) {
      yield put(fetchNewerMessagesSuccess({ alias: action.payload.alias, atStart: result.atStart, atEnd: result.atEnd, messages: result.data ?? [] }));
    } else {
      yield put(fetchNewerMessagesFailed({ alias: action.payload.alias, message: result.message }));
    }
  } catch (e) {
    yield put(fetchNewerMessagesFailed({ alias: action.payload.alias, message: e.message }));
  }
}

function* setCommunicationWatcher(action: ReturnType<typeof setCommunication>) {
  // yield put(fetchParticipationRequest({ id: action.payload.id }));
}

function* fetchParticipationWorker(action: ReturnType<typeof fetchParticipationRequest>) {
  try {
    const result = yield callGet(api.urlWithParams(api.communication.participation, { id: action.payload.id }));
    if (result) {
      yield put(fetchParticipationSuccess({ participation: result }));
    } else {
      yield put(fetchParticipationFailed({ message: 'Laden fehlgeschlagen' }));
    }
  } catch (e) {
    yield put(fetchParticipationFailed({ message: e.message }));
  }
}

function* fetchFilesWorker(action: ReturnType<typeof fetchFilesRequest>) {
  try {
    const result = yield callGet(api.urlWithParams(api.communication.files, { id: action.payload.id }));
    if (result.success) {
      yield put(fetchFilesSuccess({ files: result.data }));
    } else {
      yield put(fetchFilesFailed({ message: result.message }));
    }
  } catch (e) {
    yield put(fetchFilesFailed({ message: e.message }));
  }
}

export function useFiles() {
  const dispatch = useDispatch();
  const id = useSelector(state => state.communication.id);
  useEffect(() => {
    id && dispatch(fetchFilesRequest({ id }));
  }, [dispatch, id]);
}

export function* communication() {
  yield all([watcher(), signalRWatcher(), signalrEventHandling()]);
}
