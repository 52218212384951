import React, { useEffect, useState } from 'react';

import useTheme from '@material-ui/core/styles/useTheme';

import { Sidebar } from '../../../../components/frame';
import { ListSidebar, SidebarWrapper } from '../../../../components/sidebar';
import { useIsMobile } from '../../../../helper/hooks/useIsMobile';
import { useDispatch, useSelector } from 'react-redux';
import { setAppstateNavbarOpen } from '../../../../data/reducer/app';
import { MobileHeaderComponent } from '../../../../components/header';
import { SidebarListItemAdminChat, SidebarListItemTandem } from './tandem';
import { fetchCommunicationsRequest } from '../../../../data/reducer/communication';
import { ThingTypes } from '../../../../model/common/thingTypes';
import { AddSearch } from './search-bar';

export const TandemsSidebar: React.FC<{ onCreateTandem: () => void }> = ({ onCreateTandem }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCommunicationsRequest({ types: [ThingTypes.TANDEM, ThingTypes.ADMINCHAT] }));
  }, [dispatch]);

  const isAdmin = useSelector(state => state.app.profile?.type === 'admin');

  const communicationListItems = useSelector(state => state.communication.communications);
  const communicationListItemsWorking = useSelector(state => state.communication.communicationsWorking);

  const [searchActive, setSearchActive] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const navbarOpen = useSelector(state => state.app.navbarOpen);
  const setNavbarOpen = () => {
    dispatch(setAppstateNavbarOpen(!navbarOpen));
  };

  const theme: any = useTheme();
  const isMobile = useIsMobile();

  const maxHeightSidebarSearch = !isMobile ? 81 - 1 : undefined; // -1 due to border from ListWrapper

  return (
    <Sidebar isMobile={isMobile}>
      {isMobile && <MobileHeaderComponent open={navbarOpen || false} setOpen={setNavbarOpen} />}
      <SidebarWrapper>
        <ListSidebar<any>
          items={
            searchInput
              ? communicationListItems?.filter(item => {
                  let title = item.content.title;
                  if (item.content.partner?.content?.fullName) {
                    title = item.content.partner.content.fullName;
                  }
                  return title?.toLowerCase().includes(searchInput.toLowerCase());
                })
              : communicationListItems
          }
          renderItem={item => {
            if (item.type === ThingTypes.ADMINCHAT) {
              return <SidebarListItemAdminChat item={item} key={item._id} />;
            }
            return <SidebarListItemTandem item={item} key={item._id} />;
          }}
          top={
            <AddSearch
              addButtonCaption={isAdmin ? 'Tandem erstellen' : undefined}
              addButtonClicked={
                isAdmin
                  ? () => {
                      onCreateTandem();
                    }
                  : undefined
              }
              placedInSidebar
              isMobile={isMobile}
              maxHeight={maxHeightSidebarSearch}
              searchActive={isAdmin ? searchActive : true}
              searchInput={searchInput}
              searchInputChange={setSearchInput}
              searchEnabled={true}
              searchPlaceholder={'Suchen'}
              setSearchActive={active => {
                setSearchActive(active);
                setSearchInput('');
              }}
            />
          }
        />
      </SidebarWrapper>
    </Sidebar>
  );
};
