import { Bx, Input, InputEndAdornment, InputInfoText, InputLabelTextAdornment, InputStartAdornment, InputValidationIcon } from '@curry-group/mui-curcuma';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isFunction } from 'lodash';
import React, { useEffect } from 'react';

export abstract class FormValidator {
  private static readonly fieldGroups = new Map<string, Map<string, boolean>>();

  private constructor() {}

  public static updateState(fieldName: string, isValid: boolean, fieldGroup?: string): boolean {
    const fldGroup = fieldGroup ?? '';
    let fields: Map<string, boolean> | undefined;

    if (!FormValidator.fieldGroups.has(fldGroup)) {
      fields = new Map<string, boolean>();
      FormValidator.fieldGroups.set(fldGroup, fields);
    } else {
      fields = FormValidator.fieldGroups.get(fldGroup);
    }

    fields?.set(fieldName, isValid);
    return FormValidator.isValid('', fldGroup);
  }

  public static isValid(fieldName: string, fieldGroup: string): boolean {
    const fields = FormValidator.fieldGroups.get(fieldGroup ?? '');
    if (!!fields) {
      if (!!fieldName) {
        return fields.get(fieldName) === true;
      } else {
        return fields.size > 0 && !Array.from(fields.values()).some(v => v === false);
      }
    }

    return false;
  }
}

interface IFormControlInputProps extends IBreakpoints {
  value: string;
  label: string;
  placeholder: string;
  minLength: number;
  maxLength: number;
  onChange: (value: string) => void;
  type?: string;
  validator?: boolean | ((value: string) => boolean);
  successMessage?: string;
  invalidMessage?: string;
  multiline?: boolean;
  lazy?: boolean;
  autoTrim?: boolean;
  noLengthWarning?: boolean;
  fieldGroup?: string;
  formName?: string;
  infotext?: React.ReactChildren | React.ReactChild;
  startIcon?: IconProp;
  disabled?: boolean;
}

export interface IBreakpoints {
  isLgUp?: boolean;
  isMdUp?: boolean;
  isSmUp?: boolean;
}

export const FormControlInput: React.FC<IFormControlInputProps> = ({
  value,
  label,
  placeholder,
  minLength,
  maxLength,
  onChange,
  isLgUp,
  isMdUp,
  isSmUp,
  type,
  validator,
  successMessage,
  invalidMessage,
  multiline,
  lazy,
  autoTrim,
  noLengthWarning,
  fieldGroup,
  formName,
  infotext,
  startIcon,
  disabled
}) => {
  const [inputValue, setInputValue] = React.useState<string>(value || '');
  const [isValid, setValidInternal] = React.useState(true);
  const [updateHandler, setHandler] = React.useState(0);

  const setValid = (valid: boolean) => {
    FormValidator.updateState(formName ?? label, valid, fieldGroup);
    setValidInternal(valid);
  };

  if (!minLength) {
    minLength = 0;
  }
  if (!type) {
    type = 'text';
  }
  if (successMessage === null || successMessage === undefined) {
    successMessage = 'Ausgezeichnet!';
  }

  function updateValidator(trimmedValue: string, target?: HTMLInputElement | HTMLTextAreaElement) {
    if (validator === true && !!target) {
      setValid(target.checkValidity());
    } else if (isFunction(validator)) {
      setValid(
        (minLength === 0 && trimmedValue === '') || (trimmedValue.length >= (minLength ?? 0) && (!maxLength || trimmedValue.length <= maxLength) && validator(trimmedValue))
      );
    }
  }

  useEffect(() => {
    updateValidator(inputValue.trim());
  });

  return (
    <Bx mb={2}>
      <Input
        inputLabel={{
          children: label,
          mandatory: minLength > 0,
          endAdornment: (
            <InputLabelTextAdornment>
              {inputValue?.length || 0}
              {maxLength ? `/${maxLength}` : ''} Zeichen
            </InputLabelTextAdornment>
          )
        }}
        input={{
          disabled: disabled,
          startAdornment: !!startIcon ? (
            <InputStartAdornment>
              <FontAwesomeIcon icon={startIcon} />
            </InputStartAdornment>
          ) : undefined,
          value: inputValue,
          placeholder: placeholder,
          required: minLength > 0,
          multiline: !!multiline,
          type,
          rows: !!multiline ? 3 : 1,
          name: formName ?? label,
          onFocus: e => {
            if (!!minLength) (e.target as HTMLInputElement).minLength = minLength;
            if (!!maxLength) (e.target as HTMLInputElement).maxLength = maxLength;

            updateValidator(e.target.value.trim(), e.target);
          },
          onChange: e => {
            if (lazy) {
              window.clearTimeout(updateHandler);
            }
            const trimmedValue = autoTrim ? e.target.value.trim() : e.target.value;
            setInputValue(trimmedValue);

            updateValidator(trimmedValue, e.target);

            if (lazy) {
              setHandler(
                window.setTimeout(() => {
                  onChange(trimmedValue);
                }, 200)
              );
            } else {
              onChange(trimmedValue);
            }
          },
          endAdornment: (
            <>
              {inputValue?.length > 0 && (minLength > 0 || validator) && (
                <InputEndAdornment>
                  <InputValidationIcon type={inputValue?.length >= minLength && isValid ? 'success' : 'error'} />
                </InputEndAdornment>
              )}
            </>
          )
        }}
      />
      {/* {inputValue?.length >= minLength && isValid && (
        <Bx mt={1}>
          <InputInfoText type="success">{successMessage}</InputInfoText>
        </Bx>
      )} */}
      {!noLengthWarning && (
        <Bx mt={1}>
          <InputInfoText type={inputValue?.length < minLength || !isValid ? 'error' : 'default'}>
            {inputValue?.length < minLength
              ? `Dieses Eingabefeld sollte mindestens ${minLength} Zeichen haben.`
              : isValid
              ? successMessage ?? '&nbsp;'
              : invalidMessage ?? '&nbsp;'}
          </InputInfoText>
        </Bx>
      )}

      {!!infotext && (
        <Bx mt={1}>
          <InputInfoText type="default">{infotext}</InputInfoText>
        </Bx>
      )}
    </Bx>
  );
};
