import { useDispatch, useSelector } from 'react-redux';
import { setUploadFiles, setUploadFilesActive, uploadFilesRequest } from '../../../../../data/reducer/communication';
import { UploadFilesModal } from '../../../../../components/communication/file-manager/upload-modal';
import * as _ from 'lodash';
import { IUploadableFile } from '../../../../../model/common/file';

export const ConnectedUploadFilesModal = () => {
  const dispatch = useDispatch();

  const communicationId = useSelector(state => state.communication.id);
  const open = useSelector(state => state.communication.uploadFilesActive);
  const preparing = useSelector(state => state.communication.uploadFilesPreparing);
  const uploading = useSelector(state => state.communication.uploadFilesWorking);
  const files = useSelector(state => state.communication.uploadFiles);

  const setFiles = (files: IUploadableFile[]) => {
    communicationId && dispatch(setUploadFiles({ communicationId, files }));
  };
  const close = () => dispatch(setUploadFilesActive(false));
  const addFiles = (addedFiles: File[]) => {
    const newFiles = addedFiles;
    if (files && files.length) newFiles.push(...files);
    setFiles(newFiles);
  };
  const removeFile = (fileToRemove: IUploadableFile) => {
    const newFiles = _.reject(files, file => file === fileToRemove);
    setFiles(newFiles);
  };
  const upload = () => {
    files && communicationId && dispatch(uploadFilesRequest({ communicationId, files }));
  };

  return (
    <UploadFilesModal
      open={open || false}
      preparing={preparing}
      uploading={uploading}
      close={close}
      files={files || []}
      removeFile={removeFile}
      onSubmit={upload}
      addFiles={addFiles}
    />
  );
};
