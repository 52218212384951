import { Bx, LinearLoader, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { FramedContent } from '../../framed-content';

export interface IFinishFlowProps {
  timeout?: number;
  onTimeout?: () => void;
  hideLoader?: boolean;

  headline: string;
  image?: string;
  text?: string;
}
export const FinishFlow: React.FC<IFinishFlowProps> = ({ timeout, onTimeout, headline, text, image, hideLoader }) => {
  const [progress, setProgress] = useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        const rest = (100 - oldProgress) / 100;
        const diff = rest * Math.random() * 40;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  if (timeout) {
    setTimeout(() => {
      onTimeout?.();
    }, timeout);
  }

  return (
    <FramedContent display="flex" alignItems="center" height="100%" innerBoxProps={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item xs={12} md={10} lg={8} xl={6}>
              <Bx textAlign="center" mb={{ xs: 1, md: 2, lg: 3 }}>
                <img src={image || '/images/finish.svg'} alt={headline} />
              </Bx>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item xs={12} md={10} lg={8} xl={6}>
              <Typo variant="h1" align="center">
                {headline}
              </Typo>
              {text && (
                <Typo align="center" variant="subtitle1">
                  {text}
                </Typo>
              )}
            </Grid>
          </Grid>
        </Grid>
        {timeout && !hideLoader && (
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item xs={10} sm={8} md={6} lg={4} xl={3}>
                <Bx mt={2}>
                  <LinearLoader variant="determinate" value={progress} />
                </Bx>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </FramedContent>
  );
};
