import { Bx, CheckboxControl, Typo } from '@curry-group/mui-curcuma';
import { MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileManager, GridRowId } from '../../../../components/communication/file-manager';
import { useFiles } from '../../../../data/sagas/communication';
import { downloadFilesRequest, setDeleteFiles, setDeleteFilesActive, setUploadFilesActive } from '../../../../data/reducer/communication';
import { FileManagerButtonBar } from '../../../../components/communication/file-manager/button-bar';

import { ConnectedUploadFilesModal } from './upload-modal';
import { ConnectedDeleteFilesModal } from './delete-modal';
import { ConnectedDownloadFilesModal } from './download-modal';
import { FramedContent } from '../../../../components/framed-content';

export const ConnectedFileManager = () => {
  useFiles();

  const dispatch = useDispatch();

  const communicationId = useSelector(state => state.communication?.id);

  const files = useSelector(state => state.communication?.files);
  const working = useSelector(state => state.communication?.filesWorking);

  const allowDownload = useSelector(state => !state.communication.downloadFilesPreparing);
  const allowAttachments = useSelector(state => state.communication?.communication?.allowAttachments && state.communication?.participation?.permissions.allowAttachments);

  const [selection, setSelection] = useState<GridRowId[]>([]);
  const [filters, setFilters] = useState<Array<string>>([]);

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newFilters;
    if (filters.indexOf(e.target.name) === -1) {
      newFilters = [...filters, e.target.name];
    } else {
      newFilters = filters.filter(a => a !== e.target.name);
    }

    setFilters(newFilters);
  };

  function openUploadModal() {
    dispatch(setUploadFilesActive(true));
  }

  function openDeleteModal() {
    dispatch(setDeleteFilesActive(true));
    dispatch(setDeleteFiles({ files: files?.filter(file => selection.includes(file._id)) }));
    setSelection([]);
  }

  function startDownload() {
    const selectedFiles = files?.filter(file => selection.includes(file._id));
    selectedFiles?.length && communicationId && dispatch(downloadFilesRequest({ communicationId, files: selectedFiles }));
  }

  function resetSelection() {
    setSelection([]);
  }

  return (
    <>
      <FileManagerButtonBar
        allowDownload={allowDownload}
        allowUpload={allowAttachments}
        uploadButtonClicked={openUploadModal}
        downloadButtonClicked={startDownload}
        deleteButtonClicked={openDeleteModal}
        numSelected={selection?.length || 0}
        resetSelection={resetSelection}
        hasFilter={true}
        filters={filters}
        renderFilterMenu={() => {
          return (
            <>
              <MenuItem>
                <CheckboxControl label="Dokumente" control={{ color: 'primary', onChange: handleFilterChange, name: 'document' }} variant="outlined" size="small" />
              </MenuItem>
              <MenuItem>
                <CheckboxControl label="Bilder" control={{ color: 'primary', onChange: handleFilterChange, name: 'image' }} variant="outlined" size="small" />
              </MenuItem>
              <MenuItem>
                <CheckboxControl label="Videos" control={{ color: 'primary', onChange: handleFilterChange, name: 'video' }} variant="outlined" size="small" />
              </MenuItem>
            </>
          );
        }}
      />
      <FramedContent>
        <Bx mb={2}>
          <Typo variant="body1" fontWeight={500}>
            Stammverzeichnis
          </Typo>
        </Bx>
        <FileManager filters={filters} working={working} assets={files} selection={selection} setSelection={setSelection} />
      </FramedContent>

      <ConnectedUploadFilesModal />
      <ConnectedDeleteFilesModal />
      <ConnectedDownloadFilesModal />
    </>
  );
};
