import { imageFileExtensions } from '../../helper';
import { IScoUserProfile } from '../profile';
import { IThing } from './thing';

export interface IAsset {
  _id: string;
  type: string;
  extension: string;
  fileSize: number;

  width?: number | null;
  height?: number | null;

  name: string;
  fullPath: string;
  displayName: string;

  copyright?: string;
  indicationOfSource?: string;
  published?: boolean;

  createdBy: string;
  modifiedBy?: string;
  createdAt: string;
  modifiedAt: string;
}

export interface IAssetResolved extends IAsset {
  createdByResolved: IThing<IScoUserProfile>;
}

export function isImage(asset: IAsset) {
  return imageFileExtensions.includes(asset.extension.toLowerCase());
}
