import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FinishFlow } from '../../../../../components/flow/finish';

export const RegistrationInitializeFinished: React.FC = () => {
  const history = useHistory();
  const [registrationDone, setRegistrationDone] = useState(false);
  const { registrationInitSuccess, registrationInitFailed } = useSelector(state => state.registration);

  if (!registrationDone) {
    return (
      <FinishFlow
        timeout={3000}
        onTimeout={() => {
          setRegistrationDone(true);
        }}
        headline="Ihre Daten werden sicher übermittelt."
      />
    );
  }

  if (registrationInitSuccess) {
    return (
      <FinishFlow
        hideLoader={true}
        timeout={10000}
        onTimeout={() => {
          history.push('/');
        }}
        headline="Registrierungsmail wurde verschickt."
        text="Folgen Sie dem Registrierunglink in der Mail. Sie werden in Kürze weitergeleitet."
      />
    );
  }

  if (registrationInitFailed) {
    return (
      <FinishFlow
        hideLoader={true}
        timeout={10000}
        onTimeout={() => {
          history.push('/');
        }}
        headline="Fehler bei der Registrierung."
        text="Leider ist ein Fehler bei der Registrierung aufgetreten. Bitte versuchen Sie es später erneut. Sie werden in Kürze weitergeleitet."
      />
    );
  }
  return <></>;
};
