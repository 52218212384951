import React, {useState, useEffect} from 'react';
import { Bx, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import { FormControlInput } from '../../../../../components/form/control/input';
import { useSelector } from 'react-redux';
import { isValidIBANNumber } from '../../../../../helper';
import { IBaseRegistrationFinalStepProps } from '..';
import { FramedContent } from '../../../../../components/framed-content';

export interface IRegistrationIBANProps extends IBaseRegistrationFinalStepProps {
  
}

function validate(iban: string, holder: string) {
  return (isValidIBANNumber(iban) && !!holder);
}

export const RegistrationIBAN: React.FC<IRegistrationIBANProps> = ({
  onDataValidation
}) => {
  const {registrationFinalizeRequestData} = useSelector(state => state.registrationFinalRequest);
  const [iban, setIBAN] = useState(registrationFinalizeRequestData.bankAccountIban || "");
  const [holder, setHolder] = useState(registrationFinalizeRequestData.bankAccountHolder || "");
  
  useEffect(() => {
    onDataValidation(validate(iban, holder), {bankAccountHolder: holder, bankAccountIban: iban});
  }, [iban, holder, onDataValidation])

  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Registrierung auf Schüler Connect Online
        </Typo>
      </Bx>
      <Bx>
        <Typo variant="subtitle1">Bitte tragen Sie Ihre Bankverbindung ein</Typo>
      </Bx>
      <Bx my={8}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormControlInput
                value={iban}
                label={'IBAN'}
                placeholder={'IBAN eingeben'}
                minLength={16}
                maxLength={34}
                onChange={setIBAN}
                validator={value => isValidIBANNumber(value)}
                successMessage=""
                invalidMessage={"Bitte geben Sie eine gültige IBAN ein"}
                fieldGroup={'userData'}
                lazy
              />
            </Bx>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormControlInput
                value={iban}
                label={'Kontoinhaber'}
                placeholder={'Kontoinhaber eingeben'}
                minLength={2}
                maxLength={50}
                onChange={setHolder}
                validator={value => !!value}
                successMessage=""
                fieldGroup={'userData'}
                lazy
              />
            </Bx>
          </Grid>
        </Grid>
      </Bx>
    </FramedContent>
  );
};
