import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Btn, BtnList, Bx, Ctn, IBxProps, ICtnProps, Typo } from '@curry-group/mui-curcuma';
import Grid from '@material-ui/core/Grid';
import { ClassNameMap } from '@material-ui/styles';

interface ILandingPageKeyVisualProps {
  ctnMaxWidth: ICtnProps['maxWidth'];
  sectionPy: IBxProps['px'];
  sectionPx: IBxProps['px'];
  keyvisualFontSize: number;
  body4FontSize: number;
  imageSrc: string;
  imageAlt: string;
  logoSrc: string;
  logoAlt: string;
  registerLabel: string;
  registerLink: string;
  loginLabel: string;
  loginLink: string;
  headline: string;
  content: string;
  responsive: { isMDUp: boolean };
  classes: ClassNameMap<"line" | "boldText">;
}

const Button = props => {
  return <Btn {...props} />;
};

export const LandingPageKeyVisual: React.FunctionComponent<ILandingPageKeyVisualProps> = ({
  ctnMaxWidth,
  sectionPy,
  sectionPx,
  keyvisualFontSize,
  body4FontSize,
  imageSrc,
  imageAlt,
  logoSrc,
  logoAlt,
  registerLabel,
  registerLink,
  loginLabel,
  loginLink,
  headline,
  content,
  responsive,
  classes
}) => {
  const [firstSectionMinHeight, setFirstSectionMinHeight] = React.useState<number>(window.innerHeight * 0.75);

  React.useEffect(() => {
    function getAndSetFirstSectionMinAndMaxHeight() {
      let minHeight = window.innerHeight * 0.75;
      minHeight !== firstSectionMinHeight && setFirstSectionMinHeight(minHeight);
    }
    getAndSetFirstSectionMinAndMaxHeight();

    window.addEventListener('resize', getAndSetFirstSectionMinAndMaxHeight);

    return () => {
      window.removeEventListener('resize', getAndSetFirstSectionMinAndMaxHeight);
    };
  }, [firstSectionMinHeight]);

  return (
    <Bx
      component="section"
      width="100%"
      bgcolor="background.paper"
      py={sectionPy}
      px={sectionPx}
      display="flex"
      alignItems="center"
      style={{ overflow: 'hidden', minHeight: firstSectionMinHeight }}
      className={classes.boldText}
    >
      <Ctn maxWidth={ctnMaxWidth}>
        <Grid container spacing={10} style={{ margin: 0, width: '100%' }}>
          <Grid item xs={12} md={6} lg={5} xl={5} style={{ padding: 0 }}>
            <Bx height="100%" display="flex" justifyContent="center" flexDirection="column">
              <Bx mb={4}>
                <img src={logoSrc} alt={logoAlt} style={{height: "140px"}} />
              </Bx>  
              <Typo variant="h1" component="h1" style={{ fontSize: keyvisualFontSize }} className={classes.line} gutterBottom dangerouslySetInnerHTML={{ __html: headline }} />
              <Typo variant="body1" component="p" style={{ fontSize: body4FontSize }} gutterBottom dangerouslySetInnerHTML={{ __html: content }} />
              <Bx mt={4}>
                <BtnList spacing={1}>
                  <Button color="primary" size="large" variant="contained" component={RouterLink} to={registerLink}>
                    {registerLabel}
                  </Button>
                  <Button variant="outlined" size="large" component={RouterLink} to={loginLink}>
                    {loginLabel}
                  </Button>
                </BtnList>
              </Bx>
            </Bx>
          </Grid>
          <Grid item xs={12} md={6} lg={7} xl={7} style={{ padding: responsive.isMDUp ? 0 : '40px 0 80px' }}>
            <img src={imageSrc} alt={imageAlt} style={{ width: '100%', height: 'auto' }} />
          </Grid>
        </Grid>
      </Ctn>
    </Bx>
  );
};
