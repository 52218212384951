import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import clsx from 'clsx';
import { Bx, IBxProps } from '@curry-group/mui-curcuma';

import { conferenceTheme } from '../../theme';

export interface IConferenceFrameProps extends IBxProps {
  stream?: React.ReactChild;
  leftControls?: React.ReactChild;
  middleControls?: React.ReactChild;
  rightControls?: React.ReactChild;
  frameControls?: React.ReactChild;
  controlsAlwaysVisible?: boolean;
}

export interface IConferenceFrameThemeExtension {
  conferenceFrame: {
    gridBackgroundColor?: React.CSSProperties['backgroundColor'];
    controlsBackgroundColor?: React.CSSProperties['backgroundColor'];
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  rootGrid: {
    backgroundColor: conferenceTheme.conferenceFrame?.gridBackgroundColor
  },
  stream: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    backgroundColor: '#000',

    '& video': {
      width: '100%',
      height: '100%'
    }
  },
  participants: {
    position: 'relative',
    zIndex: 2
  },
  controls: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    background: `linear-gradient(to top, ${conferenceTheme.conferenceFrame?.controlsBackgroundColor}, transparent)`,
    opacity: 0,
    overflow: 'hidden',
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
      delay: theme.transitions.duration.complex * 2
    }),
    '& > *': {
      opacity: 0,
      transform: 'translateY(50px) scale(.75)',
      transition: theme.transitions.create(['opacity', 'transform'], {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.easeInOut,
        delay: theme.transitions.duration.complex * 2
      })
    }
  },
  leftControls: {},
  middleControls: {},
  rightControls: {},
  frameButtons: {
    position: 'relative',
    zIndex: 2
  },
  controlsTransition: {
    opacity: 1,
    willChange: 'opacity',
    transition: theme.transitions.create('opacity', { duration: theme.transitions.duration.complex, easing: theme.transitions.easing.easeInOut }),
    '& > *': {
      opacity: 1,
      transform: 'translateY(0) scale(1)',
      willChange: 'opacity, transform',
      transition: theme.transitions.create(['opacity', 'transform'], {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.easeInOut
      })
    }
  }
}));

export const ConferenceFrame: React.FunctionComponent<IConferenceFrameProps> = ({
  stream,
  leftControls,
  middleControls,
  rightControls,
  frameControls,
  controlsAlwaysVisible,
  children,
  className,
  ...rest
}) => {
  const classes = useStyles();
  const [controlsMouseOver, setControlsMouseOver] = React.useState<boolean>(false);
  let mouseOutTimeout = React.useRef<any>(null);

  React.useEffect(() => {
    if (controlsAlwaysVisible) {
      setControlsMouseOver(true);
    }

    if (!controlsAlwaysVisible) {
      mouseOutTimeout && clearTimeout(mouseOutTimeout.current);
      setControlsMouseOver(true);
      mouseOutTimeout.current = setTimeout(() => {
        setControlsMouseOver(false);
      }, 3000);
    }
  }, [controlsAlwaysVisible]);

  const handleControlsMouseOver = () => {
    if (!controlsAlwaysVisible) {
      mouseOutTimeout && clearTimeout(mouseOutTimeout.current);
      setControlsMouseOver(true);
    }
  };

  const handleControlsMouseOut = () => {
    if (!controlsAlwaysVisible) {
      mouseOutTimeout && clearTimeout(mouseOutTimeout.current);
      mouseOutTimeout.current = setTimeout(() => {
        setControlsMouseOver(false);
      }, 1500);
    }
  };

  const Controls = (
    <React.Fragment>
      {(leftControls || middleControls || rightControls) && (
        <Bx
          display="flex"
          alignItems="flex-end"
          p={2}
          onMouseEnter={() => handleControlsMouseOver()}
          onMouseLeave={() => handleControlsMouseOut()}
          className={clsx(classes.controls, controlsMouseOver && classes.controlsTransition)}
        >
          {leftControls && <Bx className={classes.leftControls}>{leftControls}</Bx>}
          {middleControls && (
            <Bx flexGrow={1} flexShrink={1} flexBasis="auto" display="flex" justifyContent="center" className={classes.middleControls}>
              {middleControls}
            </Bx>
          )}
          {rightControls && <Bx className={classes.rightControls}>{rightControls}</Bx>}
        </Bx>
      )}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Bx display="flex" flexDirection="column" className={clsx(classes.root, className)} {...rest}>
        {/* Start: Active Speaker */}
        <Bx className={classes.stream}>{stream}</Bx>
        {/* End: Active Speaker */}

        {/* Start: Frame Controls & Participants */}
        <Bx p={2} flexGrow={1} display="flex">
          {frameControls && <Bx className={classes.frameButtons}>{frameControls}</Bx>}
        </Bx>
        {/* End: Frame Controls & Participants */}

        {/* Start: Controls */}
        {Controls}
        {/* End: Controls */}
      </Bx>
    </React.Fragment>
  );
};
