import { callGet, callPost, callPostPlain } from '@curry-group/data-addons';
import { takeLatest, all, put } from 'redux-saga/effects';
import api from '../../api';
import {
  sendRegistrationRequest,
  sendRegistrationSuccess,
  sendRegistrationFailed,
  finalizeRegistrationRequest,
  finalizeRegistrationSuccess,
  finalizeRegistrationFailed,
  initRegistrationRequest,
  initRegistrationSuccess,
  initRegistrationFailed,
  uploadCertificateFileRequest,
  uploadDataProtectionFileSuccess,
  uploadDataProtectionFileFailed,
  uploadDataProtectionFileRequest,
  uploadCertificateFileSuccess,
  finalStepInitialRequest,
  finalStepInitialSuccess,
  finalStepInitialFailed,
  uploadCertificateFileFailed
} from '../../reducer/registration';

function* watcher() {
  yield takeLatest(sendRegistrationRequest.type, sendRegistrationWorker);
  yield takeLatest(finalizeRegistrationRequest.type, finalizeRegistrationWorker);
  yield takeLatest(initRegistrationRequest.type, initRegistrationWorker);
  yield takeLatest(uploadCertificateFileRequest.type, uploadCertificateFileWorker);
  yield takeLatest(uploadDataProtectionFileRequest.type, uploadDataProtectionFileWorker);
  yield takeLatest(finalStepInitialRequest.type, finalStepRegistrationWorker);
}

function* sendRegistrationWorker(action: ReturnType<typeof sendRegistrationRequest>) {
  try {
    yield callPost(api.registration.register, action.payload);
    yield put(sendRegistrationSuccess());
  } catch {
    yield put(sendRegistrationFailed());
  }
}

function* initRegistrationWorker(action: ReturnType<typeof initRegistrationRequest>) {
  try {
    yield callPost(api.urlWithQuery(api.registration.initialize, { mail: action.payload }));
    yield put(initRegistrationSuccess());
  } catch {
    yield put(initRegistrationFailed());
  }
}

function* finalizeRegistrationWorker(action: ReturnType<typeof finalizeRegistrationRequest>) {
  try {
    yield callPost(api.registration.finalize, action.payload);
    yield put(finalizeRegistrationSuccess());
  } catch {
    yield put(finalizeRegistrationFailed());
  }
}

function* uploadDataProtectionFileWorker(action: ReturnType<typeof uploadDataProtectionFileRequest>) {
  try {
    const file = action.payload;
    const newFormData = new FormData();
    newFormData.append('file', file);
    const result = yield callPostPlain(api.registration.dataprotection, newFormData);
    if (result) {
      yield put(uploadDataProtectionFileSuccess(result));
    } else {
      yield put(uploadDataProtectionFileFailed());
    }
  } catch (e) {
    console.error(e);
    yield put(uploadDataProtectionFileFailed());
  }
}

function* uploadCertificateFileWorker(action: ReturnType<typeof uploadCertificateFileRequest>) {
  try {
    const file = action.payload;
    const newFormData = new FormData();
    newFormData.append('file', file);
    const result = yield callPostPlain(api.registration.certificate, newFormData);
    if (result) {
      yield put(uploadCertificateFileSuccess(result));
    } else {
      yield put(uploadCertificateFileFailed());
    }
  } catch (e) {
    console.error(e);
    yield put(uploadCertificateFileFailed());
  }
}

function* finalStepRegistrationWorker(action: ReturnType<typeof finalStepInitialRequest>) {
  try {
    const result = yield callGet(api.urlWithQuery(api.registration.subjects, {token: action.payload}));
    yield put(finalStepInitialSuccess(result));
  } catch(e) {
    console.error(e);
    yield put(finalStepInitialFailed());
  }
}

export function* registration() {
  yield all([watcher()]);
}
