import { callGet } from '@curry-group/data-addons';
import { takeLatest, all, put } from 'redux-saga/effects';
import {
  fetchConnectionExportDataFailed,
  fetchConnectionExportDataRequest,
  fetchConnectionExportDataSuccess,
  fetchRequestsFailed,
  fetchRequestsRequest,
  fetchRequestsSuccess
} from '../../reducer/admin';
import api from '../../api';
import { IConferenceConnections } from '../../../model/data/connction-export';
import { downloadCsv, transformConnectionData } from '../../../helper';

function* watcher() {
  yield takeLatest(fetchConnectionExportDataRequest.type, fetchConnectionExportDataWorker);
  yield takeLatest(fetchRequestsRequest.type, fetchRequestsWorker);
}

function* fetchConnectionExportDataWorker(action: ReturnType<typeof fetchConnectionExportDataRequest>) {
  try {
    const url = api.urlWithQuery(api.app.connections, { from: action.payload.dateFrom, to: action.payload.dateTo });
    const connectionData: IConferenceConnections[] = yield callGet(url);
    yield put(fetchConnectionExportDataSuccess(connectionData));

    if (connectionData) {
      downloadCsv('connections', transformConnectionData(connectionData));
    }
  } catch (e) {
    yield put(fetchConnectionExportDataFailed());
  }
}

function* fetchRequestsWorker(action: ReturnType<typeof fetchRequestsRequest>) {
  try {
    const requests = yield callGet(api.app.requests);
    yield put(fetchRequestsSuccess(requests));
  } catch (e) {
    yield put(fetchRequestsFailed());
  }
}

export function* admin() {
  yield all([watcher()]);
}
