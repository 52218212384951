import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICreateTandemRequest, IFetchTandemUsersRequest } from '../../../model/data/tandem';
import { IScoUserProfileListItem } from '../../../model/profile';

export interface ITandemState {
  working?: boolean;
  error?: boolean;
  success?: boolean;

  usersWorking?: boolean;
  users: IScoUserProfileListItem[];
}

const initialState: ITandemState = {
  users: []
};

const tandem = createSlice({
  name: 'tandem',
  initialState,
  reducers: {
    createTandemRequest: (state, action: PayloadAction<ICreateTandemRequest>) => {
      state.working = true;
      state.error = false;
      state.success = false;

      state.usersWorking = false;
      state.users = [];
    },
    createTandemFailed: state => {
      state.working = false;
      state.error = true;
      state.success = false;

      state.usersWorking = false;
      state.users = [];
    },
    createTandemSuccess: state => {
      state.working = false;
      state.error = false;
      state.success = true;

      state.usersWorking = false;
      state.users = [];
    },
    fetchTandemUsersRequest: (state, action: PayloadAction<IFetchTandemUsersRequest>) => {
      state.users = [];
      state.usersWorking = true;
    },
    fetchTandemUsersSuccess: (state, action: PayloadAction<IScoUserProfileListItem[]>) => {
      state.users = action.payload;
      state.usersWorking = false;
    },
    fetchTandemUsersFailed: state => {
      state.users = [];
      state.usersWorking = false;
    }
  }
});

export const { createTandemFailed, createTandemRequest, createTandemSuccess, fetchTandemUsersFailed, fetchTandemUsersRequest, fetchTandemUsersSuccess } = tandem.actions;
export default tandem.reducer;
