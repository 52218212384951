import { INotification } from '../ryve/notification';
import { ThingTypes, TypesProps } from '../common/thingTypes';

export function resolveNotificationMessage(notification: INotification) {
  let notificators: any[] = [];

  if (notification.content?.notificatorsResolved) {
    notificators = notification.content?.notificatorsResolved.map(n => ({ ...n, timestamp: notification.content?.notificatorTimestamps?.[n._id] }));
    notificators.sort((a, b) => a.timestamp - b.timestamp);
  }

  const notificatorNames = notificators.map(n => n.content?.fullName);
  let notificatorString = '';
  switch (notificatorNames.length) {
    case 0: {
      notificatorString += 'Niemand';
      break;
    }
    case 1: {
      notificatorString += notificatorNames[0];
      break;
    }
    case 2: {
      notificatorString += notificatorNames[0] + ' und ' + notificatorNames[1];
      break;
    }
    case 3: {
      notificatorString += notificatorNames[0] + ', ' + notificatorNames[1] + ' und ' + notificatorNames[2];
      break;
    }
    default: {
      notificatorString += notificatorNames[0] + ', ' + notificatorNames[1] + ' und ' + (notificatorNames.length - 2) + ' weitere';
    }
  }

  const itemResolved = notification.content.itemRefResolved;
  let typeId: string | undefined = undefined;
  let itemTitle: string = 'Unbekannt';
  let typeString: string | undefined = undefined;
  let notificationType = notification.content?.notificationType;
  if (itemResolved) {
    typeId = itemResolved.type;
    itemTitle = itemResolved.content?.title;
    typeString = TypesProps[typeId]?.Name;
  }

  const multi = notificatorNames.length > 1 ? true : false;
  const verbString = multi ? 'haben' : 'hat';
  let prepositionString = '';
  let secondVerbString = '';
  let subjectsString = '';

  if (typeId === ThingTypes.TANDEM || typeId === ThingTypes.ADMINCHAT) {
    if (notificationType === 'conference-started') {
      return notificatorString + ' hat eine Konferenz gestartet.';
    } else if (notificationType === 'create') {
      return notificatorString + ' hat Ihnen neue Nachrichten geschrieben.';
    } else if (notificationType === 'tandem-created') {
      return 'Sie wurden einem Tandem zugewiesen';
    }
  }

  return (
    notificatorString + ' ' + verbString + ' ' + prepositionString + ' ' + typeString + (itemTitle ? ' "' + itemTitle + '" ' : ' ') + subjectsString + ' ' + secondVerbString
  );
}
