import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';

import { INotification } from '../../../model/ryve/notification';
import { Header, Content } from '../../../components/frame';
import { Btn } from '@curry-group/mui-curcuma';
import { ListWithHeadline } from '../../../components/list';
import { NotificationListItem } from '../../../components/list/item/notification';

import { ConnectedHeader } from '../header';
import { Hidden } from '@material-ui/core';
import { useIsMobile } from '../../../helper/hooks/useIsMobile';
import { BaseListItemEmpty, BaseListItemSkeleton } from '../../../components/list/item';
import { fetchNotificationsRequest, setNotificationsRead } from '../../../data/reducer/notifications';
import { FramedContent } from '../../../components/framed-content';

export const NotificationsView: React.FC = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { notifications, working } = useSelector(state => state.notifications);

  function notificationSelected(notification: INotification) {
    dispatch(setNotificationsRead({ notifications: [notification._id] }));
  }

  function markAllAsRead() {
    if (notifications?.length) {
      dispatch(setNotificationsRead({ notifications: notifications.map(n => n._id) }));
    }
  }

  useEffect(() => {
    dispatch(fetchNotificationsRequest());
  }, [dispatch]);

  const markAllCaption = isMobile ? 'Alle gelesen' : 'Alle als gelesen markieren';

  return (
    <>
      <Header>
        <ConnectedHeader />
      </Header>
      <Content>
        <FramedContent>
          <ListWithHeadline<INotification>
            items={notifications}
            headline="Aktuelle Benachrichtigungen"
            endAdornment={
              !!notifications?.length ? (
                <Btn onClick={markAllAsRead} variant="text" size="large">
                  <Hidden mdUp>{markAllCaption}</Hidden>
                  <Hidden smDown>{markAllCaption}</Hidden>
                </Btn>
              ) : undefined
            }
            renderItem={notification => <NotificationListItem notification={notification} key={notification._id} selected={() => notificationSelected(notification)} />}
          >
            {(() => {
              if (working) return _.range(5).map(i => <BaseListItemSkeleton key={i} />);
              if (!notifications?.length) {
                return <BaseListItemEmpty title="Keine Benachrichtigungen" />;
              }
            })()}
          </ListWithHeadline>
        </FramedContent>
      </Content>
    </>
  );
};
