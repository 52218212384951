import { Bx, IBxProps } from '@curry-group/mui-curcuma';
import React, { PropsWithChildren, ReactNode } from 'react';
import { IHasItems, IItemRenderer, List } from '../list';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export interface IListSideBarProps<T> extends IHasItems<T> {
  working?: boolean;
}

export interface IListSideBarDispatch<T> extends IItemRenderer<T> {
  top?: ReactNode;
}

export function ListSidebar<T>({ children, working, items, renderItem, top }: PropsWithChildren<IListSideBarProps<T> & IListSideBarDispatch<T>>) {
  return (
    <SideBar>
      <List<T> top={top} items={items} renderItem={renderItem}>
        {working && children}
      </List>
    </SideBar>
  );
}

export const SidebarWrapper: React.FC = ({ children }) => (
  <Bx>
    <Bx position="sticky" top="0" zIndex={3}>
      <Bx height="100vh">{children}</Bx>
    </Bx>
  </Bx>
);

export interface ISideBarProps extends IBxProps {}

export interface ISideBarThemeExtension {
  sideBar: {
    width?: string | number;
    backgroundColor?: string;
    boxShadow?: string;
    scrollBarTrackBackgroundColor?: string;
    scrollBarThumbBackgroundColor?: string;
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    width: 342,
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.15)',
    overflowX: 'hidden',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 12
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#DDDFE0'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}));

/**
 * The `SideBar` component is an extension of the [Material-UI Box](https://material-ui.com/components/box/) and inherits all properties of it.
 */
export const SideBar: React.FunctionComponent<ISideBarProps> = ({ className, children, ...rest }) => {
  const classes = useStyles();

  return (
    <Bx className={clsx(classes.root, className)} {...rest}>
      {children}
    </Bx>
  );
};
