import React from 'react';
import { Bx, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import { ICategoryItem } from '../../../../../model/ryve/category';
import { FormCategorySelect } from '../../../../../components/form/control/category-select';
import { buildCategoryDictionary } from '../../../../../helper';
import { FramedContent } from '../../../../../components/framed-content';

export interface IRegistrationLanguageProps {
  languageOptions: ICategoryItem[];

  languages: ICategoryItem[];
  setLanguages: (languages: ICategoryItem[]) => void;

  onValidated: (valid: boolean) => void;
}

export const RegistrationLanguage: React.FC<IRegistrationLanguageProps> = ({
  languageOptions,
  languages,
  setLanguages,
  onValidated
}) => {
  function validate() {
    onValidated(languages.length > 0);
  }

  function handleChange(value: string, setValueFunction: (languages: ICategoryItem[]) => void){
    if(languages.filter(r => r._id == value).length > 0){
      var selectedRemove = languages.find(r => r._id == value);
      if(selectedRemove){
        var idx = 0;
        for (let i = 0; i < languages.length; i++) {
          if(languages[i]._id == selectedRemove._id) idx = i;
        }
        languages.splice(languages.indexOf(selectedRemove), 1);
      }
    }else{
      var selectedAdd = languageOptions.filter(s => s._id == value)[0];
      if(selectedAdd){
        languages.push(selectedAdd);
      }
    }
    setValueFunction(languages);
  }
  validate();
  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Registrierung auf Schüler Connect Online
        </Typo>
      </Bx>
      <Bx>
        <Typo variant="subtitle1">Bitte tragen Sie Sprachen ein, die Sie fließend sprechen</Typo>
      </Bx>
      <Bx my={8}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormCategorySelect 
              value={languages.map(s => s._id)}
              categoryTree={languageOptions}
              categoryRepository={buildCategoryDictionary(languageOptions)}
              label={'Sprachen wählen'}
              placeholder={'Sie haben noch keine Sprache gewählt'}
              placeholderSearch={'Sprache finden'}
              buttonTitle={'Sprache hinzufügen'}
              minLength={0}
              maxLength={0}
              onChange={item => {
                handleChange(item, setLanguages);
              }}
              />
            </Bx>
          </Grid>
        </Grid>
      </Bx>
    </FramedContent>
  );
};
