import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITandemDetail } from '../../../model/data/tandem';
import { ContentDetail } from '../../../model/data/content';
export interface IDetailState {
  item?: any | null;
  error?: boolean;
  working?: boolean;
}

const initialState: IDetailState = {};

const detail = createSlice({
  name: 'detail',
  initialState,
  reducers: {
    fetchTandemRequest: (state, action: PayloadAction<string>) => {
      state.working = true;
      state.error = false;
      state.item = null;
    },
    fetchTandemFailed: state => {
      state.working = false;
      state.error = true;
      state.item = null;
    },
    fetchTandemSuccess: (state, action: PayloadAction<ITandemDetail>) => {
      state.working = false;
      state.error = false;
      state.item = action.payload;
    },
    fetchContentRequest: (state, action: PayloadAction<string>) => {
      state.working = true;
      state.error = false;
      state.item = null;
    },
    fetchContentFailed: state => {
      state.working = false;
      state.error = true;
      state.item = null;
    },
    fetchContentSuccess: (state, action: PayloadAction<ContentDetail>) => {
      state.working = false;
      state.error = false;
      state.item = action.payload;
    }
  }
});

export const { fetchTandemFailed, fetchTandemRequest, fetchTandemSuccess, fetchContentFailed, fetchContentRequest, fetchContentSuccess } = detail.actions;
export default detail.reducer;
