import { Btn, Bx } from '@curry-group/mui-curcuma';
import React, { useEffect } from 'react';

import { Header, Content } from '../../../components/frame';
import { ConnectionExport } from '../../../components/admin-dashboard/connection-export';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConnectionExportDataRequest, fetchRequestsRequest } from '../../../data/reducer/admin';
import { ConnectedHeader } from '../header';
import { ListWithHeadline } from '../../../components/list';
import { Hidden } from '@material-ui/core';
import { BaseListItemEmpty, BaseListItemSkeleton } from '../../../components/list/item';
import { IRegistrationRequestListItem } from '../../../model/data/registration';
import { RegistrationRequestListItem } from '../../../components/list/item/registration-request';
import { ThingTypes } from '../../../model/common/thingTypes';
import * as _ from 'lodash';
import { FramedContent } from '../../../components/framed-content';

export const AdminDasboardView: React.FC = () => {
  const dispatch = useDispatch();
  const { connectionExportWorking, connectionExportError, requests, requestsWorking } = useSelector(state => state.admin);

  const loadRequests = () => {
    dispatch(fetchRequestsRequest());
  };

  useEffect(() => {
    loadRequests();
  }, []);

  return (
    <>
      <Header>
        <ConnectedHeader />
      </Header>
      <Content>
        <FramedContent>
          <ConnectionExport
            working={connectionExportWorking}
            error={connectionExportError}
            onExportClicked={(dateFrom, dateTo) => {
              if (dateFrom && dateTo) {
                dispatch(fetchConnectionExportDataRequest({ dateFrom, dateTo }));
              }
            }}
          />
          <Bx mt={5}>
            <ListWithHeadline<IRegistrationRequestListItem>
              items={requests}
              headline="Registrierungsanfragen"
              endAdornment={
                !!requests?.length ? (
                  <Btn onClick={loadRequests} variant="text" size="large">
                    <Hidden mdUp>Aktualisieren</Hidden>
                    <Hidden smDown>Aktualisieren</Hidden>
                  </Btn>
                ) : undefined
              }
              renderItem={request => (
                <RegistrationRequestListItem
                  request={request}
                  key={request._id}
                  selected={() => window.open(`/ryve/objectmanager?mode=edit#${ThingTypes.REGISTER_REQUEST}!${request._id}`, '_blank')}
                />
              )}
            >
              {(() => {
                if (requestsWorking) return _.range(3).map(i => <BaseListItemSkeleton key={i} />);
                if (!requests?.length) {
                  return <BaseListItemEmpty title="Keine Anfragen" />;
                }
              })()}
            </ListWithHeadline>
          </Bx>
        </FramedContent>
      </Content>
    </>
  );
};
