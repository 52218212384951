import { Ctn, Bx, IBxProps, ICtnProps, Link, Typo, TBreakpointContextProps } from '@curry-group/mui-curcuma';
import Grid from '@material-ui/core/Grid';
import React from 'react';

export interface ILandingPageFooterProps {
  ctnMaxWidth: ICtnProps['maxWidth'];
  links: Array<{ name: string; title?: string; to?: string; onClick?: (e: React.MouseEvent) => void }>;
  logoSrc: string;
  logoAlt: string;
  partnerLogoSrc: string;
  partnerLogoAlt: string;
  sectionPx: IBxProps['px'];
  responsive: TBreakpointContextProps;
}

const ButtonLink = props => {
  return <Link {...props} />;
};

export const LandingPageFooter: React.FunctionComponent<ILandingPageFooterProps> = ({
  ctnMaxWidth,
  links,
  logoSrc,
  logoAlt,
  partnerLogoSrc,
  partnerLogoAlt,
  sectionPx,
  responsive
}) => {
  return (
    <Bx component="footer" width="100%" bgcolor="background.paper" py={5} px={sectionPx} boxShadow={3}>
      <Ctn maxWidth={ctnMaxWidth}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Bx textAlign={{ xs: 'center', sm: 'left' }} display="flex">
              <img src={logoSrc} alt={logoAlt} style={{ height: 56}} />
              <Typo variant={'body1'} style={{ paddingTop: 25 }}>
                  Schüler Connect Online
              </Typo>
            </Bx>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Bx mt={{ xs: 3, sm: 0 }} display="flex" justifyContent={{ xs: 'center', sm: 'flex-end' }}>
              <Bx mr={2} position="relative" top={-5}>
                <Typo variant="body2" component="span" style={{ fontSize: 12 }}>
                  Eine Initiative von
                </Typo>
              </Bx>
              <Bx>
                <img src={partnerLogoSrc} alt={partnerLogoAlt} />
              </Bx>
            </Bx>
          </Grid>
          <Grid item xs={12}>
            <Bx display="flex" justifyContent="center" flexWrap="wrap" mt={3} style={{ fontSize: 12 }}>
              {links.length > 0 &&
                links.map((link, idx) => {
                  return (
                    <Bx
                      key={idx}
                      my={0.5}
                      mr={idx === 0 ? { xs: 0, sm: 3 } : undefined}
                      mx={idx !== 0 && idx !== links.length ? { xs: 0, sm: 3 } : undefined}
                      ml={idx === links.length ? { xs: 0, sm: 3 } : undefined}
                      style={{ width: responsive.xs ? '100%' : 'auto' }}
                      textAlign={{ xs: 'center', sm: 'left' }}
                    >
                      {link.to && (
                        <Link to={link.to} title={link.title} underline="hover">
                          {link.name}
                        </Link>
                      )}
                      {!link.to && link.onClick && (
                        <ButtonLink component="button" onClick={link.onClick} title={link.title} underline="hover">
                          {link.name}
                        </ButtonLink>
                      )}
                    </Bx>
                  );
                })}
            </Bx>
          </Grid>
        </Grid>
      </Ctn>
    </Bx>
  );
};
