import { createSlice, PayloadAction, PayloadActionCreator } from '@reduxjs/toolkit';

import { IRegistrationRequestData, IRegistrationFinalizeRequestData, TProfileType } from '../../../model/data/registration';
import { ICategoryItem } from '../../../model/ryve/category';

export interface IRegistrationFinalStepData {
  type: TProfileType;
  firstName: string;
  lastName: string;
  subjectCategories: ICategoryItem[];
  languageCategories: ICategoryItem[];
}

interface IRegistrationState {
  registrationInitSuccess?: boolean;
  registrationInitWorking?: boolean;
  registrationInitFailed?: boolean;

  registrationSuccess?: boolean;
  registrationWorking?: boolean;
  registrationFailed?: boolean;

  registrationFinalizeSuccess?: boolean;
  registrationFinalizeWorking?: boolean;
  registrationFinalizeFailed?: boolean;

  dataProtectionTempFileName?: string;
  dataProtectionPreparing?: boolean;
  dataProtectionFailed?: boolean;

  certificateTempFileName?: string;
  certificatePreparing?: boolean;
  certificateFailed?: boolean;

  finalStepSuccess?: IRegistrationFinalStepData;
  finalStepWorking?: boolean;
  finalStepFailed?: boolean;
}

const initialState: IRegistrationState = {};

const registration = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    uploadDataProtectionFileRequest: (state, action: PayloadAction<File>) => {
      state.dataProtectionTempFileName = undefined;
      state.dataProtectionPreparing = true;
      state.dataProtectionFailed = false;
    },
    uploadDataProtectionFileFailed: state => {
      state.dataProtectionTempFileName = undefined;
      state.dataProtectionPreparing = false;
      state.dataProtectionFailed = true;
    },
    uploadDataProtectionFileSuccess: (state, action: PayloadAction<string>) => {
      state.dataProtectionTempFileName = action.payload;
      state.dataProtectionPreparing = false;
      state.dataProtectionFailed = false;
    },
    uploadDataProtectionFileRemoved: state => {
      state.dataProtectionTempFileName = undefined;
      state.dataProtectionPreparing = false;
      state.dataProtectionFailed = false;
    },
    uploadCertificateFileRequest: (state, action: PayloadAction<File>) => {
      state.certificateTempFileName = undefined;
      state.certificatePreparing = true;
      state.certificateFailed = false;
    },
    uploadCertificateFileFailed: state => {
      state.certificateTempFileName = undefined;
      state.certificatePreparing = false;
      state.certificateFailed = true;
    },
    uploadCertificateFileSuccess: (state, action: PayloadAction<string>) => {
      state.certificateTempFileName = action.payload;
      state.certificatePreparing = false;
      state.certificateFailed = false;
    },
    uploadCertificateFileRemoved: state => {
      state.certificateTempFileName = undefined;
      state.certificatePreparing = false;
      state.certificateFailed = false;
    },
    resetRegistration: state => {
      state.registrationSuccess = false;
      state.registrationWorking = false;
      state.registrationFailed = false;

      state.registrationFinalizeSuccess = false;
      state.registrationFinalizeWorking = false;
      state.registrationFinalizeFailed = false;

      state.registrationInitSuccess = false;
      state.registrationInitWorking = false;
      state.registrationInitFailed = false;

      state.dataProtectionTempFileName = undefined;
      state.dataProtectionPreparing = false;
      state.dataProtectionFailed = false;

      state.certificateTempFileName = undefined;
      state.certificatePreparing = false;
      state.certificateFailed = false;
    },
    initRegistrationRequest: (state, action: PayloadAction<string>) => {
      state.registrationInitSuccess = false;
      state.registrationInitWorking = true;
      state.registrationInitFailed = false;
    },
    initRegistrationSuccess: state => {
      state.registrationInitSuccess = true;
      state.registrationInitWorking = false;
      state.registrationInitFailed = false;
    },
    initRegistrationFailed: state => {
      state.registrationInitSuccess = false;
      state.registrationInitWorking = false;
      state.registrationInitFailed = true;
    },
    sendRegistrationRequest: (state, payload: PayloadAction<IRegistrationRequestData>) => {
      state.registrationSuccess = false;
      state.registrationWorking = true;
      state.registrationFailed = false;
    },
    sendRegistrationSuccess: state => {
      state.registrationSuccess = true;
      state.registrationWorking = false;
      state.registrationFailed = false;
    },
    sendRegistrationFailed: state => {
      state.registrationSuccess = false;
      state.registrationWorking = false;
      state.registrationFailed = true;
    },
    finalizeRegistrationRequest: (state, payload: PayloadAction<IRegistrationFinalizeRequestData>) => {
      state.registrationFinalizeSuccess = false;
      state.registrationFinalizeWorking = true;
      state.registrationFinalizeFailed = false;
    },
    finalizeRegistrationSuccess: state => {
      state.registrationFinalizeSuccess = true;
      state.registrationFinalizeWorking = false;
      state.registrationFinalizeFailed = false;
    },
    finalizeRegistrationFailed: state => {
      state.registrationFinalizeSuccess = false;
      state.registrationFinalizeWorking = false;
      state.registrationFinalizeFailed = true;
    },
    finalStepInitialRequest: (state, action: PayloadAction<string>) => {
      state.finalStepSuccess = undefined;
      state.finalStepWorking = true;
      state.finalStepFailed = false;
    },
    finalStepInitialSuccess: (state, action: PayloadAction<IRegistrationFinalStepData>) => {
      state.finalStepSuccess = action.payload;
      state.finalStepWorking = false;
      state.finalStepFailed = false;
    },
    finalStepInitialFailed: state => {
      state.finalStepSuccess = undefined;
      state.finalStepWorking = false;
      state.finalStepFailed = true;
    }
  }
});

export const {
  resetRegistration,
  uploadCertificateFileFailed,
  uploadCertificateFileRequest,
  uploadCertificateFileSuccess,
  uploadCertificateFileRemoved,
  uploadDataProtectionFileFailed,
  uploadDataProtectionFileRequest,
  uploadDataProtectionFileSuccess,
  uploadDataProtectionFileRemoved,
  initRegistrationFailed,
  initRegistrationRequest,
  initRegistrationSuccess,
  sendRegistrationRequest,
  sendRegistrationSuccess,
  sendRegistrationFailed,
  finalizeRegistrationRequest,
  finalizeRegistrationSuccess,
  finalizeRegistrationFailed,
  finalStepInitialRequest,
  finalStepInitialFailed,
  finalStepInitialSuccess
} = registration.actions;
export default registration.reducer;
