import React from 'react';
import { Bx, Ctn, ICtnProps, Typo } from '@curry-group/mui-curcuma';
import Grid from '@material-ui/core/Grid';
import { ClassNameMap } from '@material-ui/styles';
interface ILandingPageTextSectionProps {
    ctnMaxWidth: ICtnProps['maxWidth'];
    responsive: { isXS: boolean };
    headline: string;
    h1FontSize: number;
    body4FontSize: number;
    imageSrc: string;
    imageAlt: string;
    classes: ClassNameMap<"line" | "boldText">;
}

export const LandingPageTextSection: React.FunctionComponent<ILandingPageTextSectionProps> = ({ ctnMaxWidth, responsive, headline, h1FontSize, body4FontSize, imageSrc, imageAlt, classes }) => {
    return(
        <Bx component="section" width="100%" className={classes.boldText}>
            <Ctn maxWidth={ctnMaxWidth}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Bx width="100%" flexDirection={responsive.isXS ? "column" : "row"} display="flex" justifyContent="space-between" alignItems="center">
                            <Bx width={responsive.isXS ? "100%" : "300px"}>
                                <Typo variant="h1" style={{fontSize: h1FontSize}} className={classes.line} dangerouslySetInnerHTML={{__html: headline}} />
                            </Bx>
                            <img src={imageSrc} alt={imageAlt} style={{width: `${responsive.isXS ? "100%" : "auto"}`, height: `${responsive.isXS ? "auto" : "200px"}`}} />
                        </Bx>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typo variant="body1" style={{fontSize: body4FontSize}} gutterBottom>
                        Das Projekt  „Schüler-connect-online“ setzt an bei den neuen Fragen, Bedarfen und Möglichkeiten, die sich  in puncto sozialer Begegnung und Schulwissen in der Corona Zeit ergeben haben.
                        </Typo>
                        <Typo variant="body1" style={{fontSize: body4FontSize}} gutterBottom>
                            Peer Group Unterstützung auf Augenhöhe, von der beide Seiten profitieren. <br></br>
                            SchülerInnen höherer Klassen und Studierende unterstützen als Mentor*Innen mit fachlicher Hilfe aber auch als „best buddy“, wenn es beim Mentee bei Hausaufgaben oder anderen schulischen Problemen nicht weitergeht. Sowohl an kurze spontane Schulhilfen ist hier gedacht wie auch an klassischen Nachhilfeunterricht.
                        </Typo>
                        <Typo variant="body1" style={{fontSize: body4FontSize}} gutterBottom>
                            Das Angebot ist für Mentees komplett kostenlos und unsere Mentor*innen werden durch eine Ehrenamtspauschale für Ihren Aufwand kompensiert.
                        </Typo>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typo variant="body1" style={{fontSize: body4FontSize}} gutterBottom>
                            Du Registrierts dich entweder als Mentor*in oder Mentee auf Schüler Connect Online.
                        </Typo>
                        <Typo variant="body1" style={{fontSize: body4FontSize}} gutterBottom>
                            Die Tandems werden dann nach Kontaktaufnahme mit dem Orga-Team miteinander verbunden und begleitet. Die Kommunikation läuft per Chat, Video oder Audio Treffen. Da auf Datensicherheit und Schutz aller Beteiligten großen Wert gelegt wird, gibt es diese geschützte Onlineplattform. Wir legen daher einen großen wert auf die Qualität und die Verifizierung unserer Mentor*innen.
                        </Typo>
                        <Typo variant="body1" style={{fontSize: body4FontSize}} gutterBottom>
                            Die Tandems treffen eigenständig die Entscheidung wann und wie häufig sie sich treffen. <br></br>
                            Natürlich müssen  Erziehungsberechtigte bei Teilnehmenden unter 18 Jahre ihre Zustimmung geben.
                        </Typo>
                    </Grid>
                </Grid>
            </Ctn>
        </Bx>
    );
}